var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-col",
    { attrs: { id: "compartir-red-domicilio-id", col: "", cols: "12" } },
    [
      _c(
        "h3",
        { staticClass: "m-h3", attrs: { "v-text": _vm.$t("compartir.title") } },
        [_vm._v("Compartir por red Rizoma")]
      ),
      _vm._v(" "),
      _c(
        "b-alert",
        {
          attrs: {
            show: "",
            variant: "primary",
            "v-text": _vm.$t("compartir.alert"),
          },
        },
        [
          _vm._v(
            "\n    Indique si la información ingresada previamente puede ser vizualizada por la red Rizoma\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-form-checkbox",
        {
          staticClass: "mt-5",
          attrs: {
            value: true,
            "unchecked-value": false,
            disabled: _vm.readonly,
          },
          model: {
            value: _vm.text,
            callback: function ($$v) {
              _vm.text = $$v
            },
            expression: "text",
          },
        },
        [
          _c("span", { staticClass: "label" }, [
            _vm._v(" " + _vm._s(_vm.$t("compartir.label")) + " "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }