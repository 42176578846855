import { TipoBecaBext } from '@/shared/perfil-usuario/api';

export interface IBasicRubro {
  opciones?: string[];
  disabled?: boolean | null;
}

export interface IRubroAtributos {
  conceptoConfig?: IBasicRubro | null;
  periodoConfig?: IBasicRubro | null;
  monedaConfig?: IBasicRubro | null;
  montoConfig?: IBasicRubro | null;
  tipoBecaExtranjero?: string | null;
  tipoBeca?: TipoBecaBext | null;
}

export class RubroAtributos implements IRubroAtributos {
  constructor(
    public conceptoConfig?: IBasicRubro | null,
    public periodoConfig?: IBasicRubro | null,
    public monedaConfig?: IBasicRubro | null,
    public montoConfig?: IBasicRubro | null,
    public tipoBecaExtranjero?: string | null,
    public tipoBeca?: TipoBecaBext | null
  ) {
    // Inicializando los arrays como arrays vacíos
    this.conceptoConfig.opciones = this.conceptoConfig.opciones ? this.conceptoConfig.opciones : [];
    this.periodoConfig.opciones = this.periodoConfig.opciones ? this.periodoConfig.opciones : [];
    this.monedaConfig.opciones = this.monedaConfig.opciones ? this.monedaConfig.opciones : [];
    this.montoConfig.opciones = this.montoConfig.opciones ? this.montoConfig.opciones : [];
  }
}
