import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import PropertyCommons from "../commons/property.model";

@Component
export default class CompartirRedComponent extends mixins(PropertyCommons) {
  @Prop({ required: true })
  public value!: boolean;

  get text(): boolean {
    return this.value;
  }

  set text(newValue: boolean) {
    this.$emit('input', newValue);
  }
}
