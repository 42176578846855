import Component, { mixins } from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import PropertyCommons from '../commons/property.model';
import LoginService from '@/account/login.service';
import SocialService from '@/components/social/social.service';
@Component
export default class SocialComponent extends mixins(PropertyCommons) {
  columnas: any = [{ key: 'account', label: '' }];

  @Inject('loginService')
  private loginService: () => LoginService;

  @Inject('socialService')
  private socialService: () => SocialService;

  public items = [];
  public loading = false;
  created(): void {
    this.loadPerfil();
  }

  mounted(): void {}

  public loadPerfil() {
    this.loading = true;
    this.socialService()
      .timeline()
      .then(value => {
        this.items = value.data;
        this.loading = false;
      });
  }
  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public get username(): string {
    return this.$store.getters.account?.login ?? '';
  }
}
