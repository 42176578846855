import { Component, Inject, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';

import CatalogoService from '@/shared/catalogo/catalogo.service';
import { required } from 'vuelidate/lib/validators';

const VALIDATIONS = function () {
  return {
    scian: {
      sector: {
        id: {},
        nombre: {},
        required,
      },
      subsector: {
        id: {},
        nombre: {},
        required,
      },
      rama: {
        id: {},
        nombre: {},
        required,
      },
      subrama: {
        id: {},
        nombre: {},
        required,
      },
      clase: {
        id: {},
        nombre: {},
        required,
      },
    },
  };
};

@Component({
  validations: VALIDATIONS,
})
export default class ScianComponent extends Vue {
  @Inject('catalogoService') private catalogoService: () => CatalogoService;

  @PropSync('value', { required: true, type: Object })
  public scian;

  @Prop({ default: false })
  public readonly: boolean;

  @Prop({ default: null })
  public edit: any;

  @Prop({ default: null })
  public validComponent: boolean;
  public showScian = false;
  public busySubsector = false;
  public busyRama = false;
  public busySubRama = false;
  public busyClase = false;

  /**
   *validComponent formulario desde el componente (padre)
   *
   */
  @Watch('validComponent', { immediate: true, deep: true })
  handler(value: any) {
    if (value) {
      this.$v.scian.$touch();
    } else {
      this.$v.scian.$reset();
    }
  }

  public sectorScianOptions: Array<any> = [];
  public subSectorScianOptions: Array<any> = [];
  public ramaScianOptions: Array<any> = [];
  public subRamaScianOptions: Array<any> = [];
  public claseScianOptions: Array<any> = [];

  public sectorScianLectura = false;
  public subSectorScianLectura = true;
  public ramaScianLectura = true;
  public subRamaScianLectura = true;
  public claseScianLectura = true;

  public catalogoServiceName = {
    getSectoresScian: 'getScianSectores',
    getSubSectorScian: 'getScianDivisionesScianSectores',
    getRamaScian: 'getScianGruposScianDivisiones',
    getSubRamaScian: 'getScianClasesScianGrupos',
    getClaseScian: 'getScianSubclasesScianClases',
  };

  mounted(): void {
    if (this.edit != null) {
      if (this.scian?.sector?.id != null) {
        this.setDataScian();
      }
      this.soloLectura(this.readonly);
    }
    this.initCatalogos();
  }

  public initCatalogos(): void {
    this.catalogoService()
      .get(this.catalogoServiceName.getSectoresScian)
      .then(result => {
        this.sectorScianOptions = result;
      });
  }

  private limpiaCatalogo(catalogo: any): any[] {
    if (catalogo.length && catalogo[0].value) {
      const propiedades = Object.getOwnPropertyNames(catalogo[0].value).filter(p => p != 'id' && p != 'clave' && p != 'nombre');
      catalogo.forEach(c => {
        if (c.value) {
          propiedades.forEach(p => delete c.value[p]);
        }
      });
    }
    return catalogo;
  }

  public setDataScian() {
    this.showScian = true;
    this.onSectorScianChanged(this.scian.sector, false)
      .then(() => {
        this.onSubSectorScianChanged(this.scian.subsector, false)
          .then(() => {
            this.onRamaScianChanged(this.scian.rama, false)
              .then(() => {
                this.onSubRamaScianChanged(this.scian.subrama, false);
                this.showScian = false;
              })
              .catch(() => {
                this.showScian = false;
              });
          })
          .catch(() => {
            this.showScian = false;
          });
      })
      .catch(() => {
        this.showScian = false;
      });
  }

  public async onSectorScianChanged(e: any, deleteModel: boolean): Promise<void> {
    this.busySubsector = true;
    if (e) {
      return this.catalogoService()
        .get(this.catalogoServiceName.getSubSectorScian, e.id)
        .then(result => {
          // Setea los catalogos siguientes
          this.subSectorScianOptions = this.limpiaCatalogo(result);
          this.ramaScianOptions = [];
          this.subRamaScianOptions = [];
          this.claseScianOptions = [];

          // habilita y deshabilita campos
          this.subSectorScianLectura = false;
          this.ramaScianLectura = true;
          this.subRamaScianLectura = true;
          this.claseScianLectura = true;

          // Setea null los modelos siguientes
          if (deleteModel) {
            this.scian.subsector = null;
            this.scian.rama = null;
            this.scian.subrama = null;
            this.scian.clase = null;
          }

          //busy
          this.busySubsector = false;

          Promise.resolve();
        });
    } else {
      return Promise.resolve();
    }
  }

  public async onSubSectorScianChanged(e: any, deleteModel: boolean): Promise<void> {
    this.busyRama = true;
    if (e) {
      return this.catalogoService()
        .get(this.catalogoServiceName.getRamaScian, e.id)
        .then(result => {
          // Setea los catalogos siguientes
          this.ramaScianOptions = this.limpiaCatalogo(result);
          this.subRamaScianOptions = [];
          this.claseScianOptions = [];

          // habilita y deshabilita campos
          this.ramaScianLectura = false;
          this.subRamaScianLectura = true;
          this.claseScianLectura = true;

          // Setea null los modelos siguientes
          if (deleteModel) {
            this.scian.rama = null;
            this.scian.subrama = null;
            this.scian.clase = null;
          }

          //busy
          this.busyRama = false;

          Promise.resolve();
        });
    } else {
      return Promise.resolve();
    }
  }

  public async onRamaScianChanged(e: any, deleteModel: boolean): Promise<void> {
    this.busySubRama = true;
    if (e) {
      return this.catalogoService()
        .get(this.catalogoServiceName.getSubRamaScian, e.id)
        .then(result => {
          // Setea los catalogos siguientes
          this.subRamaScianOptions = this.limpiaCatalogo(result);
          this.claseScianOptions = [];

          // habilita y deshabilita campos
          this.subRamaScianLectura = false;
          this.claseScianLectura = true;

          // Setea null los modelos siguientes
          if (deleteModel) {
            this.scian.subrama = null;
            this.scian.clase = null;
          }

          //busy
          this.busySubRama = false;

          Promise.resolve();
        });
    } else {
      return Promise.resolve();
    }
  }

  public async onSubRamaScianChanged(e: any, deleteModel: boolean): Promise<void> {
    this.busyClase = true;
    if (e) {
      return this.catalogoService()
        .get(this.catalogoServiceName.getClaseScian, e.id)
        .then(result => {
          // Setea los catalogos siguientes
          this.claseScianOptions = this.limpiaCatalogo(result);

          // habilita y deshabilita campos
          this.edit != null ? this.soloLectura(this.readonly) : (this.claseScianLectura = false);

          // Setea null los modelos siguientes
          if (deleteModel) {
            this.scian.clase = null;
          }

          //busy
          this.busyClase = false;
          Promise.resolve();
        });
    } else {
      return Promise.resolve();
    }
  }

  public soloLectura(val: boolean) {
    this.sectorScianLectura = val;
    this.subSectorScianLectura = val;
    this.ramaScianLectura = val;
    this.subRamaScianLectura = val;
    this.claseScianLectura = val;
  }
}
