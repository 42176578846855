export interface IInst {
  id: number;
  nombre: string;
  tipo: {
    id: string;
    nombre: string;
  };
  pais: {
    id: string;
    nombre: string;
  };
  clave?: string;
  entidad?: {
    clave: string;
    nombre: string;
  };
  municipio?: {
    clave: string;
    nombre: string;
  };
  nivelUno?: {
    id: string;
    nombre: string;
  };
  nivelDos?: {
    id: string;
    nombre: string;
  };
}

export class InstitucionModel implements IInst {
  constructor(
    public id: number,
    public nombre: string,
    public tipo: {
      id: string;
      nombre: string;
    },
    public pais: {
      id: string;
      nombre: string;
    },
    public clave?: string,
    public entidad?: {
      clave: string;
      nombre: string;
    },
    public municipio?: {
      clave: string;
      nombre: string;
    },
    public nivelUno?: {
      id: string;
      nombre: string;
    },
    public nivelDos?: {
      id: string;
      nombre: string;
    }
  ) {
    this.id = this.id ? this.id : null;
    this.clave = this.clave ? this.clave : null;
    this.nombre = this.nombre ? this.nombre : null;
    this.tipo = this.tipo ? this.tipo : null;
    this.pais = this.pais ? this.pais : null;
    this.entidad = this.entidad ? this.entidad : null;
    this.municipio = this.municipio ? this.municipio : null;
    this.nivelUno = this.nivelUno ? this.nivelUno : null;
    this.nivelDos = this.nivelDos ? this.nivelDos : null;
  }
}
