import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';

@Component
export default class SaveModalComponent extends Vue {
  @Prop({ default: 'saveEntity' })
  id: string;

  @Prop({ default: false })
  public productoPrincipal: boolean;

  @Prop({ default: 0 })
  public totalPrincipal: number;

  @Prop({ default: 9 })
  public totalReferenciaPrincipal: number;

  public closeModal(): void {
    this.$emit('regresaValor');
    this.$bvModal.hide(this.id);
  }

  public remove(): void {
    this.$emit('onDelete');
  }

  public actualizaPrincipal(): void {
    this.$emit('guardar');
  }
}
