var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "mb-4" },
    [
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _vm.isVisible
            ? _c("b-alert", { attrs: { show: "", variant: "success" } }, [
                _c("p", {
                  staticClass: "text-justify",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("total-documentos.difusion", {
                        max: _vm.totalReferencia,
                        maxPrincipal: _vm.totalReferenciaPrincipal,
                      })
                    ),
                  },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("b-alert", { attrs: { show: "" } }, [
            _c("span", {
              domProps: {
                innerHTML: _vm._s(_vm.$t("total-documentos.contabilice")),
              },
            }),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isVisible
        ? _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c("strong", [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(_vm.$t("total-documentos.documentos")),
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "b-progress",
                {
                  attrs: {
                    max: _vm.totalReferencia,
                    variant: "primary",
                    height: "1rem",
                  },
                },
                [
                  _c(
                    "b-progress-bar",
                    { attrs: { value: _vm.totalDocumentos } },
                    [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.totalDocumentos) +
                            " / " +
                            _vm._s(_vm.totalReferencia)
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "6" } },
        [
          _c("strong", [
            _c(
              "span",
              {
                domProps: {
                  textContent: _vm._s(_vm.$t("total-documentos.destacado")),
                },
              },
              [_vm._v("Productos destacados")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "b-progress",
            {
              attrs: {
                max: _vm.totalReferenciaPrincipal,
                variant: "primary",
                height: "1rem",
              },
            },
            [
              _c("b-progress-bar", { attrs: { value: _vm.totalPrincipal } }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(_vm.totalPrincipal) +
                      " / " +
                      _vm._s(_vm.totalReferenciaPrincipal)
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }