import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { helpers, requiredIf } from 'vuelidate/lib/validators';
const URL = helpers.regex('URL', /^(https?:\/\/)?(www\.)?[a-zA-Z0-9@:%._\+~#=~-]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/);

const VALIDATIONS = function () {
  return {
    citas: {
      urlCita: {
        URL,
        required: requiredIf(() => this.required),
      },
      citaA: {
        required: requiredIf(() => this.required),
      },
      citaB: {
        required: requiredIf(() => this.required),
      },
      totalCitas: {},
    },
  };
};

@Component({
  validations: VALIDATIONS,
})
export default class CitasComponent extends Vue {
  @PropSync('value', { required: true, type: Object })
  public citas;

  @Prop({ default: false })
  public readonly: boolean;

  @Prop({ default: null })
  public validComponent: boolean; //validacion desde el padre

  @Prop({ default: false })
  public required: boolean; // Solo vista para poner *

  public validComponentInt = false; // Inicialización por defecto

  created() {
    this.validComponentInt = this.validComponent;
  }

  @Watch('validComponent', { immediate: true, deep: true })
  handler(value: boolean) {
    if (this.required) {
      //Ocupa ser requerido para validar
      if (value) {
        this.$v.citas.$touch();
      } else {
        this.$v.citas.$reset();
      }
    }
  }

  public urlLectura = false;
  public citaALectura = false;
  public citaBLectura = false;
  public totalLectura = true;

  mounted() {
    this.soloLectura(this.readonly);
  }

  sumaCita() {
    this.citas.citaA = Number(this.citas.citaA);
    this.citas.citaB = Number(this.citas.citaB);
    this.citas.totalCitas = this.citas.citaA + this.citas.citaB;
  }

  numbersOnly(evt) {
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault();
    } else {
      return true;
    }
  }

  public soloLectura(val: boolean) {
    this.urlLectura = val;
    this.citaALectura = val;
    this.citaBLectura = val;
  }
}
