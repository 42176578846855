import { Component, Inject, Ref, Vue, Watch } from 'vue-property-decorator';
import {
  SolucionesApi,
  IngresopermanenciaApi,
  SolicitudApi,
  TipoSolucion,
  ParticipacionesApi,
  RolAutoridad,
  TipoBeca,
} from '@/shared/perfil-usuario/api';
import { Solicitud } from '@/shared/perfil-usuario';
import EntitySolicitudFactory from '@/shared/entity-commons/entity-solicitud.factory';
import AlertService from '@/shared/alert/alert.service';
import { mapGetters } from 'vuex';
import { required, maxLength, minLength } from 'vuelidate/lib/validators';
import PerfilService from '@/shared/perfil/perfil.service';
import router from '@/router';
import AlertaComponent from '@/components/alerta/alerta.component';
import resolveBadgeVariant from '@/shared/estados/estados.solicitud.util';
import EntityBecasExtranjeroFactory from '@/shared/entity-commons/entity-becas-extranjero.factory';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const VALIDATIONS = function () {
  return {
    cancelacion: {
      id: {},
      idSolicitud: {},
      comentario: {
        minLength: minLength(50),
        maxLength: maxLength(2000),
        required,
      },
      documentoProbatorio: {},
    },
  };
};
@Component({
  validations: VALIDATIONS,
  computed: {
    ...mapGetters(['indicadorSolicitud', 'account']),
  },
})
export default class SolicitudesComponent extends Vue {
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('solucionesApi') private solucionesApi: () => SolucionesApi;
  @Inject('ingresopermanenciaApi') private ingresopermanenciaApi: () => IngresopermanenciaApi;
  @Inject('solicitudApi') private solicitudApi: () => SolicitudApi;
  @Inject('perfilService') private perfilService: () => PerfilService;
  @Inject('participacionesApi') private participacionesApi: () => ParticipacionesApi;

  public account;
  public solucionesCard = [];
  public tipoSolicitudRouter: string;
  public solicitudes: Solicitud[] = [];
  private language = this.$store.getters.currentLanguage;
  public loadingSolicitudes = false;
  public indicadorSolicitud;
  public soluciones: Array<any> = [];
  public solicitudesAct: Solicitud[] = [];

  public fechaActual: Date = new Date();
  public solucionesVigenteSNI = [];

  public INGRESO = EntitySolicitudFactory.tipoSolucion()[4].value;
  public EMERITO = EntitySolicitudFactory.tipoSolucion()[3].value;
  public BECAS_EXTRANJERAS = EntitySolicitudFactory.tipoSolucion()[1].value;
  public BECAS_NACIONALES = EntitySolicitudFactory.tipoSolucion()[2].value;
  public CANCELADA = EntitySolicitudFactory.estadoSolicitudEnum().cancelada;
  public ENVIADA = EntitySolicitudFactory.estadoSolicitudEnum().enviada;
  public CREADA = EntitySolicitudFactory.estadoSolicitudEnum().creada;
  public EN_CAPTURA = EntitySolicitudFactory.estadoSolicitudEnum().EN_CAPTURA;
  public ASIGNADA = EntitySolicitudFactory.estadoSolicitudEnum().ASIGNADA;
  public EN_ACTUALIZACION = EntitySolicitudFactory.estadoSolicitudEnum().EN_ACTUALIZACION;
  public REVISADA = EntitySolicitudFactory.estadoSolicitudEnum().REVISADA;
  public APROBADA = EntitySolicitudFactory.estadoSolicitudEnum().APROBADA;
  public RECHAZADA = EntitySolicitudFactory.estadoSolicitudEnum().RECHAZADA;
  public BAJA = EntitySolicitudFactory.estadoSolicitudEnum().BAJA;
  public RECLASIFICADA = EntitySolicitudFactory.estadoSolicitudEnum().RECLASIFICADA;
  public FORMALIZADA = EntitySolicitudFactory.estadoSolicitudEnum().FORMALIZADA;
  public OPERACION_EN_PROCESO = EntitySolicitudFactory.estadoSolicitudEnum().OPERACION_EN_PROCESO;
  public TERMINADO = EntitySolicitudFactory.estadoSolicitudEnum().TERMINADO;
  public IMPROCEDENTE = EntitySolicitudFactory.estadoSolicitudEnum().IMPROCEDENTE;
  public VALIDADA = EntitySolicitudFactory.estadoSolicitudEnum().VALIDADA;
  public EN_CORRECCION = EntitySolicitudFactory.estadoSolicitudEnum().EN_CORRECCION;
  public EN_FIRMA = EntitySolicitudFactory.estadoSolicitudEnum().EN_FIRMA;
  public DICTAMINADA = EntitySolicitudFactory.estadoSolicitudEnum().DICTAMINADA;
  public VIGENCIA_BECA = EntitySolicitudFactory.estadoAjusteBecaEnum().vigenciaBeca;
  public FUERA_PERIODO_ENVIO = EntitySolicitudFactory.estadoAjusteBecaEnum().fueraPeriodoEnvio;
  public PROGRAMA_NO_ELEGIBLE = EntitySolicitudFactory.estadoAjusteBecaEnum().programaNoElegible;
  public PROGRAMA_INCORRECTO = EntitySolicitudFactory.estadoAjusteBecaEnum().programaIncorrecto;
  public PREASIGNADA = EntitySolicitudFactory.estadoSolicitudEnum().PREASIGNADA;
  public FINALIZADA = EntitySolicitudFactory.estadoSolicitudEnum().FINALIZADA;

  public ESPECIALIDADES_MEDICAS_CUBA = EntityBecasExtranjeroFactory.tipoBecaExtranjeroEnum().ESPECIALIDADES_MEDICAS_CUBA;
  public CIENCIAS_HUMANIDADES = EntityBecasExtranjeroFactory.tipoBecaExtranjeroEnum().CIENCIAS_HUMANIDADES;
  public ESPECIALIDADES_MEDICAS = EntityBecasExtranjeroFactory.tipoBecaExtranjeroEnum().ESPECIALIDADES_MEDICAS;
  public CREACION_FUTURO = EntityBecasExtranjeroFactory.tipoBecaExtranjeroEnum().CREACION_FUTURO;

  public cancelacion = EntitySolicitudFactory.getSolicitudCancelada();
  public datosCancelar = {
    nombre: null,
    tipoSolucion: null,
    estatusSolicitud: null,
  };
  public showSolicitudCancelacion = false;

  public enTiempo = true; // Indicador para saber si la convocatoria expiro o sigue vigente

  public date: Date = new Date();
  public cveGrado = null;
  public informacionSNP = EntitySolicitudFactory.getParticipacionBrief();

  public categoriaNoElegible = false; // Indicador de si el usuario no tiene la categoria o no es elegible
  public validaCveGrado = false;
  public noElegible = false;
  public convocatoriaProgramaValido = false;
  public mesInicioEstudiosInvalido = false;
  public banderaCuba = false;

  public nombreSolucion = '';
  public nombrePrograma = '';

  public nombrePdf = 'Carta-issste.pdf';

  public esReenviable = false;

  /**
   * alerta
   */
  @Ref()
  alerta!: AlertaComponent;

  mounted() {
    this.validaSolicitud();
    //TODO hay que usar asincronos -promesas- necesitamos promero la solucion para obtener solucion.id
    this.getSolucion()
      .then(() => {
        this.getSolicitud();
      })
      .catch(err => {
        this.loadingSolicitudes = false;
        if (err.response?.data?.detail) {
          this.alertService().showError(this, err.response.data.detail);
        } else {
          this.alertService().showError(this, this.$t('solicitudes.alert.error-carga.soluciones').toString());
        }
      });
  }

  public validaSolicitud() {
    this.loadingSolicitudes = true;
    this.getSolucion()
      .then(() => {
        this.loadingSolicitudes = false;
      })
      .catch(err => {
        this.loadingSolicitudes = false;
        if (err.response?.data?.detail) {
          this.alertService().showError(this, err.response.data.detail);
        } else {
          this.alertService().showError(this, this.$t('solicitudes.alert.error-carga.soluciones').toString());
        }
      });
  }

  public getSolucion() {
    return new Promise((resolve, reject) => {
      this.solucionesApi()
        .getSolucionesActivas(1, 50)
        .then(r => {
          this.soluciones = r.data;
          this.$store.commit('setSoluciones', this.soluciones);
          this.solucionesCard = [];
          const solucion = [];
          const solucionesIngreso = this.soluciones.filter(sol => sol.tipo == this.INGRESO);
          solucionesIngreso.forEach(sol => {
            solucion.push(sol);
            if (sol.calendario.fechaFinSolicitud <= this.date.toISOString()) {
              this.enTiempo = false;
            }
            this.loadingSolicitudes = false;
            //TODO Crea todas las cards de todas las soluciones snii-ingreso-permanencia y esten con fechaFinSolicitud vigente

            //console.log(sol.id + "- " +sol.titulo + " - " + sol.calendario.fechaFinSolicitud);
            if (sol.calendario.fechaFinSolicitud >= this.date.toISOString()) {
              this.solucionesCard.push(this.creaConvocatoriaCard(sol));
            }
          });
          const solucionesEmerito = this.soluciones.filter(sol => sol.tipo == this.EMERITO);
          solucionesEmerito.forEach(sol => {
            if (sol.calendario.fechaFinSolicitud >= this.date.toISOString()) {
              this.solucionesCard.push(this.creaConvocatoriaCard(sol));
            }
          });
          const becas_nacionales = this.soluciones.filter(sol => sol.tipo == this.BECAS_NACIONALES);
          becas_nacionales.forEach(sol => {
            if (sol.calendario.fechaFinSolicitud >= this.date.toISOString()) {
              this.solucionesCard.push(this.creaConvocatoriaCard(sol));
            }
          });
          const becas_extranjeras = this.soluciones.filter(sol => sol.tipo == this.BECAS_EXTRANJERAS);
          becas_extranjeras.forEach(sol => {
            if (sol.calendario.fechaFinSolicitud >= this.date.toISOString()) {
              this.solucionesCard.push(this.creaConvocatoriaCard(sol));
            }
          });
          this.loadingSolicitudes = false;
          resolve('ok');
        })
        .catch(err => {
          console.error(err);
          if (err.response?.data?.detail) {
            this.alertService().showError(this, err.response.data.detail);
          } else {
            this.alertService().showError(this, this.$t('solicitudes.alert.error-carga.soluciones').toString());
          }
          reject(err);
        });
    });
  }

  public creaSolicitud(e) {
    this.showSolicitudCancelacion = true; //Activa overlay en general y se desactivan dentro de cada método particular
    this.$store.commit('setSolucionSolicitud', { id: e.id, nombreConvocatoria: e.title, tipoSolucion: e.tipo });
    this.$store.commit('setIndicadorSolicitud', e.tipo);
    switch (e.tipo) {
      case this.INGRESO:
        this.getReenviable(e.id, e.title);
        break;
      case this.EMERITO:
        this.validaRequisitosEmeritos();
        break;
      case this.BECAS_NACIONALES:
        this.validaRequisitosBecasNacionales(e);
        break;
      case this.BECAS_EXTRANJERAS:
        //TODO contemplar escenarios configurables en donde se permita sólo a algunos usuarios el envio
        this.validaRequqsitosBecasExtranjero(e);
        break;

      default:
        break;
    }
  }

  public validaRequqsitosBecasExtranjero(e) {
    //Valida tipo de Beca
    if (e.tags.some(bec => bec == 'becas-extranjero-cuba')) {
      //BECAS EXTRANJERAS CUBA
      this.solicitudApi()
        .validarRequisitosBecasExtranjero(this.ESPECIALIDADES_MEDICAS_CUBA)
        .then(res => {
          if (res.data.length > 0) {
            let mensaje = '';
            res.data.forEach(war => {
              mensaje += war.descripcion + '.</br>';
            });
            this.alerta.mostrar(mensaje, 'warning', 60);
            this.scrollTop();
          } else {
            router.push({ name: 'DatosEvaluarBecasExtCuba' });
            localStorage.setItem('extranjero-documentos', JSON.stringify(e.documentos));
            localStorage.setItem('bext-params', JSON.stringify(e.params));
          }
          this.showSolicitudCancelacion = false; //desactiva overlay activado en el switch de las diferentes validaciones
        })
        .catch(err => {
          if (err.response) {
            this.alertService().showError(this, err.response.data.detail);
          }
          this.showSolicitudCancelacion = false;
        });
    } else {
      this.solicitudApi()
        .validarRequisitosBecasExtranjero(e.tipoBecaExtranjero)
        .then(res => {
          if (res.data.length > 0) {
            let mensaje = '';
            res.data.forEach(war => {
              mensaje += war.descripcion + '.</br>';
            });
            this.alerta.mostrar(mensaje, 'warning', 60);
            this.scrollTop();
          } else {
            router.push({ name: 'BecasExtranjero', params: { beca: e.tipoBecaExtranjero } });
            localStorage.setItem('extranjero-documentos', JSON.stringify(e.documentos));
            localStorage.setItem('bext-params', JSON.stringify(e.params));
          }
          this.showSolicitudCancelacion = false; //desactiva overlay activado en el switch de las diferentes validaciones
        })
        .catch(err => {
          if (err.response) {
            this.alertService().showError(this, err.response.data.detail);
          }
          this.showSolicitudCancelacion = false;
        });
    }
  }

  public validaRequisitosBecasNacionales(e) {
    // TODO agregar método que valida los requisitos para postularse a la beca
    const salud = 'SALUD';
    const estados = 'ESTADOS';
    const humanidades = 'HUMANIDADES';
    const gradoEsp = 'ESPECIALIDAD';
    const modalidadEspMed = 'ESPECIALIDAD MÉDICA';
    const ecosur = 'ECOSUR';
    const inah = 'INAH';
    const inecol = 'INECOL';
    const exactas = 'EXACTAS';
    const tradicional = 'TRADICIONAL';
    const tiposEspeciales = [salud, estados, humanidades, ecosur, inah, inecol, exactas];
    this.nombrePrograma = e.title;
    this.programa()
      .then(() => {
        const tipoEspecial = this.informacionSNP.participante.programa.tipoEspecial;
        const grado = this.informacionSNP.participante.programa.grado;
        const modalidad = this.informacionSNP.participante.programa.modalidad;
        e.tags.forEach(tag => {
          this.convocatoriaProgramaValido = tag == 'salud' && tipoEspecial == salud;
          if (!this.convocatoriaProgramaValido) this.convocatoriaProgramaValido = tag == 'inecol' && tipoEspecial == inecol;
          if (!this.convocatoriaProgramaValido) this.convocatoriaProgramaValido = tag == 'ecosur' && tipoEspecial == ecosur;
          if (!this.convocatoriaProgramaValido) this.convocatoriaProgramaValido = tag == 'inah' && tipoEspecial == inah;
          if (!this.convocatoriaProgramaValido) this.convocatoriaProgramaValido = tag == 'exactas' && tipoEspecial == exactas;
          if (!this.convocatoriaProgramaValido) this.convocatoriaProgramaValido = tag == 'entidades federativas' && tipoEspecial == estados;
          if (!this.convocatoriaProgramaValido) this.convocatoriaProgramaValido = tag == 'humanidades' && tipoEspecial == humanidades;
          if (!this.convocatoriaProgramaValido)
            this.convocatoriaProgramaValido = tag == 'especialidades medicas' && grado == gradoEsp && modalidad == modalidadEspMed;
          if (!this.convocatoriaProgramaValido) {
            this.convocatoriaProgramaValido =
              tag == 'tradicional' && tipoEspecial == tradicional && !(grado === gradoEsp && modalidad === modalidadEspMed);
          }
        });
        this.noElegible = false;
        this.validaCveGrado = false;
        if (this.cveGrado) {
          if (this.convocatoriaProgramaValido) {
            if (
              this.informacionSNP.participante.programa.estatus == 'ELEGIBLE' &&
              (tiposEspeciales.includes(tipoEspecial) || (grado === gradoEsp && modalidad === modalidadEspMed) || this.validaElegibilidad())
            ) {
              this.solicitudApi()
                .validarRequisitosBecas(
                  this.cveGrado,
                  this.informacionSNP.participante.programa.caracteristica,
                  this.informacionSNP.participante.programa.tipo,
                  this.informacionSNP.participante.programa.modalidad
                )
                .then(res => {
                  this.showSolicitudCancelacion = false;
                  if (res.data.length > 0) {
                    let mensaje = '';
                    res.data.forEach(war => {
                      mensaje += war.descripcion + '.</br>';
                    });
                    this.alerta.mostrar(mensaje, 'warning', 60);
                    this.scrollTop();
                    this.$bvModal.show('infoProgramaModal');
                  } else {
                    router.push({ name: 'DatosEvaluarBecasNacionales' });
                  }
                })
                .catch(err => {
                  this.showSolicitudCancelacion = false;
                  if (err.response?.data?.detail) {
                    this.alertService().showError(this, err.response.data.detail);
                  } else {
                    this.alertService().showError(this, this.$t('solicitudes.alert.error-c').toString());
                  }
                });
            } else {
              if (this.informacionSNP.participante.programa.estatus != 'ELEGIBLE') this.noElegible = true;
              this.$bvModal.show('programaModal');
              this.showSolicitudCancelacion = false;
            }
          } else {
            this.$bvModal.show('tipoEspecialModal');
            this.showSolicitudCancelacion = false;
          }
        } else {
          this.validaCveGrado = true;
          this.$bvModal.show('programaModal');
          this.showSolicitudCancelacion = false;
        }
      })
      .catch(err => {
        if (err.response?.data?.detail) {
          this.alertService().showError(this, err.response.data.detail);
        } else {
          this.alertService().showError(this, this.$t('solicitudes.alert.error-c').toString());
        }
        this.showSolicitudCancelacion = false;
      });
  }

  public validaRequisitosEmeritos() {
    if (this.tieneSolicitudVigenteSNII()) {
      this.alertService().showError(this, this.$t('solicitudes.alert.error-d').toString());
      this.showSolicitudCancelacion = false;
    } else {
      return new Promise((resolve, reject) => {
        this.solicitudApi()
          .validaRequisitos()
          .then(v => {
            if (v.data.length == 0) {
              this.tipoSolicitudRouter = 'DatosEvaluarEmerito'; // pantalla solicitud EMERITOS
              this.$bvModal.show('solicitudModal');
            } else {
              let mensaje = '';
              v.data.forEach(war => {
                mensaje += war.descripcion + '.</br>';
              });
              this.alerta.mostrar(mensaje, 'warning', 20);
              this.scrollTop();
            }
            this.showSolicitudCancelacion = false; //desactiva overlay activado en el switch de las diferentes validaciones
          })
          .catch(err => {
            if (err.response?.data?.detail) {
              this.alertService().showError(this, err.response.data.detail);
            } else {
              this.alertService().showError(this, this.$t('solicitudes.alert.error-c').toString());
            }
            this.showSolicitudCancelacion = false;
          });
      });
    }
  }

  /**
   * Valida solicitudes de becas nacionales ordinarias y rezagadas.
   * Se considera cómo solicitudes rezagadas solo aquellas dónde el alumno inicia su estudio en el año en curso y que no cumple con los meses de la convocatoria.
   * En otras palabras, los alumnos de generaciones anteriores (años previos) no se consideran cómo rezagados.
   * @returns boolean
   */
  public validaElegibilidad(): boolean {
    this.mesInicioEstudiosInvalido = false;

    let esElegible = false;
    const diaActual = this.fechaActual.getDate();
    const mesActual = this.fechaActual.getMonth() + 1; // Los meses van de 0 a 11
    const anioActual = this.fechaActual.getFullYear(); // Obtener el año actual

    const categoria = this.informacionSNP.participante.programa.categoria;

    const inicioEstudiosDate = this.convertirFecha(this.informacionSNP.participante.inicioEstudios);
    const mesInicioEstudios = inicioEstudiosDate.getMonth() + 1;
    const anioInicioEstudios = inicioEstudiosDate.getFullYear(); // Obtener el año de inicio de estudios

    if (
      anioInicioEstudios == anioActual &&
      ((mesActual > 7 && mesInicioEstudios <= 7) || (mesActual == 5 && mesInicioEstudios < 5) || (mesActual == 6 && mesInicioEstudios < 6))
    ) {
      esElegible = this.validaCondicioneRezagados(mesActual, diaActual, categoria);
    } else if (anioInicioEstudios < anioActual) {
      esElegible = true; // Si el alumno inicia estudios en años anteriores, no se considera rezagado
    } else {
      esElegible = this.validaCondicionesActuales(mesActual, diaActual, categoria, mesInicioEstudios);
    }

    if (!esElegible) {
      this.mesInicioEstudiosInvalido = true; // Implica que el mes de inicio de estudios también es inválido bajo las reglas dadas
      return false;
    }
    return true;
  }

  private validaCondicioneRezagados(mesActual, diaActual, categoria) {
    //Rezagados
    // Para la primera mitad del año
    if (mesActual <= 6) {
      // Del 1 al 15 de mayo de 2025 solicitudes rezagadas
      if (mesActual === 5 && diaActual >= 1 && diaActual <= 15) {
        return true;
      }

      // Del 2 al 9 de junio de 2025 solicitudes rezagadas
      if (mesActual === 6 && diaActual >= 2 && diaActual <= 9) {
        return true;
      }
    } else {
      // Para la segunda mitad del año
      // Del 1 de agosto al 1 de septiembre para categorías 1 y 2
      if (mesActual === 8 || (mesActual === 9 && diaActual <= 1)) {
        if (categoria === '1' || categoria === '2') {
          return true;
        }
      }
      // Del 2 de septiembre al 15 de noviembre para categorías 1, 2, 3 y 4
      if ((mesActual === 9 && diaActual > 1) || mesActual === 10 || (mesActual === 11 && diaActual <= 15)) {
        if (categoria === '1' || categoria === '2' || categoria === '3' || categoria === '4') {
          return true;
        }
      }
    }
    // Si no cumple ninguna condición anterior
    return false;
  }

  private validaCondicionesActuales(mesActual, diaActual, categoria, mesInicioEstudios) {
    if (mesActual >= 1 && mesActual <= 6) {
      // Primera mitad del año: de enero a junio
      return this.validaPrimerPeriodoNormales(mesActual, diaActual, mesInicioEstudios);
    } else {
      // Segunda mitad del año: de julio a diciembre
      return this.validaSegundoPeriodoNormales(mesActual, diaActual, categoria, mesInicioEstudios);
    }
  }

  private validaPrimerPeriodoNormales(mesActual, diaActual, mesInicioEstudios) {
    // Nuevas reglas para la primera mitad del año

    // Del 28 de febrero al 15 de abril de 2025
    // Recepción ordinaria de solicitudes de estudiantes que inicien estudios entre enero y abril
    if ((mesActual === 2 && diaActual >= 18) || mesActual === 3 || (mesActual === 4 && diaActual <= 15)) {
      return mesInicioEstudios >= 1 && mesInicioEstudios <= 4;
    }

    // Del 1 al 15 de mayo de 2025
    // Recepción ordinaria de solicitudes de estudiantes que inicien estudios en mayo
    if (mesActual === 5 && diaActual >= 1 && diaActual <= 15) {
      return mesInicioEstudios === 5;
    }

    // Del 2 al 9 de junio de 2025
    // Recepción ordinaria de solicitudes de estudiantes que inicien estudios en junio
    if (mesActual === 6 && diaActual >= 2 && diaActual <= 9) {
      return mesInicioEstudios === 6;
    }
  }

  private validaSegundoPeriodoNormales(mesActual, diaActual, categoria, mesInicioEstudios) {
    // Segunda mitad del año
    // Del 1 de agosto al 1 de septiembre para categorías 1 y 2 con meses de estudios entre agosto y septiembre
    if (mesActual === 8 || (mesActual === 9 && diaActual <= 1)) {
      if ((categoria === '1' || categoria === '2') && mesInicioEstudios >= 8 && mesInicioEstudios <= 9) {
        return true;
      } else {
        this.categoriaNoElegible = true;
        return false;
      }
    }

    // Del 2 de septiembre al 16 de octubre:
    // categorías 1 y 2 con meses de inicio de estudios entre agosto y noviembre
    // categorías 3 y 4 con meses de inicio de estudios entre agosto y septiembre
    if ((mesActual === 9 && diaActual > 1) || (mesActual === 10 && diaActual <= 16)) {
      if (
        ((categoria === '1' || categoria === '2') && mesInicioEstudios >= 8 && mesInicioEstudios <= 11) ||
        ((categoria === '3' || categoria === '4') && mesInicioEstudios >= 8 && mesInicioEstudios <= 9)
      ) {
        return true;
      } else {
        this.categoriaNoElegible = true;
        return false;
      }
    }

    // Del 17 al 24 de octubre para inicio de estudios entre agosto y octubre
    if (mesActual === 10 && diaActual >= 17 && diaActual <= 24) {
      if (mesInicioEstudios >= 8 && mesInicioEstudios <= 10) {
        return true;
      } else {
        this.categoriaNoElegible = true;
        return false;
      }
    }

    // Del 1 al 15 de noviembre para todas las categorías de inicio de estudios entre agosto y noviembre
    if (mesActual === 11 && diaActual <= 15) {
      if (mesInicioEstudios >= 8 && mesInicioEstudios <= 11) {
        return true;
      } else {
        this.categoriaNoElegible = true;
        return false;
      }
    }
  }

  private tieneSolicitudVigenteSNII() {
    let participaciones = 0;
    this.solucionesVigenteSNI = [];
    this.solucionesCard.forEach(solucion => {
      if (solucion.tipo === 'INGRESO_PERMANENCIA_PROMOCION' || solucion.tipo === 'EMERITOS') {
        this.solucionesVigenteSNI.push(solucion);
      }
    });

    this.solicitudes.forEach(solicitud => {
      this.solucionesVigenteSNI.forEach(solucionesSNI => {
        if (
          solicitud.solucionSolicitud.id === solucionesSNI.id &&
          (solicitud.estadoActual === 'CREADA' || solicitud.estadoActual === 'ENVIADA')
        ) {
          participaciones += 1;
        }
      });
    });

    return participaciones >= 1 ? true : false;
  }

  public getReenviable(idSolucion, nombreConvocatoria) {
    this.showSolicitudCancelacion = false; //desactiva overlay activado en el switch de las diferentes validaciones
    //TODO se agrega temporalmente la funcionalidad de reenviable
    /* this.solicitudApi()
      .esReenviable(idSolucion)
      .then(res => {
        if (res.data) {
          this.tipoSolicitudRouter = 'DatosEvaluarIngreso'; //pantalla solicitud INGRESO
          this.$bvModal.show('solicitudModal');
        } else {
          this.noEsrenviable(nombreConvocatoria);
        }
      });*/

    if (this.tieneSolicitudVigenteSNII()) {
      this.alertService().showError(this, this.$t('solicitudes.alert.error-d').toString());
    } else {
      this.tipoSolicitudRouter = 'DatosEvaluarIngreso'; //pantalla solicitud INGRESO
      this.$bvModal.show('solicitudModal');
    }
  }

  private creaConvocatoriaCard(val): any {
    return {
      id: val.id,
      title: val.titulo,
      tipo: val.tipo,
      description: `Fecha apertura: ${this.formatDate(val.calendario.fechaInicio)}`,
      footer: `Cierre: ${this.formatDate(val.calendario.fechaFinSolicitud)}`,
      help: val?.params?.ayuda,
      icon: 'icon-convocatoria',
      tags: val?.tags,
      tipoBecaExtranjero: val?.params?.tipoBecaExtranjero,
      params: val?.params,
      badge: [
        {
          badge: val?.estado,
          primary: 'primary',
        },
      ],
      documentos: val.tipo == this.BECAS_EXTRANJERAS ? val.documentos : [],
    };
  }

  public cancelaSidebar(item) {
    this.cancelacion.idSolicitud = item.id;
    this.cancelacion.comentario = null;
    this.datosCancelar.nombre = item.solucionSolicitud.nombreConvocatoria;
    this.datosCancelar.tipoSolucion = item.solucionSolicitud.tipoSolucion;
    this.datosCancelar.estatusSolicitud = item.estadoSolicitud[item.estadoSolicitud.length - 1].estado;
    //alert(item.estadoSolicitud [item.estadoSolicitud.length -1].estado);

    this.$v.cancelacion.$reset();
    this.$bvModal.show('cancelarSolicitud');
  }

  public confirmaCancelarSolicitud() {
    if (!this.$v.cancelacion.$invalid) {
      this.nombreSolucion = this.datosCancelar.nombre;
      const h = this.$createElement;
      let titleText = this.$t('cancelar.confirmacion.title', { nombreSolucion: this.nombreSolucion }).toString();
      if (this.datosCancelar.tipoSolucion === 'INGRESO_PERMANENCIA_PROMOCION' || this.datosCancelar.tipoSolucion === 'EMERITOS') {
        titleText = 'Si cancela su solicitud no podrá ser reenviada <br> ¿Está seguro de cancelar la solicitud?';
      }
      const titleVNode = h('div', {
        domProps: { innerHTML: titleText },
      });
      this.$bvModal
        .msgBoxConfirm([titleVNode], {
          title: this.$t('solicitudes.cancelar.confirmacion-title').toString(),
          size: 'md',
          buttonSize: 'md',
          okVariant: 'primary',
          okTitle: 'Si',
          cancelTitle: 'No',
          cancelVariant: 'outline-primary',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          //Metodo cancelación
          if (value) {
            //TODO a la espera de la autorización de la cancelación de becas nacionales
            if (value && this.datosCancelar.tipoSolucion != 'BECAS_NACIONALES') {
              if (this.datosCancelar.tipoSolucion == 'INGRESO_PERMANENCIA_PROMOCION') {
                this.obtenerReporteAcuseCancelacion(this.cancelacion);
              } else {
                if (this.datosCancelar.tipoSolucion == 'BECAS_EXTRANJERAS') {
                  this.cancelaBecasExtranjeras(this.cancelacion);
                } else {
                  this.obtenerReporteAcuseCancelacionAll(this.cancelacion); //Método de cancelación para emeritos (posiblemente becas)
                }
              }
            }
          }
        })
        .catch(err => {
          // An error occurred
          if (err.response?.data?.detail) {
            this.alertService().showError(this, err.response.data.detail);
          } else {
            this.alertService().showError(this, this.$t('solicitudes.alert.error-c').toString());
          }
        });
    } else {
      this.$v.cancelacion.$touch();
    }
  }

  public obtenerReporteAcuseCancelacion(item) {
    this.showSolicitudCancelacion = true;
    this.solicitudApi()
      .getExportarReporteSolicitudCancelar(item.idSolicitud, item)
      .then(respSol => {
        const documentoPdfSolicitud = {
          nombre: item.idSolicitud + '.pdf',
          content: respSol.data.contenidoBase64,
        };

        this.perfilService()
          .dispatchDocumentoV2(this.$store.getters.account.cvu, documentoPdfSolicitud)
          .then(respSol => {
            if (respSol.status === 201) {
              this.cancelacion.documentoProbatorio = {
                nombre: item.idSolicitud + 'cancelacion-solicitud.pdf',
                uri: respSol.headers.location,
                contentType: 'application/pdf',
                definicionDocumento: '1',
                size: 1,
              };

              if (
                (this.datosCancelar.tipoSolucion == 'INGRESO_PERMANENCIA_PROMOCION' || this.datosCancelar.tipoSolucion == 'EMERITOS') &&
                this.datosCancelar.estatusSolicitud == 'CREADA'
              ) {
                this.solicitudApi()
                  .saveSolicitudCanceladaNoEnviada(this.datosCancelar.tipoSolucion, this.cancelacion.idSolicitud, item)
                  .then(res => {
                    this.getSolicitud();
                    this.$bvModal.hide('cancelarSolicitud');
                    this.showSolicitudCancelacion = false;
                  })
                  .catch(() => {
                    this.showSolicitudCancelacion = false;
                  });
              } else {
                this.ingresopermanenciaApi()
                  .cancelaSolicitud(this.cancelacion.idSolicitud, this.cancelacion)
                  .then(res => {
                    this.getSolicitud();
                    this.$bvModal.hide('cancelarSolicitud');
                    this.showSolicitudCancelacion = false;
                  })
                  .catch(() => {
                    this.showSolicitudCancelacion = false;
                  });
              }
            }
          })
          .catch(() => {
            this.showSolicitudCancelacion = false;
          });
      })
      .catch(() => {
        this.showSolicitudCancelacion = false;
      });
  }

  public obtenerReporteAcuseCancelacionAll(item) {
    this.showSolicitudCancelacion = true;
    this.solicitudApi()
      .getReporteSolicitudCancelada(this.datosCancelar.tipoSolucion, item.idSolicitud, item)
      .then(respSol => {
        const documentoPdfSolicitud = {
          nombre: item.idSolicitud + '.pdf',
          content: respSol.data.contenidoBase64,
        };

        this.perfilService()
          .dispatchDocumentoV2(this.$store.getters.account.cvu, documentoPdfSolicitud)
          .then(respSol => {
            if (respSol.status === 201) {
              this.cancelacion.documentoProbatorio = {
                nombre: item.idSolicitud + 'cancelacion-solicitud.pdf',
                uri: respSol.headers.location,
                contentType: 'application/pdf',
                definicionDocumento: '1',
                size: 1,
              };
              this.solicitudApi()
                .saveSolicitudCancelada(this.datosCancelar.tipoSolucion, item.idSolicitud, item)
                .then(res => {
                  this.getSolicitud();
                  this.$bvModal.hide('cancelarSolicitud');
                  this.showSolicitudCancelacion = false;
                })
                .catch(err => {
                  this.showSolicitudCancelacion = false;
                  this.$bvModal.hide('cancelarSolicitud');
                  if (err.response?.data?.detail) {
                    this.alertService().showError(this, err.response.data.detail);
                  } else {
                    this.alertService().showError(this, this.$t('solicitudes.alert.error-cancelando').toString());
                  }
                });
            }
          })
          .catch(err => {
            this.showSolicitudCancelacion = false;
            this.$bvModal.hide('cancelarSolicitud');
            if (err.response?.data?.detail) {
              this.alertService().showError(this, err.response.data.detail);
            } else {
              this.alertService().showError(this, this.$t('solicitudes.alert.error-c').toString());
            }
          });
      })
      .catch(err => {
        this.showSolicitudCancelacion = false;
        this.$bvModal.hide('cancelarSolicitud');
        if (err.response?.data?.detail) {
          this.alertService().showError(this, err.response.data.detail);
        } else {
          this.alertService().showError(this, this.$t('solicitudes.alert.error-c').toString());
        }
      });
  }

  public navigateSolicitudes(s) {
    this.$store.commit('setSolucionSolicitud', {
      id: s.solucionSolicitud.id,
      nombreConvocatoria: s.solucionSolicitud.nombreConvocatoria,
      tipoSolucion: s.solucionSolicitud.tipoSolucion,
    });
    switch (s.solucionSolicitud.tipoSolucion) {
      case 'INGRESO_PERMANENCIA_PROMOCION':
        router.push({ name: 'DatosEvaluarIngresoEdit', params: { idSolicitud: s.id } });
        break;
      case this.EMERITO:
        router.push({ name: 'DatosEvaluarEmeritosEdit', params: { idSolicitud: s.id } });
        break;
      case this.BECAS_NACIONALES:
        router.push({ name: 'DatosEvaluarBecasNacionalesEdit', params: { idSolicitud: s.id } });
        break;
      case this.BECAS_EXTRANJERAS:
        if (s.tipoBecaExtranjero == this.ESPECIALIDADES_MEDICAS_CUBA) {
          router.push({ name: 'DatosEvaluarBecasExtCubaEdit', params: { idSolicitud: s.id } });
        } else {
          //BECAS_EXTRANJERAS
          router.push({ name: 'BecasExtranjeroEdit', params: { beca: s.tipoBecaExtranjero, idSolicitud: s.id } });
        }
        break;
      default:
        break;
    }
  }

  public getSolicitud(): void {
    this.solicitudApi()
      .getSolicitudes()
      .then(res => {
        this.solicitudes = res.data;
        if (this.solicitudes.length > 0) {
          this.$store.commit('setSolicitud', this.solicitudes);
          // TODO filtra solicitud ingreso-permanencia por ID en lugar de tipo
          const solucionVigenteById = this.soluciones.find(
            solucion => solucion.tipo == this.INGRESO && solucion.calendario.fechaFinSolicitud >= this.date.toISOString()
          );
          this.solicitudesAct = this.solicitudes;
          // TODO filtra solicitud ingreso-permanencia por ID en lugar de tipo
          const INGRESO_PERMANENCIA_PROMOCION = solucionVigenteById
            ? this.solicitudes.filter(s => s.solucionSolicitud.id == solucionVigenteById.id)
            : [];
          if (INGRESO_PERMANENCIA_PROMOCION.length > 0) {
            this.$store.commit('setIndicadorSolicitud', INGRESO_PERMANENCIA_PROMOCION[0].solucionSolicitud.tipoSolucion);
            this.$store.commit('setSolucionSolicitud', {
              id: INGRESO_PERMANENCIA_PROMOCION[0].solucionSolicitud.id,
              nombreConvocatoria: INGRESO_PERMANENCIA_PROMOCION[0].solucionSolicitud.nombreConvocatoria,
              tipoSolucion: INGRESO_PERMANENCIA_PROMOCION[0].solucionSolicitud.tipoSolucion,
            });
            const estadoSolicitudEnviada = INGRESO_PERMANENCIA_PROMOCION[0].estadoSolicitud.filter(e => e.estado == this.ENVIADA);
            if (estadoSolicitudEnviada.length > 0) this.$store.commit('setEstadoSolicitudEnviada', true);
            const estadoSolicitudCancelada = INGRESO_PERMANENCIA_PROMOCION[0].estadoSolicitud.filter(e => e.estado == this.CANCELADA);
            if (estadoSolicitudCancelada.length > 0) this.$store.commit('setEstadoSolicitudCancelada', true);
          }
          // cambio de fecha-creacion a UTC-6 (tomamos fechaAlta del estado creada)
          this.solicitudesAct.forEach(solicitud => {
            const fechaOriginal = new Date(solicitud.estadoSolicitud[0].fechaAlta);
            const desplazamientoUtcMenos6 = -6 * 60;
            const fechaUtcMenos6 = new Date(fechaOriginal.getTime() + desplazamientoUtcMenos6 * 60000);
            const fechaString = fechaUtcMenos6.toLocaleDateString('es-MX', { timeZone: 'America/Mexico_City' });
            const [dia, mes, anio] = fechaString.split('/');
            const fechaNueva = `${anio}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`;
            solicitud.estadoSolicitud[0].fechaAlta = fechaNueva;
          });
        }
      })
      .catch(err => {
        if (err.response?.data?.detail) {
          this.alertService().showError(this, err.response.data.detail);
        } else {
          this.alertService().showError(this, this.$t('solicitudes.alert.error-c').toString());
        }
      });
  }

  public scrollTop() {
    window.scrollTo({
      top: window.scrollX,
      behavior: 'smooth',
    });
  }

  private programa() {
    return new Promise((resolve, reject) => {
      this.participacionesApi()
        .getParticipacionesByCvu(RolAutoridad.ALUMNO, TipoBeca.PostulanteBeca, this.account.cvu, true, 1, 100)
        .then(result => {
          if (result.data.length > 0) {
            this.informacionSNP = result.data[0];
            this.cveGrado = result.data[0].participante?.programa?.cveGrado;
            this.nombrePrograma = result.data[0]?.participante?.programa?.nombre;
          } else {
            this.cveGrado = null;
          }
          resolve('ok');
        })
        .catch(err => {
          if (err.response?.data?.detail) {
            this.alertService().showError(this, err.response.data.detail);
          } else {
            this.alertService().showError(this, this.$t('solicitudes.alert.error-c').toString());
          }
          reject(err);
        });
    });
  }

  public getBadgeVariantBecas(estado) {
    switch (estado) {
      case this.VIGENCIA_BECA:
      case this.FUERA_PERIODO_ENVIO:
        return 'warning';
      case this.PROGRAMA_NO_ELEGIBLE:
      case this.PROGRAMA_INCORRECTO:
        return 'danger';
      default:
        return '';
    }
  }

  public getBadgeVariantBecas2(estado) {
    return resolveBadgeVariant(estado[estado.length - 1].estado);
  }

  public getBadgeTextBecas(estado) {
    switch (estado) {
      case this.VIGENCIA_BECA:
        return this.$t('solicitudes.solicitud-becas.vigencia-becas');
      case this.FUERA_PERIODO_ENVIO:
        return this.$t('solicitudes.solicitud-becas.fuera-periodo-envio');
      case this.PROGRAMA_NO_ELEGIBLE:
        return this.$t('solicitudes.solicitud-becas.programa-no-elegible');
      case this.PROGRAMA_INCORRECTO:
        return this.$t('solicitudes.solicitud-becas.programa-incorrecto');
      default:
        return '';
    }
  }

  public consultarCartaISSSTE(id): void {
    this.solicitudApi()
      .getDocumentoExportCartaISSSTE(id)
      .then(res => {
        this.downloadPDFSolicitud(res.data.contenidoBase64);
      })
      .catch(err => {
        if (err?.response?.data?.detail) {
          this.alertService().showError(this, err.response.data.detail);
        } else {
          this.alertService().showError(this, this.$t('error.internalServerError').toString());
        }
      });
  }

  public downloadPDFSolicitud(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement('a');
    const fileName = this.nombrePdf;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  // Regresa true si se debe mostrar el botón de cancelar
  // Regresa false si no se debe mostrar el botón de cancelar
  public permiteCancelar(): boolean[] {
    return this.solicitudesAct.map(solicitud => {
      switch (solicitud.solucionSolicitud.tipoSolucion) {
        case TipoSolucion.INGRESO_PERMANENCIA_PROMOCION:
          return (
            solicitud.estadoSolicitud[solicitud.estadoSolicitud.length - 1].estado == 'ENVIADA' ||
            solicitud.estadoSolicitud[solicitud.estadoSolicitud.length - 1].estado == 'CREADA'
          );
        case TipoSolucion.EMERITOS:
          return (
            solicitud.estadoSolicitud[solicitud.estadoSolicitud.length - 1].estado == 'ENVIADA' ||
            solicitud.estadoSolicitud[solicitud.estadoSolicitud.length - 1].estado == 'CREADA'
          );
        case TipoSolucion.BECAS_NACIONALES:
          return false;
        case TipoSolucion.BECAS_EXTRANJERAS:
          return solicitud.estadoActual == 'EN_CAPTURA' || solicitud.estadoActual == 'ENVIADA';
        default:
          return false;
      }
    });
  }

  private convertirFecha(fechaString: string): Date {
    if (fechaString) {
      if (fechaString.includes('-')) {
        const [year, month, day] = fechaString.split('-').map(val => parseInt(val));
        return new Date(year, month - 1, day);
      } else {
        const [day, month, year] = fechaString.split('/').map(val => parseInt(val));
        return new Date(year, month - 1, day);
      }
    }
  }

  public validarFechaPublicacionResultados(solucionItem): boolean {
    const solucionCoincidente = this.soluciones.find(solucion => solucion.id === solucionItem.solucionSolicitud.id);
    if (solucionCoincidente) {
      //alert(solucionCoincidente?.params?.fechaResultado);
      if (this.convertirFecha(solucionCoincidente?.params?.fechaResultado) > new Date()) {
        return true;
      }
      //aqui va false
      return true;
    }
  }

  // Va la solución con su solicitud asociada
  public asociarSolicitudesConSoluciones(): any[] {
    const solucionesByCard = [];
    this.solucionesCard.forEach(solucionCard => {
      const solucionCoincidente = this.soluciones.find(solucion => solucion.id === solucionCard.id);
      if (solucionCoincidente) {
        solucionesByCard.push(solucionCoincidente);
      }
    });
    if (this.solicitudesAct) {
      solucionesByCard.forEach(solucion => {
        const solicitudAsociada = this.solicitudesAct.filter(solicitud => solucion.id === solicitud.solucionSolicitud.id);
        solucion.solicitudAsociada = solicitudAsociada;
      });
    }
    return solucionesByCard;
  }

  // Regresa true si se debe mostrar el botón de participar en la solucion
  // Regresa false si no se debe mostrar el botón de participar en la solucion
  public permiteParticipar(): boolean[] {
    if (this.soluciones.length > 0) {
      const solucionSolicitud = this.asociarSolicitudesConSoluciones();

      return solucionSolicitud.map(solucion => {
        let validacion = true;

        switch (solucion.tipo) {
          case TipoSolucion.INGRESO_PERMANENCIA_PROMOCION:
          case TipoSolucion.EMERITOS:
            if (solucion.solicitudAsociada.length > 0) {
              if (
                solucion.solicitudAsociada[0].estadoActual == this.ENVIADA ||
                solucion.solicitudAsociada[0].estadoActual == this.CREADA ||
                solucion.solicitudAsociada[0].estadoActual == this.EN_CAPTURA ||
                solucion.solicitudAsociada[0].estadoActual == this.CANCELADA
              ) {
                validacion = false;
              }
            } else {
              validacion = true;
            }
            return validacion;
          case TipoSolucion.BECAS_EXTRANJERAS:
            if (solucion.solicitudAsociada.length > 0) {
              if (
                !solucion.solicitudAsociada.every(
                  solAct => solAct.estadoActual === this.FINALIZADA || solAct.estadoActual === this.CANCELADA
                )
              ) {
                validacion = false;
              }
            } else {
              validacion = true;
            }
            return validacion;
          // Si almenos una solicitud esta en un estado distinto de finalizada ya no puede crear
          case TipoSolucion.BECAS_NACIONALES:
            if (solucion.solicitudAsociada.length > 0) {
              if (solucion.solicitudAsociada.some(solAct => solAct.estadoActual != this.FINALIZADA)) {
                validacion = false;
              }
            } else {
              validacion = true;
            }
            return validacion;
          default:
            // No cae en ningún tipo de solución
            return false;
        }
      });
    }
  }

  public cancelaBecasExtranjeras(item) {
    this.solicitudApi()
      .saveSolicitudCancelada(this.datosCancelar.tipoSolucion, item.idSolicitud, item)
      .then(res => {
        this.getSolicitud();
        this.$bvModal.hide('cancelarSolicitud');
        this.showSolicitudCancelacion = false;
      })
      .catch(err => {
        this.showSolicitudCancelacion = false;
        this.$bvModal.hide('cancelarSolicitud');
        if (err.response?.data?.detail) {
          this.alertService().showError(this, err.response.data.detail);
        } else {
          this.alertService().showError(this, this.$t('solicitudes.alert.error-cancelando').toString());
        }
      });
  }

  private formatDate(input) {
    if (input) {
      // Parsear la fecha con Day.js y convertirla a la zona horaria deseada
      const date = dayjs.utc(input).tz('America/Mexico_City');

      const ye = date.format('YYYY');
      const mo = date.format('MMM');
      const da = date.format('DD');

      return `${da} ${mo} ${ye}`;
    } else {
      return '';
    }
  }

  public getReenviableBext(idSolucion) {
    return new Promise((resolve, reject) => {
      this.solicitudApi()
        .esReenviable(idSolucion)
        .then(res => {
          this.showSolicitudCancelacion = false; //desactiva overlay activado en el switch de las diferentes validaciones
          this.esReenviable = res.data;
          resolve('ok');
        })
        .catch(err => {
          if (err.response?.data?.detail) {
            this.alertService().showError(this, err.response.data.detail);
          } else {
            this.alertService().showError(this, this.$t('solicitudes.alert.error-c').toString());
          }
          reject(err);
        });
    });
  }

  public noEsrenviable(nombreConvocatoria) {
    this.alerta.mostrar(this.$t('solicitudes.alert.warning-c').toString() + ': ' + nombreConvocatoria, 'warning', 20);
    this.scrollTop();
  }
}
