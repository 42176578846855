var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-documento-probatorio",
            title: _vm.$t("document.carga-documento"),
            size: "lg",
            "z-index": "2",
            centered: "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-primary" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(" Cerrar ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        id: "documento-probatorio-save-id",
                        variant: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.save()
                        },
                      },
                    },
                    [_vm._v(" Guardar ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("input-pdf", {
            attrs: {
              id: "documento-probatorio-id",
              label: _vm.$t("document.documento-probatorio"),
              description: _vm.$t("document.description", { size: "10" }),
              readonly: false,
              type: "pdf",
              tiposMime: ".pdf",
              pesoMaximo: 10240,
            },
            model: {
              value: _vm.$v.documento.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.documento, "$model", $$v)
              },
              expression: "$v.documento.$model",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }