var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-col",
    { attrs: { md: "12" } },
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { md: "12" } }, [
            _c("div", { staticClass: "title-block" }, [
              _c("h2", { staticClass: "title mt-4" }, [
                _c(
                  "span",
                  {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("datos-evaluacion.title-rubros")
                      ),
                    },
                  },
                  [_vm._v("rubros")]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { lg: "12" } },
            [_c("alerta", { ref: "alerta", attrs: { id: "alertContacto" } })],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "6" } },
            [
              _c("input-select-one", {
                attrs: {
                  id: "rubro-concepto-id",
                  label: _vm.$t("rubro.concepto.label"),
                  options: _vm.conceptoOptions,
                  readonly: !_vm.conceptoDisabled,
                  required: true,
                  valid: _vm.$v.rubroNuevo.concepto.$dirty
                    ? !_vm.$v.rubroNuevo.concepto.$error
                    : null,
                  validationsCommons: {
                    requiredValue: !_vm.$v.rubroNuevo.concepto.required,
                    requiredMessage: _vm.$t(
                      "rubro.concepto.validations.required"
                    ),
                  },
                },
                on: { change: _vm.conceptoChange },
                model: {
                  value: _vm.$v.rubroNuevo.concepto.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.rubroNuevo.concepto, "$model", $$v)
                  },
                  expression: "$v.rubroNuevo.concepto.$model",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "6" } },
            [
              _c("input-select-one", {
                attrs: {
                  id: "rubro-periodo-id",
                  label: _vm.$t("rubro.periodo.label"),
                  options: _vm.periodoOptions,
                  readonly: !_vm.periodoDisabled,
                  required: true,
                  valid: _vm.$v.rubroNuevo.periodo.$dirty
                    ? !_vm.$v.rubroNuevo.periodo.$error
                    : null,
                  validationsCommons: {
                    requiredValue: !_vm.$v.rubroNuevo.periodo.required,
                    requiredMessage: _vm.$t(
                      "rubro.periodo.validations.required"
                    ),
                  },
                },
                model: {
                  value: _vm.$v.rubroNuevo.periodo.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.rubroNuevo.periodo, "$model", $$v)
                  },
                  expression: "$v.rubroNuevo.periodo.$model",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "6" } },
            [
              _c("input-select-one", {
                attrs: {
                  id: "rubro-moneda-id",
                  label: _vm.$t("rubro.moneda.label"),
                  options: _vm.monedaOptions,
                  readonly: !_vm.monedaDisabled,
                  required: true,
                  valid: _vm.$v.rubroNuevo.moneda.$dirty
                    ? !_vm.$v.rubroNuevo.moneda.$error
                    : null,
                  validationsCommons: {
                    requiredValue: !_vm.$v.rubroNuevo.moneda.required,
                    requiredMessage: _vm.$t(
                      "rubro.moneda.validations.required"
                    ),
                  },
                },
                model: {
                  value: _vm.$v.rubroNuevo.moneda.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.rubroNuevo.moneda, "$model", $$v)
                  },
                  expression: "$v.rubroNuevo.moneda.$model",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "6" } },
            [
              _c("input-text", {
                attrs: {
                  id: "rubro-monto-total-id",
                  label: _vm.$t("rubro.monto-total.label"),
                  readonly: !_vm.montoDisabled,
                  required: true,
                  valid: _vm.$v.rubroNuevo.montoTotal.$dirty
                    ? !_vm.$v.rubroNuevo.montoTotal.$error
                    : null,
                  validationsCommons: {
                    requiredValue: !_vm.$v.rubroNuevo.montoTotal.required,
                    requiredMessage: _vm.$t(
                      "rubro.monto-total.validations.required"
                    ),
                    minValue: !_vm.$v.rubroNuevo.montoTotal.minLength,
                    minMessage: _vm.$t(
                      "rubro.monto-total.validations.minMessage",
                      { min: "1" }
                    ),
                    maxValue: !_vm.$v.rubroNuevo.montoTotal.maxLength,
                    maxMessage: _vm.$t(
                      "rubro.monto-total.validations.maxMessage",
                      { max: "6" }
                    ),
                  },
                  maxCaracteres: 6,
                },
                on: { keypress: _vm.numbersOnly },
                model: {
                  value: _vm.$v.rubroNuevo.montoTotal.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.rubroNuevo.montoTotal, "$model", $$v)
                  },
                  expression: "$v.rubroNuevo.montoTotal.$model",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "6" } },
            [
              this.atributos.tipoBecaExtranjero !==
                "ESPECIALIDADES_MEDICAS_CUBA" &&
              _vm.rubroNuevo.concepto == "COLEGIATURA"
                ? _c("input-text", {
                    attrs: {
                      id: "costo-total-anual-id",
                      label: _vm.$t("rubro.costo-total-anual.label"),
                      required: true,
                      valid: _vm.$v.rubroNuevo.costoTotalAnual.$dirty
                        ? !_vm.$v.rubroNuevo.costoTotalAnual.$error
                        : null,
                      validationsCommons: {
                        requiredValue:
                          !_vm.$v.rubroNuevo.costoTotalAnual.required,
                        requiredMessage: _vm.$t(
                          "rubro.costo-total-anual.validations.required"
                        ),
                      },
                      maxCaracteres: 6,
                    },
                    on: { keypress: _vm.numbersOnly },
                    model: {
                      value: _vm.$v.rubroNuevo.costoTotalAnual.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.rubroNuevo.costoTotalAnual,
                          "$model",
                          $$v
                        )
                      },
                      expression: "$v.rubroNuevo.costoTotalAnual.$model",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.rubroNuevo.concepto == "SEGURO_MEDICO" &&
      this.atributos.tipoBecaExtranjero == "ESPECIALIDADES_MEDICAS_CUBA"
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "div",
                    { staticClass: "header-table-custom text-left col mt-4" },
                    [
                      _c(
                        "span",
                        {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("rubro.title-tabla-sma", {
                                periodo: _vm.$v.rubroNuevo.periodo.$model,
                              })
                            ),
                          },
                        },
                        [_vm._v("SMP")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("b-table", {
                    staticClass: "shadow",
                    attrs: {
                      striped: "",
                      hover: "",
                      items: _vm.itemsSeguro,
                      fields: _vm.fields,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c("b-col", [_c("div", { staticClass: "border-custom" })]),
          _vm._v(" "),
          _c("b-col", { staticClass: "mt-3", attrs: { md: "12" } }, [
            _c(
              "div",
              { staticClass: "mb-5 float-right" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "outline-primary" },
                    on: { click: _vm.resetForm },
                  },
                  [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(_vm.$t("entity.action.cancel")),
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "icon-cerrar" }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    attrs: { variant: "primary" },
                    on: { click: _vm.addRubro },
                  },
                  [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(_vm.$t("entity.action.save")),
                      },
                    }),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      staticClass: "mr-2",
                      attrs: { icon: "save" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }