var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "b-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.disabledBtn,
              expression: "!disabledBtn",
            },
          ],
          attrs: { variant: "outline-primary", disabled: _vm.disabledBtn },
          on: {
            click: function ($event) {
              return _vm.onEdit()
            },
          },
        },
        [
          _c("span", { staticClass: "icon-editar" }),
          _vm._v(" "),
          _c(
            "label",
            { domProps: { innerHTML: _vm._s(_vm.$t("entity.action.edit")) } },
            [_vm._v("Editar")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.disabledBtn,
              expression: "!disabledBtn",
            },
          ],
          attrs: { variant: "outline-primary", disabled: _vm.disabledBtn },
          on: {
            click: function ($event) {
              return _vm.onDelete()
            },
          },
        },
        [
          _c("span", { staticClass: "icon-eliminar" }),
          _vm._v(" "),
          _c(
            "label",
            { domProps: { innerHTML: _vm._s(_vm.$t("entity.action.delete")) } },
            [_vm._v("Eliminar")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }