var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c("b-col", { attrs: { md: "12" } }, [
        _c("div", { staticClass: "title-block" }, [
          _c("h3", { staticClass: "title mt-4" }, [
            _c(
              "span",
              {
                domProps: {
                  textContent: _vm._s(
                    _vm.tipo == 1
                      ? _vm.$t("actores-tecno.title")
                      : _vm.tipo == 2
                      ? _vm.$t("actores-tecno.title2")
                      : _vm.$t("actores-tecno.title3")
                  ),
                },
              },
              [_vm._v("Actores")]
            ),
            _vm._v(" "),
            _c("span", {
              staticClass: "sparkline bar",
              attrs: { "data-type": "bar" },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      !_vm.readonly
        ? [
            _c(
              "b-col",
              { attrs: { lg: "12" } },
              [_c("alerta", { ref: "alerta", attrs: { id: "alertAutor" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "b-col",
              { attrs: { md: "12 mt-2 mb-2" } },
              [
                _c("b-alert", { attrs: { show: "", variant: "primary" } }, [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("actores-tecno.alert.msg")),
                    },
                  }),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-col",
              { attrs: { md: "4" } },
              [
                _c("input-text", {
                  attrs: {
                    id: "autor-tecno-nombre-id",
                    label: _vm.$t("actores-tecno.form.nombre.label"),
                    placeholder: _vm.$t(
                      "actores-tecno.form.nombre.placeholder"
                    ),
                    required: _vm.required,
                    valid: _vm.$v.nuevoActor.nombre.$dirty
                      ? !_vm.$v.nuevoActor.nombre.$error
                      : null,
                    validationsCommons: {
                      requiredValue: !_vm.$v.nuevoActor.nombre.required,
                      requiredMessage: _vm.$t(
                        "actores-tecno.form.nombre.validations.required"
                      ),
                      maxValue: !_vm.$v.nuevoActor.nombre.maxLength,
                      maxMessage: _vm.$t(
                        "actores-tecno.form.nombre.validations.maxMessage",
                        { max: "100" }
                      ),
                    },
                  },
                  model: {
                    value: _vm.$v.nuevoActor.nombre.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.nuevoActor.nombre, "$model", $$v)
                    },
                    expression: "$v.nuevoActor.nombre.$model",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-col",
              { attrs: { md: "4" } },
              [
                _c("input-text", {
                  attrs: {
                    id: "autor-tecno-primer-apellido-id",
                    label: _vm.$t("actores-tecno.form.primerApellido.label"),
                    placeholder: _vm.$t(
                      "actores-tecno.form.primerApellido.placeholder"
                    ),
                    required: _vm.required,
                    valid: _vm.$v.nuevoActor.primerApellido.$dirty
                      ? !_vm.$v.nuevoActor.primerApellido.$error
                      : null,
                    validationsCommons: {
                      requiredValue: !_vm.$v.nuevoActor.primerApellido.required,
                      requiredMessage: _vm.$t(
                        "actores-tecno.form.primerApellido.validations.required"
                      ),
                      maxValue: !_vm.$v.nuevoActor.primerApellido.maxLength,
                      maxMessage: _vm.$t(
                        "actores-tecno.form.primerApellido.validations.maxMessage",
                        { max: "60" }
                      ),
                    },
                  },
                  model: {
                    value: _vm.$v.nuevoActor.primerApellido.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.nuevoActor.primerApellido, "$model", $$v)
                    },
                    expression: "$v.nuevoActor.primerApellido.$model",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-col",
              { attrs: { md: "4" } },
              [
                _c("input-text", {
                  attrs: {
                    id: "autor-segundo-apellido-id",
                    label: _vm.$t("actores-tecno.form.segundoApellido.label"),
                    placeholder: _vm.$t(
                      "actores-tecno.form.segundoApellido.placeholder"
                    ),
                    required: false,
                    valid: _vm.$v.nuevoActor.segundoApellido.$dirty
                      ? !_vm.$v.nuevoActor.segundoApellido.$error
                      : null,
                    validationsCommons: {
                      requiredValue:
                        !_vm.$v.nuevoActor.segundoApellido.required,
                      requiredMessage: _vm.$t(
                        "actores-tecno.form.segundoApellido.validations.required"
                      ),
                      maxValue: !_vm.$v.nuevoActor.segundoApellido.maxLength,
                      maxMessage: _vm.$t(
                        "actores-tecno.form.segundoApellido.validations.maxMessage",
                        { max: "60" }
                      ),
                    },
                  },
                  model: {
                    value: _vm.$v.nuevoActor.segundoApellido.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.nuevoActor.segundoApellido, "$model", $$v)
                    },
                    expression: "$v.nuevoActor.segundoApellido.$model",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-col",
              { attrs: { md: "4" } },
              [
                _c("input-select-one", {
                  attrs: {
                    id: "autor-tecno-rol-id",
                    label: _vm.$t("actores-tecno.form.rol.label"),
                    descripcion: _vm.$t("actores-tecno.form.rol.descripcion"),
                    required: _vm.required,
                    options: _vm.rolesOptions,
                    valid: _vm.$v.nuevoActor.rol.$dirty
                      ? !_vm.$v.nuevoActor.rol.$error
                      : null,
                    validationsCommons: {
                      requiredValue: !_vm.$v.nuevoActor.rol.required,
                      requiredMessage: _vm.$t(
                        "actores-tecno.form.rol.validations.required"
                      ),
                    },
                  },
                  model: {
                    value: _vm.$v.nuevoActor.rol.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.nuevoActor.rol, "$model", $$v)
                    },
                    expression: "$v.nuevoActor.rol.$model",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("b-col", { attrs: { md: "8" } }, [
              _c(
                "div",
                { staticClass: "float-right" },
                [
                  _c("span", { staticClass: "label-hidden" }),
                  _vm._v(" "),
                  _vm.removeIndex !== null
                    ? _c(
                        "b-button",
                        {
                          attrs: { variant: "outline-primary" },
                          on: { click: _vm.resetForm },
                        },
                        [
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("entity.action.cancel")
                                ),
                              },
                            },
                            [_vm._v("Cancelar")]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "icon-cerrar" }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: { click: _vm.agregaAutor },
                    },
                    [
                      _vm.removeIndex === null
                        ? _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("entity.action.add")
                                ),
                              },
                            },
                            [_vm._v("añadir")]
                          )
                        : _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("entity.action.save")
                                ),
                              },
                            },
                            [_vm._v("guardar")]
                          ),
                      _vm._v(" "),
                      _vm.removeIndex === null
                        ? _c("font-awesome-icon", {
                            staticClass: "mr-2",
                            attrs: { icon: "plus" },
                          })
                        : _c("font-awesome-icon", {
                            staticClass: "mr-2",
                            attrs: { icon: "pencil" },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("b-col", { attrs: { md: "12" } }, [
        _c(
          "div",
          { staticClass: "table-responsive" },
          [
            _c("b-table", {
              staticClass: "btn-custom-acciones",
              attrs: {
                fields: _vm.fieldsActor,
                items: _vm.actores,
                "current-page": _vm.currentPage,
                "per-page": _vm.recordsPage,
                "sort-by": _vm.sortBy,
                "sort-desc": _vm.sortDesc,
                "sort-direction": _vm.sortDirection,
                stacked: "md",
                "show-empty": "",
                small: "",
                striped: "",
                borderless: "",
                outlined: "",
                hover: "",
                "sort-compare-options": { numeric: true, sensitivity: "base" },
                "head-variant": "light",
                "empty-filtered-text": "No se encontraron coincidencias.",
                "empty-text": _vm.$t("actores-tecno.alert.empty"),
              },
              on: {
                "update:sortBy": function ($event) {
                  _vm.sortBy = $event
                },
                "update:sort-by": function ($event) {
                  _vm.sortBy = $event
                },
                "update:sortDesc": function ($event) {
                  _vm.sortDesc = $event
                },
                "update:sort-desc": function ($event) {
                  _vm.sortDesc = $event
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "cell(periodoInicio)",
                  fn: function (row) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(row.item.periodoInicio) +
                          " - " +
                          _vm._s(row.item.periodoFin) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "cell(acciones)",
                  fn: function (row) {
                    return [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            title: _vm.$t("entity.action.edit"),
                            size: "sm",
                            variant: "outline-primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.prepareActor(row.item, row.item.indice)
                            },
                          },
                        },
                        [_c("b-icon", { attrs: { icon: "pencil-square" } })],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            title: _vm.$t("entity.action.delete"),
                            size: "sm",
                            variant: "outline-primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.prepareRemove(row.item.indice)
                            },
                          },
                        },
                        [_c("b-icon", { attrs: { icon: "trash" } })],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _vm.validAutorComponent
              ? [
                  _c("b-col", [
                    _c("div", { staticClass: "invalid-feedback d-block" }, [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("actores-tecno.generalRequired")
                          ),
                        },
                      }),
                    ]),
                  ]),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("c-delete-modal", {
              attrs: { id: "removeAutor" },
              on: {
                onDelete: function ($event) {
                  return _vm.remove()
                },
              },
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "6", "offset-md": "3" } },
        [
          _vm.actores.length > _vm.recordsPage
            ? _c("b-pagination", {
                attrs: {
                  "total-rows": _vm.actores.length,
                  "per-page": _vm.recordsPage,
                  "aria-controls": "my-table",
                  align: "fill",
                },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }