import { render, staticRenderFns } from "./resumen.vue?vue&type=template&id=119a3467&scoped=true&"
import script from "./resumen.component.ts?vue&type=script&lang=ts&"
export * from "./resumen.component.ts?vue&type=script&lang=ts&"
import style0 from "./resumen.vue?vue&type=style&index=0&id=119a3467&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "119a3467",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/1/gateway/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('119a3467')) {
      api.createRecord('119a3467', component.options)
    } else {
      api.reload('119a3467', component.options)
    }
    module.hot.accept("./resumen.vue?vue&type=template&id=119a3467&scoped=true&", function () {
      api.rerender('119a3467', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/entities/msPerfil/solicitudes/resumen-productos-evaluar/resumen.vue"
export default component.exports