import { Module } from 'vuex';

export const solicitudStore: Module<any, any> = {
  state: {
    indicadorSolicitud: null,
    solicitud: null,
    solucionSolicitud: null,
    estadoSolicitudEnviada: false,
    solicitudTotalesBandera: false,
    estadoSolicitudCancelada: false,
  },
  getters: {
    indicadorSolicitud: state => state.indicadorSolicitud,
    solicitud: state => state.solicitud,
    solucionSolicitud: state => state.solucionSolicitud,
    estadoSolicitudEnviada: state => state.estadoSolicitudEnviada,
    solicitudTotalesBandera: state => state.solicitudTotalesBandera,
    estadoSolicitudCancelada: state => state.estadoSolicitudCancelada,
  },
  mutations: {
    setIndicadorSolicitud(state, indicadorSolicitud) {
      state.indicadorSolicitud = indicadorSolicitud;
    },
    setSolicitud(state, solicitud) {
      state.solicitud = solicitud;
    },
    setSolucionSolicitud(state, solucionSolicitud) {
      state.solucionSolicitud = solucionSolicitud;
    },
    setEstadoSolicitudEnviada(state, estadoSolicitudEnviada) {
      state.estadoSolicitudEnviada = estadoSolicitudEnviada;
    },
    setsolicitudTotalesBandera(state, solicitudTotalesBandera) {
      state.solicitudTotalesBandera = solicitudTotalesBandera;
    },
    setEstadoSolicitudCancelada(state, estadoSolicitudCancelada) {
      state.estadoSolicitudCancelada = estadoSolicitudCancelada;
    },
  },
};
