import Component, { mixins } from 'vue-class-component';
import { Inject, Prop, PropSync, Watch } from 'vue-property-decorator';
import { maxLength, minLength, required, requiredIf } from 'vuelidate/lib/validators';
import CatalogoService from '@/shared/catalogo/catalogo.service';
import EntityFactory from '@/shared/entity-commons/entity.factory';
import { InstitucionModel } from '../commons/institucion.model';
import JhiDataUtils from '@/shared/data/data-utils.service';
import isEqual from 'lodash.isequal';

const VALIDATIONS = function () {
  return {
    busquedaInstitucion: {
      buscadorInstitucion: {
        minLength: minLength(3),
        maxLength: maxLength(100),
      },
      tipoInstitucion: {
        id: {},
        nombre: {},
        required: requiredIf(() => !this.setteoManual),
      },
      publicaPrivada: {
        id: {},
        nombre: {},
        id2: {},
        nombre2: {},
        required: requiredIf(() => this.publicPrivadaIf && !this.setteoManual),
      },
      pais: {
        id: {},
        nombre: {},
        required: requiredIf(() => this.paisIf && !this.setteoManual),
      },
      entidad: {
        id: {},
        clave: {},
        nombre: {},
        required: requiredIf(() => this.entidadIf && !this.setteoManual),
      },
      municipio: {
        id: {},
        clave: {},
        nombre: {},
        required: requiredIf(() => this.municipioIf && !this.setteoManual),
      },
      institucion: {
        id: {},
        nombre: {},
        clave: {},
        required: requiredIf(() => !this.setteoManual),
      },
      institucionManual: {
        id: {},
        nombre: {
          minLength: minLength(2),
          maxLength: maxLength(100),
          required: requiredIf(() => this.setteoManual),
        },
        clave: {},
      },
    },
  };
};

const claveInstNacional = '01';
const claveInstExtranjera = '02';
const claveInstPublica = '01';
const claveInstPrivada = '02';
const claveInstFederal = '01';
const claveInstEstatal = '02';
const claveInstMunicipal = '03';

const ingManInst = 'ingManInst';

const estados = {
  AGUASCALIENTES: 'Aguascalientes',
  BAJA_CALIFORNIA: 'Baja California',
  BAJA_CALIFORNIA_SUR: 'Baja California Sur',
  CAMPECHE: 'Campeche',
  CHIAPAS: 'Chiapas',
  CHIHUAHUA: 'Chihuahua',
  CIUDAD_DE_MEXICO: 'Ciudad de México',
  COAHUILA: 'Coahuila',
  COLIMA: 'Colima',
  DURANGO: 'Durango',
  ESTADO_DE_MEXICO: 'Estado de México',
  GUANAJUATO: 'Guanajuato',
  GUERRERO: 'Guerrero',
  HIDALGO: 'Hidalgo',
  JALISCO: 'Jalisco',
  MICHOACAN: 'Michoacán',
  MORELOS: 'Morelos',
  NAYARIT: 'Nayarit',
  NUEVO_LEON: 'Nuevo León',
  OAXACA: 'Oaxaca',
  PUEBLA: 'Puebla',
  QUERETARO: 'Querétaro',
  QUINTANA_ROO: 'Quintana Roo',
  SAN_LUIS_POTOSI: 'San Luis Potosí',
  SINALOA: 'Sinaloa',
  SONORA: 'Sonora',
  TABASCO: 'Tabasco',
  TAMAULIPAS: 'Tamaulipas',
  TLAXCALA: 'Tlaxcala',
  VERACRUZ: 'Veracruz',
  YUCATAN: 'Yucatán',
  ZACATECAS: 'Zacatecas',
};
@Component({
  validations: VALIDATIONS,
})
export default class BuquedaInstitucionComponent extends mixins(JhiDataUtils) {
  @Inject('catalogoService') private catalogoService: () => CatalogoService;

  @PropSync('value', { required: true, type: Object })
  public institucionModel: InstitucionModel;

  @Prop({ default: null })
  public validInst: boolean; //Validacion desde el padre

  @Prop({ default: null })
  public required: boolean;

  //tipo de pantalla, 1: por defecto para las pantallas de msPerfil que usan este componente, 2: para la tranferencia-tecnologica que tiene otros labels
  @Prop({ default: 1 })
  public tipo: number;

  public validInstComponent = false; //Validacion desde el componente
  public showInst = false; //Overlay

  public placeholderBuscador = this.$t('busqueda-institucion.buscador-institucion.placeHolder');

  /**
   *validComponent formulario desde el componente (padre)
   *
   */

  @Watch('validInst', { immediate: true, deep: true })
  handler(value: boolean) {
    if (value) {
      this.validInstComponent = !(this.institucionCards.length >= 1);
    } else {
      this.validInstComponent = value;
    }
    if (value) {
      this.$v.busquedaInstitucion.$touch();
    } else {
      this.$v.busquedaInstitucion.$reset();
    }
  }

  public institucionCards: Array<any> = [];
  public isEdit = false;
  public editingIndex = null;
  public isSaving = false;

  public busquedaInstitucion = EntityFactory.setInstitucion();

  public catTipoInstitucion: Array<any> = [];
  public catPublicaPrivada = EntityFactory.getTipoPublicaPrivada(this.$t.bind(this));
  public catPais: Array<any> = [];
  public catEntidad: Array<any> = [];
  public catMunicipio: Array<any> = [];
  public catInstitucion: Array<any> = [];
  public requiredString = '';

  /**
   * Variables
   */
  public publicPrivadaIf = false;
  public paisIf = false;
  public entidadIf = false;
  public municipioIf = false;
  public institucionIf = false;

  // Para el setteo manual de institución
  public setteoManual = false;
  public nombreInstitucionManual = '';
  public changeInstitucionManual = false;

  mounted() {
    this.checkInfo();
  }

  protected setVariablesDefault() {
    this.publicPrivadaIf = false;
    this.paisIf = false;
    this.entidadIf = false;
    this.municipioIf = false;
    this.institucionIf = false;
  }

  protected setValoresDefault() {
    //Sin TipoInstitucion
    this.busquedaInstitucion.publicaPrivada = null;
    this.busquedaInstitucion.pais = null;
    this.busquedaInstitucion.entidad = null;
    this.busquedaInstitucion.municipio = null;
    this.busquedaInstitucion.institucion = null;
    this.busquedaInstitucion.institucionManual.nombre = EntityFactory.setInstitucion().institucionManual.nombre;
  }

  public checkInfo() {
    if (this.modelReviews()) {
      if (this.institucionModel.id) {
        this.institucionCards.push(this.creaRegistroCard(this.institucionModel));
        this.isEdit = false;
      } else {
        this.getTipoInstituciones();
        this.onTipoChanged(claveInstNacional);
      }
    } else {
      this.institucionModel = EntityFactory.getInstitucionModel();
      this.getTipoInstituciones();
      this.onTipoChanged(claveInstNacional);
    }
  }

  private modelReviews() {
    let existe = false;
    if (this.institucionModel) {
      existe = true;
    }
    return existe;
  }

  private getTipoInstituciones() {
    this.catalogoService()
      .get('getTipoInstituciones')
      .then(result => {
        this.catTipoInstitucion = result;
      });
  }

  private getPaises() {
    this.catalogoService()
      .get('getPaises')
      .then(result => {
        this.catPais = result.filter(function (pais) {
          return pais.value.id != 'MEX';
        });
      });
  }

  private getEntidadFederativa() {
    this.showInst = true;
    this.catalogoService()
      .get('getAreaGeoestadisticaEstatal')
      .then(result => {
        this.catEntidad = this.formatCatalogos('estado', result);
        this.showInst = false;
      });
  }

  private getMunicipioEstado(idEndidad: any) {
    this.showInst = true;
    this.catalogoService()
      .get('getMunicipiosEstado', idEndidad)
      .then(result => {
        this.catMunicipio = this.formatCatalogos('municipio', result);
        this.showInst = false;
      });
  }

  private getInstitucionesPaises(idPais: string) {
    this.showInst = true;
    this.catalogoService()
      .getPaginatedService('getClaveCatExtranjeraPais', this.busquedaInstitucion.buscadorInstitucion, idPais)
      .then((listaInstTotal: any[]) => {
        this.catInstitucion = this.formatCatalogos('institucion', listaInstTotal);
        this.showInst = false;
      });
  }

  private getInstPrivado() {
    this.showInst = true;
    this.catalogoService()
      .getPaginatedService('getCveCatNacionalPublico', this.busquedaInstitucion.buscadorInstitucion, claveInstPrivada)
      .then((listaInstTotal: any[]) => {
        this.catInstitucion = this.formatCatalogos('institucion', listaInstTotal);
        this.showInst = false;
      });
  }

  private getInstPublicoFederal() {
    this.showInst = true;
    this.catalogoService()
      .getPaginatedService(
        'getCveClaveCatNacionalPublico',
        this.busquedaInstitucion.buscadorInstitucion,
        claveInstPublica,
        claveInstFederal
      )
      .then((listaInstTotal: any[]) => {
        this.catInstitucion = this.formatCatalogos('institucion', listaInstTotal);
        this.showInst = false;
      });
  }

  private getInstPublicoEstatal(claveEntidad: string) {
    this.showInst = true;
    this.catalogoService()
      .getPaginatedService(
        'getsCveClaveCatNacionalPublico',
        this.busquedaInstitucion.buscadorInstitucion,
        claveInstPublica,
        claveInstEstatal,
        claveEntidad
      )
      .then((listaInstTotal: any[]) => {
        this.catInstitucion = this.formatCatalogos('institucion', listaInstTotal);
        this.showInst = false;
      });
  }

  private getInstPublicoMunicipios(claveEntidad: string, claveMunicipios: string) {
    this.showInst = true;
    this.catalogoService()
      .getPaginatedService(
        'getClaveCveCatNacionalPublico',
        this.busquedaInstitucion.buscadorInstitucion,
        claveInstPublica,
        claveInstMunicipal,
        claveEntidad,
        claveMunicipios
      )
      .then((listaInstTotal: any[]) => {
        this.catInstitucion = this.formatCatalogos('institucion', listaInstTotal);
        this.showInst = false;
      });
  }

  public onTipoChanged(e: string) {
    this.setVariablesDefault();
    this.setValoresDefault();
    this.$v.busquedaInstitucion.$reset();
    if (e == claveInstNacional) {
      this.paisIf = false;
      this.publicPrivadaIf = true;
      return;
    }
    if (e == claveInstExtranjera) {
      this.getPaises();
      this.publicPrivadaIf = false;
      this.paisIf = true;
      return;
    }
  }

  public onPublicaPrivadaChanged(e: { id: string; id2: string }) {
    this.busquedaInstitucion.institucion = null;
    if (e.id == claveInstPublica) {
      if (e.id2 == claveInstFederal) {
        //Federal
        if (this.validaCampoBuscador()) {
          this.entidadIf = false;
          this.busquedaInstitucion.entidad = null;
          this.municipioIf = false;
          this.busquedaInstitucion.municipio = null;
          this.institucionIf = true;
          //Busqueda de instituciones nivel nacional
          this.getInstPublicoFederal();
        }
        return;
      }
      if (e.id2 == claveInstEstatal) {
        //Estatal
        this.getEntidadFederativa();
        this.entidadIf = true;
        this.busquedaInstitucion.municipio = null;
        this.municipioIf = false;
        this.institucionIf = false;
        this.cleanEntidadInsitucion('');
        return;
      }
      if (e.id2 == claveInstMunicipal) {
        //Municipal
        this.getEntidadFederativa();
        this.entidadIf = true;
        this.municipioIf = true;
        this.institucionIf = false;
        this.cleanEntidadInsitucion('m');
        return;
      }
    } else if (e.id == claveInstPrivada) {
      if (this.validaCampoBuscador()) {
        this.busquedaInstitucion.entidad = null;
        this.entidadIf = false;
        this.busquedaInstitucion.municipio = null;
        this.municipioIf = false;
        this.institucionIf = true;
        //Busqueda de instituciones nacionales pero privadas
        this.getInstPrivado();
        this.cleanEntidadInsitucion('i');
      }
      return;
    }
  }

  protected cleanEntidadInsitucion(num: string) {
    if (num != 'e') this.busquedaInstitucion.entidad = null;
    if (num != 'm') this.busquedaInstitucion.municipio = null;
    if (num != 'i') this.busquedaInstitucion.institucion = null;
  }

  public onPaisChanged(e: any) {
    this.institucionIf = true;
    //Busqueda de Institucion por el id o clave del pais
    this.getInstitucionesPaises(e.id);
  }

  public onEntidadChanged(e: { clave: any; id: any }) {
    if (!this.municipioIf) {
      // Si municipio esta false, buscara directo la institucion por el id estado
      this.getInstPublicoEstatal(e.clave);
      this.institucionIf = true;
    } else {
      //Buscara el municipio con el id de la entidad
      this.getMunicipioEstado(e.id);
      if (this.municipioIf) {
        this.busquedaInstitucion.municipio = null;
        this.busquedaInstitucion.institucion = null;
        this.institucionIf = false;
      }
    }
  }

  public onMunicipioChanged(e: { clave: any }) {
    //Buscar la institucion con el id del municipio
    this.getInstPublicoMunicipios(this.busquedaInstitucion.entidad.clave, e.clave);
    this.institucionIf = true;
  }

  public removeInstitucion(removeId: number): void {
    this.institucionCards.splice(removeId, 1);
    this.busquedaInstitucion = EntityFactory.setInstitucion();
    this.cleanInstitucionmodel();
    this.onTipoChanged(claveInstNacional);
    if (this.catTipoInstitucion.length == 0) {
      this.getTipoInstituciones();
    }
  }

  public cleanInstitucionmodel() {
    this.institucionModel.tipo = null;
    this.institucionModel.id = null;
    this.institucionModel.clave = null;
    this.institucionModel.nombre = null;
    this.institucionModel.nivelUno = null;
    this.institucionModel.nivelDos = null;
    this.institucionModel.entidad = null;
    this.institucionModel.municipio = null;
    this.institucionModel.pais = null;
  }

  public prepareBusquedaInstitucion(index: any) {
    this.editingIndex = index;
    this.busquedaInstitucion = EntityFactory.setInstitucion();
    if (this.institucionModel.clave != ingManInst) {
      this.busquedaInstitucion.buscadorInstitucion = this.institucionModel.nombre;
      this.organizeWithInfo(this.institucionModel);
      this.copyProperties(JSON.parse(JSON.stringify(this.institucionModel)));
    } else {
      this.organizeWithInfoManual(this.institucionModel);
      this.copyPropertiesManual(this.institucionModel);
    }
    this.edit();
  }

  private organizeWithInfoManual(model: InstitucionModel) {
    //TODO cambio para ocultar parche y que se pueda editar de institucionManual a institucionBuscador
    this.setteoManual = false;
    this.changeInstitucionManual = false;
    this.getTipoInstituciones();
    this.onTipoChanged(claveInstNacional);
  }

  private copyPropertiesManual(model: InstitucionModel) {
    (this.busquedaInstitucion.tipo = {
      id: model.tipo.id,
      nombre: model.tipo.nombre,
    }),
      (this.busquedaInstitucion.institucionManual.id = model.id);
    this.busquedaInstitucion.institucionManual.clave = model.clave;
    this.busquedaInstitucion.institucionManual.nombre = model.nombre;
    this.institucionModel.pais = {
      id: model.pais.id,
      nombre: model.pais.nombre,
    };
  }

  private organizeWithInfo(model: InstitucionModel) {
    let tipoInst = '';
    if (model.tipo && model.tipo.id != null) {
      if (this.catTipoInstitucion.length == 0) this.getTipoInstituciones();
      tipoInst = model.tipo.id;
    }

    this.publicPrivadaIf = false;
    if (model.nivelUno && model.nivelUno.id != null && (model.nivelUno.id == '01' || model.nivelUno.id == '02')) {
      if (this.catPublicaPrivada.length == 0) this.catPublicaPrivada = EntityFactory.getTipoPublicaPrivada;
      this.publicPrivadaIf = true;
    }

    let tipoNivelDos = '';
    if (model.nivelDos && model.nivelDos.id != null) {
      tipoNivelDos = model.nivelDos.id;
    }
    let selectEstado = EntityFactory.getDefaultCatalogoClave();
    this.entidadIf = false;
    if (model.entidad && model.entidad.clave != null && (tipoNivelDos == '02' || tipoNivelDos == '03')) {
      //Se carga el catalogo encaso de ser vacio y se quiere editar un dato ya guardado;
      if (this.catEntidad.length == 0) {
        this.catalogoService()
          .get('getAreaGeoestadisticaEstatal')
          .then(result => {
            this.catEntidad = this.formatCatalogos('estado', result);
            selectEstado = this.filtraCatalogoClave(this.catEntidad, model.entidad.clave);
            this.busquedaInstitucion.entidad = selectEstado;
            if (model.municipio && model.municipio.clave != null) {
              this.getMunicipioEstadoPreselection(model, selectEstado.id);
            }
          });
      } else {
        //Cuando se da cancelar y el catalogo ya esta cargado
        selectEstado = this.filtraCatalogoClave(this.catEntidad, model.entidad.clave);
        this.busquedaInstitucion.entidad = selectEstado;
        if (model.municipio && model.municipio.clave != null) {
          this.getMunicipioEstadoPreselection(model, selectEstado.id);
        }
      }
      this.entidadIf = true;
    }

    this.municipioIf = false;
    if (model.municipio && model.municipio.clave != null && model.nivelUno && model.nivelUno.id == '01') {
      this.municipioIf = true;
    }

    this.paisIf = false;
    if (model.pais && model.pais.id != null && tipoInst == '02') {
      //Se carga el catalogo encaso de ser vacio, cuamndo se quiere editar un dato ya guardado;
      if (this.catPais.length == 0) this.getPaises();
      this.paisIf = true;
    }

    if (model.id != null) {
      this.institucionIf = true;
      if (this.paisIf) {
        this.getInstitucionesPaises(model.pais.id);
        return;
      }
      if (this.municipioIf && this.entidadIf) {
        this.getInstPublicoMunicipios(model.entidad.clave, model.municipio.clave); //Se tiene que cambiar para que el id venga directamente de la busqueda de la busqueda del formulario
        return;
      }
      if (!this.municipioIf && this.entidadIf) {
        this.getInstPublicoEstatal(model.entidad.clave); //Se tiene que buscar para que el id venga directamente de la busqueda de la entidad del formulario
        return;
      }
      if (!this.municipioIf && !this.entidadIf && model.nivelDos.id == claveInstFederal) {
        this.getInstPublicoFederal();
        return;
      }
      if (!this.municipioIf && !this.entidadIf && model.nivelUno.id == claveInstPrivada) {
        this.getInstPrivado();
        return;
      }
    } else {
      this.institucionIf = false;
    }
  }

  private getMunicipioEstadoPreselection(model: InstitucionModel, idEstado: any) {
    this.catalogoService()
      .get('getMunicipiosEstado', idEstado)
      .then(result => {
        this.catMunicipio = this.formatCatalogos('municipio', result);
        this.busquedaInstitucion.municipio = this.filtraCatalogoClave(this.catMunicipio, model.municipio.clave);
      });
  }

  private formatCatalogos(tipo: string, catalogo: any[]) {
    switch (tipo) {
      case 'institucion':
        catalogo.forEach(c => {
          if (c.value) {
            delete c.value.id_tipo_institucion;
            delete c.value.nombre_tipo_institucion;
            delete c.value.nombre_municipio;
            delete c.value.id_municipio;
            delete c.value.nombre_municipio;
            delete c.value.id_municipio;
            delete c.value.nombre_municipio;
          }
        });
        break;
      case 'municipio':
        catalogo.forEach(c => {
          if (c.value) {
            delete c.value.id_estado;
          }
        });
        break;
    }
    return catalogo;
  }

  public copyProperties(model: InstitucionModel) {
    this.busquedaInstitucion.institucion.id = model.id;
    this.busquedaInstitucion.institucion.clave = model.clave;
    this.busquedaInstitucion.institucion.nombre = model.nombre;
    if (model.pais) {
      this.busquedaInstitucion.institucion.id_pais = model.pais.id;
      this.busquedaInstitucion.institucion.nombre_pais = model.pais.nombre;
    }
    if (model.tipo.id == '01') {
      //id entidad y nombre_entidad solo existen como propiedad en la respuesta de la consulta cuando es nacional, para extranjero no se ocupa
      this.busquedaInstitucion.institucion.id_entidad = null;
      this.busquedaInstitucion.institucion.nombre_entidad = null;
      //
    }
    if (model.entidad) {
      this.busquedaInstitucion.institucion.id_entidad = model.entidad.clave;
      this.busquedaInstitucion.institucion.nombre_entidad = this.convertirEstado(model.entidad.nombre);
    }
    this.busquedaInstitucion.institucion.id_tipo_inst_nivel_uno = model.nivelUno.id;
    this.busquedaInstitucion.institucion.nombre_tipo_inst_nivel_uno = model.nivelUno.nombre;
    this.busquedaInstitucion.institucion.id_tipo_inst_nivel_dos = null;
    this.busquedaInstitucion.institucion.nombre_tipo_inst_nivel_dos = null;
    if (model.nivelDos) {
      this.busquedaInstitucion.institucion.id_tipo_inst_nivel_dos = model.nivelDos.id;
      this.busquedaInstitucion.institucion.nombre_tipo_inst_nivel_dos = model.nivelDos.nombre;
    }
    this.busquedaInstitucion.tipoInstitucion = model.tipo;
    this.busquedaInstitucion.pais = model.pais;
    if (model.tipo.id == '01') {
      this.busquedaInstitucion.publicaPrivada.id = model.nivelUno.id;
      this.busquedaInstitucion.publicaPrivada.nombre = model.nivelUno.nombre;
      this.busquedaInstitucion.publicaPrivada.id2 = '';
      this.busquedaInstitucion.publicaPrivada.nombre2 = '';
      if (model.nivelUno.id == '01') {
        this.busquedaInstitucion.publicaPrivada.id2 = model.nivelDos.id;
        this.busquedaInstitucion.publicaPrivada.nombre2 = model.nivelDos.nombre;
      }
    } else {
      this.busquedaInstitucion.publicaPrivada = null;
    }
  }

  public edit() {
    this.isEdit = !this.isEdit;
  }

  public cancel(): void {
    this.busquedaInstitucion = EntityFactory.setInstitucion();
    this.editingIndex = null;
    this.onTipoChanged(claveInstNacional);
    this.edit();
  }

  public addInstitucion(bI: any): void {
    if (!this.$v.busquedaInstitucion.$invalid) {
      this.setInstitucionmodel(bI);
      if (this.editingIndex === null) {
        this.institucionCards.push(this.creaRegistroCard(this.institucionModel));
      } else {
        //update
        this.$set(this.institucionCards, this.editingIndex, this.creaRegistroCard(this.institucionModel));
        this.editingIndex = null;
      }
      this.$emit('addInstitucion', this.institucionModel);
      this.edit();
      this.busquedaInstitucion = EntityFactory.setInstitucion();
      this.$v.busquedaInstitucion.$reset();
      this.validInstComponent = false;
    } else {
      this.$v.busquedaInstitucion.$touch();
      this.validInstComponent = true;
    }
  }

  private setInstitucionmodel(val) {
    //TODO se agrega institución manualmente con el mismo modelo, setteando algunos datos en duro con la plabra clave: "ingresoManualInstitucion" e id: 0
    if (!val.institucionManual.nombre) {
      this.institucionModel.tipo = val.tipoInstitucion;
      this.institucionModel.id = val.institucion.id;
      this.institucionModel.clave = val.institucion.clave;
      this.institucionModel.nombre = val.institucion.nombre;
      if (val.institucion.id_tipo_inst_nivel_uno) {
        this.institucionModel.nivelUno = {
          id: val.institucion.id_tipo_inst_nivel_uno,
          nombre: val.institucion.nombre_tipo_inst_nivel_uno,
        };
      }
      if (val.institucion.id_tipo_inst_nivel_dos) {
        this.institucionModel.nivelDos = {
          id: val.institucion.id_tipo_inst_nivel_dos,
          nombre: val.institucion.nombre_tipo_inst_nivel_dos,
        };
      }
      if (val.institucion.id_pais) {
        this.institucionModel.pais = {
          id: val.institucion.id_pais,
          nombre: val.institucion.nombre_pais,
        };
      }
      if (val.tipoInstitucion.id == claveInstNacional) {
        this.institucionModel.entidad = null;
        if (val.institucion.id_entidad) {
          //Se permite guardar en null por si reemplaza un registro, ya que hay registros en el catalogo institucion que pueden tener o no, datos en entidad
          this.institucionModel.entidad = {
            clave: val.institucion.id_entidad,
            nombre: val.institucion.nombre_entidad,
          };
        }

        if (val.municipio && val.municipio.id) {
          this.institucionModel.municipio = {
            clave: val.municipio.clave,
            nombre: val.municipio.nombre,
          };
        }
      } else {
        this.institucionModel.entidad = null;
        this.institucionModel.municipio = null;
      }
    } else {
      (this.institucionModel.tipo = {
        id: ingManInst,
        nombre: ingManInst,
      }),
        (this.institucionModel.id = val.institucionManual.id);
      this.institucionModel.clave = val.institucionManual.clave;
      this.institucionModel.nombre = val.institucionManual.nombre;
      this.institucionModel.pais = {
        id: ingManInst,
        nombre: ingManInst,
      };
    }
  }

  private creaRegistroCard(val: InstitucionModel): any {
    if (val.clave != ingManInst) {
      let lugar = '';
      let nivel2 = '';

      if (val.pais && val.pais.id) lugar = 'País: ' + val.pais.nombre;

      if (val.entidad && val.entidad.clave) lugar = 'Entidad federativa: ' + val.entidad.nombre;

      if (val.nivelDos && val.nivelDos.id) nivel2 = '- ' + val.nivelDos.nombre;

      return {
        title: val.id ? val.nombre : '',
        description: lugar,
        hidden: true,
        footer: val.nivelUno ? val.nivelUno.nombre + nivel2 : '',
        icon: 'icon-libro',
      };
    } else {
      return {
        hidden: true,
        title: val.id ? val.nombre : '',
        icon: 'icon-libro',
      };
    }
  }

  public esIgual() {
    if (this.isEdit && this.busquedaInstitucion.institucion != null && !this.setteoManual) {
      if (this.municipioIf) {
        return (
          isEqual(this.institucionModel.tipo, this.busquedaInstitucion.tipoInstitucion) &&
          this.institucionModel.nivelUno.id == this.busquedaInstitucion.publicaPrivada.id &&
          this.institucionModel.nivelDos.id == this.busquedaInstitucion.publicaPrivada.id2 &&
          isEqual(this.institucionModel.entidad, this.busquedaInstitucion.entidad) &&
          isEqual(this.institucionModel.municipio, this.busquedaInstitucion.municipio) &&
          this.institucionModel.id == this.busquedaInstitucion.institucion.id
        );
      }
      if (this.entidadIf) {
        return (
          isEqual(this.institucionModel.tipo, this.busquedaInstitucion.tipoInstitucion) &&
          this.institucionModel.nivelUno.id == this.busquedaInstitucion.publicaPrivada.id &&
          this.institucionModel.nivelDos.id == this.busquedaInstitucion.publicaPrivada.id2 &&
          isEqual(this.institucionModel.entidad, this.busquedaInstitucion.entidad) &&
          this.institucionModel.id == this.busquedaInstitucion.institucion.id
        );
      }
      if (this.paisIf) {
        return (
          this.institucionModel.tipo.id == this.busquedaInstitucion.tipoInstitucion.id &&
          this.institucionModel.pais.id == this.busquedaInstitucion.pais.id &&
          this.institucionModel.id == this.busquedaInstitucion.institucion.id
        );
      } else {
        return (
          isEqual(this.institucionModel.tipo, this.busquedaInstitucion.tipoInstitucion) &&
          this.institucionModel.nivelUno.id == this.busquedaInstitucion.publicaPrivada.id &&
          this.institucionModel.nivelDos.id == this.busquedaInstitucion.publicaPrivada.id2 &&
          this.institucionModel.id == this.busquedaInstitucion.institucion.id
        );
      }
    } else if (this.busquedaInstitucion?.institucionManual != '') {
      return true;
    } else {
      return true;
    }
  }
  /**
   * se hace un filtro a los catalogos por clave para sacar el modelo y setearlo en el formulario
   * @param cat catalogo que se va a filtrar
   * @param val valor con el cual se va a filtrar
   * @returns el valor del catalogo
   */

  // filtra catalogo por clave
  public filtraCatalogoClave(cat: any[], val: any) {
    const filtro = cat.filter(e => e.value.clave == val);
    return filtro[0].value;
  }

  private validaCampoBuscador() {
    if (this.busquedaInstitucion.buscadorInstitucion == '' || this.busquedaInstitucion.buscadorInstitucion.length <= 2) {
      this.$bvModal
        .msgBoxOk('Se requiere ingresar minimo 3 letras de búsqueda para seleccionar esta opción', {
          title: 'Informativo',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'primary',
          okTitle: 'Ok',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          this.onTipoChanged(this.busquedaInstitucion.tipoInstitucion.id);
          const input = document.getElementById('busquedaInstitucion.buscadorInstitucion-id');
          input.focus();
        });
      return false;
    }
    return true;
  }

  private convertirEstado(estado: string) {
    // Se pone esta validacion por que los datos migrados de la base MIIC vienen en mayusculas
    // pero el catalogo saci provee la informacion de un formato diferente por lo que no se llega a preeseleccionar
    //  en la opcion de consulta. Solo se convierte la informacion migrada al mismo formato del catalogo para empatar
    if (estado === estado.toUpperCase()) {
      return estados[this.textoSinAcentosNiespacios(estado)];
    } else {
      return estado;
    }
  }

  private textoSinAcentosNiespacios(texto: string) {
    return texto
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '_');
  }

  openModal(): void {
    this.$bvModal.show('modalInstitucionAyudaId');
  }

  /**
   * Acciones si existe la selección de ingreso manual de la institución
   */
  public ingresoManual() {
    if (this.changeInstitucionManual) {
      if (this.catTipoInstitucion.length == 0) this.getTipoInstituciones();
      this.onTipoChanged(claveInstNacional);
      this.busquedaInstitucion = EntityFactory.setInstitucion();
      this.catPublicaPrivada = EntityFactory.getTipoPublicaPrivada(this.$t.bind(this));
      this.publicPrivadaIf = true;
    } else {
      this.editingIndex = 0;
      this.setValoresDefault();
    }
    this.setteoManual = !this.setteoManual;
    this.changeInstitucionManual = !this.changeInstitucionManual;
  }

  public validaDisabled(): boolean {
    if (this.institucionModel.nombre) {
      if (!this.setteoManual) {
        return this.editingIndex != null && this.esIgual();
      } else {
        if (
          this.busquedaInstitucion.institucionManual.nombre &&
          this.busquedaInstitucion.institucionManual.nombre != this.institucionModel.nombre
        ) {
          return false;
        }
        return true;
      }
    }
  }
}
