import { render, staticRenderFns } from "./etapa-desarrollo.vue?vue&type=template&id=5aef116e&"
import script from "./etapa-desarrollo.component.ts?vue&type=script&lang=ts&"
export * from "./etapa-desarrollo.component.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/0/gateway/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5aef116e')) {
      api.createRecord('5aef116e', component.options)
    } else {
      api.reload('5aef116e', component.options)
    }
    module.hot.accept("./etapa-desarrollo.vue?vue&type=template&id=5aef116e&", function () {
      api.rerender('5aef116e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/components/etapa-desarrollo/etapa-desarrollo.vue"
export default component.exports