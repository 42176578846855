var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.type == "form"
        ? [
            _c("b-skeleton", {
              staticClass: "mb-3 mt-3",
              attrs: { width: "85%" },
            }),
            _vm._v(" "),
            _c("b-skeleton", {
              staticClass: "mb-3 mt-3",
              attrs: { width: "55%" },
            }),
            _vm._v(" "),
            _c("b-skeleton", {
              staticClass: "mb-3 mt-3",
              attrs: { width: "70%" },
            }),
            _vm._v(" "),
            _c("b-skeleton", {
              staticClass: "mb-3 mt-3",
              attrs: { width: "85%" },
            }),
            _vm._v(" "),
            _c("b-skeleton", {
              staticClass: "mb-3 mt-3",
              attrs: { width: "55%" },
            }),
            _vm._v(" "),
            _c("b-skeleton", {
              staticClass: "mb-3 mt-3",
              attrs: { width: "70%" },
            }),
            _vm._v(" "),
            _c("b-skeleton", {
              staticClass: "mb-3 mt-3",
              attrs: { width: "85%" },
            }),
            _vm._v(" "),
            _c("b-skeleton", { attrs: { type: "input" } }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-5 mt-5 float-right" },
              [_c("b-skeleton", { attrs: { type: "button" } })],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "card"
        ? [
            _c(
              "b-card",
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { sm: "10" } },
                      [
                        _c("b-skeleton", {
                          staticClass: "mb-3",
                          attrs: { width: "80%" },
                        }),
                        _vm._v(" "),
                        _c("b-skeleton", {
                          staticClass: "mb-3",
                          attrs: { width: "30%" },
                        }),
                        _vm._v(" "),
                        _c("b-skeleton", { attrs: { width: "25%" } }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { attrs: { sm: "2" } },
                      [
                        _c("b-skeleton", {
                          staticClass: "mb-3",
                          staticStyle: { margin: "auto" },
                          attrs: { width: "50%" },
                        }),
                        _vm._v(" "),
                        _c("b-skeleton", {
                          staticStyle: { margin: "auto" },
                          attrs: { type: "avatar" },
                        }),
                        _vm._v(" "),
                        _c("b-skeleton", {
                          staticClass: "mt-1",
                          staticStyle: { margin: "auto" },
                          attrs: { width: "33%" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "social"
        ? [
            _c(
              "b-card",
              { staticClass: "mt-5" },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { lg: "1", sm: "12" } },
                      [_c("b-skeleton", { attrs: { type: "avatar" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { attrs: { lg: "8", sm: "12" } },
                      [
                        _c("b-skeleton", {
                          staticClass: "mt-3",
                          attrs: { width: "25%" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "border-custom" }),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "mt-3", attrs: { lg: "12" } },
                      [
                        _c("b-skeleton", {
                          staticClass: "mb-3",
                          attrs: { width: "85%" },
                        }),
                        _vm._v(" "),
                        _c("b-skeleton", {
                          staticClass: "mb-3",
                          attrs: { width: "65%" },
                        }),
                        _vm._v(" "),
                        _c("b-skeleton", {
                          staticClass: "mb-3",
                          attrs: { width: "75%" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("b-col", [_c("b-skeleton-img")], 1),
                    _vm._v(" "),
                    _c("b-col", [_c("b-skeleton-img")], 1),
                    _vm._v(" "),
                    _c("b-col", [_c("b-skeleton-img")], 1),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-card",
              { staticClass: "mt-5" },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { lg: "1", sm: "12" } },
                      [_c("b-skeleton", { attrs: { type: "avatar" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { attrs: { lg: "8", sm: "12" } },
                      [
                        _c("b-skeleton", {
                          staticClass: "mt-3",
                          attrs: { width: "25%" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "border-custom" }),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "mt-3", attrs: { lg: "12" } },
                      [
                        _c("b-skeleton", {
                          staticClass: "mb-3",
                          attrs: { width: "85%" },
                        }),
                        _vm._v(" "),
                        _c("b-skeleton", {
                          staticClass: "mb-3",
                          attrs: { width: "65%" },
                        }),
                        _vm._v(" "),
                        _c("b-skeleton", {
                          staticClass: "mb-3",
                          attrs: { width: "75%" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "image"
        ? [
            _c(
              "b-card",
              { staticClass: "mb-4" },
              [
                _c(
                  "b-row",
                  { attrs: { "align-v": "center" } },
                  [
                    _c(
                      "b-col",
                      { attrs: { lg: "1", sm: "12" } },
                      [
                        _c("b-skeleton", {
                          staticClass: "m-4",
                          attrs: { type: "avatar", size: "5rem" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { attrs: { lg: "8", sm: "12" } },
                      [
                        _c("b-skeleton", {
                          staticClass: "ml-5",
                          attrs: { width: "10%" },
                        }),
                        _vm._v(" "),
                        _c("b-skeleton", {
                          staticClass: "ml-5",
                          attrs: { width: "20%" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "grupos"
        ? [
            _c(
              "div",
              { staticClass: "mb-4 shadow-sm border p-4 rounded-lg" },
              [
                _c(
                  "b-row",
                  { attrs: { "align-v": "center" } },
                  [
                    _c(
                      "b-col",
                      { attrs: { sm: "12" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              {
                                staticClass: "border-right",
                                attrs: { cols: "9" },
                              },
                              [
                                _c("b-skeleton", {
                                  staticClass: "mb-3 mt-3",
                                  attrs: { width: "85%" },
                                }),
                                _vm._v(" "),
                                _c("b-skeleton", {
                                  staticClass: "mb-3 mt-3",
                                  attrs: { width: "25%" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              [
                                _c("b-skeleton", {
                                  staticClass: "mb-3 mt-3",
                                  attrs: { width: "100%" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-4 shadow-sm border p-4 rounded-lg" },
              [
                _c(
                  "b-row",
                  { attrs: { "align-v": "center" } },
                  [
                    _c(
                      "b-col",
                      { attrs: { sm: "12" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              {
                                staticClass: "border-right",
                                attrs: { cols: "9" },
                              },
                              [
                                _c("b-skeleton", {
                                  staticClass: "mb-3 mt-3",
                                  attrs: { width: "100%" },
                                }),
                                _vm._v(" "),
                                _c("b-skeleton", {
                                  staticClass: "mb-3 mt-3",
                                  attrs: { width: "25%" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              [
                                _c("b-skeleton", {
                                  staticClass: "mb-3 mt-3",
                                  attrs: { width: "100%" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-4 shadow-sm border p-4 rounded-lg" },
              [
                _c(
                  "b-row",
                  { attrs: { "align-v": "center" } },
                  [
                    _c(
                      "b-col",
                      { attrs: { sm: "12" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              {
                                staticClass: "border-right",
                                attrs: { cols: "9" },
                              },
                              [
                                _c("b-skeleton", {
                                  staticClass: "mb-3 mt-3",
                                  attrs: { width: "75%" },
                                }),
                                _vm._v(" "),
                                _c("b-skeleton", {
                                  staticClass: "mb-3 mt-3",
                                  attrs: { width: "25%" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              [
                                _c("b-skeleton", {
                                  staticClass: "mb-3 mt-3",
                                  attrs: { width: "100%" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "miPerfil"
        ? [
            _c(
              "b-row",
              { attrs: { "align-v": "center" } },
              [
                _c(
                  "b-col",
                  [
                    _c("b-skeleton", {
                      staticClass: "mb-3",
                      attrs: { width: "85%" },
                    }),
                    _vm._v(" "),
                    _c("b-skeleton", {
                      staticClass: "mb-3",
                      attrs: { width: "65%" },
                    }),
                    _vm._v(" "),
                    _c("b-skeleton", {
                      staticClass: "mb-3",
                      attrs: { width: "75%" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-card",
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { lg: "1", sm: "12" } },
                      [_c("b-skeleton", { attrs: { type: "avatar" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { attrs: { lg: "8", sm: "12" } },
                      [
                        _c("b-skeleton", {
                          staticClass: "mb-3",
                          attrs: { width: "20%" },
                        }),
                        _vm._v(" "),
                        _c("b-skeleton", {
                          staticClass: "mb-3 mt-3",
                          attrs: { width: "30%" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("b-col", { attrs: { lg: "12" } }, [
                      _c(
                        "div",
                        { staticClass: "float-right" },
                        [_c("b-skeleton", { attrs: { type: "button" } })],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "solicitud"
        ? [
            _c(
              "b-row",
              { staticClass: "mt-4", attrs: { "align-v": "center" } },
              [
                _c(
                  "b-col",
                  [
                    _c("b-skeleton", {
                      staticClass: "mb-3",
                      attrs: { width: "85%" },
                    }),
                    _vm._v(" "),
                    _c("b-skeleton", {
                      staticClass: "mb-3",
                      attrs: { width: "65%" },
                    }),
                    _vm._v(" "),
                    _c("b-skeleton", {
                      staticClass: "mb-3",
                      attrs: { width: "75%" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-card",
              { staticClass: "mt-4" },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { lg: "1", sm: "12" } },
                      [_c("b-skeleton", { attrs: { type: "avatar" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { attrs: { lg: "8", sm: "12" } },
                      [
                        _c("b-skeleton", {
                          staticClass: "mb-3",
                          attrs: { width: "20%" },
                        }),
                        _vm._v(" "),
                        _c("b-skeleton", {
                          staticClass: "mb-3 mt-3",
                          attrs: { width: "30%" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("b-col", { attrs: { lg: "12" } }, [
                      _c(
                        "div",
                        { staticClass: "float-right" },
                        [_c("b-skeleton", { attrs: { type: "button" } })],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-row",
              { staticClass: "mt-4", attrs: { "align-v": "center" } },
              [
                _c(
                  "b-col",
                  [
                    _c("b-skeleton", {
                      staticClass: "mb-3",
                      attrs: { width: "85%" },
                    }),
                    _vm._v(" "),
                    _c("b-skeleton", {
                      staticClass: "mb-3",
                      attrs: { width: "65%" },
                    }),
                    _vm._v(" "),
                    _c("b-skeleton", {
                      staticClass: "mb-3",
                      attrs: { width: "75%" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-card",
              { staticClass: "mt-4" },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { lg: "1", sm: "12" } },
                      [_c("b-skeleton", { attrs: { type: "avatar" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { attrs: { lg: "8", sm: "12" } },
                      [
                        _c("b-skeleton", {
                          staticClass: "mb-3",
                          attrs: { width: "20%" },
                        }),
                        _vm._v(" "),
                        _c("b-skeleton", {
                          staticClass: "mb-3 mt-3",
                          attrs: { width: "30%" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("b-col", { attrs: { lg: "12" } }, [
                      _c(
                        "div",
                        { staticClass: "float-right" },
                        [_c("b-skeleton", { attrs: { type: "button" } })],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }