var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("menu-tabbar"),
      _vm._v(" "),
      _c("ribbon"),
      _vm._v(" "),
      _c("jhi-navbar"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "wrapper",
          class: { toggled: !_vm.authenticated },
          attrs: { id: "app" },
        },
        [
          _c("div", { attrs: { id: "sidebar-wrapper" } }, [_c("sidebar")], 1),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { id: "page-content-wrapper" } },
            [
              _c("header", { class: _vm.headerClasses }, [_c("dataUser")], 1),
              _vm._v(" "),
              _vm.isLoginPage ? _c("breadcrumb") : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "plr-50 container" }, [
                _c(
                  "div",
                  [
                    _c("router-view"),
                    _vm._v(" "),
                    _c(
                      "b-modal",
                      {
                        attrs: {
                          id: "avisoPrivacidadModal",
                          size: "xl",
                          title: _vm.$t("aviso.title"),
                          scrollable: "",
                          "z-index": "2000",
                          "no-close-on-esc": "",
                          "no-close-on-backdrop": "",
                          "hide-header-close": "",
                          centered: "",
                          "footer-class": "aviso-custom-modal",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "modal-footer",
                            fn: function () {
                              return [
                                _c(
                                  "b-row",
                                  [
                                    _c("b-col", { attrs: { md: "12" } }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              attrs: {
                                                name: "check-button",
                                                switch: "",
                                              },
                                              on: { change: _vm.changeAcepto },
                                              model: {
                                                value:
                                                  _vm.$v.aviso.aceptado.$model,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.$v.aviso.aceptado,
                                                    "$model",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "$v.aviso.aceptado.$model",
                                              },
                                            },
                                            [
                                              _c("b", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "aviso.messages.manifiesto-aceptacion"
                                                    )
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("b-col", { attrs: { md: "12" } }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              attrs: {
                                                name: "check-button",
                                                switch: "",
                                                disabled:
                                                  !_vm.$v.aviso.aceptado.$model,
                                              },
                                              model: {
                                                value:
                                                  _vm.$v.aviso
                                                    .aceptaTransferencia.$model,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.$v.aviso
                                                      .aceptaTransferencia,
                                                    "$model",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "$v.aviso.aceptaTransferencia.$model",
                                              },
                                            },
                                            [
                                              _c("b", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "aviso.messages.manifiesto-otorga"
                                                    )
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("b-col", { attrs: { md: "12" } }, [
                                      _c(
                                        "div",
                                        { staticClass: "float-right" },
                                        [
                                          _vm.$v.aviso.aceptado.$model
                                            ? _c(
                                                "b-button",
                                                {
                                                  staticClass: "float-right",
                                                  attrs: {
                                                    variant: "primary",
                                                    size: "sm",
                                                  },
                                                  on: {
                                                    click: _vm.aceptoAviso,
                                                  },
                                                },
                                                [
                                                  _c("span", {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t(
                                                          "aviso.btn.acepto"
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [_c("aviso-privacidad")],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-modal",
                      {
                        attrs: {
                          id: "correoAlternativoModal",
                          title: _vm.$t(
                            "correo-alternativo.modal-informacion.title"
                          ),
                          size: "lg",
                          "z-index": "2",
                          "no-close-on-esc": "",
                          "no-close-on-backdrop": "",
                          centered: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "modal-footer",
                            fn: function () {
                              return [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "float-right",
                                    attrs: {
                                      variant: "outline-primary",
                                      to: "/cuenta/correo-alternativo",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$bvModal.hide(
                                          "correoAlternativoModal"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t(
                                            "correo-alternativo.action.register"
                                          )
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t(
                                "correo-alternativo.modal-informacion.mensaje"
                              )
                            ),
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("jhi-footer"),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }