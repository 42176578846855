import { Module } from 'vuex';

export const solucionesStore: Module<any, any> = {
  state: {
    soluciones: null,
  },
  getters: {
    soluciones: state => state.soluciones,
  },
  mutations: {
    setSoluciones(state, soluciones) {
      state.soluciones = soluciones;
    },
  },
};
