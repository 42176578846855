import AccountService from '@/account/account.service';
import LoginService from '@/account/login.service';
import TranslationService from '@/locale/translation.service';
import { Component, Inject, Vue } from 'vue-property-decorator';

import EntitiesMenu from '@/entities/entities-menu.vue';
import ContactoAsistenciaComponent from '../contacto/contacto-asistencia.vue';

@Component({
  components: {
    'entities-menu': EntitiesMenu,
    'contacto-asistencia': ContactoAsistenciaComponent,
  },
})
export default class JhiNavbar extends Vue {
  @Inject('loginService')
  private loginService: () => LoginService;
  @Inject('translationService') private translationService: () => TranslationService;
  @Inject('accountService') private accountService: () => AccountService;

  public version = 'v' + VERSION;
  private currentLanguage = this.$store.getters.currentLanguage;
  private languages: any = this.$store.getters.languages;
  private hasAnyAuthorityValues = {};

  public subIsActive(input) {
    const paths = Array.isArray(input) ? input : [input];
    return paths.some(path => {
      return this.$route.path.indexOf(path) === 0; // current path starts with this path string
    });
  }

  public logout(): void {
    this.loginService()
      .logout()
      .then(response => {
        this.$store.commit('logout');
        this.$router.push('/', () => {});
        const data = response.data;
        let logoutUrl = data.logoutUrl;
        // if Keycloak, uri has protocol/openid-connect/token
        if (logoutUrl.indexOf('/protocol') > -1) {
          if (logoutUrl.indexOf('?redirect_uri=') === -1) {
            logoutUrl = logoutUrl + '?redirect_uri=' + window.location.origin;
          }
        } else {
          // Okta
          logoutUrl = logoutUrl + '?id_token_hint=' + data.idToken + '&post_logout_redirect_uri=' + window.location.origin;
        }
        window.location.href = logoutUrl;
      })
      .catch(() => {
        this.$store.commit('logout');
        window.location.href = window.location.origin;
      });
  }

  public openLogin(): void {
    this.loginService().login();
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public hasAnyAuthority(authorities: any): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth(authorities)
      .then(value => {
        this.hasAnyAuthorityValues[authorities] = value;
      });
    return this.hasAnyAuthorityValues[authorities] ?? false;
  }

  public reportarIncidencia(): void {
    this.$bvModal.show('modalIncidencia');
  }

  public sugerencia(): void {
    this.$bvModal.show('modalSugerencia');
  }

  public changeLanguage(newLanguage: string): void {
    this.translationService().refreshTranslation(newLanguage);
  }

  public isActiveLanguage(key: string): boolean {
    return key === this.$store.getters.currentLanguage;
  }
}
