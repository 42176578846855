import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import PropertyCommons from '../commons/property.model';

import { mixins } from 'vue-class-component';
@Component
export default class TablePageableComponent extends mixins(PropertyCommons) {
  @Prop({ required: true, type: Array })
  public items!: Array<any>;

  @Prop({ required: true, type: Array })
  public columns!: Array<any>;

  @Prop({ default: false })
  public busy: boolean;

  @Prop({ default: 'id' })
  public trackedBy: string;

  @Prop({ default: 'id' })
  public paramId: string;

  @Prop()
  public editView: string;

  @Prop()
  public detailsView: string;

  public removeId = null;

  public prepareRemove(instance: any): void {
    this.removeId = instance.id;
    this.$bvModal.show('removeEntity');
  }

  public remove(): void {
    this.$emit('remove', this.removeId);
  }
}
