import Component, { mixins } from 'vue-class-component';
import { Inject, Prop, Watch } from 'vue-property-decorator';
import PropertyCommons from '../commons/property.model';
import PerfilService from '@/shared/perfil/perfil.service';
import { TotalDocumentosApi } from '@/shared/aportaciones/api';
import AlertService from '@/shared/alert/alert.service';
@Component
export default class CardsListComponent extends mixins(PropertyCommons) {
  @Inject('totalDocumentosApi') private totalDocumentosApi: () => TotalDocumentosApi;
  @Inject('alertService') private alertService: () => AlertService;
  @Prop({ required: true, type: Array })
  public items!: Array<any>;

  @Prop({ required: false, type: Boolean })
  public verDocumentoUpload!: boolean;

  @Prop({ default: false })
  public verPrincipal: boolean;

  @Prop({ default: false })
  public productoPrincipal: boolean;

  @Prop({ default: false })
  public busy: boolean;

  // Deshabilita componente actionButtons dentro del cardList
  @Prop({ default: false })
  public disabledBtnAcctions: boolean;

  @Prop({ default: 'id' })
  public trackedBy: string;

  @Prop({ default: 'id' })
  public paramId: string;

  @Prop()
  public editView: string;

  @Prop()
  public detailsView: string;

  @Prop({ default: '' })
  title: string;

  @Prop({ default: 0 })
  public totalPrincipal: number;

  @Prop({ default: 0 })
  public totalReferenciaPrincipal: number;

  @Prop({ default: 0 })
  public totalRegistros: number;

  @Prop({ default: 0 })
  public registrosListados: number;

  @Prop({ default: true })
  public mostrarListado: boolean;

  @Prop({ default: null })
  public tipo: string;

  public totalDocumentosCargados = 0;

  public removeIndex = null;
  public registroActual = null;

  public isFixed = false;
  public scrollThreshold = 800;

  mounted(): void {
    window.addEventListener('scroll', this.handleScroll);
  }
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const scrollPosition = window.scrollY || window.pageYOffset;
    if (scrollPosition >= this.scrollThreshold) {
      this.isFixed = true;
    } else {
      this.isFixed = false;
    }
  }

  public prepareRemove(instance: any): void {
    this.removeIndex = instance;
    this.$bvModal.show(this.id + '-removeEntityModal');
  }

  public remove(): void {
    this.$emit('remove', this.removeIndex);
    this.$bvModal.hide(this.id + '-removeEntityModal');
  }

  public edit(instance: any): void {
    this.$emit('edit', instance);
  }

  public async editDocumento(instance: any): Promise<void> {
    if (this.tipo) {
      //TODO se pone esta validacion para evitar errores, se recomienda cambiar este flujo ya que algo particular y no generico
      if (this.tipo.startsWith('DIFUSION')) {
        await this.getTotalDifusion();
      } else if (this.tipo.startsWith('CIENTIFICA') || this.tipo === '') {
        await this.getTotalProduccionTecnologicaCientifica();
      }

      if (this.totalDocumentosCargados >= 60) {
        this.alertService().showWarning(this, this.$t('Solo se permite adjuntar un máximo de 60 documentos.').toString());
        return;
      }
    }

    this.$bvModal.show('modal-documento-probatorio');
    this.$emit('editDocumento', instance);
  }

  public eliminarDocumento(instance: any): void {
    this.$emit('eliminarDocumento', instance);
  }

  public cargaDocumentos(): void {
    this.verDocumentoUpload = true;
  }

  public verDocumento(documento: any): void {
    this.$bvModal.show('modal-ver-documento-probatorio');
    //this.$emit('editDocumento', instance);
  }

  public mandaPrincipal(instance: any): void {
    // Envio en el setValorPrincipal el id en turno y el valor booleano del input-boolean en turno
    this.$emit('setValorPrincipal', instance, this.items[instance].productoPrincipal);
    this.registroActual = instance;
    this.$bvModal.show(this.id + '-saveEntityModal');
  }

  public actualizaPrincipal(): void {
    this.$emit('actualizaPrincipal');
    this.$bvModal.hide(this.id + '-saveEntityModal');
  }

  public regresaAlValor(): void {
    this.items[this.registroActual].productoPrincipal = !this.items[this.registroActual].productoPrincipal;
    this.$emit('regresaAlValor', this.registroActual, this.items[this.registroActual].productoPrincipal);
  }

  public removeClassActice(idCard) {
    if (idCard && idCard == this.$store.getters.idCard) {
      const el = document.querySelector(`[id="${idCard}"]`);
      const classCardBody = document.getElementById(`${idCard}`);
      if (classCardBody) {
        const classCard = classCardBody.querySelector('.card-body');
        setTimeout(() => {
          classCard.classList.remove('shadow-lg');
          classCard.classList.remove('border-primary');
          this.$store.commit('setIdCardNull');
        }, 500);
      }
    }
  }

  public async getTotalProduccionTecnologicaCientifica(): Promise<void> {
    try {
      const res = await this.totalDocumentosApi().getTotalProduccionTecnologicaCientifica();
      this.totalDocumentosCargados = res.data;
    } catch (err) {
      this.totalDocumentosCargados = 0;
    }
  }

  public async getTotalDifusion(): Promise<void> {
    try {
      const res = await this.totalDocumentosApi().getTotalDifusion();
      this.totalDocumentosCargados = res.data;
    } catch (err) {
      this.totalDocumentosCargados = 0;
    }
  }
}
