import Component from 'vue-class-component';
import { Inject, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import CatalogoService from '@/shared/catalogo/catalogo.service';

const VALIDATIONS = function () {
  return {
    areaConocimiento: {
      area: {
        required,
      },
      campo: {
        required,
      },
      disciplina: {
        required,
      },
      subdisciplina: {
        required,
      },
    },
  };
};

@Component({
  validations: VALIDATIONS,
})
export default class AreaConocimientoComponent extends Vue {
  @Inject('catalogoService') private catalogoService: () => CatalogoService;

  @PropSync('value', { required: true, type: Object })
  public areaConocimiento;

  @Prop({ default: false })
  public readonly: boolean;

  @Prop({ default: false })
  public required: boolean;

  @Prop({ default: null })
  public edit;

  @Prop({ default: null })
  public validA: boolean;

  @Prop({ default: 1 })
  public version: number;

  @Prop({ default: false })
  public alertaSolicitud: boolean;

  //Modifica titulo para mostrar en becas extranjero readonly
  @Prop({ default: false })
  public becasExtranjero: boolean;

  /**
   *Valida formulario desde el componente (padre)
   *
   */
  @Watch('validA', { immediate: true })
  handler(value) {
    if (value) {
      this.$v.areaConocimiento.$touch();
    } else {
      this.areaConocimiento = this.limpiaAreaC();
      this.$v.areaConocimiento.$reset();
    }
  }

  public catArea: Array<any> = [];
  public catCampo: Array<any> = [];
  public catDisciplina: Array<any> = [];
  public catSubdisciplina: Array<any> = [];

  /**
   * Variables para solo Lectura
   */
  public areaLectura = false;
  public campoLectura = true;
  public disciplinaLectura = true;
  public subdisciplinaLectura = true;
  public showAC = false;

  mounted() {
    if (this.edit != null) {
      if (this.areaConocimiento?.area?.id != null) {
        this.limpiaArea();
        this.setArea();
      }
    }
    this.consultarAreas();
    this.soloLectura();
  }

  /**
   * Pone modo solo lectura los campos
   */
  soloLectura() {
    if (this.readonly) {
      this.areaLectura = true;
      this.campoLectura = true;
      this.disciplinaLectura = true;
      this.subdisciplinaLectura = true;
    }
  }

  protected setArea() {
    this.showAC = true;
    this.catalogoService()
      .get('getCatCamposAreasConocimiento', this.areaConocimiento.area.id)
      .then(c => {
        this.campoLectura = false;
        this.catCampo = this.limpiaCatalogo(c, 'campo');
        this.catalogoService()
          .get('getCatDisciplinasCampos', this.areaConocimiento.campo.id)
          .then(d => {
            this.disciplinaLectura = false;
            this.catDisciplina = this.limpiaCatalogo(d, 'disciplina');
            this.catalogoService()
              .get('getSubDisciplinasDisciplinas', this.areaConocimiento.disciplina.id)
              .then(s => {
                this.subdisciplinaLectura = false;
                this.catSubdisciplina = this.limpiaCatalogo(s, 'subdisciplina');
                this.showAC = false;
                this.soloLectura();
              })
              .catch(() => {
                this.catSubdisciplina = [];
                this.showAC = false;
              });
          })
          .catch(() => {
            this.catDisciplina = [];
            this.showAC = false;
          });
      })
      .catch(() => {
        this.catCampo = [];
        this.showAC = false;
      });
  }

  /**
   * Consulta las áreas de conocimiento.
   */
  private consultarAreas() {
    if (this.version == 0) {
      this.catalogoService()
        .get('getAreaConocimiento')
        .then(res => {
          this.catArea = res;
        });
    } else {
      this.catalogoService()
        .get('getIdAreaConocimiento', 1)
        .then(res => {
          this.catArea = res;
        });
    }
  }

  public onAreaChanged(e) {
    if (e) {
      this.catalogoService()
        .get('getCatCamposAreasConocimiento', e.id)
        .then(res => {
          this.catCampo = res;
          this.campoLectura = false;
          this.disciplinaLectura = true;
          this.subdisciplinaLectura = true;
        });
      this.limpiaAreas('area');
    }
  }

  public onCampoChanged(e) {
    if (e) {
      this.catalogoService()
        .get('getCatDisciplinasCampos', e.id)
        .then(res => {
          this.catDisciplina = res;
          this.disciplinaLectura = false;
          this.subdisciplinaLectura = true;
        });
      this.limpiaAreas('campo');
    }
  }

  public onDisciplinaChanged(e) {
    if (e) {
      this.catalogoService()
        .get('getSubDisciplinasDisciplinas', e.id)
        .then(res => {
          this.catSubdisciplina = res;
          this.subdisciplinaLectura = false;
        });
      this.limpiaAreas('disciplina');
    }
  }

  /**
   * Limpia los campos cuando se seleccionan diferentes opciones
   */
  private limpiaAreas(nombre) {
    switch (nombre) {
      case 'area':
        this.areaConocimiento.campo = null;
        this.catCampo = [];
        this.areaConocimiento.disciplina = null;
        this.catDisciplina = [];
        this.areaConocimiento.subdisciplina = null;
        this.catSubdisciplina = [];
        break;
      case 'campo':
        this.areaConocimiento.disciplina = null;
        this.catDisciplina = [];
        this.areaConocimiento.subdisciplina = null;
        this.catSubdisciplina = [];
        break;
      case 'disciplina':
        this.areaConocimiento.subdisciplina = null;
        break;
    }
  }

  public limpiaAreaC() {
    return {
      area: null,
      campo: null,
      disciplina: null,
      subdisciplina: null,
    };
  }

  private limpiaCatalogo(catalogo, nombre) {
    catalogo.forEach(c => {
      if (c.value) {
        switch (nombre) {
          case 'campo':
            delete c.value.id_area_conocimiento;
            break;
          case 'disciplina':
            delete c.value.id_campo;
            break;
          case 'subdisciplina':
            delete c.value.id_disciplina;
            break;
        }
      }
    });
    return catalogo;
  }

  public limpiaArea() {
    if (this.areaConocimiento.campo && this.areaConocimiento.campo.id_area_conocimiento) {
      delete this.areaConocimiento.campo.id_area_conocimiento;
    }
    if (this.areaConocimiento.disciplina && this.areaConocimiento.disciplina.id_campo) {
      delete this.areaConocimiento.disciplina.id_campo;
    }
    if (this.areaConocimiento.subdisciplina && this.areaConocimiento.subdisciplina.id_disciplina) {
      delete this.areaConocimiento.subdisciplina.id_disciplina;
    }
  }
}
