var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "float-right", attrs: { md: "12" } },
        [
          _c(
            "b-button",
            { attrs: { variant: "blue" }, on: { click: _vm.abrirAyuda } },
            [
              _c("span", {
                domProps: { textContent: _vm._s(_vm.$t("global.help")) },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-ayuda" }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "dialogoAyuda",
            "ok-only": "",
            size: "xl",
            title: _vm.$t("global.menu.help"),
            scrollable: "",
            "z-index": "2000",
            "ok-title": _vm.$t("entity.action.accept"),
            centered: "",
          },
        },
        [
          _c("h4", {
            domProps: { textContent: _vm._s(_vm.$t("mi-perfil.tituloUno")) },
          }),
          _vm._v(" "),
          _c("span", {
            domProps: { textContent: _vm._s(_vm.$t("mi-perfil.texto1")) },
          }),
          _c("br"),
          _vm._v(" "),
          _c("img", {
            staticClass: "img-fluid",
            attrs: {
              src: "content/images/perfil/menu-principal.png",
              alt: "tour",
            },
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.perfil.titulo")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.perfil.texto1")),
            },
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("img", {
            staticClass: "img-fluid",
            attrs: { src: "content/images/perfil/perfil-1.png", alt: "tour" },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.mediosContacto.titulo")),
            },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.mediosContacto.texto1")),
            },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("img", {
            staticClass: "img-fluid",
            attrs: { src: "content/images/perfil/perfil-3.png", alt: "tour" },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.mediosContacto.texto2")),
            },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.mediosContacto.texto3")),
            },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.mediosContacto.texto4")),
            },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("img", {
            staticClass: "img-fluid",
            attrs: { src: "content/images/perfil/perfil-4.png", alt: "tour" },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.mediosContacto.texto5")),
            },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("img", {
            staticClass: "img-fluid",
            attrs: { src: "content/images/perfil/perfil-5.png", alt: "tour" },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.mediosContacto.texto6")),
            },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.mediosContacto.texto7")),
            },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.habilidades.titulo")),
            },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.habilidades.texto1")),
            },
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("img", {
            attrs: { src: "content/images/perfil/perfil-6.png", alt: "tour" },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.intereses.titulo")),
            },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.intereses.texto1")),
            },
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("img", {
            attrs: { src: "content/images/perfil/perfil-7.png", alt: "tour" },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.areasConocimiento.titulo")),
            },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.areasConocimiento.texto1")),
            },
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("img", {
            attrs: { src: "content/images/perfil/perfil-8.png", alt: "tour" },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.areasConocimiento.texto2")),
            },
          }),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.identificadorAutor.titulo")
              ),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.identificadorAutor.texto1")
              ),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("img", {
            attrs: { src: "content/images/perfil/perfil-9.png", alt: "tour" },
          }),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.identificadorAutor.opcionA")
              ),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.identificadorAutor.opcionB")
              ),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.identificadorAutor.opcionC")
              ),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.identificadorAutor.opcionD")
              ),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.identificadorAutor.opcionE")
              ),
            },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.acreditacionNacionalidad.titulo")
              ),
            },
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.acreditacionNacionalidad.texto1")
              ),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("img", {
            attrs: { src: "content/images/perfil/perfil-10.png", alt: "tour" },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.acreditacionNacionalidad.opcionA")
              ),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.acreditacionNacionalidad.opcionB")
              ),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.acreditacionNacionalidad.opcionC")
              ),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.acreditacionNacionalidad.opcionD")
              ),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.acreditacionNacionalidad.opcionE")
              ),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.acreditacionNacionalidad.opcionF")
              ),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.acreditacionNacionalidad.opcionG")
              ),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.acreditacionNacionalidad.opcionH")
              ),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.acreditacionNacionalidad.opcionI")
              ),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.acreditacionNacionalidad.opcionJ")
              ),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.acreditacionNacionalidad.opcionK")
              ),
            },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.documentoprobatoriodenacionalidad.titulo")
              ),
            },
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.documentoprobatoriodenacionalidad.texto1")
              ),
            },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.documentoprobatoriodenacionalidad.texto2")
              ),
            },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.documentoprobatoriodenacionalidad.texto3")
              ),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("img", {
            attrs: { src: "content/images/perfil/perfil-11.png", alt: "tour" },
          }),
          _c("br"),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.domicilio.titulo")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.domicilio.texto1")),
            },
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("img", {
            attrs: { src: "content/images/perfil/perfil-12.png", alt: "tour" },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.domicilio.opcionA")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.domicilio.opcionB")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.domicilio.opcionC")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.domicilio.opcionD")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.domicilio.opcionE")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.domicilio.opcionF")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.domicilio.opcionG")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.domicilio.opcionH")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.domicilio.opcionI")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.domicilio.opcionJ")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.domicilio.opcionK")),
            },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.titulo")),
            },
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.texto1")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("img", {
            attrs: { src: "content/images/perfil/perfil-13.png", alt: "tour" },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion1")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion2")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion3")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion4")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion5")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion6")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion7")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion8")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion9")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion10")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion11")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion12")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion13")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion14")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion15")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion16")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion17")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion18")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion19")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion20")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion21")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion22")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion23")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion24")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion25")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion26")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion27")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion28")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion29")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion30")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion31")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion32")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion33")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.vialidad.opcion34")),
            },
          }),
          _c("br"),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.referencias.titulo")),
            },
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.referencias.texto1")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.referencias.texto2")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("img", {
            attrs: { src: "content/images/perfil/perfil-14.png", alt: "tour" },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.referencias.opcionA")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.referencias.opcionB")),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("mi-perfil.referencias.opcionC")),
            },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.contactoEmergencia.titulo")
              ),
            },
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.contactoEmergencia.texto1")
              ),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("img", {
            attrs: { src: "content/images/perfil/perfil-15.png", alt: "tour" },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.contactoEmergencia.texto2")
              ),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("img", {
            attrs: { src: "content/images/perfil/perfil-16.png", alt: "tour" },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.contactoEmergencia.texto3")
              ),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.contactoEmergencia.texto4")
              ),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("img", {
            attrs: { src: "content/images/perfil/perfil-17.png", alt: "tour" },
          }),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.contactoEmergencia.texto5")
              ),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.contactoEmergencia.texto6")
              ),
            },
          }),
          _c("br"),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.dependientesEconomicos.titulo")
              ),
            },
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("mi-perfil.dependientesEconomicos.texto1")
              ),
            },
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("img", {
            attrs: { src: "content/images/perfil/perfil-18.png", alt: "tour" },
          }),
          _c("br"),
          _vm._v(" "),
          _c("br"),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }