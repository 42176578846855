import { Module } from 'vuex';

export const idCardStore: Module<any, any> = {
  state: {
    idCard: null,
  },
  getters: {
    idCard: state => state.idCard,
  },
  mutations: {
    setIdCard(state, idCard) {
      state.idCard = idCard;
    },
    setIdCardNull(state) {
      state.idCard = null;
    },
  },
};
