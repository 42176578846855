var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-col",
    { attrs: { md: "12" } },
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { md: "12" } }, [
            _c("h3", {
              staticClass: "m-h3 mb-5",
              domProps: {
                textContent: _vm._s(_vm.$t("dependientes-economicos.title")),
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c("b-alert", {
                attrs: { show: "", variant: "primary" },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("dependientes-economicos.alerts.orden-aviso")
                  ),
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [_c("alerta", { ref: "alerta" })],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "6" } },
            [
              _c("input-select-radio", {
                attrs: {
                  id: "dependientes-economicos-esExtranjero-id",
                  label: _vm.$t("dependientes-economicos.esExtranjero.label"),
                  options: _vm.nacionalidadOptions,
                  required: true,
                  valid: _vm.$v.dependientesNuevo.esExtranjero.$dirty
                    ? !_vm.$v.dependientesNuevo.esExtranjero.$error
                    : null,
                },
                on: { change: _vm.changeNacionalidad },
                model: {
                  value: _vm.$v.dependientesNuevo.esExtranjero.$model,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$v.dependientesNuevo.esExtranjero,
                      "$model",
                      $$v
                    )
                  },
                  expression: "$v.dependientesNuevo.esExtranjero.$model",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "6" } },
            [
              _c("input-text", {
                attrs: {
                  id: "dependientes-economicos-curp-id",
                  label: _vm.$t("dependientes-economicos.curp.label"),
                  description: _vm.$t(
                    "dependientes-economicos.curp.description"
                  ),
                  placeholder: _vm.$t(
                    "dependientes-economicos.curp.placeholder"
                  ),
                  readonly: _vm.curpReadOnly,
                  required: true,
                  valid: _vm.$v.dependientesNuevo.curp.$dirty
                    ? !_vm.$v.dependientesNuevo.curp.$error
                    : null,
                  validationsCommons: {
                    requiredValue: !_vm.$v.dependientesNuevo.curp.required,
                    requiredMessage: _vm.$t(
                      "dependientes-economicos.curp.validations.required"
                    ),
                    minValue: !_vm.$v.dependientesNuevo.curp.minLength,
                    minMessage: _vm.$t(
                      "dependientes-economicos.curp.validations.minMessage",
                      { min: "1" }
                    ),
                    maxValue: !_vm.$v.dependientesNuevo.curp.maxLength,
                    maxMessage: _vm.$t(
                      "dependientes-economicos.curp.validations.maxMessage",
                      { max: "18" }
                    ),
                    regexValue: !_vm.$v.dependientesNuevo.curp.CURP,
                    regexMessage: _vm.$t(
                      "dependientes-economicos.curp.validations.regexMessage"
                    ),
                  },
                },
                on: { input: _vm.capitalsOnly },
                model: {
                  value: _vm.$v.dependientesNuevo.curp.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.dependientesNuevo.curp, "$model", $$v)
                  },
                  expression: "$v.dependientesNuevo.curp.$model",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4" } },
            [
              _c("input-text", {
                attrs: {
                  id: "dependientes-economicos-nombre-id",
                  label: _vm.$t("dependientes-economicos.nombre.label"),
                  readonly: false,
                  required: true,
                  valid: _vm.$v.dependientesNuevo.nombre.$dirty
                    ? !_vm.$v.dependientesNuevo.nombre.$error
                    : null,
                  validationsCommons: {
                    requiredValue: !_vm.$v.dependientesNuevo.nombre.required,
                    requiredMessage: _vm.$t(
                      "dependientes-economicos.nombre.validations.required"
                    ),
                    minValue: !_vm.$v.dependientesNuevo.nombre.minLength,
                    minMessage: _vm.$t(
                      "dependientes-economicos.nombre.validations.minMessage",
                      { min: "2" }
                    ),
                    maxValue: !_vm.$v.dependientesNuevo.nombre.maxLength,
                    maxMessage: _vm.$t(
                      "dependientes-economicos.nombre.validations.maxMessage",
                      { max: "50" }
                    ),
                  },
                  maxCaracteres: 50,
                },
                model: {
                  value: _vm.$v.dependientesNuevo.nombre.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.dependientesNuevo.nombre, "$model", $$v)
                  },
                  expression: "$v.dependientesNuevo.nombre.$model",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4" } },
            [
              _c("input-text", {
                attrs: {
                  id: "dependientes-economicos.apellido-paterno-id",
                  label: _vm.$t(
                    "dependientes-economicos.apellido-paterno.label"
                  ),
                  readonly: false,
                  required: true,
                  valid: _vm.$v.dependientesNuevo.primerApellido.$dirty
                    ? !_vm.$v.dependientesNuevo.primerApellido.$error
                    : null,
                  validationsCommons: {
                    requiredValue:
                      !_vm.$v.dependientesNuevo.primerApellido.required,
                    requiredMessage: _vm.$t(
                      "dependientes-economicos.apellido-paterno.validations.required"
                    ),
                    minValue:
                      !_vm.$v.dependientesNuevo.primerApellido.minLength,
                    minMessage: _vm.$t(
                      "dependientes-economicos.apellido-paterno.validations.minMessage",
                      { min: "2" }
                    ),
                    maxValue:
                      !_vm.$v.dependientesNuevo.primerApellido.maxLength,
                    maxMessage: _vm.$t(
                      "dependientes-economicos.apellido-paterno.validations.maxMessage",
                      { max: "50" }
                    ),
                  },
                  maxCaracteres: 50,
                },
                model: {
                  value: _vm.$v.dependientesNuevo.primerApellido.$model,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$v.dependientesNuevo.primerApellido,
                      "$model",
                      $$v
                    )
                  },
                  expression: "$v.dependientesNuevo.primerApellido.$model",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4" } },
            [
              _c("input-text", {
                attrs: {
                  id: "dependientes-economicos.apellido-materno-id",
                  label: _vm.$t(
                    "dependientes-economicos.apellido-materno.label"
                  ),
                  readonly: false,
                  required: true,
                  valid: _vm.$v.dependientesNuevo.segundoApellido.$dirty
                    ? !_vm.$v.dependientesNuevo.segundoApellido.$error
                    : null,
                  validationsCommons: {
                    requiredValue:
                      !_vm.$v.dependientesNuevo.segundoApellido.required,
                    requiredMessage: _vm.$t(
                      "dependientes-economicos.apellido-materno.validations.required"
                    ),
                    minValue:
                      !_vm.$v.dependientesNuevo.segundoApellido.minLength,
                    minMessage: _vm.$t(
                      "dependientes-economicos.apellido-materno.validations.minMessage",
                      { min: "2" }
                    ),
                    maxValue:
                      !_vm.$v.dependientesNuevo.segundoApellido.maxLength,
                    maxMessage: _vm.$t(
                      "dependientes-economicos.apellido-materno.validations.maxMessage",
                      { max: "50" }
                    ),
                  },
                  maxCaracteres: 50,
                },
                model: {
                  value: _vm.$v.dependientesNuevo.segundoApellido.$model,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$v.dependientesNuevo.segundoApellido,
                      "$model",
                      $$v
                    )
                  },
                  expression: "$v.dependientesNuevo.segundoApellido.$model",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { sm: "4" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "8" } },
                    [
                      _c("input-date-time", {
                        attrs: {
                          id: "dependientes-economicos-nacimiento-id",
                          label: _vm.$t(
                            "dependientes-economicos.nacimiento.label"
                          ),
                          placeholder: _vm.$t(
                            "dependientes-economicos.nacimiento.placeholder"
                          ),
                          description: _vm.$t(
                            "dependientes-economicos.nacimiento.description"
                          ),
                          readonly: false,
                          required: true,
                          limitDate: true,
                          valid: _vm.$v.dependientesNuevo.fechaNacimiento.$dirty
                            ? !_vm.$v.dependientesNuevo.fechaNacimiento.$error
                            : null,
                          validationsCommons: {
                            requiredValue:
                              !_vm.$v.dependientesNuevo.fechaNacimiento
                                .required,
                            requiredMessage: _vm.$t(
                              "dependientes-economicos.nacimiento.validations.required"
                            ),
                          },
                        },
                        on: { change: _vm.changeEdad },
                        model: {
                          value:
                            _vm.$v.dependientesNuevo.fechaNacimiento.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.dependientesNuevo.fechaNacimiento,
                              "$model",
                              $$v
                            )
                          },
                          expression:
                            "$v.dependientesNuevo.fechaNacimiento.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("dependientes-economicos.edad.label"),
                          },
                        },
                        [
                          _c(
                            "b-badge",
                            {
                              staticClass: "padding-badge",
                              attrs: {
                                pill: "",
                                variant: _vm.edad >= 18 ? "success" : "warning",
                              },
                            },
                            [_vm._v(_vm._s(_vm.edad) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4" } },
            [
              _c("input-select-one", {
                attrs: {
                  id: "dependientes-economicos-parentesco-id",
                  label: _vm.$t("dependientes-economicos.parentesco.label"),
                  readonly: false,
                  required: true,
                  options: _vm.parentestcoOption,
                  valid: _vm.$v.dependientesNuevo.parentesco.$dirty
                    ? !_vm.$v.dependientesNuevo.parentesco.$error
                    : null,
                  validationsCommons: {
                    requiredValue:
                      !_vm.$v.dependientesNuevo.parentesco.required,
                    requiredMessage: _vm.$t(
                      "dependientes-economicos.parentesco.validations.required"
                    ),
                    regexMessage: _vm.$t(
                      "dependientes-economicos.parentesco.validations.regexMessage"
                    ),
                  },
                },
                on: { change: _vm.sexoParentescoChange },
                model: {
                  value: _vm.$v.dependientesNuevo.parentesco.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.dependientesNuevo.parentesco, "$model", $$v)
                  },
                  expression: "$v.dependientesNuevo.parentesco.$model",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("input-select-radio", {
                attrs: {
                  id: "dependientes-economicos-sexo-id",
                  label: _vm.$t("dependientes-economicos.sexo.label"),
                  options: _vm.sexoOptions,
                  readonly: _vm.sexoDisabled,
                  required: true,
                  valid: _vm.$v.dependientesNuevo.sexo.$dirty
                    ? !_vm.$v.dependientesNuevo.sexo.$error
                    : null,
                },
                model: {
                  value: _vm.$v.dependientesNuevo.sexo.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.dependientesNuevo.sexo, "$model", $$v)
                  },
                  expression: "$v.dependientesNuevo.sexo.$model",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("input-text", {
                attrs: {
                  id: "dependientes-economicos-orden-id",
                  label: _vm.$t("dependientes-economicos.orden.label"),
                  placeholder: _vm.$t(
                    "dependientes-economicos.orden.placeholder"
                  ),
                  required: true,
                  valid: _vm.$v.dependientesNuevo.orden.$dirty
                    ? !_vm.$v.dependientesNuevo.orden.$error
                    : null,
                  validationsCommons: {
                    requiredValue: !_vm.$v.dependientesNuevo.orden.required,
                    requiredMessage: _vm.$t(
                      "dependientes-economicos.orden.validations.required"
                    ),
                    maxValue: !_vm.$v.dependientesNuevo.orden.maxLength,
                    maxMessage: _vm.$t(
                      "dependientes-economicos.orden.validations.maxMessage",
                      { max: "3" }
                    ),
                  },
                },
                on: { keypress: _vm.numbersOnly, change: _vm.cambiaOrden },
                model: {
                  value: _vm.$v.dependientesNuevo.orden.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.dependientesNuevo.orden, "$model", $$v)
                  },
                  expression: "$v.dependientesNuevo.orden.$model",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c("b-col", [_c("div", { staticClass: "border-custom" })]),
          _vm._v(" "),
          _c("b-col", { staticClass: "mt-3", attrs: { md: "12" } }, [
            _c(
              "div",
              { staticClass: "mb-5 float-right" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "outline-primary" },
                    on: { click: _vm.resetForm },
                  },
                  [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(_vm.$t("entity.action.cancel")),
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "icon-cerrar" }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    attrs: { variant: "primary" },
                    on: { click: _vm.addDependiente },
                  },
                  [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(_vm.$t("entity.action.save")),
                      },
                    }),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      staticClass: "mr-2",
                      attrs: { icon: "save" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }