import {
  Configuration,
  DatosGeneralesApi,
  VariablesSociodemograficasApi,
  EducacionApi,
  ExperienciaProfesionalApi,
  SolicitudApi,
  DesarrollosInstitucionalesApi,
  TotalProductosApi,
  SolucionesApi,
  AvisosApi,
  IngresopermanenciaApi,
  PerfilApi,
  ParticipacionesApi,
  ColaboracionesApi,
  UsuariosExternosApi,
  SoporteApi,
  BuscarApi,
  ContraseaApi,
} from '@/shared/perfil-usuario';
const perfilConfiguration = new Configuration({ basePath: '/services/msperfil/api' });

const miPerfilApi = new DatosGeneralesApi(perfilConfiguration);
const gruposApi = new VariablesSociodemograficasApi(perfilConfiguration);
const desarrollosInstitucionalesApi = new DesarrollosInstitucionalesApi(perfilConfiguration);
const trayectoriaAcademicaApi = new EducacionApi(perfilConfiguration);
const cursosApi = new EducacionApi(perfilConfiguration);
const logrosApi = new EducacionApi(perfilConfiguration);
const idiomasApi = new EducacionApi(perfilConfiguration);
const trayectoriaProfesionalApi = new ExperienciaProfesionalApi(perfilConfiguration);
const evaluacionesOtorgadasApi = new ExperienciaProfesionalApi(perfilConfiguration);
const estanciasApi = new ExperienciaProfesionalApi(perfilConfiguration);
const cursosImpartidosApi = new ExperienciaProfesionalApi(perfilConfiguration);
const tesisApi = new ExperienciaProfesionalApi(perfilConfiguration);
const diplomadosApi = new ExperienciaProfesionalApi(perfilConfiguration);
const congresosApi = new ExperienciaProfesionalApi(perfilConfiguration);
const divulgacionApi = new ExperienciaProfesionalApi(perfilConfiguration);
const solicitudApi = new SolicitudApi(perfilConfiguration);
const totalProductosApi = new TotalProductosApi(perfilConfiguration);
const solucionesApi = new SolucionesApi(perfilConfiguration);
const avisosApi = new AvisosApi(perfilConfiguration);
const ingresopermanenciaApi = new IngresopermanenciaApi(perfilConfiguration);
const perfilApi = new PerfilApi(perfilConfiguration);
const participacionesApi = new ParticipacionesApi(perfilConfiguration);
const soporteApi = new SoporteApi(perfilConfiguration);
const colaboracionesApi = new ColaboracionesApi(perfilConfiguration);
const usuariosExternosApi = new UsuariosExternosApi(perfilConfiguration);
const buscarApi = new BuscarApi(perfilConfiguration);
const contraseaApi = new ContraseaApi(perfilConfiguration);

export {
  miPerfilApi,
  gruposApi,
  desarrollosInstitucionalesApi,
  trayectoriaAcademicaApi,
  cursosApi,
  logrosApi,
  idiomasApi,
  trayectoriaProfesionalApi,
  evaluacionesOtorgadasApi,
  estanciasApi,
  cursosImpartidosApi,
  tesisApi,
  diplomadosApi,
  congresosApi,
  divulgacionApi,
  solicitudApi,
  totalProductosApi,
  solucionesApi,
  avisosApi,
  ingresopermanenciaApi,
  perfilApi,
  participacionesApi,
  soporteApi,
  colaboracionesApi,
  usuariosExternosApi,
  buscarApi,
  contraseaApi,
};
