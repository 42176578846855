var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-dropdown-item",
        { attrs: { to: "/aportaciones/articulos", "active-class": "active" } },
        [_c("span", [_vm._v("Artículos")])]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/aportaciones/libros", "active-class": "active" } },
        [_c("span", [_vm._v("Libros")])]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/aportaciones/capitulos", "active-class": "active" } },
        [_c("span", [_vm._v("Capítulos")])]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/aportaciones/desarrollos-tecnologicos",
            "active-class": "active",
          },
        },
        [_c("span", [_vm._v("Desarrollos Tecnológicos")])]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/aportaciones/desarrollos-software",
            "active-class": "active",
          },
        },
        [_c("span", [_vm._v("Desarrollos Software")])]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: { to: "/aportaciones/innovaciones", "active-class": "active" },
        },
        [_c("span", [_vm._v("Innovaciones")])]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/aportaciones/patentes", "active-class": "active" } },
        [_c("span", [_vm._v("Patentes")])]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/aportaciones-simple/all", "active-class": "active" } },
        [_c("span", [_vm._v("Sin clasificación")])]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/aportaciones-simple/fortalecimiento",
            "active-class": "active",
          },
        },
        [
          _c("span", [
            _vm._v("Fortalecimiento y consolidación de la comunidad"),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/aportaciones-simple/impulso",
            "active-class": "active",
          },
        },
        [
          _c("span", [
            _vm._v(
              "Impulso a la investigación de frontera y la ciencia básica en todos los campos de conocimiento"
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/aportaciones-simple/incidencia",
            "active-class": "active",
          },
        },
        [
          _c("span", [
            _vm._v("Incidencia y atención de problemáticas nacionales"),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/aportaciones-simple/desarrollo",
            "active-class": "active",
          },
        },
        [
          _c("span", [
            _vm._v(
              "Desarrollo de Tecnologías estratégicas de vanguardia e innovación abierta para la transformación social"
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/aportaciones-simple/difusion",
            "active-class": "active",
          },
        },
        [
          _c("span", [
            _vm._v(
              "Acceso universal al conocimiento y sus beneficios sociales"
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }