import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';

@Component
export default class AlerCardComponent extends Vue {
  @Prop({ default: false })
  public error: boolean;

  @Prop({ default: true })
  public existeConvocatoria: boolean;
}
