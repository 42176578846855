var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.id,
        "header-bg-variant": "dark",
        "body-bg-variant": "light",
        "header-text-variant": "white",
        size: "xl",
        centered: "",
        "no-close-on-backdrop": "",
        "hide-header-close": "",
      },
    },
    [
      _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
        _c("span", { attrs: { id: "modal-sugerencia.title" } }, [
          _vm._v(_vm._s(_vm.$t("sugerencia.modal.title"))),
        ]),
      ]),
      _vm._v(" "),
      _c("b-alert", { attrs: { show: "", variant: "primary" } }, [
        _c("span", {
          domProps: {
            textContent: _vm._s(
              _vm.$t("sugerencia.messages.avisoImportanteContenidoSugerencia")
            ),
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-body" },
        [
          _c("input-select-one", {
            attrs: {
              id: "sugerencia-id",
              options: _vm.sugerenciaOptions,
              label: _vm.$t("sugerencia.modal.seccion"),
              readonly: false,
              required: true,
              valid: _vm.$v.selectedSuggestion.$dirty
                ? !_vm.$v.selectedSuggestion.$error
                : null,
              validationsCommons: {
                requiredValue: !_vm.$v.selectedSuggestion.required,
                requiredMessage: _vm.$t("sugerencia.requerido.seccion"),
              },
            },
            model: {
              value: _vm.selectedSuggestion,
              callback: function ($$v) {
                _vm.selectedSuggestion = $$v
              },
              expression: "selectedSuggestion",
            },
          }),
          _vm._v(" "),
          _c("input-text-area", {
            attrs: {
              id: "descripcion-sugerencia-id",
              label: _vm.$t("sugerencia.modal.descripcion"),
              readonly: false,
              required: true,
              rows: 6,
              placeholder: _vm.$t("sugerencia.modal.placeholder"),
              maxCaracteres: 850,
              valid: _vm.$v.descripcionSugerencia.$dirty
                ? !_vm.$v.descripcionSugerencia.$error
                : null,
              validationsCommons: {
                requiredValue: !_vm.$v.descripcionSugerencia.required,
                requiredMessage: _vm.$t("sugerencia.requerido.descripcion"),
                maxValue: !_vm.$v.descripcionSugerencia.maxLength,
                maxMessage: _vm.$t("sugerencia.requerido.maxMessage", {
                  max: "850",
                }),
              },
            },
            model: {
              value: _vm.descripcionSugerencia,
              callback: function ($$v) {
                _vm.descripcionSugerencia = $$v
              },
              expression: "descripcionSugerencia",
            },
          }),
          _vm._v(" "),
          _c("p", {
            staticClass: "font-weight-bold",
            domProps: {
              textContent: _vm._s(_vm.$t("sugerencia.nota.notaAdjuntos")),
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "float-right" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "primary", disabled: _vm.buttonDisabled },
                  on: { click: _vm.openFileInput },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("sugerencia.boton.btnSeleccionaArchivo")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "icon-mas" }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.selectedFiles2.length > 0
            ? _c(
                "ul",
                _vm._l(_vm.selectedFiles2, function (fileObject, index) {
                  return _c(
                    "li",
                    { key: index },
                    [
                      _vm._v(
                        "\n        " + _vm._s(fileObject.name) + "\n\n        "
                      ),
                      _c(
                        "b-button-group",
                        { staticClass: "ml-2" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "outline-primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.showImages(index)
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "icon-ver" }),
                              _vm._v(" "),
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("sugerencia.boton.ver")
                                  ),
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "outline-primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeFileInput2(index)
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "icon-eliminar" }),
                              _vm._v(" "),
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("sugerencia.boton.eliminar")
                                  ),
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            ref: "fileInput",
            staticStyle: { display: "none" },
            attrs: { type: "file", accept: "image/*" },
            on: { change: _vm.handleFileChange2 },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
        _c("button", {
          staticClass: "btn btn-outline-primary",
          attrs: { type: "button" },
          domProps: { textContent: _vm._s(_vm.$t("entity.action.cancel")) },
          on: {
            click: function ($event) {
              return _vm.closeModal()
            },
          },
        }),
        _vm._v(" "),
        _c("button", {
          staticClass: "btn btn-primary",
          attrs: { type: "button", id: "jhi-confirm-delete-celula" },
          domProps: { textContent: _vm._s(_vm.$t("sugerencia.boton.enviar")) },
          on: {
            click: function ($event) {
              return _vm.enviarSugerencia()
            },
          },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }