import { render, staticRenderFns } from "./alerta.vue?vue&type=template&id=b80f7b6e&"
import script from "./alerta.component.ts?vue&type=script&lang=ts&"
export * from "./alerta.component.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/1/gateway/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b80f7b6e')) {
      api.createRecord('b80f7b6e', component.options)
    } else {
      api.reload('b80f7b6e', component.options)
    }
    module.hot.accept("./alerta.vue?vue&type=template&id=b80f7b6e&", function () {
      api.rerender('b80f7b6e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/components/alerta/alerta.vue"
export default component.exports