import { Component, Vue } from 'vue-property-decorator';
@Component
export default class MenuTabbarComponent extends Vue {
  public isHoveringProfile = false;
  public isHoveringEducacion = false;
  public isHoveringExperiencia = false;
  public isHoveringAportaciones = false;
  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }
}
