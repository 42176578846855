import { Solicitud } from '@/shared/perfil-usuario';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
  computed: {
    ...mapGetters(['solicitud']),
  },
})
export default class DatosSolicitudComponent extends Vue {
  public solicitudes: Solicitud[] = [];

  @Prop({ default: '' })
  public title: string;

  @Prop({ default: 'solicitante' })
  public tipoDato: string;

  public fechaCreacion = '';
}

function cambiarFormatoFecha(fecha) {
  const [dia, mes, anio] = fecha.split('/');
  const fechaNueva = `${anio}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`;
  return fechaNueva;
}
