import { Module } from 'vuex';

export const catalogosStore: Module<any, any> = {
  state: {
    institucionesSnp: null,
  },
  getters: {
    institucionesSnp: state => state.institucionesSnp,
  },
  mutations: {
    setInstitucionesSnp(state, institucionesSnp) {
      state.institucionesSnp = institucionesSnp;
    },
  },
};
