var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { lg: "12" } },
        [
          _c("cards-list", {
            attrs: {
              id: _vm.id,
              items: _vm.escolaridadesCard,
              title: _vm.title,
              mostrarListado: false,
              disabledBtnAcctions: !_vm.formShow,
            },
            on: {
              remove: _vm.eliminaEscolaridad,
              edit: _vm.prepareEscolaridad,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { lg: "12" } },
        [_c("alerta", { ref: "alerta", attrs: { id: "alertContacto" } })],
        1
      ),
      _vm._v(" "),
      _vm.formShow
        ? [
            _c(
              "b-col",
              { attrs: { md: "6" } },
              [
                _c("input-select-one", {
                  attrs: {
                    id: "escolaridad-familiar.familiar-id",
                    label: _vm.$t("escolaridad-familiar.familiar.label"),
                    description: _vm.$t(
                      "escolaridad-familiar.familiar.description"
                    ),
                    options: _vm.parentestcoOption,
                    readonly: false,
                    valid: _vm.$v.escolaridadFamiliar.parentesco.$dirty
                      ? !_vm.$v.escolaridadFamiliar.parentesco.$error
                      : null,
                    validationsCommons: {
                      requiredValue:
                        !_vm.$v.escolaridadFamiliar.parentesco.required,
                      requiredMessage: _vm.$t(
                        "escolaridad-familiar.parentesco.validations.required"
                      ),
                    },
                  },
                  model: {
                    value: _vm.$v.escolaridadFamiliar.parentesco.$model,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.$v.escolaridadFamiliar.parentesco,
                        "$model",
                        $$v
                      )
                    },
                    expression: "$v.escolaridadFamiliar.parentesco.$model",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-col",
              { attrs: { md: "6" } },
              [
                _c("input-select-one", {
                  attrs: {
                    id: "escolaridad-familiar.escolaridad-id",
                    label: _vm.$t("escolaridad-familiar.escolaridad.label"),
                    description: _vm.$t(
                      "escolaridad-familiar.escolaridad.description"
                    ),
                    options: _vm.escolaridadOptions,
                    readonly: false,
                    valid: _vm.$v.escolaridadFamiliar.escolaridad.$dirty
                      ? !_vm.$v.escolaridadFamiliar.escolaridad.$error
                      : null,
                    validationsCommons: {
                      requiredValue:
                        !_vm.$v.escolaridadFamiliar.escolaridad.required,
                      requiredMessage: _vm.$t(
                        "escolaridad-familiar.escolaridad.validations.required"
                      ),
                    },
                  },
                  model: {
                    value: _vm.$v.escolaridadFamiliar.escolaridad.$model,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.$v.escolaridadFamiliar.escolaridad,
                        "$model",
                        $$v
                      )
                    },
                    expression: "$v.escolaridadFamiliar.escolaridad.$model",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-col",
              { attrs: { md: "12" } },
              [
                _c("input-select-one", {
                  attrs: {
                    id: "escolaridad-familiar.ocupacion-id",
                    label: _vm.$t("escolaridad-familiar.ocupacion.label"),
                    description: _vm.$t(
                      "escolaridad-familiar.ocupacion.description"
                    ),
                    options: _vm.ocupacionOptions,
                    readonly: false,
                    valid: _vm.$v.escolaridadFamiliar.ocupacion.$dirty
                      ? !_vm.$v.escolaridadFamiliar.ocupacion.$error
                      : null,
                    validationsCommons: {
                      requiredValue:
                        !_vm.$v.escolaridadFamiliar.ocupacion.required,
                      requiredMessage: _vm.$t(
                        "escolaridad-familiar.ocupacion.validations.required"
                      ),
                    },
                  },
                  model: {
                    value: _vm.$v.escolaridadFamiliar.ocupacion.$model,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.$v.escolaridadFamiliar.ocupacion,
                        "$model",
                        $$v
                      )
                    },
                    expression: "$v.escolaridadFamiliar.ocupacion.$model",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("b-col", { attrs: { col: "", cols: "12" } }, [
              _c(
                "div",
                { staticClass: "form-group float-right" },
                [
                  _c("span", { staticClass: "label-hidden" }),
                  _vm._v(" "),
                  _vm.editingIndex !== null
                    ? _c(
                        "b-button",
                        {
                          attrs: { variant: "outline-primary" },
                          on: { click: _vm.resetForm },
                        },
                        [
                          _c("span", [_vm._v("Cancelar")]),
                          _vm._v(" "),
                          _c("span", { staticClass: "icon-cerrar" }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: { click: _vm.agregaEscolaridad },
                    },
                    [
                      _vm.editingIndex === null
                        ? _c("span", [_vm._v("Agregar")])
                        : _c("span", [_vm._v("Editar")]),
                      _vm._v(" "),
                      _c("b-icon", {
                        attrs: { icon: "plus", "aria-hidden": "true" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }