var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { md: "12" } },
        [
          _c("input-boolean", {
            attrs: {
              id: "recibio-apoyo-conacyt-id",
              label: _vm.$t("apoyo-conacyt.recibio-apoyo-conacyt.label"),
              readonly: _vm.readonly,
              valid: !_vm.$v.recibioApoyoConacyt.recibioApoyoConacyt.$model,
            },
            on: { change: _vm.changeApoyoConacyt },
            model: {
              value: _vm.$v.recibioApoyoConacyt.recibioApoyoConacyt.$model,
              callback: function ($$v) {
                _vm.$set(
                  _vm.$v.recibioApoyoConacyt.recibioApoyoConacyt,
                  "$model",
                  $$v
                )
              },
              expression: "$v.recibioApoyoConacyt.recibioApoyoConacyt.$model",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "12" } },
        [
          _vm.$v.recibioApoyoConacyt.recibioApoyoConacyt.$model
            ? _c("input-select-one", {
                attrs: {
                  id: "fondo-programa-id",
                  label: _vm.$t("apoyo-conacyt.fondo-programa.label"),
                  description: _vm.$t(
                    "apoyo-conacyt.fondo-programa.description"
                  ),
                  options: _vm.fondoProgramaOptions,
                  readonly: _vm.readonly,
                  required:
                    (_vm.required &&
                      this.$v.recibioApoyoConacyt.recibioApoyoConacyt.$model) ||
                    this.$v.recibioApoyoConacyt.recibioApoyoConacyt.$model,
                  valid: _vm.$v.recibioApoyoConacyt.fondoPrograma.$dirty
                    ? !_vm.$v.recibioApoyoConacyt.fondoPrograma.$error
                    : null,
                  validationsCommons: {
                    requiredValue:
                      !_vm.$v.recibioApoyoConacyt.fondoPrograma.required,
                    requiredMessage: _vm.$t(
                      "articulos.articulos.validations.required"
                    ),
                  },
                },
                model: {
                  value: _vm.$v.recibioApoyoConacyt.fondoPrograma.$model,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$v.recibioApoyoConacyt.fondoPrograma,
                      "$model",
                      $$v
                    )
                  },
                  expression: "$v.recibioApoyoConacyt.fondoPrograma.$model",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }