var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-dropdown-item",
        {
          staticClass: "custom-dropdown-item",
          attrs: {
            to: "/educacion/trayectoria-academica",
            "active-class": "active",
          },
        },
        [
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("trayectoria-academica.title")),
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          staticClass: "custom-dropdown-item",
          attrs: { to: "/educacion/cursos", "active-class": "active" },
        },
        [
          _c("span", {
            domProps: { textContent: _vm._s(_vm.$t("cursos.title")) },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          staticClass: "custom-dropdown-item",
          attrs: { to: "/educacion/logros", "active-class": "active" },
        },
        [
          _c("span", {
            domProps: { textContent: _vm._s(_vm.$t("logros.title")) },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          staticClass: "custom-dropdown-item",
          attrs: { to: "/educacion/idiomas", "active-class": "active" },
        },
        [
          _c("span", {
            domProps: { textContent: _vm._s(_vm.$t("idiomas.title")) },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }