var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { md: "12" } },
        [
          !_vm.estadoSolicitudEnviada
            ? _c("b-alert", { attrs: { show: "", variant: "primary" } }, [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("resumen.alert.info-pu")),
                  },
                }),
              ])
            : _c("b-alert", { attrs: { show: "", variant: "warning" } }, [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(_vm.$t("resumen.alert.info-pu-env")),
                  },
                }),
              ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        {
          attrs: {
            md: _vm.indicadorSolicitudBandera == this.INGRESO ? "6" : "12",
          },
        },
        [
          _c("h3", { staticClass: "title mt-4" }, [
            _c(
              "span",
              {
                domProps: { textContent: _vm._s(_vm.$t("resumen.produccion")) },
              },
              [_vm._v("produccion")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card mt-3" },
            [
              _c("b-table", {
                attrs: {
                  fields: _vm.fieldsProduccion,
                  items: _vm.itemsProduccion,
                  "head-variant": "dark",
                  hover: "",
                  striped: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "head(destacados)",
                    fn: function () {
                      return [_c("span", { staticClass: "icon-calificacion" })]
                    },
                    proxy: true,
                  },
                  {
                    key: "cell(productos)",
                    fn: function (row) {
                      return [
                        _c(
                          "router-link",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover.left",
                                value: _vm
                                  .$t("resumen.numero-productos")
                                  .toString(),
                                expression:
                                  "$t('resumen.numero-productos').toString()",
                                modifiers: { hover: true, left: true },
                              },
                            ],
                            attrs: { to: { name: row.item.nameDestacados } },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.closeModal.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.item.productos) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(destacados)",
                    fn: function (row) {
                      return [
                        _c(
                          "router-link",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover.left",
                                value: _vm.$t("resumen.destacados").toString(),
                                expression:
                                  "$t('resumen.destacados').toString()",
                                modifiers: { hover: true, left: true },
                              },
                            ],
                            attrs: { to: { name: row.item.nameDestacados } },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.closeModal.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.item.destacados) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-col",
        {
          attrs: {
            md: _vm.indicadorSolicitudBandera == this.INGRESO ? "6" : "12",
          },
        },
        [
          _c("h3", { staticClass: "title mt-4" }, [
            _c(
              "span",
              {
                domProps: {
                  textContent: _vm._s(_vm.$t("resumen.tecnologica")),
                },
              },
              [_vm._v("tecnologica")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card mt-3" },
            [
              _c("b-table", {
                attrs: {
                  fields: _vm.fieldsTecnologica,
                  items: _vm.itemsTecnologica,
                  "head-variant": "dark",
                  hover: "",
                  striped: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "head(destacados)",
                    fn: function () {
                      return [_c("span", { staticClass: "icon-calificacion" })]
                    },
                    proxy: true,
                  },
                  {
                    key: "cell(productos)",
                    fn: function (row) {
                      return [
                        _c(
                          "router-link",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover.left",
                                value: _vm
                                  .$t("resumen.numero-productos")
                                  .toString(),
                                expression:
                                  "$t('resumen.numero-productos').toString()",
                                modifiers: { hover: true, left: true },
                              },
                            ],
                            attrs: { to: { name: row.item.nameDestacados } },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.closeModal.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.item.productos) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(destacados)",
                    fn: function (row) {
                      return [
                        _c(
                          "router-link",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover.left",
                                value: _vm.$t("resumen.destacados").toString(),
                                expression:
                                  "$t('resumen.destacados').toString()",
                                modifiers: { hover: true, left: true },
                              },
                            ],
                            attrs: { to: { name: row.item.nameDestacados } },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.closeModal.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.item.destacados) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-col",
        {
          attrs: {
            md: _vm.indicadorSolicitudBandera == this.INGRESO ? "6" : "12",
          },
        },
        [
          _c("h3", { staticClass: "title mt-4" }, [
            _c(
              "span",
              {
                domProps: { textContent: _vm._s(_vm.$t("resumen.formacion")) },
              },
              [_vm._v("formacion")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card mt-3" },
            [
              _c("b-table", {
                attrs: {
                  fields: _vm.fieldsFormacion,
                  items: _vm.itemsFormacion,
                  "head-variant": "dark",
                  hover: "",
                  striped: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "head(destacados)",
                    fn: function () {
                      return [_c("span", { staticClass: "icon-calificacion" })]
                    },
                    proxy: true,
                  },
                  {
                    key: "cell(productos)",
                    fn: function (row) {
                      return [
                        _c(
                          "router-link",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover.left",
                                value: _vm
                                  .$t("resumen.numero-productos")
                                  .toString(),
                                expression:
                                  "$t('resumen.numero-productos').toString()",
                                modifiers: { hover: true, left: true },
                              },
                            ],
                            attrs: { to: { name: row.item.nameDestacados } },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.closeModal.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.item.productos) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(destacados)",
                    fn: function (row) {
                      return [
                        _c(
                          "router-link",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover.left",
                                value: _vm.$t("resumen.destacados").toString(),
                                expression:
                                  "$t('resumen.destacados').toString()",
                                modifiers: { hover: true, left: true },
                              },
                            ],
                            attrs: { to: { name: row.item.nameDestacados } },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.closeModal.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.item.destacados) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.indicadorSolicitudBandera == this.INGRESO
        ? _c("b-col", { attrs: { md: "6" } }, [
            _c("h3", { staticClass: "title mt-4" }, [
              _c(
                "span",
                { domProps: { textContent: _vm._s(_vm.$t("resumen.acceso")) } },
                [_vm._v("acceso")]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card mt-3" },
              [
                _c("b-table", {
                  attrs: {
                    fields: _vm.fieldsAcceso,
                    items: _vm.itemsAcceso,
                    "head-variant": "dark",
                    hover: "",
                    striped: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "head(destacados)",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "icon-calificacion" }),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "cell(productos)",
                        fn: function (row) {
                          return [
                            _c(
                              "router-link",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover.left",
                                    value: _vm
                                      .$t("resumen.numero-productos")
                                      .toString(),
                                    expression:
                                      "$t('resumen.numero-productos').toString()",
                                    modifiers: { hover: true, left: true },
                                  },
                                ],
                                attrs: {
                                  to: { name: row.item.nameDestacados },
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.closeModal.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(row.item.productos) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(destacados)",
                        fn: function (row) {
                          return [
                            row.item.destacados != null
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover.left",
                                            value: _vm
                                              .$t("resumen.destacados")
                                              .toString(),
                                            expression:
                                              "$t('resumen.destacados').toString()",
                                            modifiers: {
                                              hover: true,
                                              left: true,
                                            },
                                          },
                                        ],
                                        attrs: {
                                          to: { name: row.item.nameDestacados },
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.closeModal.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(row.item.destacados) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c("span", [_vm._v("--")]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2385888645
                  ),
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.indicadorSolicitudBandera == this.INGRESO
        ? _c("b-col", { attrs: { md: "12" } }, [
            _c("h3", { staticClass: "title mt-4" }, [
              _c(
                "span",
                { domProps: { textContent: _vm._s(_vm.$t("resumen.citas")) } },
                [_vm._v("resumen")]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card mt-3" },
              [
                _c("b-table", {
                  attrs: {
                    fields: _vm.fieldsResumenCitas,
                    items: _vm.itemsResumenCitas,
                    "head-variant": "dark",
                    hover: "",
                    striped: "",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.indicadorSolicitudBandera != this.INGRESO
        ? _c("b-col", { attrs: { md: "12" } }, [
            _c("h3", { staticClass: "title mt-4" }, [
              _c(
                "span",
                { domProps: { textContent: _vm._s(_vm.$t("resumen.logros")) } },
                [_vm._v("logros")]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card mt-3" },
              [
                _c("b-table", {
                  attrs: {
                    fields: _vm.fieldsLogros,
                    items: _vm.itemsLogros,
                    "head-variant": "dark",
                    hover: "",
                    striped: "",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }