import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import PropertyCommons from '../commons/property.model';
import DatePick from 'vue-date-pick';

@Component({
  components: {
    DatePick,
  },
})
export default class InputDateTimeComponent extends mixins(PropertyCommons) {
  public format = 'YYYY-MM-DD';
  public displayFormat = 'DD-MM-YYYY H:mm A';
  public currentDate = new Date();
  public starMonthLocal = null;
  public starDateLocal = null;
  @Prop({ required: true })
  public value!: string;

  @Prop({ default: null })
  minDate: string;

  @Prop({ default: null })
  maxDate: string;

  @Prop({ default: new Date().getFullYear() })
  starDate: number;

  @Prop({ default: new Date().getMonth() })
  starMonth: number;

  @Prop({ default: false })
  limitDate: string;

  @Prop({ default: false })
  public time: boolean;
  @Watch('time', { immediate: true })
  handler(value) {
    if (value) {
      this.format = 'YYYY-MM-DD HH:mm';
      this.displayFormat = 'DD-MM-YYYY H:mm A';
    } else {
      this.format = 'YYYY-MM-DD';
      this.displayFormat = 'DD-MM-YYYY';
    }
  }

  get dateValue(): string {
    return this.value;
  }

  set dateValue(newValue: string) {
    this.$emit('input', newValue);
  }

  isFutureDate(date) {
    if (this.limitDate && !this.maxDate && !this.minDate) {
      const currentDate = new Date();
      return date > currentDate;
    } else if (this.maxDate || this.minDate) {
      const minDate = this.minDate ? this.minDate : new Date(1920, 0, 1); // La fecha mínima solicitada o default
      const maxDate = this.maxDate ? this.maxDate : new Date(); // La fecha solicitada máxima o default

      const pickedDate = new Date(date);
      return pickedDate < minDate || pickedDate > maxDate;
    }
  }

  cambio(e) {
    this.$emit('change', e);
    //obtiene fecha para sacar mes y año y setearlo en startPeriod cuando ya tiene una fecha seleccionada
    const date = new Date(e);
    this.starMonthLocal = date.getMonth();
    this.starDateLocal = date.getFullYear();
  }
}
