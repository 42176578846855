import { Prop, Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';

@Component
export default class BreadcrumbComponent extends Vue {
  public breadcrumbs: { name: string; link?: string }[] = [];

  mounted() {
    this.$watch(
      () => this.$route,
      () => {
        this.updateBreadcrumbs();
      }
    );
  }

  created() {
    this.updateBreadcrumbs();
  }

  // Watch para detectar cambios en la ruta
  watch: {
    $route: 'updateBreadcrumbs';
  };

  private updateBreadcrumbs() {
    this.breadcrumbs = this.$route.meta.breadcrumb || [];
  }
}
