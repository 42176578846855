import { render, staticRenderFns } from "./autores.vue?vue&type=template&id=13ec8b8d&"
import script from "./autores.component.ts?vue&type=script&lang=ts&"
export * from "./autores.component.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/1/gateway/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('13ec8b8d')) {
      api.createRecord('13ec8b8d', component.options)
    } else {
      api.reload('13ec8b8d', component.options)
    }
    module.hot.accept("./autores.vue?vue&type=template&id=13ec8b8d&", function () {
      api.rerender('13ec8b8d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/components/autores/autores.vue"
export default component.exports