import { render, staticRenderFns } from "./cards-list.vue?vue&type=template&id=360bc369&scoped=true&"
import script from "./cards-list.component.ts?vue&type=script&lang=ts&"
export * from "./cards-list.component.ts?vue&type=script&lang=ts&"
import style0 from "./cards-list.vue?vue&type=style&index=0&id=360bc369&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "360bc369",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/0/gateway/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('360bc369')) {
      api.createRecord('360bc369', component.options)
    } else {
      api.reload('360bc369', component.options)
    }
    module.hot.accept("./cards-list.vue?vue&type=template&id=360bc369&scoped=true&", function () {
      api.rerender('360bc369', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/components/cards-list/cards-list.vue"
export default component.exports