import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const MiPerfil = () => import('@/entities/msPerfil/acerca-de-mi/mi-perfil/mi-perfil.vue');

// prettier-ignore
const Grupos = () => import('@/entities/msPerfil/acerca-de-mi/grupos/grupos.vue');

// prettier-ignore
const TrayectoriaAcademica = () => import('@/entities/msPerfil/educacion/trayectoria-academica/trayectoria-academica.vue');

// prettier-ignore
const Cursos = () => import('@/entities/msPerfil/educacion/cursos/cursos.vue');

// prettier-ignore
const Logros = () => import('@/entities/msPerfil/educacion/logros/logros.vue');

// prettier-ignore
const Idiomas = () => import('@/entities/msPerfil/educacion/idiomas/idiomas.vue');

// prettier-ignore
const TrayectoriaProfesional = () => import('@/entities/msPerfil/experiencia/trayectoria-profesional/trayectoria-profesional.vue');

// prettier-ignore
const EvaluacionesOtorgadas = () => import('@/entities/msPerfil/experiencia/evaluaciones-otorgadas/evaluaciones-otorgadas.vue');

// prettier-ignore
const Estancias = () => import('@/entities/msPerfil/experiencia/estancias/estancias.vue');

// prettier-ignore
const CursosImpartidos = () => import('@/entities/msPerfil/experiencia/formacion/cursos-impartidos/cursos-impartidos.vue');

// prettier-ignore
const Tesis = () => import('@/entities/msPerfil/experiencia/formacion/tesis/tesis.vue');

// prettier-ignore
const Diplomados = () => import('@/entities/msPerfil/experiencia/formacion/diplomados/diplomados.vue');

// prettier-ignore
const Congresos = () => import('@/entities/msPerfil/experiencia/formacion/congresos/congresos.vue');

// prettier-ignore
const EniGemaSisdai = () => import('@/entities/msPerfil/experiencia/eni-gema-sisdai/eni-gema-sisdai.vue');

// prettier-ignore
const Divulgacion = () => import('@/entities/msPerfil/experiencia/formacion/divulgacion/divulgacion.vue');

// prettier-ignore
const Articulos = () => import('@/entities/msPerfil/aportaciones/articulos/articulos.vue');

// prettier-ignore
const Libros = () => import('@/entities/msPerfil/aportaciones/libros/libros.vue');

// prettier-ignore
const Capitulos = () => import('@/entities/msPerfil/aportaciones/capitulos/capitulos.vue');

// prettier-ignore
const DesarrolloInstitucional = () => import('@/entities/msPerfil/educacion/desarrollo-institucional/desarrollo-institucional.vue');

// prettier-ignore
const DesarrollosTecnologicosInnovaciones = () => import('@/entities/msPerfil/aportaciones/desarrollos-tecnologicos-innovaciones/desarrollos-tecnologicos-innovaciones.vue');

// prettier-ignore
const PropiedadesIntelectuales = () => import('@/entities/msPerfil/aportaciones/propiedades-intelectuales/propiedades-intelectuales.vue');

// prettier-ignore
const TransferenciasTecnologicas = () => import('@/entities/msPerfil/aportaciones/transferencias-tecnologicas/transferencias-tecnologicas.vue');
const Solicitudes = () => import('@/entities/msPerfil/solicitudes/solicitudes.vue');
const DatosEvaluar = () => import('@/entities/msPerfil/solicitudes/datos-evaluacion/datos-evaluacion.vue');
const DatosEvaluarEmeritos = () => import('@/entities/msPerfil/solicitudes/2023/emeritos/datos-evaluacion/datos-evaluacion.vue');
const DatosEvaluarBecasNacionales = () => import('@/entities/msPerfil/solicitudes/becas-nacionales/datos-evaluacion/datos-evaluacion.vue');
const DatosEvaluarBecasExtCuba = () => import('@/entities/msPerfil/solicitudes/becas-extranjero/cuba/datos-evaluacion.vue');
const BecasExtranjeroComponent = () => import('@/entities/msPerfil/solicitudes/becas-extranjero/becas-extranjero.vue');
// prettier-ignore
const CambioEmail = () => import('@/entities/msPerfil/cuenta-usuario/cambio-email/cambio-email.vue');
// prettier-ignore
const CambioPassword = () => import('@/entities/msPerfil/cuenta-usuario/cambio-password/cambio-password.vue');
export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: '/acerca-de-mi/mi-perfil',
      name: 'MiPerfil',
      component: MiPerfil,
      meta: {
        breadcrumb: [{ name: 'Inicio', link: '/' }, { name: 'Acerca de' }, { name: 'Mi perfil' }],
      },
    },

    {
      path: '/acerca-de-mi/grupos',
      name: 'Grupos',
      component: Grupos,
      meta: {
        breadcrumb: [{ name: 'Inicio', link: '/' }, { name: 'Acerca de' }, { name: 'Variables sociodemográficas' }],
      },
    },
    {
      path: '/educacion/trayectoria-academica',
      name: 'TrayectoriaAcademica',
      component: TrayectoriaAcademica,
      meta: {
        breadcrumb: [{ name: 'Inicio', link: '/' }, { name: 'Educación' }, { name: 'Trayectoria académica' }],
      },
    },
    {
      path: '/educacion/cursos',
      name: 'Cursos',
      component: Cursos,
      meta: {
        breadcrumb: [{ name: 'Inicio', link: '/' }, { name: 'Educación' }, { name: 'Cursos y Certificaciones Médicas' }],
      },
    },
    {
      path: '/educacion/logros',
      name: 'Logros',
      component: Logros,
      meta: {
        breadcrumb: [{ name: 'Inicio', link: '/' }, { name: 'Educación' }, { name: 'Logros' }],
      },
    },
    {
      path: '/educacion/idiomas',
      name: 'Idiomas',
      component: Idiomas,
      meta: {
        breadcrumb: [{ name: 'Inicio', link: '/' }, { name: 'Educación' }, { name: 'Idiomas' }],
      },
    },
    {
      path: '/trayectoria-profesional/empleo',
      name: 'TrayectoriaProfesional',
      component: TrayectoriaProfesional,
      meta: {
        breadcrumb: [{ name: 'Inicio', link: '/' }, { name: 'Trayectoria profesional' }, { name: 'Empleo actual' }],
      },
    },
    {
      path: '/trayectoria-profesional/evaluaciones',
      name: 'EvaluacionesOtorgadas',
      component: EvaluacionesOtorgadas,
      meta: {
        breadcrumb: [{ name: 'Inicio', link: '/' }, { name: 'Trayectoria profesional' }, { name: 'Evaluaciones realizadas' }],
      },
    },
    {
      path: '/trayectoria-profesional/estancias-investigacion',
      name: 'Estancias',
      component: Estancias,
      meta: {
        breadcrumb: [{ name: 'Inicio', link: '/' }, { name: 'Trayectoria profesional' }, { name: 'Estancias de investigación' }],
      },
    },
    {
      path: '/trayectoria-profesional/eni-gema-sisdai',
      name: 'EniGemaSisdai',
      component: EniGemaSisdai,
      meta: {
        breadcrumb: [{ name: 'Inicio', link: '/' }, { name: 'Trayectoria profesional' }, { name: 'ENI-Gema-Sisdai' }],
      },
    },
    {
      path: '/trayectoria-profesional/formacion-comunidad/docencia/cursos-impartidos',
      name: 'CursosImpartidos',
      component: CursosImpartidos,
      meta: {
        breadcrumb: [
          { name: 'Inicio', link: '/' },
          { name: 'Trayectoria profesional' },
          { name: 'Formación de comunidad HCTI' },
          { name: 'Docencia' },
          { name: 'Cursos impartidos' },
        ],
      },
    },
    {
      path: '/trayectoria-profesional/formacion-comunidad/trabajos-titulacion',
      name: 'Tesis',
      component: Tesis,
      meta: {
        breadcrumb: [
          { name: 'Inicio', link: '/' },
          { name: 'Trayectoria profesional' },
          { name: 'Formación de comunidad HCTI' },
          { name: 'Trabajos de titulación' },
        ],
      },
    },
    {
      path: '/trayectoria-profesional/formacion-comunidad/docencia/diplomados-impartidos',
      name: 'Diplomados',
      component: Diplomados,
      meta: {
        breadcrumb: [
          { name: 'Inicio', link: '/' },
          { name: 'Trayectoria profesional' },
          { name: 'Formación de comunidad HCTI' },
          { name: 'Docencia' },
          { name: 'Diplomados impartidos' },
        ],
      },
    },
    {
      path: '/trayectoria-profesional/acceso-universal/participacion-congresos',
      name: 'Congresos',
      component: Congresos,
      meta: {
        breadcrumb: [
          { name: 'Inicio', link: '/' },
          { name: 'Trayectoria profesional' },
          { name: 'Acceso universal al conocimiento' },
          { name: 'Participación en congresos' },
        ],
      },
    },
    {
      path: '/trayectoria-profesional/acceso-universal/divulgacion',
      name: 'Divulgacion',
      component: Divulgacion,
      meta: {
        breadcrumb: [
          { name: 'Inicio', link: '/' },
          { name: 'Trayectoria profesional' },
          { name: 'Acceso universal al conocimiento' },
          { name: 'Divulgación' },
        ],
      },
    },
    {
      path: '/trayectoria-profesional/produccion/cientifica-humanistica/articulos',
      name: 'CIENTIFICA-Articulos',
      component: Articulos,
      meta: {
        breadcrumb: [
          { name: 'Inicio', link: '/' },
          { name: 'Trayectoria profesional' },
          { name: 'Producción' },
          { name: 'Humanista y científica' },
          { name: 'Artículos' },
        ],
      },
    },
    {
      path: '/trayectoria-profesional/produccion/cientifica-humanistica/libros',
      name: 'CIENTIFICA-Libros',
      component: Libros,
      meta: {
        breadcrumb: [
          { name: 'Inicio', link: '/' },
          { name: 'Trayectoria profesional' },
          { name: 'Producción' },
          { name: 'Humanista y científica' },
          { name: 'Libros' },
        ],
      },
    },
    {
      path: '/trayectoria-profesional/produccion/cientifica-humanistica/capitulos',
      name: 'CIENTIFICA-Capitulos',
      component: Capitulos,
      meta: {
        breadcrumb: [
          { name: 'Inicio', link: '/' },
          { name: 'Trayectoria profesional' },
          { name: 'Producción' },
          { name: 'Humanista y científica' },
          { name: 'Capítulos' },
        ],
      },
    },
    {
      path: '/trayectoria-profesional/acceso-universal-conocimiento/difusion/articulos',
      name: 'DIFUSION-Articulos',
      component: Articulos,
      meta: {
        breadcrumb: [
          { name: 'Inicio', link: '/' },
          { name: 'Trayectoria profesional' },
          { name: 'Acceso universal al conocimiento' },
          { name: 'Difusión' },
          { name: 'Artículos' },
        ],
      },
    },
    {
      path: '/trayectoria-profesional/acceso-universal-conocimiento/difusion/libros',
      name: 'DIFUSION-Libros',
      component: Libros,
      meta: {
        breadcrumb: [
          { name: 'Inicio', link: '/' },
          { name: 'Trayectoria profesional' },
          { name: 'Acceso universal al conocimiento' },
          { name: 'Difusión' },
          { name: 'Libros' },
        ],
      },
    },
    {
      path: '/trayectoria-profesional/acceso-universal-conocimiento/difusion/capitulos',
      name: 'DIFUSION-Capitulos',
      component: Capitulos,
      meta: {
        breadcrumb: [
          { name: 'Inicio', link: '/' },
          { name: 'Trayectoria profesional' },
          { name: 'Acceso universal al conocimiento' },
          { name: 'Difusión' },
          { name: 'Capítulos' },
        ],
      },
    },
    {
      path: '/educacion/desarrollo-institucional',
      name: 'DesarrolloInstitucional',
      component: DesarrolloInstitucional,
      meta: {
        breadcrumb: [
          { name: 'Inicio', link: '/' },
          { name: 'Trayectoria profesional' },
          { name: 'Formación de comunidad HCTI' },
          { name: 'Desarrollo institucional' },
        ],
      },
    },
    {
      path: '/aportaciones/desarrollos-tecnologicos-innovaciones',
      name: 'DesarrollosTecnologicosInnovaciones',
      component: DesarrollosTecnologicosInnovaciones,
      meta: {
        breadcrumb: [
          { name: 'Inicio', link: '/' },
          { name: 'Trayectoria profesional' },
          { name: 'Produción' },
          { name: 'Tecnológica' },
          { name: 'Desarrollo tecnológico e innovación' },
        ],
      },
    },
    {
      path: '/aportaciones/propiedades-intelectuales',
      name: 'PropiedadesIntelectuales',
      component: PropiedadesIntelectuales,
      meta: {
        breadcrumb: [
          { name: 'Inicio', link: '/' },
          { name: 'Trayectoria profesional' },
          { name: 'Produción' },
          { name: 'Tecnológica' },
          { name: 'Propiedad intelectual' },
        ],
      },
    },
    {
      path: '/aportaciones/transferencias-tecnologicas',
      name: 'TransferenciasTecnologicas',
      component: TransferenciasTecnologicas,
      meta: {
        breadcrumb: [
          { name: 'Inicio', link: '/' },
          { name: 'Trayectoria profesional' },
          { name: 'Produción' },
          { name: 'Tecnológica' },
          { name: 'Transferencia tecnológica' },
        ],
      },
    },
    {
      path: '/solicitudes',
      name: 'Solicitudes',
      component: Solicitudes,
    },
    {
      path: '/registro-solicitud/datos-evaluacion',
      name: 'DatosEvaluarIngreso',
      component: DatosEvaluar,
    },
    {
      path: '/registro-solicitud/datos-evaluacion/:idSolicitud/edicion',
      name: 'DatosEvaluarIngresoEdit',
      component: DatosEvaluar,
    },
    {
      path: '/registro-solicitud/2023/emeritos/datos-evaluacion',
      name: 'DatosEvaluarEmerito',
      component: DatosEvaluarEmeritos,
    },
    {
      path: '/registro-solicitud/2023/emeritos/datos-evaluacion/:idSolicitud/edicion',
      name: 'DatosEvaluarEmeritosEdit',
      component: DatosEvaluarEmeritos,
    },
    {
      path: '/registro-solicitud/becas-nacionales/datos-evaluacion',
      name: 'DatosEvaluarBecasNacionales',
      component: DatosEvaluarBecasNacionales,
    },
    {
      path: '/registro-solicitud/becas-nacionales/datos-evaluacion/:idSolicitud/edicion',
      name: 'DatosEvaluarBecasNacionalesEdit',
      component: DatosEvaluarBecasNacionales,
    },
    {
      path: '/registro-solicitud/becas-extranjero/cuba/datos-evaluacion',
      name: 'DatosEvaluarBecasExtCuba',
      component: DatosEvaluarBecasExtCuba,
    },
    {
      path: '/registro-solicitud/becas-extranjero/cuba/datos-evaluacion/:idSolicitud/edicion',
      name: 'DatosEvaluarBecasExtCubaEdit',
      component: DatosEvaluarBecasExtCuba,
    },
    {
      path: '/registro-solicitud/becas-extranjero/:beca',
      name: 'BecasExtranjero',
      component: BecasExtranjeroComponent,
    },
    {
      path: '/registro-solicitud/becas-extranjero/:beca/:idSolicitud',
      name: 'BecasExtranjeroEdit',
      component: BecasExtranjeroComponent,
    },
    {
      path: '/cuenta/cambio-email',
      name: 'CambioEmail',
      component: CambioEmail,
      meta: {
        breadcrumb: [{ name: 'Inicio', link: '/' }, { name: 'Cuenta usuario' }, { name: 'Cambio de correo' }],
      },
    },
    {
      path: '/cuenta/cambio-password',
      name: 'CambioPassword',
      component: CambioPassword,
      meta: {
        breadcrumb: [{ name: 'Inicio', link: '/' }, { name: 'Cuenta usuario' }, { name: 'Cambio de contraseña' }],
      },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
