var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-dropdown-item",
        {
          staticClass: "custom-dropdown-item",
          attrs: {
            to: "/trayectoria-profesional/empleo",
            "active-class": "active",
          },
        },
        [
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("global.menu.left-menu.empleo-actual")
              ),
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          staticClass: "pointer custom-dropdown-item",
          class: { active: _vm.isOpen("collapseProduccionCientifica") },
          attrs: { id: "produccion", "data-cy": "entity" },
          on: {
            click: function ($event) {
              return _vm.toggleCollapse("collapseProduccion")
            },
          },
        },
        [
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.left-menu.produccion")),
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "ml-auto when-items" },
            [
              _c("b-icon", {
                attrs: {
                  icon: _vm.isOpen("collapseProduccion")
                    ? "chevron-down"
                    : "chevron-right",
                  "font-scale": "1.5",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          attrs: {
            id: "collapseProduccion",
            visible: _vm.isOpen("collapseProduccion"),
          },
        },
        [
          _c(
            "ul",
            { staticClass: "list-unstyled subitems-2" },
            [
              _c(
                "b-dropdown-item",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle:collapseProduccionCientifica",
                      arg: "collapseProduccionCientifica",
                    },
                  ],
                  staticClass: "custom-dropdown-item",
                  attrs: { id: "cientifica-menu", "active-class": "active" },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("global.menu.left-menu.humanista")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-auto when-items" }, [
                    _c(
                      "span",
                      { staticClass: "when-open" },
                      [
                        _c("b-icon", {
                          attrs: {
                            icon: "chevron-compact-down",
                            "font-scale": "1",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "when-closed" },
                      [
                        _c("b-icon", {
                          attrs: {
                            icon: "chevron-compact-right",
                            "font-scale": "1",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-collapse",
                { attrs: { id: "collapseProduccionCientifica" } },
                [
                  _c(
                    "ul",
                    { staticClass: "list-unstyled subitems-3" },
                    [
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "custom-dropdown-item",
                          attrs: {
                            to: "/trayectoria-profesional/produccion/cientifica-humanistica/articulos",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(_vm.$t("articulos.title")),
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "custom-dropdown-item",
                          attrs: {
                            to: "/trayectoria-profesional/produccion/cientifica-humanistica/libros",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(_vm.$t("libros.title")),
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "custom-dropdown-item",
                          attrs: {
                            to: "/trayectoria-profesional/produccion/cientifica-humanistica/capitulos",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(_vm.$t("capitulos.title")),
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-dropdown-item",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle:collapseTecnologica",
                      arg: "collapseTecnologica",
                    },
                  ],
                  staticClass: "custom-dropdown-item",
                  attrs: { id: "tecnologica-menu", "active-class": "active" },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("global.menu.left-menu.tecnologica")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-auto when-items" }, [
                    _c("span", { staticClass: "ml-auto when-items" }, [
                      _c(
                        "span",
                        { staticClass: "when-open" },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "chevron-compact-down",
                              "font-scale": "1",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "when-closed" },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "chevron-compact-right",
                              "font-scale": "1",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-collapse",
                {
                  attrs: {
                    id: "collapseTecnologica",
                    visible: _vm.isOpen("collapseTecnologica"),
                  },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "list-unstyled subitems-3" },
                    [
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "custom-dropdown-item",
                          attrs: {
                            to: "/aportaciones/desarrollos-tecnologicos-innovaciones",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "desarrollos-tecnologicos-innovaciones.title"
                                )
                              ),
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "custom-dropdown-item",
                          attrs: {
                            to: "/aportaciones/propiedades-intelectuales",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("propiedades-intelectuales.title")
                              ),
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "custom-dropdown-item",
                          attrs: {
                            to: "/aportaciones/transferencias-tecnologicas",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("transferencias-tecnologicas.title")
                              ),
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          staticClass: "custom-dropdown-item",
          attrs: {
            to: "/trayectoria-profesional/evaluaciones",
            "active-class": "active",
          },
        },
        [
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("evaluaciones-otorgadas.title")),
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          staticClass: "custom-dropdown-item",
          attrs: {
            to: "/trayectoria-profesional/estancias-investigacion",
            "active-class": "active",
          },
        },
        [
          _c("span", {
            domProps: { textContent: _vm._s(_vm.$t("estancias.title")) },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          staticClass: "pointer custom-dropdown-item",
          class: { active: _vm.isOpen("collapseFormacionComunidad") },
          attrs: { id: "formacion-comunidad", "data-cy": "entity" },
          on: {
            click: function ($event) {
              return _vm.toggleCollapse("collapseFormacionComunidad")
            },
          },
        },
        [
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.left-menu.formacion")),
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "ml-auto when-items" },
            [
              _c("b-icon", {
                attrs: {
                  icon: _vm.isOpen("collapseFormacionComunidad")
                    ? "chevron-down"
                    : "chevron-right",
                  "font-scale": "1.5",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          staticStyle: { "padding-left": "36px" },
          attrs: {
            id: "collapseFormacionComunidad",
            visible: _vm.isOpen("collapseFormacionComunidad"),
          },
        },
        [
          _c(
            "ul",
            { staticClass: "list-unstyled subitems-2" },
            [
              _c(
                "b-dropdown-item",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle:collapseDocencia",
                      arg: "collapseDocencia",
                    },
                  ],
                  staticClass: "custom-dropdown-item",
                  attrs: { id: "docencia-menu", "active-class": "active" },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("global.menu.left-menu.docencia")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-auto when-items" }, [
                    _c(
                      "span",
                      { staticClass: "when-open" },
                      [
                        _c("b-icon", {
                          attrs: {
                            icon: "chevron-compact-down",
                            "font-scale": "1",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "when-closed" },
                      [
                        _c("b-icon", {
                          attrs: {
                            icon: "chevron-compact-right",
                            "font-scale": "1",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-collapse",
                {
                  staticStyle: { "padding-left": "36px" },
                  attrs: { id: "collapseDocencia" },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "list-unstyled" },
                    [
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "custom-dropdown-item",
                          attrs: {
                            to: "/trayectoria-profesional/formacion-comunidad/docencia/cursos-impartidos",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("cursos-impartidos.title")
                              ),
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "custom-dropdown-item",
                          attrs: {
                            to: "/trayectoria-profesional/formacion-comunidad/docencia/diplomados-impartidos",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(_vm.$t("diplomados.title")),
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-dropdown-item",
                {
                  staticClass: "custom-dropdown-item",
                  attrs: {
                    to: "/trayectoria-profesional/formacion-comunidad/trabajos-titulacion",
                    "active-class": "active",
                  },
                },
                [
                  _c("span", {
                    domProps: { textContent: _vm._s(_vm.$t("tesis.title")) },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-dropdown-item",
                {
                  staticClass: "custom-dropdown-item",
                  attrs: {
                    to: "/educacion/desarrollo-institucional",
                    "active-class": "active",
                  },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("desarrollo-institucional.title")
                      ),
                    },
                  }),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          staticClass: "pointer custom-dropdown-item",
          class: { active: _vm.isOpen("collapseAccesoUniversal") },
          attrs: { id: "acceso-universal", "data-cy": "entity" },
          on: {
            click: function ($event) {
              return _vm.toggleCollapse("collapseAccesoUniversal")
            },
          },
        },
        [
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("global.menu.left-menu.acceso-conocimiento")
              ),
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "ml-auto when-items" },
            [
              _c("b-icon", {
                attrs: {
                  icon: _vm.isOpen("collapseAccesoUniversal")
                    ? "chevron-down"
                    : "chevron-right",
                  "font-scale": "1.5",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          staticStyle: { "padding-left": "36px" },
          attrs: {
            id: "collapseAccesoUniversal",
            visible: _vm.isOpen("collapseAccesoUniversal"),
          },
        },
        [
          _c(
            "ul",
            { staticClass: "list-unstyled subitems-2" },
            [
              _c(
                "b-dropdown-item",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle:collapseDifusion",
                      arg: "collapseDifusion",
                    },
                  ],
                  staticClass: "custom-dropdown-item",
                  attrs: { id: "difusion-menu", "active-class": "active" },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("global.menu.left-menu.difusion")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-auto when-items" }, [
                    _c(
                      "span",
                      { staticClass: "when-open" },
                      [
                        _c("b-icon", {
                          attrs: {
                            icon: "chevron-compact-down",
                            "font-scale": "1",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "when-closed" },
                      [
                        _c("b-icon", {
                          attrs: {
                            icon: "chevron-compact-right",
                            "font-scale": "1",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("b-collapse", { attrs: { id: "collapseDifusion" } }, [
                _c(
                  "ul",
                  { staticClass: "list-unstyled subitems-3" },
                  [
                    _c(
                      "b-dropdown-item",
                      {
                        staticClass: "custom-dropdown-item",
                        attrs: {
                          to: "/trayectoria-profesional/acceso-universal-conocimiento/difusion/articulos",
                          "active-class": "active",
                        },
                      },
                      [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(_vm.$t("articulos.title")),
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-dropdown-item",
                      {
                        staticClass: "custom-dropdown-item",
                        attrs: {
                          to: "/trayectoria-profesional/acceso-universal-conocimiento/difusion/libros",
                          "active-class": "active",
                        },
                      },
                      [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(_vm.$t("libros.title")),
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-dropdown-item",
                      {
                        staticClass: "custom-dropdown-item",
                        attrs: {
                          to: "/trayectoria-profesional/acceso-universal-conocimiento/difusion/capitulos",
                          "active-class": "active",
                        },
                      },
                      [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(_vm.$t("capitulos.title")),
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "b-dropdown-item",
                {
                  staticClass: "custom-dropdown-item",
                  attrs: {
                    to: "/trayectoria-profesional/acceso-universal/divulgacion",
                    "active-class": "active",
                  },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(_vm.$t("divulgacion.title")),
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-dropdown-item",
                {
                  staticClass: "custom-dropdown-item",
                  attrs: {
                    to: "/trayectoria-profesional/acceso-universal/participacion-congresos",
                    "active-class": "active",
                  },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(_vm.$t("congresos.title")),
                    },
                  }),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          staticClass: "custom-dropdown-item",
          attrs: {
            to: "/trayectoria-profesional/eni-gema-sisdai",
            "active-class": "active",
          },
        },
        [_c("span", [_vm._v("ENI-Gema-Sisdai")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }