var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("h3", { staticClass: "m-h3" }, [_vm._v(_vm._s(_vm.title))]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { lg: "12" } },
                [
                  _c("alerta", {
                    ref: "alerta",
                    attrs: { id: "alertContacto" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { lg: "12" } },
                [
                  _c("input-select-one", {
                    attrs: {
                      id: "tipo-id",
                      label: _vm.$t("contacto.tipo.label"),
                      options: _vm.catTipoDeContacto,
                      readonly: _vm.editingIndex !== null,
                      required: true,
                      valid: _vm.$v.nuevoMedioDeContacto.tipo.$dirty
                        ? !_vm.$v.nuevoMedioDeContacto.tipo.$error
                        : null,
                      validationsCommons: {
                        requiredValue:
                          !_vm.$v.nuevoMedioDeContacto.tipo.required,
                        requiredMessage: _vm.$t(
                          "contacto.tipo.validations.required"
                        ),
                      },
                    },
                    on: {
                      change: function ($event) {
                        return _vm.changeTipo($event)
                      },
                    },
                    model: {
                      value: _vm.$v.nuevoMedioDeContacto.tipo.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.nuevoMedioDeContacto.tipo,
                          "$model",
                          $$v
                        )
                      },
                      expression: "$v.nuevoMedioDeContacto.tipo.$model",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { sm: "12", md: "12", lg: "6" } },
                [
                  _vm.correoBandera
                    ? [
                        _c("input-text", {
                          attrs: {
                            id: "tipo-correo",
                            label: _vm.labelTipo,
                            readonly: _vm.readonlyTipo,
                            required: true,
                            placeholder: _vm.placeholderTipo,
                            valid: _vm.$v.nuevoMedioDeContacto.correo.$dirty
                              ? !_vm.$v.nuevoMedioDeContacto.correo.$error
                              : null,
                            validationsCommons: {
                              requiredValue:
                                !_vm.$v.nuevoMedioDeContacto.correo.required,
                              requiredMessage: _vm.$t(
                                "contacto.correo.validations.required",
                                { labelTipo: _vm.labelTipo }
                              ),
                              regexValue:
                                !_vm.$v.nuevoMedioDeContacto.correo.CORREO,
                              regexMessage: _vm.$t(
                                "contacto.correo.validations.regexMessage",
                                { labelTipo: _vm.labelTipo }
                              ),
                            },
                          },
                          model: {
                            value: _vm.$v.nuevoMedioDeContacto.correo.$model,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.$v.nuevoMedioDeContacto.correo,
                                "$model",
                                $$v
                              )
                            },
                            expression: "$v.nuevoMedioDeContacto.correo.$model",
                          },
                        }),
                      ]
                    : [
                        _c("input-text", {
                          attrs: {
                            id: "tipo-telefono",
                            label: _vm.labelTipo,
                            readonly: _vm.readonlyTipo,
                            required: true,
                            placeholder: _vm.placeholderTipo,
                            valid: _vm.$v.nuevoMedioDeContacto.telefono.$dirty
                              ? !_vm.$v.nuevoMedioDeContacto.telefono.$error
                              : null,
                            validationsCommons: {
                              requiredValue:
                                !_vm.$v.nuevoMedioDeContacto.telefono.required,
                              requiredMessage: _vm.$t(
                                "contacto.telefono.validations.required",
                                { labelTipo: _vm.labelTipo }
                              ),
                              minValue:
                                !_vm.$v.nuevoMedioDeContacto.telefono.minLength,
                              minMessage: _vm.$t(
                                "contacto.telefono.validations.minMessage",
                                { min: "10" }
                              ),
                              maxValue:
                                !_vm.$v.nuevoMedioDeContacto.telefono.maxLength,
                              maxMessage: _vm.$t(
                                "contacto.telefono.validations.maxMessage",
                                { max: "10" }
                              ),
                            },
                          },
                          on: { keypress: _vm.numbersOnly },
                          model: {
                            value: _vm.$v.nuevoMedioDeContacto.telefono.$model,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.$v.nuevoMedioDeContacto.telefono,
                                "$model",
                                $$v
                              )
                            },
                            expression:
                              "$v.nuevoMedioDeContacto.telefono.$model",
                          },
                        }),
                      ],
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { sm: "12", md: "12", lg: "6" } },
                [
                  _c("input-boolean", {
                    attrs: {
                      id: "principal-id",
                      label: _vm.$t("contacto.principal"),
                    },
                    model: {
                      value: _vm.$v.nuevoMedioDeContacto.principal.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.nuevoMedioDeContacto.principal,
                          "$model",
                          $$v
                        )
                      },
                      expression: "$v.nuevoMedioDeContacto.principal.$model",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-col", [_c("div", { staticClass: "border-custom" })]),
              _vm._v(" "),
              _c("b-col", { attrs: { col: "", cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "form-group float-right" },
                  [
                    _c("span", { staticClass: "label-hidden" }),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "outline-primary" },
                        on: { click: _vm.resetForm },
                      },
                      [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(_vm.$t("entity.action.cancel")),
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "icon-cerrar" }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        attrs: {
                          variant: "primary",
                          disabled:
                            _vm.index != null &&
                            _vm.esIgual(_vm.$v.nuevoMedioDeContacto.$model),
                        },
                        on: { click: _vm.agregaTelefono },
                      },
                      [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(_vm.$t("entity.action.save")),
                          },
                        }),
                        _vm._v(" "),
                        _c("font-awesome-icon", {
                          staticClass: "mr-2",
                          attrs: { icon: "save" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }