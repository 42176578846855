import { File } from '@/components/commons/file.model';
import type { Archivo, EtapaEnum, DmsApi } from '@conacyt/dms-client';
import { Store } from 'vuex';
import { DatosGeneralesApi } from '@/shared/perfil-usuario';
import { ResilientWebPipelineBuilder, TimeSpansInMilliSeconds } from 'resilience-typescript';
import * as Sentry from '@sentry/vue';

export default class PerfilService {
  private SECRETARIA = 'RIZOMA';
  private CLAVE_CONVOCATORIA = 'perfil';
  private ETAPA: EtapaEnum = 'REGISTRO';
  private proxy = ResilientWebPipelineBuilder.New()
    .useConsoleLogger()
    .useCircuitBreaker(1, TimeSpansInMilliSeconds.tenMinutes, 12, TimeSpansInMilliSeconds.tenMinutes)
    .useRetry(2, 12)
    .useTimeout(3, TimeSpansInMilliSeconds.threeMinutes)
    .builtToRequestFactory();

  constructor(private dmsApi: DmsApi, private miPerfilApi: DatosGeneralesApi, private store: Store<any>) {}

  public dispatchFotografia(cvu: string, fotografia: File): Promise<any> {
    if (fotografia.uri === null && fotografia.content !== null && fotografia.content.length > 0) {
      // nueva
      const archivo: Archivo = { nombre: fotografia.nombre, contenido: fotografia.content };
      return new Promise((resolve, reject) => {
        this.dmsApi
          .agregaDocumento(this.SECRETARIA, this.CLAVE_CONVOCATORIA, cvu, this.ETAPA, undefined, archivo)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    } else if (fotografia.uri !== null && fotografia.content !== null && fotografia.content.length > 0) {
      // actualizacion
      return new Promise((resolve, reject) => {
        this.dmsApi
          .borraDocumentoPorUrl(fotografia.uri)
          .then(res => {
            if (res.status === 200) {
              fotografia.uri = null;
              resolve(this.dispatchFotografia(cvu, fotografia));
            } else {
              resolve(false);
            }
          })
          .catch(err => {
            reject(err);
          });
      });
    } else if (fotografia.uri !== null) {
      // elimina
      return new Promise((resolve, reject) => {
        this.dmsApi
          .borraDocumentoPorUrl(fotografia.uri)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    }

    return new Promise(resolve => {
      resolve(false);
    });
  }

  public dispatchFotografiaV2(cvu: string, fotografia: File): Promise<any> {
    if (fotografia.uri === null && fotografia.content !== null && fotografia.content.length > 0) {
      return this.creaDocumento(cvu, fotografia);
    } else if (fotografia.uri !== null && fotografia.content !== null && fotografia.content.length > 0) {
      return this.actualizaDocumento(cvu, fotografia);
    } else if (fotografia.uri !== null) {
      return this.borraDocumento(fotografia.uri);
    } else {
      return Promise.resolve(false);
    }
  }

  public obtienePdfGuardado(url: string): Promise<any> {
    if (url) {
      return new Promise((resolve, reject) => {
        this.dmsApi
          .obtenerDocumentoPorUrl(url)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    } else {
      return Promise.resolve(false);
    }
  }

  //Este documento no se tiene que remplazar ya que se utiliza para datos historicos
  public dispatchDocumentoV2(cvu: string, pdf: File): Promise<any> {
    if (pdf.content !== null && pdf.content.length > 0) {
      const archivo: Archivo = {
        nombre: pdf.nombre.substring(0, pdf.nombre.lastIndexOf('.')) + new Date().getTime() + '.pdf',
        contenido: pdf.content,
      };
      return this.creaDocumentoResilience(cvu, archivo);
    }
  }

  public creaDocumento(cvu: string, fotografia: File): Promise<any> {
    const archivo: Archivo = { nombre: fotografia.nombre, contenido: fotografia.content };
    return new Promise((resolve, reject) => {
      this.obtenerDocumentoResilience(cvu, archivo)
        .then(response => {
          if (response.data.urlPublica != null) {
            this.borraDocumentoResilience(response.data.urlPublica)
              .then(response => {
                resolve(this.creaDocumentoResilience(cvu, archivo));
              })
              .catch(error => {
                reject(error);
              });
          } else {
            resolve(this.creaDocumentoResilience(cvu, archivo));
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  public actualizaDocumento(cvu: string, fotografia: File): Promise<any> {
    return new Promise((resolve, reject) => {
      this.borraDocumentoResilience(fotografia.uri)
        .then(response => {
          const archivo: Archivo = { nombre: fotografia.nombre, contenido: fotografia.content };
          this.obtenerDocumentoResilience(cvu, archivo)
            .then(response => {
              if (response.data.urlPublica != null) {
                this.borraDocumentoResilience(response.data.urlPublica)
                  .then(response => {
                    resolve(this.creaDocumentoResilience(cvu, archivo));
                  })
                  .catch(error => {
                    reject(error);
                  });
              } else {
                resolve(this.creaDocumentoResilience(cvu, archivo));
              }
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  public borraDocumento(url: string): Promise<any> {
    return this.borraDocumentoResilience(url);
  }

  public obtenerDocumentoResilience(cvu: string, archivo: Archivo): Promise<any> {
    const url = `/services/dmsms/api/documentos/RIZOMA/convocatorias/perfil/${cvu}/${archivo.nombre}/compartido?etapa=${this.ETAPA}`;
    this.resetProxy();
    return this.proxy.request().get(url).execute<any>();
  }

  public creaDocumentoResilience(cvu: string, archivo: Archivo): Promise<any> {
    const url = `/services/dmsms/api/documentos/RIZOMA/convocatorias/perfil/${cvu}?etapa=${this.ETAPA}`;
    this.resetProxy();
    return this.proxy.request().post(url).withBody(archivo).execute<any>();
  }

  public borraDocumentoResilience(url: string): Promise<any> {
    url = `/services/dmsms/api/documentos?urlCompartida=${url}`;
    this.resetProxy();
    return this.proxy.request().delete(url).execute<any>();
  }

  public resetProxy(): void {
    this.proxy.maintenance().cache().clear();
    this.proxy.maintenance().circuitBreaker().resetErrorCount();
    this.proxy.maintenance().circuitBreaker().close();
  }

  public retrieveInicialPerfil(): void {
    if (this.authenticated) {
      this.miPerfilApi
        .validaPerfil()
        .then(response => {
          const perfil = {
            fotografia: response.data.fotografia,
          };
          this.store.commit('setPerfil', perfil);
        })
        .catch(error => {
          this.store.commit('setPerfil', null);
          if (!error.response?.data?.status || error.response.data.status !== 404) {
            throw error;
          }
        });
    }
  }

  public retrievePerfil(): void {
    if (this.authenticated) {
      this.miPerfilApi
        .getMiPerfil()
        .then(response => {
          const perfil = {
            fotografia: response.data.fotografia,
          };
          this.store.commit('setPerfil', perfil);
        })
        .catch(error => {
          this.store.commit('setPerfil', null);
          if (!error.response?.data?.status || error.response.data.status !== 404) {
            throw error;
          }
        });
    }
  }

  public get authenticated(): boolean {
    return this.store.getters.authenticated;
  }

  public eliminaDocumento(uri: string): void {
    this.borraDocumento(uri)
      .then(res => {})
      .catch(err => {
        Sentry.captureException(err + ' NO se elimino documento: ' + uri);
      });
  }
}
