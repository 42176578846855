var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "h4",
        {
          staticClass: "mt-4 mb-4",
          attrs: { "v-text": _vm.$t("scian.title") },
        },
        [_vm._v("Sector industrial SCIAN")]
      ),
      _vm._v(" "),
      _c("input-select-one", {
        attrs: {
          id: "scian-sector-scian-id",
          label: _vm.$t("scian.sector-scian.label"),
          description: _vm.$t("scian.sector-scian.description"),
          options: _vm.sectorScianOptions,
          readonly: _vm.sectorScianLectura,
          required: true,
          valid: _vm.$v.scian.sector.$dirty
            ? !_vm.$v.scian.sector.$error
            : null,
          validationsCommons: {
            requiredValue: !_vm.$v.scian.sector.required,
            requiredMessage: _vm.$t("scian.sector-scian.validations.required"),
          },
        },
        on: {
          change: function ($event) {
            return _vm.onSectorScianChanged($event, true)
          },
        },
        model: {
          value: _vm.$v.scian.sector.$model,
          callback: function ($$v) {
            _vm.$set(_vm.$v.scian.sector, "$model", $$v)
          },
          expression: "$v.scian.sector.$model",
        },
      }),
      _vm._v(" "),
      _c(
        "b-overlay",
        {
          attrs: {
            show: _vm.busySubsector,
            rounded: "",
            opacity: "0.6",
            "spinner-small": "",
            "spinner-variant": "primary",
          },
        },
        [
          _c("input-select-one", {
            attrs: {
              id: "scian-sub-sector-id",
              label: _vm.$t("scian.sub-sector-scian.label"),
              description: _vm.$t("scian.sub-sector-scian.description"),
              options: _vm.subSectorScianOptions,
              readonly: _vm.subSectorScianLectura,
              required: true,
              valid: _vm.$v.scian.subsector.$dirty
                ? !_vm.$v.scian.subsector.$error
                : null,
              validationsCommons: {
                requiredValue: !_vm.$v.scian.subsector.required,
                requiredMessage: _vm.$t(
                  "scian.sub-sector-scian.validations.required"
                ),
              },
            },
            on: {
              change: function ($event) {
                return _vm.onSubSectorScianChanged($event, true)
              },
            },
            model: {
              value: _vm.$v.scian.subsector.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.scian.subsector, "$model", $$v)
              },
              expression: "$v.scian.subsector.$model",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-overlay",
        {
          attrs: {
            show: _vm.busyRama,
            rounded: "",
            opacity: "0.6",
            "spinner-small": "",
            "spinner-variant": "primary",
          },
        },
        [
          _c("input-select-one", {
            attrs: {
              id: "scian-rama-id",
              label: _vm.$t("scian.rama-scian.label"),
              description: _vm.$t("scian.rama-scian.description"),
              options: _vm.ramaScianOptions,
              readonly: _vm.ramaScianLectura,
              required: true,
              valid: _vm.$v.scian.rama.$dirty
                ? !_vm.$v.scian.rama.$error
                : null,
              validationsCommons: {
                requiredValue: !_vm.$v.scian.rama.required,
                requiredMessage: _vm.$t(
                  "scian.rama-scian.validations.required"
                ),
              },
            },
            on: {
              change: function ($event) {
                return _vm.onRamaScianChanged($event, true)
              },
            },
            model: {
              value: _vm.$v.scian.rama.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.scian.rama, "$model", $$v)
              },
              expression: "$v.scian.rama.$model",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-overlay",
        {
          attrs: {
            show: _vm.busySubRama,
            rounded: "",
            opacity: "0.6",
            "spinner-small": "",
            "spinner-variant": "primary",
          },
        },
        [
          _c("input-select-one", {
            attrs: {
              id: "scian-sub-rama-id",
              label: _vm.$t("scian.sub-rama-scian.label"),
              description: _vm.$t("scian.sub-rama-scian.description"),
              options: _vm.subRamaScianOptions,
              readonly: _vm.subRamaScianLectura,
              required: true,
              valid: _vm.$v.scian.subrama.$dirty
                ? !_vm.$v.scian.subrama.$error
                : null,
              validationsCommons: {
                requiredValue: !_vm.$v.scian.subrama.required,
                requiredMessage: _vm.$t(
                  "scian.sub-rama-scian.validations.required"
                ),
              },
            },
            on: {
              change: function ($event) {
                return _vm.onSubRamaScianChanged($event, true)
              },
            },
            model: {
              value: _vm.$v.scian.subrama.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.scian.subrama, "$model", $$v)
              },
              expression: "$v.scian.subrama.$model",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-overlay",
        {
          attrs: {
            show: _vm.busyClase,
            rounded: "",
            opacity: "0.6",
            "spinner-small": "",
            "spinner-variant": "primary",
          },
        },
        [
          _c("input-select-one", {
            attrs: {
              id: "scian-clase-id",
              label: _vm.$t("scian.clase-scian.label"),
              description: _vm.$t("scian.clase-scian.description"),
              options: _vm.claseScianOptions,
              readonly: _vm.claseScianLectura,
              required: true,
              valid: _vm.$v.scian.clase.$dirty
                ? !_vm.$v.scian.clase.$error
                : null,
              validationsCommons: {
                requiredValue: !_vm.$v.scian.clase.required,
                requiredMessage: _vm.$t(
                  "scian.clase-scian.validations.required"
                ),
              },
            },
            model: {
              value: _vm.$v.scian.clase.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.scian.clase, "$model", $$v)
              },
              expression: "$v.scian.clase.$model",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("b-overlay", {
        attrs: {
          show: _vm.showScian,
          "no-wrap": "",
          "spinner-type": "grow",
          "spinner-variant": "primary",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }