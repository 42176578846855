// Errors
export const PROBLEM_BASE_URL = 'https://www.jhipster.tech/problem';
export const EMAIL_ALREADY_USED_TYPE = `${PROBLEM_BASE_URL}/email-already-used`;
export const LOGIN_ALREADY_USED_TYPE = `${PROBLEM_BASE_URL}/login-already-used`;
export const COMMENT_LENGTH = 90;
export const URL_REGEX = /^http[s]?:\/\/(www\.)?[a-zA-Z0-9\-_]+\.[a-zA-Z]{2,}([\/a-zA-Z0-9\-_#?=&]*)?/;
export const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const NOT_BLANK_SPACES_REGEX = /^(?!.*\s).+$/;
export const CARACTERES_PASSWORD = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$%!?';
export const ENI = 'ENI';
export const GEMA = 'Gema';
export const SISDAI = 'Sisdai';

export const TIPO_CIENTIFICA_ARTICULOS = 'CIENTIFICA-Articulos';
export const TIPO_CIENTIFICA_LIBROS = 'CIENTIFICA-Libros';
export const TIPO_CIENTIFICA_CAPITULOS = 'CIENTIFICA-Capitulos';
export const CORREO_INVALIDO_CAMBIO1 = 'conahcyt.mx';
export const CORREO_INVALIDO_CAMBIO2 = 'secihti.mx';

export const TIPO_DIFUSION_ARTICULOS = 'DIFUSION-Articulos';
export const TIPO_DIFUSION_CAPITULOS = 'DIFUSION-Capitulos';
export const TIPO_DIFUSION_LIBROS = 'DIFUSION-Libros';
export const ALTA_INSITUCION = 'Alta de Instituciones para Becas en el Extranjero';
export const MAXIMO_REGISTROS_PAGINACION_SACI = 300;
