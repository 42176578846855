var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "body" }, [
    _vm.authenticated
      ? _c("div", [
          _c("div", { staticClass: "container-f" }, [
            _c("div", { staticClass: "drop-wrapper" }, [
              _c(
                "button",
                {
                  staticClass: "btn rounded-0",
                  on: {
                    click: function ($event) {
                      return _vm.generarPdf()
                    },
                  },
                },
                [
                  _vm.showOverlay
                    ? _c(
                        "span",
                        [
                          _c("b-spinner", {
                            attrs: { small: "", variant: "light" },
                          }),
                        ],
                        1
                      )
                    : _c("span", {
                        staticClass: "item-text",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("perfil-unico.title-descarga")
                          ),
                        },
                      }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "drop-wrapper",
                on: {
                  mouseover: function ($event) {
                    _vm.isHoveringProfile = true
                  },
                  mouseout: function ($event) {
                    _vm.isHoveringProfile = false
                  },
                  click: function ($event) {
                    _vm.isHoveringProfile = true
                  },
                },
              },
              [
                _c("button", { staticClass: "btn rounded-0" }, [
                  _c(
                    "span",
                    {
                      staticClass: "item-text",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("global.menu.user-profile-menu.user-profile")
                        ),
                      },
                    },
                    [_vm._v(" User profile ")]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "drop-menu dropup",
                    class: { active: _vm.isHoveringProfile },
                    attrs: { id: "dropup" },
                  },
                  [
                    _c("div", { staticClass: "dm-2" }, [
                      _c(
                        "div",
                        { staticClass: "titulo-drop" },
                        [
                          _c("b-icon", {
                            attrs: { id: "icon-person-2", icon: "person" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "global.menu.user-profile-menu.user-profile"
                                  )
                                ),
                              },
                            },
                            [_vm._v(" User profile ")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass: "list-unstyled",
                          attrs: { id: "subitems" },
                        },
                        [_c("acerca-de-menu")],
                        1
                      ),
                    ]),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "drop-wrapper",
                on: {
                  mouseover: function ($event) {
                    _vm.isHoveringEducacion = true
                  },
                  mouseout: function ($event) {
                    _vm.isHoveringEducacion = false
                  },
                  click: function ($event) {
                    _vm.isHoveringEducacion = true
                  },
                },
              },
              [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "drop-menu dropup",
                    class: { active: _vm.isHoveringEducacion },
                    attrs: { id: "dropup1" },
                  },
                  [
                    _c("div", { staticClass: "dm-2" }, [
                      _vm._m(1),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass: "list-unstyled",
                          attrs: { id: "subitems" },
                        },
                        [_c("educacion-menu")],
                        1
                      ),
                    ]),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "drop-wrapper",
                on: {
                  mouseover: function ($event) {
                    _vm.isHoveringExperiencia = true
                  },
                  mouseout: function ($event) {
                    _vm.isHoveringExperiencia = false
                  },
                  click: function ($event) {
                    _vm.isHoveringExperiencia = true
                  },
                },
              },
              [
                _vm._m(2),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "drop-menu dropup",
                    class: { active: _vm.isHoveringExperiencia },
                    attrs: { id: "dropup2" },
                  },
                  [
                    _c("div", { staticClass: "dm-2" }, [
                      _vm._m(3),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass: "list-unstyled",
                          attrs: { id: "subitems" },
                        },
                        [_c("trayectoria-menu")],
                        1
                      ),
                    ]),
                  ]
                ),
              ]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "btn rounded-0" }, [
      _c("span", { staticClass: "item-text" }, [_vm._v("Educación")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titulo-drop" }, [
      _c("img", {
        attrs: {
          src: require("../../../content/images/educacion.svg"),
          alt: "Educación",
        },
      }),
      _vm._v(" "),
      _c("span", [_vm._v("Educación")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "btn rounded-0" }, [
      _c("span", { staticClass: "item-text" }, [
        _vm._v("Trayectoria profesional"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titulo-drop" }, [
      _c("img", {
        attrs: {
          src: require("../../../content/images/experiencia.svg"),
          alt: "Trayectoria profesional",
        },
      }),
      _vm._v(" "),
      _c("span", [_vm._v("Trayectoria profesional")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }