import Component, { mixins } from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import BModal from 'bootstrap-vue';
import PropertyCommons from '../commons/property.model';

@Component({
  inheritAttrs: false,
})
export default class InputSelectAutocompleteComponent extends mixins(PropertyCommons) {
  @Prop({ required: true })
  public value!: string;

  @Prop({ required: true, type: Array })
  public options!: Array<any>;

  get text(): string {
    return this.value;
  }

  set text(newValue: string) {
    this.$emit('input', newValue);
  }

  get selectedId(): string {
    return this.value ? '' : this.value;
  }

  set selectedId(newValue: string) {
    this.$emit('input', newValue);
  }

  cambio(e) {
    this.$emit('change', e);
  }

  keyup(e) {
    e.text = this.text;
    this.$emit('keyup', e);
  }
}
