var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c("b-col", { attrs: { md: "12" } }, [
        _c("p", {
          domProps: { textContent: _vm._s(_vm.$t("aviso.messages.consejo")) },
        }),
        _vm._v(" "),
        _c("p", {
          staticClass: "font-weight-bold",
          domProps: { textContent: _vm._s(_vm.$t("aviso.messages.datos")) },
        }),
        _vm._v(" "),
        _c("p", {
          domProps: { textContent: _vm._s(_vm.$t("aviso.messages.sistema")) },
        }),
        _vm._v(" "),
        _c("p", {
          domProps: { textContent: _vm._s(_vm.$t("aviso.messages.llevar")) },
        }),
      ]),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "6" } },
        [
          _c(
            "b-list-group",
            [
              _c("b-list-group-item", [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(_vm.$t("aviso.messages.tabla.1")),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("b-list-group-item", [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(_vm.$t("aviso.messages.tabla.2")),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("b-list-group-item", [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(_vm.$t("aviso.messages.tabla.3")),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("b-list-group-item", [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(_vm.$t("aviso.messages.tabla.4")),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("b-list-group-item", [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(_vm.$t("aviso.messages.tabla.5")),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("b-list-group-item", [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(_vm.$t("aviso.messages.tabla.6")),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("b-list-group-item", [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(_vm.$t("aviso.messages.tabla.7")),
                  },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "6" } },
        [
          _c(
            "b-list-group",
            [
              _c("b-list-group-item", [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(_vm.$t("aviso.messages.tabla.8")),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("b-list-group-item", [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(_vm.$t("aviso.messages.tabla.9")),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("b-list-group-item", [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(_vm.$t("aviso.messages.tabla.10")),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("b-list-group-item", [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(_vm.$t("aviso.messages.tabla.11")),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("b-list-group-item", [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(_vm.$t("aviso.messages.tabla.12")),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("b-list-group-item", [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(_vm.$t("aviso.messages.tabla.13")),
                  },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("b-col", { staticClass: "mt-4", attrs: { md: "12" } }, [
        _c("p", {
          domProps: {
            textContent: _vm._s(_vm.$t("aviso.messages.adicionalmente")),
          },
        }),
        _vm._v(" "),
        _c("p", {
          staticClass: "font-weight-bold",
          domProps: { textContent: _vm._s(_vm.$t("aviso.messages.con-quien")) },
        }),
        _vm._v(" "),
        _c("p", {
          domProps: { textContent: _vm._s(_vm.$t("aviso.messages.los-datos")) },
        }),
        _vm._v(" "),
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("aviso.messages.en-caso")) },
        }),
        _vm._v(" "),
        _c("p", [
          _c("span", {
            domProps: { innerHTML: _vm._s(_vm.$t("aviso.messages.usted")) },
          }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }