import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import PropertyCommons from '../commons/property.model';

@Component
export default class InputSelectYearComponent extends mixins(PropertyCommons) {
  @Prop({ required: true })
  public value!: string;

  @Prop({ default: 1900 })
  minYear: number;

  @Prop({ default: new Date().getFullYear() })
  maxYear: number;

  get yearValue(): string {
    return this.value;
  }

  set yearValue(newValue: string) {
    this.$emit('input', newValue);
  }

  cambio(e) {
    this.$emit('change', e);
  }

  years: Array<any> = [];

  mounted() {
    for (let index = this.maxYear; index >= this.minYear; index--) {
      this.years.push(index);
    }
  }
}
