import Component, { mixins } from 'vue-class-component';
import { Prop, PropSync } from 'vue-property-decorator';
import PropertyCommons from '../commons/property.model';
import { required } from 'vuelidate/lib/validators';

const VALIDATIONS = function () {
  return {
    documento: {
      required,
    },
  };
};

@Component({
  validations: VALIDATIONS,
})
export default class ModalDocumentoComponent extends mixins(PropertyCommons) {
  public documento: any;

  save(): void {
    if (this.documento?.content) {
      this.$emit('save', this.documento);
      this.documento = {};
    }
  }

  cancel(): void {
    this.documento = {};
  }
}
