import EntityBecasExtranjeroFactory from '@/shared/entity-commons/entity-becas-extranjero.factory';
import * as Entities from '@/shared/perfil-usuario/api';
import calculaEdad from '@/shared/calcula-edad/calcula-edad.util';

/**
 * Interface con los atributos que necesita el negocio de becas extranjero, crece acorde a negocios particulares CUBA, JAPON, etc
 */
export interface IReglasRubro {
  tipoBeca: string | null;
  programa: string | null;
  sexo: string | null;
  duracionMeses: number | null;
  fechaInicioBeca: string | null;
  dependientes: number | null;
  fechaNacimiento: string | null;
}

/**
 * Clase que implementa la interface general de becas extranjero y define los métodos del negocio especifico de CUBA
 */
export class ReglasRubroCuba implements IReglasRubro {
  constructor(
    public tipoBeca: string | null,
    public programa: string | null,
    public sexo: string | null,
    public duracionMeses: number | null,
    public fechaInicioBeca: string | null,
    public dependientes: number | null,
    public fechaNacimiento: string | null
  ) {}

  //Catalogos de Rubros
  public catEsquemaRubros = EntityBecasExtranjeroFactory.getEsquemaRubros();
  public rubroNuevo = EntityBecasExtranjeroFactory.getRubro();

  // Setteo de los datos de los rubros dependiendo el concepto
  public setteoRubrosBecaCuba(e) {
    this.rubroNuevo = EntityBecasExtranjeroFactory.getRubro();
    this.rubroNuevo.moneda = Entities.Moneda.EUR;
    switch (e) {
      case Entities.ConceptoRubro.MANUTENCION:
        this.rubroNuevo.concepto = Entities.ConceptoRubro.MANUTENCION;
        this.rubroNuevo.periodo = Entities.PeriodoPagoEnum.MENSUAL;
        this.dependientes > 0 && this.dependientes <= 3
          ? (this.rubroNuevo.montoTotal = this.catEsquemaRubros.rubros.MANUTENCION.MENSUAL.CON_DEPENDIENTES)
          : (this.rubroNuevo.montoTotal = this.catEsquemaRubros.rubros.MANUTENCION.MENSUAL.SIN_DEPENDIENTES);
        return this.rubroNuevo;
      case Entities.ConceptoRubro.COLEGIATURA:
        this.rubroNuevo.concepto = Entities.ConceptoRubro.COLEGIATURA;
        this.rubroNuevo.periodo = Entities.PeriodoPagoEnum.ANUAL;
        const especialidad = this.catEsquemaRubros.rubros.COLEGIATURA.ANUAL.especialidades.find(el => el.nombre == this.programa);
        this.rubroNuevo.montoTotal = especialidad ? especialidad.monto : null;
        return this.rubroNuevo;
      case Entities.ConceptoRubro.SEGURO_MEDICO:
        this.rubroNuevo.concepto = Entities.ConceptoRubro.SEGURO_MEDICO;
        this.rubroNuevo.periodo = Entities.PeriodoPagoEnum.ANUAL;
        let seguroArray = [];
        const edadDate = calculaEdad(new Date(this.fechaNacimiento));
        const edadCalculada = this.ajustaEdadPorNacimiento(edadDate);
        this.sexo === 'Femenino'
          ? (seguroArray = this.catEsquemaRubros.rubros.SEGURO_MEDICO.ANUAL.MUJER)
          : (seguroArray = this.catEsquemaRubros.rubros.SEGURO_MEDICO.ANUAL.HOMBRE);
        const seguro = seguroArray.find(el => el.edad == edadCalculada);
        this.rubroNuevo.montoTotal = seguro ? seguro.monto : null;
        this.rubroNuevo.montosPosteriores = this.proximoSeguro(edadCalculada);
        return this.rubroNuevo;
      case Entities.ConceptoRubro.BOLETO_AVION:
        this.rubroNuevo.concepto = null;
        this.rubroNuevo.periodo = null;
        this.rubroNuevo.montoTotal = null;
        this.rubroNuevo.moneda = null;
        return this.rubroNuevo;
      default:
        // Cualquier otro caso
        break;
    }
  }

  // Método interno para determinar si el usuario nacio antes de fechaInicioBeca (se agrega 1 año más a su edad actual)
  ajustaEdadPorNacimiento(edadDate: number) {
    let res = 0;
    const partesFN = this.fechaNacimiento.split('-');
    const partesFIB = this.fechaInicioBeca.split('-');
    const mesDiaFN = `${partesFN[1]}-${partesFN[2]}`;
    const mesDiaFIB = `${partesFIB[1]}-${partesFIB[2]}`;
    if (mesDiaFN <= mesDiaFIB) {
      return (res = edadDate + 1);
    } else {
      return (res = edadDate);
    }
  }

  /**
   * Calcula la edad-monto-año de los seguros próximos del becario-extranjero,
   * la información se muestra en la tabla de seguro médico.
   * Consideraciones:
   * 1.- El comienzo de la beca esta marcada por fechaInicioBeca, apartir de ese momento la edad del usuario se contempla,
   *     es decir, personas que nacen después de la fechaInicioBeca empiezan con edad actual que tienen a la fechaInicioBeca.
   * 2.- Si el usuario nacio antes de la fechaInicioBeca entonces la edad del cálculo debe considerar 1 año más a la edad que tiene
   *     al momento de la postulación.
   * Ejemplo: Usuario nacido el 14/06/1996 y fechaInicioBeca 01/09/2024... edad-monto-año: 28,2024 - 29,2025 - 30,2026 - 31,2027 - 32,2028
   */
  private proximoSeguro(edad) {
    const itemsSeguro = [];
    const aniosCompletos = Math.floor(this.duracionMeses / 12);
    const aniosFinBeca = edad + aniosCompletos;
    let anioInicioBeca = new Date(this.fechaInicioBeca + 'T06:00:00Z').getFullYear();
    let seguroArray = [];
    for (let i = parseInt(edad.toString(), 10); i < aniosFinBeca + 1; i++) {
      const proximoAnioSeguro = EntityBecasExtranjeroFactory.proximoAnioSeguro();
      this.sexo === 'Femenino'
        ? (seguroArray = this.catEsquemaRubros.rubros.SEGURO_MEDICO.ANUAL.MUJER)
        : (seguroArray = this.catEsquemaRubros.rubros.SEGURO_MEDICO.ANUAL.HOMBRE);
      const seguro = seguroArray.find(el => el.edad == i);
      if (seguro) {
        proximoAnioSeguro.edad = i;
        proximoAnioSeguro.monto = seguro.monto;
        proximoAnioSeguro.anio = anioInicioBeca;
        itemsSeguro.push(proximoAnioSeguro);
      }

      anioInicioBeca++;
    }
    return itemsSeguro;
  }
}

export class ReglasRubroCH implements IReglasRubro {
  constructor(
    public tipoBeca: string | null,
    public programa: string | null,
    public sexo: string | null,
    public duracionMeses: number | null,
    public fechaInicioBeca: string | null,
    public dependientes: number | null,
    public fechaNacimiento: string | null,
    public clavePais: string | null,
    public grado: string | null
  ) {}

  //Catalogos de Rubros
  // public catEsquemaRubros = EntityBecasExtranjeroFactory.getEsquemaRubrosHumanidades();
  public catEsquemaRubros = JSON.parse(localStorage.getItem('bext-catPais'));
  public rubroNuevo: any = EntityBecasExtranjeroFactory.getRubro();

  //Globales
  public indexActual = null;

  public setteoRubrosBecaCH(e) {
    this.indexActual = null;
    const index = this.catEsquemaRubros.findIndex(value => this.clavePais == value.value.clave);
    if (index !== -1) {
      // Se encuentra el país
      this.indexActual = index;
      this.rubroNuevo = EntityBecasExtranjeroFactory.getRubro();
      this.rubroNuevo.moneda = this.catEsquemaRubros[index].value.divisa;
      switch (e) {
        case Entities.ConceptoRubro.MANUTENCION:
          this.rubroNuevo.concepto = Entities.ConceptoRubro.MANUTENCION;
          this.rubroNuevo.periodo = Entities.PeriodoPagoEnum.MENSUAL;
          this.dependientes > 0
            ? (this.rubroNuevo.montoTotal = this.catEsquemaRubros[index].value.rubros.manutencion.CON_DEPENDIENTES)
            : (this.rubroNuevo.montoTotal = this.catEsquemaRubros[index].value.rubros.manutencion.SIN_DEPENDIENTES);
          return this.rubroNuevo;
        case Entities.ConceptoRubro.COLEGIATURA:
          this.rubroNuevo.concepto = Entities.ConceptoRubro.COLEGIATURA;
          this.rubroNuevo.periodo = Entities.PeriodoPagoEnum.ANUAL;
          return this.rubroNuevo;
        case Entities.ConceptoRubro.SEGURO_MEDICO:
          this.rubroNuevo.concepto = Entities.ConceptoRubro.SEGURO_MEDICO;
          this.rubroNuevo.periodo = Entities.PeriodoPagoEnum.ANUAL;
          this.setMontoSeguroPorDependiente(index);
          //Set de valores de seguros próximos para la tabla
          //this.rubroNuevo.montosPosteriores = this.proximoSeguro(this.rubroNuevo.montoTotal);
          return this.rubroNuevo;
        case Entities.ConceptoRubro.BOLETO_AVION:
          this.rubroNuevo.concepto = null;
          this.rubroNuevo.periodo = null;
          this.rubroNuevo.montoTotal = null;
          this.rubroNuevo.moneda = null;
          return this.rubroNuevo;
        default:
          // Cualquier otro caso
          break;
      }
    } else {
      // No se encontro el país
      //TODO Elemento (país) no encontrado en el array.
    }
  }

  public validaMontosColegiatura(monto) {
    if (monto) {
      if (this.grado == 'Maestría' && monto >= 0) {
        if (monto <= this.catEsquemaRubros[this.indexActual].value.rubros.colegiatura.MAESTRIA_MAX) {
          return true;
        }
      } else if ((this.grado == 'Doctorado' || this.grado == 'Doctorado Directo') && monto >= 0) {
        if (monto <= this.catEsquemaRubros[this.indexActual].value.rubros.colegiatura.DOCTORADO_MAX) {
          return true;
        }
      } else if (this.grado == 'Especialidad médica' && monto >= 0) {
        if (monto <= this.catEsquemaRubros[this.indexActual].value.rubros.colegiatura.ESPECIALIDAD_MEDICA_MAX) {
          return true;
        }
      }
    }
    return false;
  }

  public validaMontosColegiaturaFuturo(monto) {
    if (monto) {
      if ((this.grado == 'Maestría' || this.grado == 'Doctorado' || this.grado == 'Doctorado Directo') && monto >= 0) {
        if (monto <= this.catEsquemaRubros[this.indexActual].value.rubros.colegiatura.DOCTORADO_MAX) {
          return true;
        }
      }
    }
    return false;
  }

  setMontoSeguroPorDependiente(index) {
    const montosDependientes = {
      0: 'SIN_DEPENDIENTES',
      1: 'DEPENDIENTES_1',
      2: 'DEPENDIENTES_2',
      3: 'DEPENDIENTES_3',
    };
    if (this.dependientes >= 0 && this.dependientes <= 3) {
      this.rubroNuevo.montoTotal = this.catEsquemaRubros[index].value.rubros.seguroMedico[montosDependientes[this.dependientes]];
    } else {
      //TODO Monto de seguro médico no corresponde a 0, 1, 2 o 3 dependientes
      return;
    }
  }

  private proximoSeguro(monto) {
    const itemsSeguro = [];
    const aniosCompletos = Math.floor(this.duracionMeses / 12);
    let anioInicioBeca = new Date(this.fechaInicioBeca + 'T06:00:00Z').getFullYear();
    const anioFinBeca = anioInicioBeca + aniosCompletos;

    for (let i = anioInicioBeca; i < anioFinBeca; i++) {
      const proximoAnioSeguro = EntityBecasExtranjeroFactory.proximoAnioSeguroCH();
      proximoAnioSeguro.monto = monto;
      proximoAnioSeguro.anio = anioInicioBeca;
      itemsSeguro.push(proximoAnioSeguro);
      anioInicioBeca++;
    }
    return itemsSeguro;
  }
}
