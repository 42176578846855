var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c("b-col", { attrs: { md: "12" } }, [
        _c("div", { staticClass: "title-block" }, [
          _c("h3", { staticClass: "title mt-4" }, [
            _c("span", {
              domProps: { textContent: _vm._s(_vm.$t("citas.title")) },
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "sparkline bar",
              attrs: { "data-type": "bar" },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { m: "12" } },
        [
          _c("b-alert", { attrs: { show: "", variant: "primary" } }, [
            _c("span", {
              domProps: { textContent: _vm._s(_vm.$t("citas.url.alerta")) },
            }),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "12" } },
        [
          _c("input-text", {
            attrs: {
              id: "cita-ur-id",
              label: _vm.$t("citas.url.label"),
              placeholder: _vm.$t("citas.url.placeHolder"),
              description: _vm.$t("citas.url.description"),
              readonly: _vm.urlLectura,
              required: false,
              valid: _vm.$v.citas.urlCita.$dirty
                ? !_vm.$v.citas.urlCita.$error
                : null,
              validationsCommons: {
                regexValue: !_vm.$v.citas.urlCita.URL,
                regexMessage: _vm.$t("citas.url.validations.regexMessage"),
              },
            },
            model: {
              value: _vm.$v.citas.urlCita.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.citas.urlCita, "$model", $$v)
              },
              expression: "$v.citas.urlCita.$model",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "4" } },
        [
          _c("input-text", {
            attrs: {
              id: "cita-a-id",
              label: _vm.$t("citas.citaA.label"),
              placeholder: _vm.$t("citas.citaA.placeHolder"),
              description: _vm.$t("citas.citaA.description"),
              readonly: _vm.citaALectura,
              type: "url",
              required: false,
              valid: _vm.$v.citas.citaA.$dirty
                ? !_vm.$v.citas.citaA.$error
                : null,
            },
            on: { keypress: _vm.numbersOnly, change: _vm.sumaCita },
            model: {
              value: _vm.$v.citas.citaA.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.citas.citaA, "$model", $$v)
              },
              expression: "$v.citas.citaA.$model",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "4" } },
        [
          _c("input-text", {
            attrs: {
              id: "cita-b-id",
              label: _vm.$t("citas.citaB.label"),
              placeholder: _vm.$t("citas.citaB.placeHolder"),
              description: _vm.$t("citas.citaB.description"),
              readonly: _vm.citaBLectura,
              type: "number",
              required: false,
              valid: _vm.$v.citas.citaB.$dirty
                ? !_vm.$v.citas.citaB.$error
                : null,
            },
            on: { keypress: _vm.numbersOnly, change: _vm.sumaCita },
            model: {
              value: _vm.$v.citas.citaB.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.citas.citaB, "$model", $$v)
              },
              expression: "$v.citas.citaB.$model",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "4" } },
        [
          _c("input-text", {
            attrs: {
              id: "cita-total-id",
              label: _vm.$t("citas.total.label"),
              placeholder: _vm.$t("citas.total.placeHolder"),
              description: _vm.$t("citas.total.description"),
              readonly: _vm.totalLectura,
            },
            model: {
              value: _vm.$v.citas.totalCitas.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.citas.totalCitas, "$model", $$v)
              },
              expression: "$v.citas.totalCitas.$model",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }