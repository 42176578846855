var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-dropdown-item",
        { attrs: { to: "/datos-generales", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.user-profile-menu.general-data")
                ),
              },
            },
            [_vm._v("General Data")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/formacion-academica", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.user-profile-menu.academic-training")
                ),
              },
            },
            [_vm._v("Academic Training")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/trayectoria-profesional", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.user-profile-menu.professional-path")
                ),
              },
            },
            [_vm._v("Professional Path")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/produccion-cientifica", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.user-profile-menu.production-scientific")
                ),
              },
            },
            [_vm._v("Scientific, technological and innovation production")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: { to: "/formacion-capittal-humano", "active-class": "active" },
        },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t(
                    "global.menu.user-profile-menu.formation-human-capital"
                  )
                ),
              },
            },
            [_vm._v("Formation of Human Capital")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/comunicacion", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.user-profile-menu.communication")
                ),
              },
            },
            [
              _vm._v(
                "Public communication of science, technology and innovation"
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/vinculacion", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.user-profile-menu.binding")
                ),
              },
            },
            [_vm._v("Binding")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/evaluaciones", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.user-profile-menu.assessments")
                ),
              },
            },
            [_vm._v("Assessments")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/premios-distinciones", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.user-profile-menu.awards-distinctions")
                ),
              },
            },
            [_vm._v("Awards and Distinctions")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/lenguas-idiomas", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.user-profile-menu.languages-languages")
                ),
              },
            },
            [_vm._v("Languages")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }