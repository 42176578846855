import Component from 'vue-class-component';
import { Prop, Vue, Inject } from 'vue-property-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import AlertService from '@/shared/alert/alert.service';
import EntityFactory from '@/shared/entity-commons/entity.factory';
import { SoporteApi, CorreoSugerenciaRequest } from '@/shared/perfil-usuario';

const VALIDATIONS = function () {
  return {
    selectedSuggestion: {
      required,
    },
    descripcionSugerencia: {
      required,
      maxLength: maxLength(850),
    },
  };
};

@Component({
  validations: VALIDATIONS,
  computed: {
    sugerenciaOptions() {
      return EntityFactory.getListaSeccionesOptions(this.$t.bind(this));
    },
  },
})
export default class ModalSugerenciaComponent extends Vue {
  @Inject('soporteApi') private soporteSugerenciaApi: () => SoporteApi;
  @Inject('alertService') private alertService: () => AlertService;
  @Prop({ default: 'removeEntity' })
  id: string;

  public buttonTextLabel = this.$t('sugerencia.boton.btnArchivos');
  public buttonDisabled = false;
  public validFiles = false;
  public selectedSuggestion = '';
  public descripcionSugerencia = '';
  public selectedFiles: File[] = [];
  public selectedFiles2: { name: string; file: File }[] = [];

  public closeModal(): void {
    this.selectedSuggestion = null;
    this.descripcionSugerencia = '';
    this.selectedFiles2 = [];
    this.validFiles = false;
    this.$bvModal.hide(this.id);
  }

  public openFileInput() {
    if (this.selectedFiles.length >= 5 || this.getTotalFileSize() >= 5 * 1024 * 1024) {
      this.buttonDisabled = true;
      this.alertService().showWarning(this, this.$t('sugerencia.messages.warning').toString());
      return;
    }

    this.$nextTick(() => {
      const fileInput = this.$refs.fileInput as HTMLInputElement | undefined;
      if (fileInput) {
        fileInput.click();
      }
    });
  }

  private isValidFileType(file: File): boolean {
    const validTypes = ['image/jpeg', 'image/png'];
    return validTypes.includes(file.type);
  }

  public getTotalFileSize(): number {
    let totalSize = 0;
    for (let i = 0; i < this.selectedFiles.length; i++) {
      totalSize += 1000;
    }
    return totalSize;
  }

  public handleFileChange2(event: Event) {
    this.validFiles = false;
    const target = event.target as HTMLInputElement;
    if (target.files) {
      const files = target.files;
      const newFiles: { name: string; file: File }[] = [];
      for (let i = 0; i < files.length; i++) {
        if (this.isValidFileType(files[i])) {
          newFiles.push({
            name: files[i].name,
            file: files[i] as File,
          });
        } else {
          this.alertService().showWarning(this, this.$t('sugerencia.messages.invalidFileType').toString());
        }
      }

      if (newFiles.length > 0) {
        this.selectedFiles2.push(...newFiles);
        this.buttonTextLabel = this.$t('sugerencia.boton.btnArchivos').toString();
        if (this.selectedFiles2.length >= 5 || this.getTotalFileSize() >= 5 * 1024 * 1024) {
          this.buttonDisabled = true;
        }
      }
    }
  }

  public showImages(index: number): void {
    const fileObject = this.selectedFiles2[index];

    if (fileObject) {
      const imageUrl = window.URL.createObjectURL(fileObject.file);
      window.open(imageUrl, '_blank');
    }
  }

  public removeFileInput2(index: number) {
    this.selectedFiles2.splice(index, 1);
    if (this.selectedFiles2.length < 5 && this.getTotalFileSize() < 5 * 1024 * 1024) {
      this.buttonDisabled = false;
    }
  }

  private convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }

  public enviarSugerencia(): void {
    if (!this.$v.selectedSuggestion.$invalid && !this.$v.descripcionSugerencia.$invalid) {
      this.validFiles = !(this.selectedFiles2.length >= 1);

      const imagenesPromises = this.selectedFiles2.map(fileObj => this.convertFileToBase64(fileObj.file));

      Promise.all(imagenesPromises).then(base64Images => {
        const correoSugerencia: CorreoSugerenciaRequest = {
          sugerencia: this.selectedSuggestion,
          descripcion: this.descripcionSugerencia,
          imagenes: base64Images,
        };

        this.soporteSugerenciaApi()
          .enviarCorreoConAdjuntoSugerencia(correoSugerencia)
          .then(res => {
            if (res.status === 201) {
              this.alertService().showSuccess(this, this.$t('sugerencia.messages.success').toString());
              this.closeModal();
            }
          })
          .catch(err => {
            this.alertService().showError(this, err.response.data.detail);
          });
      });
    } else {
      this.$v.$touch();
    }
  }
}
