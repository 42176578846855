import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import JhiDataUtils from '@/shared/data/data-utils.service';

@Component({
  inheritAttrs: false,
})
export default class VisualizadorPdfComponent extends mixins(JhiDataUtils) {
  @Prop({ required: true })
  modalId!: string;
  @Prop({ required: true })
  contenidoBase64!: string;
  @Prop({ default: false })
  mostrar!: boolean;

  @Watch('mostrar')
  onMostrarChange(newMostrar: boolean): void {
    this.visualizar(newMostrar);
  }

  async visualizar(mostrar: boolean): Promise<void> {
    if (mostrar) {
      this.$bvModal.show(this.modalId);
    } else {
      this.$bvModal.hide(this.modalId);
    }
  }

  hideModal() {
    this.$bvModal.hide(this.modalId);
  }
}
