import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import PropertyCommons from '../commons/property.model';

import { mixins } from 'vue-class-component';
@Component
export default class InputTextAreaComponent extends mixins(PropertyCommons) {
  @Prop({ required: true })
  public value!: string;

  @Prop({ default: 0 })
  maxCaracteres: number;

  @Prop({ default: 3 })
  rows!: number;

  get calculatedRows(): number {
    if (this.rows === 3) {
      const length = this.value ? this.value.length : 0;
      const calculatedRows = Math.min(Math.ceil(length / 85), 25);

      return calculatedRows;
    }
    return this.rows;
  }

  get text(): string {
    return this.value;
  }

  set text(newValue: string) {
    this.$emit('input', newValue);
  }

  cambio(e) {
    this.$emit('change', e);
  }
}
