import * as Entities from '@/shared/perfil-usuario/api';
import EntitySolicitudFactory from './entity-solicitud.factory';

export default class EntityBecasExtranjeroFactory {
  //BECAS EXTRANJERAS CUBA
  public static getSolicitudBecasExtranjeraCuba(): Entities.Solicitud {
    return {
      solucionSolicitud: null,
      periodo: EntitySolicitudFactory.getPeriodosBecas(),
      dependientesEconomicos: [],
      contactosEmergencia: [],
      solicitante: EntitySolicitudFactory.getSolicitanteBecas(),
      apoyo: this.getApoyos(),
      tipoSolicitud: null,
      calificacion: null,
      folioENARM: null,
      tipoBecaExtranjero: null,
      rubros: [],
      motivos: [],
      documentos: [],
    };
  }

  public static getApoyos(): Entities.Apoyo {
    return {
      tipoApoyo: null,
      pais: null,
      institucion: null,
      programa: null,
      obtuvoApoyoPrevio: false,
      dependienteBecarioActual: false,
      dependienteSolicitanteSimultaneo: false,
      categoria: null,
    };
  }

  //Cataogo TIPO SOLICITUD
  public static getTipoSolicitud() {
    return [
      {
        value: { id: '1', nombre: 'Especialidades Médicas en Cuba (Con ENARM)' },
        text: 'Especialidades Médicas en Cuba (Con ENARM)',
      },
      {
        value: { id: '2', nombre: 'Especialidades Médicas en Cuba (Exenta de ENARM)' },
        text: 'Especialidades Médicas en Cuba (Exenta de ENARM)',
      },
    ];
  }
  public static getApoyoObtener() {
    return [
      {
        value: { id: '9', nombre: 'Especialidad médica' },
        text: 'Especialidad médica',
      },
      {
        value: { id: '8', nombre: 'Doctorado' },
        text: 'Doctorado',
      },
      {
        value: { id: '6', nombre: 'Especialidad' },
        text: 'Especialidad',
      },
      {
        value: { id: '5', nombre: 'Licenciatura' },
        text: 'Licenciatura',
      },
      {
        value: { id: '7', nombre: 'Maestría' },
        text: 'Maestría',
      },
      {
        value: { id: '3', nombre: 'Preparatoria' },
        text: 'Preparatoria',
      },
      {
        value: { id: '1', nombre: 'Primaria' },
        text: 'Primaria',
      },
      {
        value: { id: '2', nombre: 'Secundaria' },
        text: 'Secundaria',
      },
      {
        value: { id: '4', nombre: 'Técnico superior' },
        text: 'Técnico superior',
      },
    ];
  }
  public static getInstitucionCuba() {
    return [
      {
        value: { id: '250651', nombre: 'Comercializadora de Servicios Médicos Cubanos (CSMC)' },
        text: 'Comercialiadora de Servicios Médicos Cubanos (CSMC)',
      },
    ];
  }

  public static getEsquemaRubros() {
    return {
      _id: '...',
      id_pais: 'CUB',
      nombre: 'Cuba',
      divisa: 'EUR',
      tipo_cambio_pesos: 18,
      version: '2024-01',
      activo: true,
      rubros: {
        MANUTENCION: {
          MENSUAL: {
            SIN_DEPENDIENTES: 1090,
            CON_DEPENDIENTES: 1362,
          },
        },
        SEGURO_MEDICO: {
          ANUAL: {
            MUJER: [
              {
                edad: 18,
                monto: 337.5,
              },
              {
                edad: 19,
                monto: 349.49,
              },
              {
                edad: 20,
                monto: 359.77,
              },
              {
                edad: 21,
                monto: 372.62,
              },
              {
                edad: 22,
                monto: 383.76,
              },
              {
                edad: 23,
                monto: 394.89,
              },
              {
                edad: 24,
                monto: 406.89,
              },
              {
                edad: 25,
                monto: 418.88,
              },
              {
                edad: 26,
                monto: 430.02,
              },
              {
                edad: 27,
                monto: 442.86,
              },
              {
                edad: 28,
                monto: 451.43,
              },
              {
                edad: 29,
                monto: 460,
              },
              {
                edad: 30,
                monto: 596.2,
              },
              {
                edad: 31,
                monto: 606.48,
              },
              {
                edad: 32,
                monto: 618.47,
              },
              {
                edad: 33,
                monto: 624.46,
              },
              {
                edad: 34,
                monto: 629.6,
              },
              {
                edad: 35,
                monto: 636.46,
              },
              {
                edad: 36,
                monto: 641.6,
              },
              {
                edad: 37,
                monto: 648.45,
              },
              {
                edad: 38,
                monto: 682.71,
              },
              {
                edad: 39,
                monto: 718.69,
              },
              {
                edad: 40,
                monto: 753.81,
              },
              {
                edad: 41,
                monto: 920,
              },
              {
                edad: 42,
                monto: 961,
              },
              {
                edad: 43,
                monto: 1008,
              },
              {
                edad: 44,
                monto: 1056,
              },
              {
                edad: 45,
                monto: 1103,
              },
              {
                edad: 46,
                monto: 1151,
              },
              {
                edad: 47,
                monto: 1198,
              },
              {
                edad: 48,
                monto: 1251,
              },
              {
                edad: 49,
                monto: 1302,
              },
              {
                edad: 50,
                monto: 1355,
              },
              {
                edad: 51,
                monto: 1406,
              },
              {
                edad: 52,
                monto: 1457,
              },
              {
                edad: 53,
                monto: 1552,
              },
              {
                edad: 54,
                monto: 1647,
              },
              {
                edad: 55,
                monto: 1741,
              },
              {
                edad: 56,
                monto: 1836,
              },
              {
                edad: 57,
                monto: 1931,
              },
              {
                edad: 58,
                monto: 2060,
              },
              {
                edad: 59,
                monto: 2186,
              },
              {
                edad: 60,
                monto: 1900,
              },
              {
                edad: 61,
                monto: 2004,
              },
              {
                edad: 62,
                monto: 2109,
              },
              {
                edad: 63,
                monto: 2251,
              },
              {
                edad: 64,
                monto: 2391,
              },
              {
                edad: 65,
                monto: 2532,
              },
              {
                edad: 66,
                monto: 2674,
              },
              {
                edad: 67,
                monto: 2814,
              },
              {
                edad: 68,
                monto: 2955,
              },
              {
                edad: 69,
                monto: 3096,
              },
              {
                edad: 70,
                monto: 3204,
              },
            ],
            HOMBRE: [
              {
                edad: 18,
                monto: 231.28,
              },
              {
                edad: 19,
                monto: 242.42,
              },
              {
                edad: 20,
                monto: 253.55,
              },
              {
                edad: 21,
                monto: 264.69,
              },
              {
                edad: 22,
                monto: 275.83,
              },
              {
                edad: 23,
                monto: 286.96,
              },
              {
                edad: 24,
                monto: 298.1,
              },
              {
                edad: 25,
                monto: 309.23,
              },
              {
                edad: 26,
                monto: 320.37,
              },
              {
                edad: 27,
                monto: 331.51,
              },
              {
                edad: 28,
                monto: 342.64,
              },
              {
                edad: 29,
                monto: 353.78,
              },
              {
                edad: 30,
                monto: 364.91,
              },
              {
                edad: 31,
                monto: 376.05,
              },
              {
                edad: 32,
                monto: 387.19,
              },
              {
                edad: 33,
                monto: 398.32,
              },
              {
                edad: 34,
                monto: 409.46,
              },
              {
                edad: 35,
                monto: 420.59,
              },
              {
                edad: 36,
                monto: 431.73,
              },
              {
                edad: 37,
                monto: 442.86,
              },
              {
                edad: 38,
                monto: 454,
              },
              {
                edad: 39,
                monto: 465.14,
              },
              {
                edad: 40,
                monto: 476.27,
              },
              {
                edad: 41,
                monto: 666,
              },
              {
                edad: 42,
                monto: 706,
              },
              {
                edad: 43,
                monto: 755,
              },
              {
                edad: 44,
                monto: 805,
              },
              {
                edad: 45,
                monto: 855,
              },
              {
                edad: 46,
                monto: 905,
              },
              {
                edad: 47,
                monto: 955,
              },
              {
                edad: 48,
                monto: 989,
              },
              {
                edad: 49,
                monto: 1025,
              },
              {
                edad: 50,
                monto: 1059,
              },
              {
                edad: 51,
                monto: 1095,
              },
              {
                edad: 52,
                monto: 1130,
              },
              {
                edad: 53,
                monto: 1216,
              },
              {
                edad: 54,
                monto: 1303,
              },
              {
                edad: 55,
                monto: 1390,
              },
              {
                edad: 56,
                monto: 1475,
              },
              {
                edad: 57,
                monto: 1562,
              },
              {
                edad: 58,
                monto: 1697,
              },
              {
                edad: 59,
                monto: 1831,
              },
              {
                edad: 60,
                monto: 1942,
              },
              {
                edad: 61,
                monto: 2075,
              },
              {
                edad: 62,
                monto: 2209,
              },
              {
                edad: 63,
                monto: 2314,
              },
              {
                edad: 64,
                monto: 2420,
              },
              {
                edad: 65,
                monto: 2526,
              },
              {
                edad: 66,
                monto: 2631,
              },
              {
                edad: 67,
                monto: 2738,
              },
              {
                edad: 68,
                monto: 2843,
              },
              {
                edad: 69,
                monto: 2950,
              },
              {
                edad: 70,
                monto: 3053,
              },
            ],
          },
        },
        COLEGIATURA: {
          ANUAL: {
            especialidades: [
              {
                nombre: 'Anatomía Patológica',
                monto: 7800,
                duracion: 39,
                fechaInicioBeca: '2024-09-01',
                fechaFinBeca: '2027-11-30',
              },
              {
                nombre: 'Genética Médica',
                monto: 12500,
                duracion: 51,
                fechaInicioBeca: '2024-09-01',
                fechaFinBeca: '2028-11-30',
              },
              {
                nombre: 'Geriatría y Gerontología',
                monto: 7800,
                duracion: 51,
                fechaInicioBeca: '2024-09-01',
                fechaFinBeca: '2028-11-30',
              },
              {
                nombre: 'Higiene y Epidemiología',
                monto: 7800,
                duracion: 39,
                fechaInicioBeca: '2024-09-01',
                fechaFinBeca: '2027-11-30',
              },
              {
                nombre: 'Patología Clínica',
                monto: 9900,
                duracion: 39,
                fechaInicioBeca: '2024-09-01',
                fechaFinBeca: '2027-11-30',
              },
              {
                nombre: 'Medicina Física y Rehabilitación',
                monto: 9900,
                duracion: 51,
                fechaInicioBeca: '2024-09-01',
                fechaFinBeca: '2028-11-30',
              },
              {
                nombre: 'Medicina Intensiva y Emergencias (Medicina de Urgencias)',
                monto: 12500,
                duracion: 39,
                fechaInicioBeca: '2024-09-01',
                fechaFinBeca: '2027-11-30',
              },
              {
                nombre: 'Medicina Interna',
                monto: 9900,
                duracion: 51,
                fechaInicioBeca: '2024-09-01',
                fechaFinBeca: '2028-11-30',
              },
              {
                nombre: 'Neumología',
                monto: 7800,
                duracion: 51,
                fechaInicioBeca: '2024-09-01',
                fechaFinBeca: '2028-11-30',
              },
              {
                nombre: 'Psiquiatría',
                monto: 9900,
                duracion: 51,
                fechaInicioBeca: '2024-09-01',
                fechaFinBeca: '2028-11-30',
              },
              {
                nombre: 'Traumatología y Ortopedia',
                monto: 12500,
                duracion: 51,
                fechaInicioBeca: '2024-09-01',
                fechaFinBeca: '2028-11-30',
              },
            ],
          },
        },
      },
    };
  }

  public static tipoBecaExtranjeroEnum() {
    return {
      ESPECIALIDADES_MEDICAS_CUBA: Entities.TipoBecaExtranjero.ESPECIALIDADES_MEDICAS_CUBA,
      CIENCIAS_HUMANIDADES: Entities.TipoBecaExtranjero.CIENCIAS_HUMANIDADES,
      ESPECIALIDADES_MEDICAS: Entities.TipoBecaExtranjero.ESPECIALIDADES_MEDICAS,
      CREACION_FUTURO: Entities.TipoBecaExtranjero.CREACION_FUTURO,
    };
  }

  public static getMotivosEnum() {
    return {
      EXPERIENCIA_LABORAL: Entities.MotivoEnum.EXPERIENCIA_LABORAL,
      IMPORTANCIA_APOYO: Entities.MotivoEnum.IMPORTANCIA_APOYO,
      VENTAJAS_PROGRAMA: Entities.MotivoEnum.VENTAJAS_PROGRAMA,
      ALINEACION_AREAS_PRIORITARIAS: Entities.MotivoEnum.ALINEACION_AREAS_PRIORITARIAS,
    };
  }

  public static getMotivosArray() {
    return [
      { id: this.getMotivosEnum().EXPERIENCIA_LABORAL, maxLength: 5000, minLength: 100 },
      { id: this.getMotivosEnum().IMPORTANCIA_APOYO, maxLength: 5000, minLength: 100 },
      { id: this.getMotivosEnum().VENTAJAS_PROGRAMA, maxLength: 5000, minLength: 100 },
    ];
  }

  public static getMotivo() {
    return {
      orden: null,
      tipoMotivo: null,
      valor: null,
      label: null,
      id: null,
      minLength: null,
      maxLength: null,
      required: false,
      description: null,
      readonly: false,
      validationsCommons: {
        minMessage: null,
        maxMessage: null,
        requiredMessage: null,
      },
    };
  }

  public static getDocumentoExtranjero() {
    return {
      nombre: null,
      contentType: null,
      uri: null,
      definicionDocumento: null,
      size: null,
      requerido: false,
      etiqueta: null,
      maxSize: null,
      valor: null,
      mediaTypes: null,
      estado: null,
      tipoFormatoInvalido: null,
      pesoInvalido: null,
      nombreInvalido: null,
      expresion: null,
      visible: null,
    };
  }

  public static getRubro(): Entities.Rubro {
    return {
      concepto: null,
      periodo: null,
      moneda: null,
      montoTotal: null,
      montosPosteriores: null,
      costoTotalAnual: null,
    };
  }

  public static getConceptoEnum(): any {
    return [
      { value: Entities.ConceptoRubro.MANUTENCION, text: 'Manutención' },
      { value: Entities.ConceptoRubro.COLEGIATURA, text: 'Colegiatura' },
      { value: Entities.ConceptoRubro.SEGURO_MEDICO, text: 'Seguro médico' },
      { value: Entities.ConceptoRubro.BOLETO_AVION, text: 'Boleto avión' },
    ];
  }

  public static getPeriodoEnum(): any {
    return [
      { value: Entities.PeriodoPagoEnum.ANUAL, text: 'Anual' },
      { value: Entities.PeriodoPagoEnum.MENSUAL, text: 'Mensual' },
      { value: Entities.PeriodoPagoEnum.SEMESTRAL, text: 'Semestral' },
    ];
  }

  public static getMonedaEnum(): any {
    return [
      { value: Entities.Moneda.EUR, text: 'EUR' },
      { value: Entities.Moneda.GBP, text: 'GBP' },
      { value: Entities.Moneda.USD, text: 'USD' },
    ];
  }

  public static proximoAnioSeguro(): any {
    return {
      edad: null,
      monto: null,
      anio: null,
      status: null,
    };
  }

  public static proximoAnioSeguroCH(): any {
    return {
      monto: null,
      anio: null,
    };
  }

  /**
   * (Componente Rubros) Configuración temporal de atributos
   * que se envian al componente
   */
  public static configuracionAtributosCuba(): any {
    return {
      conceptoConfig: {
        opciones: ['COLEGIATURA', 'MANUTENCION', 'SEGURO_MEDICO'],
        disabled: false,
      },
      periodoConfig: {
        opciones: ['ANUAL', 'MENSUAL'],
        disabled: false,
      },
      monedaConfig: {
        opciones: ['EUR'],
        disabled: false,
      },
      montoConfig: {
        disabled: false,
      },
      tipoBecaExtranjero: Entities.TipoBecaExtranjero.ESPECIALIDADES_MEDICAS_CUBA,
    };
  }

  /**
   * (Componente Rubros - ciencias humanidades) Configuración temporal de atributos
   * que se envian al componente
   */
  public static configuracionAtributosCienciasHumanidades(): any {
    return {
      conceptoConfig: {
        opciones: ['COLEGIATURA', 'MANUTENCION', 'SEGURO_MEDICO'],
        disabled: false,
      },
      periodoConfig: {
        opciones: ['ANUAL', 'MENSUAL'],
        disabled: false,
      },
      monedaConfig: {
        opciones: ['EUR', 'USD', 'GBP'],
        disabled: false,
      },
      montoConfig: {
        disabled: false,
      },
      tipoBecaExtranjero: Entities.TipoBecaExtranjero.CIENCIAS_HUMANIDADES,
    };
  }
}
