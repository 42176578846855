import { Component, Inject, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import { required, requiredIf } from 'vuelidate/lib/validators';
import EntityBecasExtranjeroFactory from '@/shared/entity-commons/entity-becas-extranjero.factory';
import * as Entities from '@/shared/perfil-usuario/api';
import { ReglasRubroCH, ReglasRubroCuba } from '@/shared/model/configuracion/configuracion-rubro.model';
import { RubroAtributos } from '@/shared/model/configuracion/atributos-rubro.model';
import type AlertaComponent from '@/components/alerta/alerta.component';
import EntityFactory from '@/shared/entity-commons/entity.factory';

const VALIDATIONS = function () {
  return {
    rubroNuevo: {
      concepto: {
        required,
      },
      periodo: {
        required,
      },
      moneda: {
        required,
      },
      montoTotal: {
        required,
      },
      costoTotalAnual: {
        required: requiredIf(
          () =>
            this.atributos.tipoBecaExtranjero !== Entities.TipoBecaExtranjero.ESPECIALIDADES_MEDICAS_CUBA &&
            this.rubroNuevo.concepto == Entities.ConceptoRubro.COLEGIATURA
        ),
      },
    },
  };
};

@Component({
  validations: VALIDATIONS,
})
export default class RubrosComponent extends Vue {
  @Ref()
  alerta!: AlertaComponent;

  //Catalogos
  public rubroNuevo = EntityBecasExtranjeroFactory.getRubro();
  public conceptoOptions = [];
  public periodoOptions = [];
  public monedaOptions = [];

  //Tabla
  public proximoAnioSeguro = EntityBecasExtranjeroFactory.proximoAnioSeguro();
  public itemsSeguro: Array<any> = [];
  public fields = [];

  //Configuracion de Rubros
  public configuracionRubro = null;
  public configuracionRubroCH = null;
  public monedaDisabled = false;
  public montoDisabled = false;
  public periodoDisabled = false;
  public conceptoDisabled = true; //Parece que siempre concepto será el eje central para elegir el rubro en BECAS CUBA

  //Banderas
  public editingIndex = null;

  @PropSync('value', { type: Array, default: () => [] })
  public rubros;

  @Prop()
  public atributos: RubroAtributos;

  @Prop({ required: false, default: null })
  numeroDependientes: number;

  @Prop({ required: false, default: null })
  programa: string;

  @Prop({ required: false, default: null })
  sexo: string;

  @Prop({ required: false, default: null })
  fechaInicioBeca: string;

  @Prop({ required: false, default: null })
  duracionMeses: number;

  @Prop({ required: false, default: null })
  index: number;

  @Prop({ required: false, default: null })
  fechaNacimiento: string;

  @Watch('index', { immediate: true, deep: true })
  handler(value: any) {
    if (value || value == 0) {
      this.prepareRubro();
    }
  }

  @Prop({ required: false, default: null })
  clavePais: string;

  @Prop({ required: false, default: null })
  gradoPrograma: string;

  mounted() {
    const concepto = this.rubroNuevo.concepto ? this.rubroNuevo.concepto : null;
    this.procesaDisabled(this.atributos.tipoBecaExtranjero, concepto);
    if (this.atributos.tipoBecaExtranjero == Entities.TipoBecaExtranjero.CIENCIAS_HUMANIDADES) {
      this.procesaTipoBecaCyH();
    }
    this.procesaOptions();
    this.procesaTablaSeguros(this.atributos.tipoBecaExtranjero);
    //Aquí agregamos más procesamientos, por ejemplo, si fuese necesario agregar acciones para settear moneda, etc.

    //Humanidades
    if (this.atributos.tipoBecaExtranjero !== Entities.TipoBecaExtranjero.ESPECIALIDADES_MEDICAS_CUBA) {
      if (this.index || this.index == 0) {
        const colegiatura = this.rubros.filter(r => Entities.ConceptoRubro.COLEGIATURA == r.concepto);
        if (colegiatura.length > 0) this.conceptoChange(colegiatura[0].concepto);
        this.rubroNuevo.montoTotal = colegiatura[0].montoTotal;
        this.rubroNuevo.costoTotalAnual = colegiatura[0].costoTotalAnual;
      }
    }
  }

  /**
   * Procesa las opciones de rubros dependiendo el tipo de beca
   * de Ciencias y Humanidades
   */
  procesaTipoBecaCyH() {
    if (this.atributos.tipoBeca != null) {
      this.atributos.conceptoConfig.opciones = this.atributos.tipoBeca.rubros;
    }
  }

  procesaTablaSeguros(tipoBeca) {
    if (tipoBeca == Entities.TipoBecaExtranjero.ESPECIALIDADES_MEDICAS_CUBA) {
      this.fields = [
        { label: this.$t('rubro.tabla-seguros.header.edad').toString(), key: 'edad', class: 'text-center' },
        { label: this.$t('rubro.tabla-seguros.header.monto').toString(), key: 'monto', class: 'text-center' },
        { label: this.$t('rubro.tabla-seguros.header.anio').toString(), key: 'anio', class: 'text-center' },
      ];
      //TODO colocar el tipo Entities.TipoBecaExtranjero.CIENCIAS_HUMANIDADES o propiedad que indique tipo (todo ha sido obtenido de TipoBecaExtranjero)
    } else if (
      tipoBeca == Entities.TipoBecaExtranjero.CIENCIAS_HUMANIDADES ||
      tipoBeca == Entities.TipoBecaExtranjero.ESPECIALIDADES_MEDICAS ||
      tipoBeca == Entities.TipoBecaExtranjero.CREACION_FUTURO
    ) {
      this.fields = [
        { label: this.$t('rubro.tabla-seguros.header.monto').toString(), key: 'monto', class: 'text-center' },
        { label: this.$t('rubro.tabla-seguros.header.anio').toString(), key: 'anio', class: 'text-center' },
      ];
    }
  }

  // Procesa las acciones para deshabilitar/habilitar inputs- Acorde a las opciones en el archivo de la configuración-atributos.
  // Si fuese necesario llenar esos menús de manera más precisa se puede ejecutar acciones (validaciones, setteos) especificos por tipoBecaExtranjero
  procesaOptions() {
    this.conceptoOptions = this.getEnumWithSelection(EntityBecasExtranjeroFactory.getConceptoEnum, this.atributos.conceptoConfig.opciones);
    this.periodoOptions = this.getEnumWithSelection(EntityBecasExtranjeroFactory.getPeriodoEnum, this.atributos.periodoConfig.opciones);
    this.monedaOptions = this.getEnumWithSelection(EntityBecasExtranjeroFactory.getMonedaEnum, this.atributos.monedaConfig.opciones);
  }

  getEnumWithSelection(getEnumFunction: () => any[], selectedOptions: string[]) {
    const conceptoEnum = getEnumFunction();
    const opcionesConSeleccion = conceptoEnum.filter(option => selectedOptions.includes(option.value));
    return opcionesConSeleccion;
  }

  //Procesa las acciones para settear catálogos con base en la combinación de la configuración de atributos y reglas especificas
  procesaDisabled(tipoBeca, concepto) {
    if (tipoBeca == Entities.TipoBecaExtranjero.ESPECIALIDADES_MEDICAS_CUBA) {
      this.monedaDisabled = this.atributos.monedaConfig.disabled;
      this.periodoDisabled = this.atributos.periodoConfig.disabled;
      this.montoDisabled = this.atributos.montoConfig.disabled;
      //TODO colocar el tipo Entities.TipoBecaExtranjero.CIENCIAS_HUMANIDADES o propiedad que indique tipo (todo ha sido obtenido de TipoBecaExtranjero)
    } else if (
      tipoBeca == Entities.TipoBecaExtranjero.CIENCIAS_HUMANIDADES ||
      tipoBeca == Entities.TipoBecaExtranjero.ESPECIALIDADES_MEDICAS ||
      tipoBeca == Entities.TipoBecaExtranjero.CREACION_FUTURO
    ) {
      this.monedaDisabled = this.atributos.monedaConfig.disabled;
      this.periodoDisabled = this.atributos.periodoConfig.disabled;
      concepto == Entities.ConceptoRubro.COLEGIATURA
        ? (this.montoDisabled = true)
        : (this.montoDisabled = this.atributos.montoConfig.disabled);
    }
    //Agregar los setteos (y validaciones) que se realiacen en otro tipo de beca extranjera
  }

  //Se crean modelos y se envian al CARD
  public addRubro(): void {
    if (!this.$v.rubroNuevo.$invalid) {
      if (this.validaConcepto(this.rubroNuevo)) {
        if (this.validaMontoColegiatura(this.rubroNuevo)) {
          if (this.editingIndex === null) {
            this.rubros.push(this.rubroNuevo);
          } else {
            const nr = this.rubroNuevo;
            this.$set(this.rubros, this.editingIndex, nr);
            this.editingIndex = null;
          }
          this.resetForm();
        } else {
          this.alerta.mostrar(this.$t('rubro.alert.monto-excedido-colegiatura'), 'warning', 20);
        }
      } else {
        this.alerta.mostrar(this.$t('rubro.alert.duplicate'), 'warning', 20);
      }
    } else {
      this.$v.$touch();
    }
  }

  validaMontoColegiatura(rubroNuevo: Entities.Rubro): boolean {
    //TODO colocar el tipo Entities.TipoBecaExtranjero.CIENCIAS_HUMANIDADES o propiedad que indique tipo (todo ha sido obtenido de TipoBecaExtranjero)
    if (
      (this.atributos.tipoBecaExtranjero == Entities.TipoBecaExtranjero.CIENCIAS_HUMANIDADES &&
        rubroNuevo?.concepto == Entities.ConceptoRubro.COLEGIATURA) ||
      (this.atributos.tipoBecaExtranjero == Entities.TipoBecaExtranjero.ESPECIALIDADES_MEDICAS &&
        rubroNuevo?.concepto == Entities.ConceptoRubro.COLEGIATURA)
    ) {
      if (!this.configuracionRubroCH.validaMontosColegiatura(rubroNuevo.montoTotal)) {
        return false;
      }
    } else if (
      this.atributos.tipoBecaExtranjero == Entities.TipoBecaExtranjero.CREACION_FUTURO &&
      rubroNuevo?.concepto == Entities.ConceptoRubro.COLEGIATURA
    ) {
      if (!this.configuracionRubroCH.validaMontosColegiaturaFuturo(rubroNuevo.montoTotal)) {
        return false;
      }
    }
    return true;
  }

  public resetForm(): void {
    this.rubroNuevo = EntityBecasExtranjeroFactory.getRubro();
    this.editingIndex = null;
    setTimeout(() => {
      this.$v.rubroNuevo.$reset();
    }, 10);
    this.mostrarFormRubroChange();
  }

  //emit a componente padre
  public mostrarFormRubroChange() {
    this.$emit('mostrarFormRubroChange');
  }

  public conceptoChange(e) {
    this.procesaDisabled(this.atributos.tipoBecaExtranjero, e);
    if (this.atributos.tipoBecaExtranjero == Entities.TipoBecaExtranjero.ESPECIALIDADES_MEDICAS_CUBA) {
      this.configuracionRubro = new ReglasRubroCuba( //Instancia para el negocio de rubros BEXT-CUBA, pasamos los parametros necesarios
        this.atributos.tipoBecaExtranjero,
        this.programa,
        this.sexo,
        this.duracionMeses,
        this.fechaInicioBeca,
        this.numeroDependientes,
        this.fechaNacimiento
      );
      this.rubroNuevo = this.configuracionRubro.setteoRubrosBecaCuba(e);
      this.validaMontosPosteriores();
      //TODO colocar el tipo Entities.TipoBecaExtranjero.CIENCIAS_HUMANIDADES o propiedad que indique tipo (todo ha sido obtenido de TipoBecaExtranjero)
    } else if (
      this.atributos.tipoBecaExtranjero == Entities.TipoBecaExtranjero.CIENCIAS_HUMANIDADES ||
      this.atributos.tipoBecaExtranjero == Entities.TipoBecaExtranjero.ESPECIALIDADES_MEDICAS ||
      this.atributos.tipoBecaExtranjero == Entities.TipoBecaExtranjero.CREACION_FUTURO
    ) {
      this.configuracionRubroCH = new ReglasRubroCH( //Instancia para el negocio de rubros BEXT-CIENCIAS-HUMANIDADES, pasamos los parametros necesarios
        this.atributos.tipoBecaExtranjero,
        this.programa,
        this.sexo,
        this.duracionMeses,
        this.fechaInicioBeca,
        this.numeroDependientes,
        this.fechaNacimiento,
        this.clavePais,
        this.gradoPrograma
      );
      this.rubroNuevo = this.configuracionRubroCH.setteoRubrosBecaCH(e);
      this.validaMontosPosteriores();
    }
    // Agregar los métodos de setteo correspondientes a otro tipoBecaExtranjero acorde al cambio en concepto
  }

  /**
   * Selecciona el objeto del arreglo para edición
   * @param index posición del arreglo para editar
   */
  public prepareRubro(): void {
    if (this.index || this.index == 0) {
      this.editingIndex = this.index;

      const rubroInit = EntityFactory.getDependientesEconomicos();
      const rubroClonado = JSON.parse(JSON.stringify(this.rubros.at(this.editingIndex)));
      this.rubroNuevo = {
        ...rubroInit,
        ...rubroClonado,
      };
      this.validaMontosPosteriores();
    }
  }

  public validaMontosPosteriores() {
    this.rubroNuevo.montosPosteriores ? (this.itemsSeguro = this.rubroNuevo.montosPosteriores) : (this.itemsSeguro = null);
  }

  public validaConcepto(r): boolean {
    let res = true;

    if (this.editingIndex === null) {
      this.rubros.some(c => {
        if (c.concepto == r.concepto) res = false;
      });
    } else {
      for (let i = 0; i < this.rubros.length; i++) {
        if (i === this.editingIndex) {
          continue;
        }
        if (this.rubros[i].concepto == r.concepto) {
          res = false;
        }
      }
    }

    return res;
  }

  numbersOnly(evt) {
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault();
    } else {
      return true;
    }
  }
}
