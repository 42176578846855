var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c("b-col", { attrs: { md: "12" } }, [
        _c("div", { staticClass: "title-block" }, [
          _c("h3", { staticClass: "title mt-4" }, [
            _c(
              "span",
              { domProps: { textContent: _vm._s(_vm.$t("etapa.title")) } },
              [_vm._v("etapa-desarrollo")]
            ),
            _vm._v(" "),
            _c("span", {
              staticClass: "sparkline bar",
              attrs: { "data-type": "bar" },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      !_vm.readonly
        ? [
            _c(
              "b-col",
              { attrs: { lg: "12" } },
              [_c("alerta", { ref: "alerta", attrs: { id: "alertEtapa" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "b-col",
              { attrs: { md: "4" } },
              [
                _c("input-select-one", {
                  attrs: {
                    id: "etapa.etapa-desarrollo-id",
                    label: _vm.$t("etapa.etapa-desarrollo.label"),
                    description: _vm.$t("etapa.etapa-desarrollo.description"),
                    options: _vm.etapaDesarrolloOptions,
                    required: true,
                    valid: _vm.$v.nuevaEtapa.etapaDesarrollo.$dirty
                      ? !_vm.$v.nuevaEtapa.etapaDesarrollo.$error
                      : null,
                    validationsCommons: {
                      requiredValue:
                        !_vm.$v.nuevaEtapa.etapaDesarrollo.required,
                      requiredMessage: _vm.$t(
                        "etapa.etapa-desarrollo.validations.required"
                      ),
                    },
                  },
                  model: {
                    value: _vm.$v.nuevaEtapa.etapaDesarrollo.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.nuevaEtapa.etapaDesarrollo, "$model", $$v)
                    },
                    expression: "$v.nuevaEtapa.etapaDesarrollo.$model",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-col",
              { attrs: { md: "4" } },
              [
                _c("input-text", {
                  attrs: {
                    id: "etapa.horas-invertidas-id",
                    label: _vm.$t("etapa.horas-invertidas.label"),
                    description: _vm.$t("etapa.horas-invertidas.description"),
                    required: true,
                    valid: _vm.$v.nuevaEtapa.horasInvertidas.$dirty
                      ? !_vm.$v.nuevaEtapa.horasInvertidas.$error
                      : null,
                    validationsCommons: {
                      requiredValue:
                        !_vm.$v.nuevaEtapa.horasInvertidas.required,
                      requiredMessage: _vm.$t(
                        "etapa.horas-invertidas.validations.required"
                      ),
                    },
                  },
                  on: { keypress: _vm.numbersOnly },
                  model: {
                    value: _vm.$v.nuevaEtapa.horasInvertidas.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.nuevaEtapa.horasInvertidas, "$model", $$v)
                    },
                    expression: "$v.nuevaEtapa.horasInvertidas.$model",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-col",
              { attrs: { md: "4" } },
              [
                _c("input-text", {
                  attrs: {
                    id: "etapa.costo-id",
                    label: _vm.$t("etapa.costo.label"),
                    description: _vm.$t("etapa.costo.description"),
                    required: true,
                    valid: _vm.$v.nuevaEtapa.costo.$dirty
                      ? !_vm.$v.nuevaEtapa.costo.$error
                      : null,
                    validationsCommons: {
                      requiredValue: !_vm.$v.nuevaEtapa.costo.required,
                      requiredMessage: _vm.$t(
                        "etapa.costo.validations.required"
                      ),
                    },
                  },
                  on: { keypress: _vm.numbersOnly },
                  model: {
                    value: _vm.$v.nuevaEtapa.costo.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.nuevaEtapa.costo, "$model", $$v)
                    },
                    expression: "$v.nuevaEtapa.costo.$model",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-col",
              { attrs: { md: "4" } },
              [
                _c("input-date-time", {
                  attrs: {
                    id: "fecha-inicio-id",
                    label: _vm.$t("etapa.fecha-inicio.label"),
                    description: _vm.$t("etapa.fecha-inicio.description"),
                    readonly: false,
                    required: true,
                    valid: _vm.$v.nuevaEtapa.fechaInicio.$dirty
                      ? !_vm.$v.nuevaEtapa.fechaInicio.$error
                      : null,
                    validationsCommons: {
                      requiredValue: !_vm.$v.nuevaEtapa.fechaInicio.required,
                      requiredMessage: _vm.$t(
                        "etapa.fecha-inicio.validations.required"
                      ),
                    },
                  },
                  on: { change: _vm.limpiaFechaFin },
                  model: {
                    value: _vm.$v.nuevaEtapa.fechaInicio.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.nuevaEtapa.fechaInicio, "$model", $$v)
                    },
                    expression: "$v.nuevaEtapa.fechaInicio.$model",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-col",
              { attrs: { md: "4" } },
              [
                _c("input-date-time", {
                  attrs: {
                    id: "fecha-fin-id",
                    label: _vm.$t("etapa.fecha-fin.label"),
                    description: _vm.$t("etapa.fecha-fin.description"),
                    readonly: false,
                    required: true,
                    minDate: _vm.convertStringToDate(
                      _vm.$v.nuevaEtapa.fechaInicio.$model
                    ),
                    valid: _vm.$v.nuevaEtapa.fechaFin.$dirty
                      ? !_vm.$v.nuevaEtapa.fechaFin.$error
                      : null,
                    validationsCommons: {
                      requiredValue: !_vm.$v.nuevaEtapa.fechaFin.required,
                      requiredMessage: _vm.$t(
                        "etapa.fecha-fin.validations.required"
                      ),
                    },
                  },
                  model: {
                    value: _vm.$v.nuevaEtapa.fechaFin.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.nuevaEtapa.fechaFin, "$model", $$v)
                    },
                    expression: "$v.nuevaEtapa.fechaFin.$model",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("b-col", { attrs: { md: "4" } }, [
              _c(
                "div",
                { staticClass: "float-right" },
                [
                  _c("span", { staticClass: "label-hidden" }),
                  _vm._v(" "),
                  _vm.removeIndex !== null
                    ? _c(
                        "b-button",
                        {
                          attrs: { variant: "outline-primary" },
                          on: { click: _vm.resetForm },
                        },
                        [
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("entity.action.cancel")
                                ),
                              },
                            },
                            [_vm._v("Cancelar")]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "icon-cerrar" }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: { click: _vm.agregaEtapa },
                    },
                    [
                      _vm.removeIndex === null
                        ? _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("entity.action.add")
                                ),
                              },
                            },
                            [_vm._v("añadir")]
                          )
                        : _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("entity.action.save")
                                ),
                              },
                            },
                            [_vm._v("guardar")]
                          ),
                      _vm._v(" "),
                      _vm.removeIndex === null
                        ? _c("font-awesome-icon", {
                            staticClass: "mr-2",
                            attrs: { icon: "plus" },
                          })
                        : _c("font-awesome-icon", {
                            staticClass: "mr-2",
                            attrs: { icon: "pencil" },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("b-col", { attrs: { md: "12" } }, [
        _c(
          "div",
          { staticClass: "table-responsive" },
          [
            _c("b-table", {
              staticClass: "btn-custom-acciones",
              attrs: {
                fields: _vm.fieldsEtapa,
                items: _vm.etapas,
                "current-page": _vm.currentPage,
                "per-page": _vm.recordsPage,
                "sort-by": _vm.sortBy,
                "sort-desc": _vm.sortDesc,
                "sort-direction": _vm.sortDirection,
                stacked: "md",
                "show-empty": "",
                small: "",
                striped: "",
                borderless: "",
                outlined: "",
                hover: "",
                "sort-compare-options": { numeric: true, sensitivity: "base" },
                "head-variant": "light",
                "empty-filtered-text": "No se encontraron coincidencias.",
              },
              on: {
                "update:sortBy": function ($event) {
                  _vm.sortBy = $event
                },
                "update:sort-by": function ($event) {
                  _vm.sortBy = $event
                },
                "update:sortDesc": function ($event) {
                  _vm.sortDesc = $event
                },
                "update:sort-desc": function ($event) {
                  _vm.sortDesc = $event
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "cell(periodoInicio)",
                  fn: function (row) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(row.item.periodoInicio) +
                          " - " +
                          _vm._s(row.item.periodoFin) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "cell(acciones)",
                  fn: function (row) {
                    return [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            title: _vm.$t("entity.action.edit"),
                            size: "sm",
                            variant: "outline-primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.prepareAutor(row.item, row.index)
                            },
                          },
                        },
                        [_c("b-icon", { attrs: { icon: "pencil-square" } })],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            title: _vm.$t("entity.action.delete"),
                            size: "sm",
                            variant: "outline-primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.prepareRemove(row.item.orden)
                            },
                          },
                        },
                        [_c("b-icon", { attrs: { icon: "trash" } })],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _vm.validEtapaComponent
              ? [
                  _c("b-col", [
                    _c("div", { staticClass: "invalid-feedback d-block" }, [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("etapa-desarrollo.generalRequired")
                          ),
                        },
                      }),
                    ]),
                  ]),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("c-delete-modal", {
              attrs: { id: "removeAutor" },
              on: {
                onDelete: function ($event) {
                  return _vm.remove()
                },
              },
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "6", "offset-md": "3" } },
        [
          _vm.etapas.length > _vm.recordsPage
            ? _c("b-pagination", {
                attrs: {
                  "total-rows": _vm.etapas.length,
                  "per-page": _vm.recordsPage,
                  "aria-controls": "my-table",
                  align: "fill",
                },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }