import Component, { mixins } from 'vue-class-component';
import PropertyCommons from '../commons/property.model';
import { Inject, Prop, PropSync, Ref } from 'vue-property-decorator';
import { required, minLength, maxLength, helpers, numeric, requiredIf } from 'vuelidate/lib/validators';
import CatalogoService from '@/shared/catalogo/catalogo.service';
import type AlertaComponent from '@/components/alerta/alerta.component';
import isEqual from 'lodash.isequal';
import EntityFactory from '@/shared/entity-commons/entity.factory';

const VALIDATIONS = function () {
  return {
    escolaridadFamiliar: {
      parentesco: {
        required,
      },
      escolaridad: {
        required,
      },
      ocupacion: {
        required,
      },
    },
  };
};

@Component({
  validations: VALIDATIONS,
})
export default class EscolaridadFamiliarComponent extends mixins(PropertyCommons) {
  @Inject('catalogoService') private catalogoService: () => CatalogoService;

  @Ref()
  alerta!: AlertaComponent;

  @PropSync('value', { type: Array, default: () => [] })
  public listadoEscolaridad!: Array<any>;
  public escolaridadesCard: Array<any> = [];
  public escolaridadOptions: Array<any> = [];
  public isEdit = false;
  public editingIndex = null;
  public isSaving = false;
  public ocupacionOptions: Array<any> = EntityFactory.getCatOcupacion(this.$t.bind(this));
  public parentestcoOption: Array<any> = [];

  mounted() {
    this.getCatalogos();
    this.mapCards();
  }

  public initCatalogos(): void {
    // TODO insertar catalogos correspondientes
  }

  public mapCards() {
    if (this.listadoEscolaridad) {
      this.listadoEscolaridad.forEach(e => {
        this.escolaridadesCard.push(this.creaRegistroCard(e));
      });
    } else {
      this.listadoEscolaridad = [];
    }
  }

  public getCatalogos() {
    this.catalogoService()
      .get('getNivelesAcademicos')
      .then(result => {
        this.escolaridadOptions = result;
      });
    this.parentestcoOption = EntityFactory.getCatParentesco(this.$t.bind(this));
  }

  protected escolaridadFamiliar: {
    escolaridad: any;
    ocupacion: any;
    parentesco: any;
  } = {
    escolaridad: null,
    ocupacion: null,
    parentesco: null,
  };
  @Prop({ default: '' })
  title: string;

  @Prop({ default: true })
  formShow: boolean;

  @Prop({ required: false, default: 1 })
  public readonly min!: number;

  @Prop({ required: false, default: false, type: Boolean })
  public readonly readonly!: boolean;

  public eliminaEscolaridad(removeId): void {
    this.alerta.mostrar(this.$t('escolaridad-familiar.alert.delete'), 'warning', 20);
    this.escolaridadesCard.splice(removeId, 1);
    this.listadoEscolaridad.splice(removeId, 1);
    this.resetForm();
  }

  //Se crean modelos y se envian al CARD y EMIT
  public agregaEscolaridad(): void {
    if (!this.$v.escolaridadFamiliar.$invalid) {
      if (this.editingIndex === null) {
        this.escolaridadesCard.push(this.creaRegistroCard(this.escolaridadFamiliar));
        this.listadoEscolaridad.push(this.creaEscolaridad(this.escolaridadFamiliar));
        this.alerta.mostrar(this.$t('escolaridad-familiar.alert.save'), 'success', 20);
        this.$emit('agregaEscolaridad', this.listadoEscolaridad);
      } else {
        const nmc = this.escolaridadFamiliar;
        this.$set(this.listadoEscolaridad, this.editingIndex, this.creaEscolaridad(nmc));
        this.$set(this.escolaridadesCard, this.editingIndex, this.creaRegistroCard(nmc));
        this.editingIndex = null;
        this.alerta.mostrar(this.$t('escolaridad-familiar.alert.edit'), 'success', 20);
      }
      this.resetForm();
    } else {
      this.$v.$touch();
    }
  }

  //Crea modelo que se va a guardar
  private creaEscolaridad(element) {
    return {
      escolaridad: element.escolaridad,
      ocupacion: element.ocupacion,
      parentesco: element.parentesco,
    };
  }
  //Crea modelo para CARD
  private creaRegistroCard(ele): any {
    return {
      title: ele.parentesco.nombre,
      description: `${ele.ocupacion.nombre}`,
      hidden: true,
      badge: [
        {
          badge: ele.escolaridad.nombre,
          primary: true,
        },
      ],
      icon: 'icon-educacion',
    };
  }

  public resetForm(): void {
    this.escolaridadFamiliar.escolaridad = null;
    this.escolaridadFamiliar.ocupacion = null;
    this.escolaridadFamiliar.parentesco = null;
    this.editingIndex = null;
    setTimeout(() => {
      this.$v.escolaridadFamiliar.$reset();
    }, 10);
  }

  /**
   * edit
   */
  public edit() {
    this.isEdit = !this.isEdit;
  }

  public prepareEscolaridad(index) {
    this.editingIndex = index;
    this.escolaridadFamiliar = this.shallowCopy(this.listadoEscolaridad.at(this.editingIndex));
    this.edit();
  }

  /** Copia las propiedades de un objeto */
  public shallowCopy<T extends object>(source: T): T {
    return {
      ...source,
    };
  }
}
