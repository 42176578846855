import { render, staticRenderFns } from "./area-conocimiento.vue?vue&type=template&id=53b8ac11&"
import script from "./area-conocimiento.component.ts?vue&type=script&lang=ts&"
export * from "./area-conocimiento.component.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/1/gateway/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('53b8ac11')) {
      api.createRecord('53b8ac11', component.options)
    } else {
      api.reload('53b8ac11', component.options)
    }
    module.hot.accept("./area-conocimiento.vue?vue&type=template&id=53b8ac11&", function () {
      api.rerender('53b8ac11', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/components/area-conocimiento/area-conocimiento.vue"
export default component.exports