var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("hr"),
      _vm._v(" "),
      _c("b-jumbotron", { attrs: { lead: _vm.$t("global.contact.title") } }, [
        _c(
          "span",
          {
            domProps: {
              textContent: _vm._s(_vm.$t("global.contact.messages.questions")),
            },
          },
          [
            _vm._v(
              "If you have questions about the System, contact the Technical Support Center from 08:00 a.m. to 08:00 p.m. (central time) Mexico)\n      to the following telephone numbers:"
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "fw-bold",
            attrs: { href: "tel:+5553227708" },
            domProps: {
              textContent: _vm._s(_vm.$t("global.contact.tels.tel-1")),
            },
          },
          [_vm._v("5553 22 77 08")]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            domProps: {
              textContent: _vm._s(_vm.$t("global.contact.messages.and")),
            },
          },
          [_vm._v("and")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "fw-bold",
            attrs: { href: "tel:+8008008649" },
            domProps: {
              textContent: _vm._s(_vm.$t("global.contact.tels.tel-2")),
            },
          },
          [_vm._v("800 800 86 49")]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            domProps: {
              textContent: _vm._s(_vm.$t("global.contact.messages.send")),
            },
          },
          [_vm._v("or send an email to")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: { href: "mailto:cca@conahcyt.mx" },
            domProps: {
              textContent: _vm._s(_vm.$t("global.contact.emails.email-1")),
            },
          },
          [_vm._v("cca@conahcyt.mx")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }