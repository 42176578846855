var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "mb-4" },
    [
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-alert",
            {
              attrs: {
                show: "",
                variant:
                  _vm.totalPrincipal == _vm.totalReferenciaPrincipal
                    ? "success"
                    : "warning",
              },
            },
            [
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("total-documentos.principal", {
                      max: _vm.totalReferenciaPrincipal,
                      principal: _vm.totalPrincipal,
                      maxPrincipal: _vm.totalReferenciaPrincipal,
                    })
                  ),
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("b-alert", { attrs: { show: "" } }, [
            _c("span", {
              domProps: {
                innerHTML: _vm._s(_vm.$t("total-documentos.contabilice")),
              },
            }),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "6" } },
        [
          _c("strong", [
            _c(
              "span",
              {
                domProps: {
                  textContent: _vm._s(_vm.$t("total-documentos.destacado")),
                },
              },
              [_vm._v("Productos destacados")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "b-progress",
            {
              attrs: {
                max: _vm.totalReferenciaPrincipal,
                variant: "primary",
                height: "1rem",
              },
            },
            [
              _c("b-progress-bar", { attrs: { value: _vm.totalPrincipal } }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(_vm.totalPrincipal) +
                      " / " +
                      _vm._s(_vm.totalReferenciaPrincipal)
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }