import { Module } from 'vuex';

export const dmsPdfStore: Module<any, any> = {
  state: {
    pdfModificable: null,
  },
  getters: {
    pdfModificable: state => state.pdfModificable,
  },
  mutations: {
    setPdfModificable(state, pdfModificable) {
      state.pdfModificable = pdfModificable;
    },
  },
};
