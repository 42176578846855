import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import PropertyCommons from '../commons/property.model';

@Component({
  inheritAttrs: false,
})
export default class MultiselectTagsComponent extends mixins(PropertyCommons) {
  @Prop({ required: true })
  public value!: string;

  @Prop({ required: true, type: Array })
  public tags!: Array<any>;

  @Prop({ default: null })
  requiredValue: boolean;

  @Prop({ default: null })
  typeValue: boolean;

  @Prop({ default: '' })
  requiredMessage: string;

  get selectedId(): string {
    return this.value;
  }

  set selectedId(newValue: string) {
    this.$emit('input', newValue);
  }
}
