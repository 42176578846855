import { Component, Prop, Vue } from 'vue-property-decorator';

const VALIDATIONS = function () {
  return {
    totalPrincipal: {},
  };
};

@Component({
  validations: VALIDATIONS,
})
export default class TotalPrincipalComponent extends Vue {
  @Prop({ required: true, type: Number })
  public totalPrincipal;

  @Prop({ default: false })
  public readonly: boolean;

  @Prop({ default: null })
  public validComponent: boolean;

  @Prop({ required: true, type: Number })
  public totalReferenciaPrincipal: number;

  @Prop({ default: 60 })
  public totalReferencia: number;

  @Prop({ default: 0 })
  public totalDocumentos: number;

  @Prop({ default: true })
  public isVisible: boolean;

  mounted(): void {
    this.getTotal();
  }

  public getTotal(): void {
    this.totalPrincipal = Number(this.totalPrincipal);
    this.totalReferenciaPrincipal = Number(this.totalReferenciaPrincipal);
  }
}
