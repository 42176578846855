var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-dropdown-item",
        { attrs: { to: "/acerca-de-mi/mi-perfil", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("acerca-de-mi.mi-perfil.title")),
              },
            },
            [_vm._v("Mi perfil")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/acerca-de-mi/grupos", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("acerca-de-mi.grupos-vulnerables.title")
                ),
              },
            },
            [_vm._v("Grupos Vulnerables")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/educacion/trayectoria-academica",
            "active-class": "active",
          },
        },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("educacion.trayectoria-academica.title")
                ),
              },
            },
            [_vm._v("Trayectoria académica")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/educacion/cursos", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("educacion.cursos.title")),
              },
            },
            [_vm._v("Cursos y Certificaciones Médicas")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/educacion/logros", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("educacion.logros.title")),
              },
            },
            [_vm._v("Logros")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/educacion/idiomas", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("educacion.idiomas.title")),
              },
            },
            [_vm._v("Idiomas")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/experiencia/trayectoria-profesional",
            "active-class": "active",
          },
        },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("experiencia.trayectoria-profesional.title")
                ),
              },
            },
            [_vm._v("Trayectoria profesional")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/experiencia/evaluaciones-otorgadas",
            "active-class": "active",
          },
        },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("experiencia.evaluaciones-otorgadas.title")
                ),
              },
            },
            [_vm._v("Evaluaciones otorgadas")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/experiencia/estancias", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("experiencia.estancias.title")),
              },
            },
            [_vm._v("Estancias de investigación")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/experiencia/formacion/cursos-impartidos",
            "active-class": "active",
          },
        },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("experiencia.formacion.cursos-impartidos.title")
                ),
              },
            },
            [_vm._v("Cursos Impartidos")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/experiencia/formacion/tesis",
            "active-class": "active",
          },
        },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("experiencia.formacion.tesis.title")
                ),
              },
            },
            [_vm._v("Tesis dirigidas")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/experiencia/formacion/diplomados",
            "active-class": "active",
          },
        },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("experiencia.formacion.diplomados.title")
                ),
              },
            },
            [_vm._v("Diplomados Impartidos")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/experiencia/formacion/congresos",
            "active-class": "active",
          },
        },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("experiencia.formacion.congresos.title")
                ),
              },
            },
            [_vm._v("Participación en congresos")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/experiencia/formacion/divulgacion",
            "active-class": "active",
          },
        },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("experiencia.formacion.divulgacion.title")
                ),
              },
            },
            [_vm._v("Divulgación")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/aportaciones-simple", "active-class": "active" } },
        [
          _c(
            "span",
            { domProps: { textContent: _vm._s(_vm.$t("aportaciones.title")) } },
            [_vm._v("Aportaciones ")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/aportaciones/articulos", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("aportaciones.articulos.title")),
              },
            },
            [_vm._v("Artículos")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/aportaciones/libros", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("aportaciones.libros.title")),
              },
            },
            [_vm._v("libros")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/aportaciones/capitulos", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("aportaciones.capitulos.title")),
              },
            },
            [_vm._v("Capítulos")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/aportaciones/desarrollos-tecnologicos",
            "active-class": "active",
          },
        },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("aportaciones.desarrollos-tecnologicos.title")
                ),
              },
            },
            [_vm._v("Desarrollos Tecnológicos")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/aportaciones/desarrollos-software",
            "active-class": "active",
          },
        },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("aportaciones.desarrollos-software.title")
                ),
              },
            },
            [_vm._v("DesarrollosSof")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: { to: "/aportaciones/innovaciones", "active-class": "active" },
        },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("aportaciones.innovaciones.title")),
              },
            },
            [_vm._v("Innovaciones")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/aportaciones/patentes", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("aportaciones.patentes.title")),
              },
            },
            [_vm._v("Patentes")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/educacion/desarrollo-institucional",
            "active-class": "active",
          },
        },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("educacion.desarrollo-institucional.title")
                ),
              },
            },
            [
              _vm._v(
                "Participación en proyectos que amplíen la capacidad de investigación"
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/aportaciones/desarrollos-tecnologicos-innovaciones",
            "active-class": "active",
          },
        },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t(
                    "aportaciones.desarrollos-tecnologicos-innovaciones.title"
                  )
                ),
              },
            },
            [_vm._v("Desarrollo tecnológico e Innovación")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/aportaciones/propiedades-intelectuales",
            "active-class": "active",
          },
        },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("aportaciones.propiedades-intelectuales.title")
                ),
              },
            },
            [_vm._v("Propiedad Intelectual")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/aportaciones/transferencias-tecnologicas",
            "active-class": "active",
          },
        },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("aportaciones.transferencias-tecnologicas.title")
                ),
              },
            },
            [_vm._v("Transferencia Tecnológica")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }