import { Component, Inject, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import CatalogoService from '@/shared/catalogo/catalogo.service';
import AlertService from '@/shared/alert/alert.service';
import EntityFactory from '@/shared/entity-commons/entity.factory';

import { required, minLength, maxLength, email, sameAs, numeric, requiredIf, helpers } from 'vuelidate/lib/validators';
import AlertaComponent from '../alerta/alerta.component';
import { isEqual, cloneDeep } from 'lodash';
import { TipoUbicacion } from '@/shared/perfil-usuario';
import DatePick from 'vue-date-pick';

const FECHA = helpers.regex('fecha', /^\d{4}-(0[1-9]|1[0-2])-([0-2][0-9]|3[01])$/);
const VALIDATIONS = function () {
  return {
    emergencia: {
      nombre: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(50),
      },
      primerApellido: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(50),
      },
      segundoApellido: {
        minLength: minLength(2),
        maxLength: maxLength(50),
      },
      parentesco: {
        required,
      },
      sexo: {
        required,
      },
      fechaNacimiento: {
        required,
        maxLength: maxLength(10),
        FECHA,
      },
      correo: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(254),
        email,
      },
      confirmCorreo: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(254),
        email,
        sameAsEmail: sameAs(function () {
          return this.emergencia.correo;
        }),
      },
      lada: {
        numeric,
        required,
        maxLength: maxLength(10),
      },
      telefono: {
        numeric,
        required,
        maxLength: maxLength(10),
      },
      celular: {
        numeric,
        required,
        maxLength: maxLength(10),
      },
      tipoContacto: {
        required,
      },
      domicilio: {
        estado: {
          required: requiredIf(() => !this.banderaExtranjero),
        },
        codigoPostal: {
          numeric,
          required: requiredIf(() => !this.banderaExtranjero),
          minLength: minLength(5),
          maxLength: maxLength(5),
        },
        municipio: {
          required: requiredIf(() => !this.banderaExtranjero),
        },
        asentamiento: {},
        tipoAsentamiento: {},
        tipoUbicacion: {},
        pais: {
          required: requiredIf(() => this.banderaExtranjero),
        },
        cpExtranjero: {
          numeric,
          required: requiredIf(() => this.banderaExtranjero),
          minLength: minLength(5),
          maxLength: maxLength(10),
        },
        estadoExtranjero: {
          required: requiredIf(() => this.banderaExtranjero),
          minLength: minLength(2),
          maxLength: maxLength(50),
        },
        ciudadExtranjero: {
          required: requiredIf(() => this.banderaExtranjero),
          minLength: minLength(2),
          maxLength: maxLength(50),
        },
        condadoExtranjero: {
          required: requiredIf(() => this.banderaExtranjero),
          minLength: minLength(2),
          maxLength: maxLength(50),
        },
        calleExtranjero: {
          required: requiredIf(() => this.banderaExtranjero),
          minLength: minLength(2),
          maxLength: maxLength(50),
        },
        numeroExtranjero: {
          required: requiredIf(() => this.banderaExtranjero),
          minLength: minLength(2),
          maxLength: maxLength(50),
        },
        referenciasExtranjero: {
          required: requiredIf(() => this.banderaExtranjero),
          maxLength: maxLength(256),
        },
      },
    },
  };
};

@Component({
  validations: VALIDATIONS,
  components: {
    DatePick,
  },
})
export default class ContactosEmergenciaComponent extends Vue {
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('catalogoService') private catalogoService: () => CatalogoService;

  @Ref()
  alerta!: AlertaComponent;

  @PropSync('value', { type: Array, default: () => [] })
  public contactosEmergencia;

  @Prop({ required: false, default: null })
  index: number;

  @Watch('index', { immediate: true, deep: true })
  handler(value: any) {
    if (value || value == 0) {
      this.prepareContacto();
    }
  }

  @Prop({ default: {} })
  domicilio;

  public catTipoDeContacto: Array<any> = [];
  public sexoOptions: Array<any> = [];
  public paises: Array<any> = [];
  public banderaExtranjero = false;
  public domicilia = false;
  public sexoDisabled = true;
  public minAgeDate = new Date();
  public startDate = new Date().getFullYear();
  public starMonth = new Date().getMonth();
  public catNacionalExtranjero = EntityFactory.getNacionalExtranjeroOptions(this.$t.bind(this));

  public isEdit = false;
  public editingIndex = null;

  public showCP = false;

  public catEstado: Array<any> = [];
  public catMunicipio: Array<any> = [];
  public catTipoAsentamiento: Array<any> = [];
  public catTipoVialidadCarretera: Array<any> = [];
  public catTipoVialidadC: Array<any> = [];
  public catTipoVialidad: Array<any> = [];
  public catAsentamiento: Array<any> = [];
  public catLocalidad: Array<any> = [];

  public parentestcoOption: Array<any> = [];
  public emergencia = EntityFactory.getContactoEmergencia();

  public alertaContactoEId;

  public format = 'YYYY-MM-DD';
  public displayFormat = 'DD-MM-YYYY';

  mounted() {
    this.emergencia.domicilio.tipoUbicacion = TipoUbicacion.NACIONAL;
    this.banderaExtranjero = this.emergencia.domicilio.tipoUbicacion !== TipoUbicacion.NACIONAL;
    this.initCatalogos();
    this.validateDate();
    this.alertaContactoEId = document.querySelector(`[id="emergencia-nombre-id"]`);
  }

  //Se crean modelos y se envian al CARD
  public addContacto(): void {
    if (!this.$v.emergencia.$invalid) {
      if (!this.validaDuplicados(this.emergencia)) {
        if (this.editingIndex === null) {
          this.contactosEmergencia.push(this.emergencia);
        } else {
          const nmc = this.emergencia;
          this.$set(this.contactosEmergencia, this.editingIndex, nmc);
          this.editingIndex = null;
        }
        this.resetForm();
      } else {
        this.alerta.mostrar(this.$t('contactos-emergencia.alerts.duplicate'), 'warning', 20);
        this.alertaContactoEId.scrollIntoView({ block: 'center', inline: 'center' });
      }
    } else {
      this.$v.$touch();
    }
  }

  /**
   * Selecciona el objeto del arreglo para edición
   * @param index posición del arreglo para editar
   */
  public prepareContacto(): void {
    if (this.index || this.index == 0) {
      this.editingIndex = this.index;
      this.setCP();

      const emergenciaInit = EntityFactory.getContactoEmergencia();
      this.emergencia = {
        ...emergenciaInit,
        ...JSON.parse(JSON.stringify(this.contactosEmergencia.at(this.editingIndex))),
      };
      this.emergencia.confirmCorreo = this.emergencia.correo;
    }
  }

  /**
   * validaDuplicados
   * this.contactosEmergencia.some(v => v.text == val) busca dentro del arreglo coicidencias y devuelve true o false
   */
  public validaDuplicados(val) {
    return this.contactosEmergencia.some(m => isEqual(m, val));
  }

  public resetForm(): void {
    this.domicilia = false;
    this.emergencia = EntityFactory.getContactoEmergencia();
    this.emergencia.domicilio.tipoUbicacion = TipoUbicacion.NACIONAL;
    this.banderaExtranjero = false;
    this.editingIndex = null;
    setTimeout(() => {
      this.$v.emergencia.$reset();
    }, 10);
    this.mostrarFormContactoEmergenciaChange();
  }

  //emit a componente padre
  public mostrarFormContactoEmergenciaChange() {
    this.$emit('mostrarFormContactoEmergenciaChange');
  }

  public initCatalogos(): void {
    this.catalogoService()
      .get('getPaises')
      .then(result => {
        this.paises = result.filter(function (pais) {
          return pais.value.id != 'MEX';
        });
      });
    this.catalogoService()
      .get('getGeneros')
      .then(result => {
        this.sexoOptions = result;
      });
    this.catalogoService()
      .get('getTipoContactos')
      .then(res => {
        this.catTipoDeContacto = res;
      });
    this.getCatEstado();
    this.getCatTipoAsentamiento();
    this.parentestcoOption = EntityFactory.getCatParentesco(this.$t.bind(this));
  }

  /**
   * Ventana confirmación cuando se cambia el tipo de ubicación
   *
   */
  public changeTipoUbicacion(e) {
    this.banderaExtranjero = e !== TipoUbicacion.NACIONAL;
    if (this.banderaExtranjero) {
      this.$bvModal
        .msgBoxConfirm(this.$t('contactos-emergencia.form.domicilio.modal.localizacion').toString(), {
          title: this.$t('domicilio.modal.title').toString(),
          size: 'md',
          buttonSize: 'md',
          okVariant: 'primary',
          okTitle: this.$t('domicilio.modal.confirm').toString(),
          cancelTitle: this.$t('domicilio.modal.cancel').toString(),
          cancelVariant: 'outline-primary',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.setUbicacionExtranjero();
            this.cleanUbicacionNacional();
          } else {
            this.setUbicacionNacional();
          }
        });
    } else {
      this.$bvModal
        .msgBoxConfirm(this.$t('contactos-emergencia.form.domicilio.modal.localizacion').toString(), {
          title: this.$t('domicilio.modal.title').toString(),
          size: 'md',
          buttonSize: 'md',
          okVariant: 'primary',
          okTitle: this.$t('domicilio.modal.confirm').toString(),
          cancelTitle: this.$t('domicilio.modal.cancel').toString(),
          cancelVariant: 'outline-primary',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.emergencia.domicilio.pais = null;
            this.emergencia.domicilio.cpExtranjero = null;
            this.emergencia.domicilio.estadoExtranjero = null;
            this.emergencia.domicilio.ciudadExtranjero = null;
            this.emergencia.domicilio.condadoExtranjero = null;
            this.emergencia.domicilio.calleExtranjero = null;
            this.emergencia.domicilio.numeroExtranjero = null;
            this.emergencia.domicilio.referenciasExtranjero = null;
            this.setUbicacionNacional();
          } else {
            this.setUbicacionExtranjero();
          }
        });
    }
  }

  /**
   * setUbicacionNacional
   */
  public setUbicacionNacional() {
    this.emergencia.domicilio.tipoUbicacion = TipoUbicacion.NACIONAL;
  }

  /**
   * setUbicacionExtranjero
   */
  public setUbicacionExtranjero() {
    this.emergencia.domicilio.tipoUbicacion = TipoUbicacion.EXTRANJERA;
  }

  /**
   * cleanUbicacionNacional
   */
  public cleanUbicacionNacional() {
    this.emergencia.domicilio.codigoPostal = null;
    this.emergencia.domicilio.estado = null;
    this.emergencia.domicilio.municipio = null;
    this.emergencia.domicilio.localidad = null;
    this.emergencia.domicilio.asentamiento = null;
    this.emergencia.domicilio.tipoAsentamiento = null;
  }
  /**
   * cleanUbicacionNacional
   */
  public cleanUbicacionExtranjera() {
    this.emergencia.domicilio.pais = null;
    this.emergencia.domicilio.cpExtranjero = null;
    this.emergencia.domicilio.estadoExtranjero = null;
    this.emergencia.domicilio.ciudadExtranjero = null;
    this.emergencia.domicilio.condadoExtranjero = null;
    this.emergencia.domicilio.calleExtranjero = null;
    this.emergencia.domicilio.numeroExtranjero = null;
    this.emergencia.domicilio.referenciasExtranjero = null;
  }

  /**
   * Setea Domicilio del usuario PU
   * @param e valor switch para setear información del domicilio existente en PU
   */
  public domiciliaChange(e) {
    this.banderaExtranjero = this.domicilio.tipoUbicacion !== TipoUbicacion.NACIONAL;
    if (e) {
      if (this.domicilio.tipoUbicacion == TipoUbicacion.NACIONAL) {
        this.cleanUbicacionExtranjera();
        this.getCP(this.domicilio.codigoPostal, true);
        this.emergencia.domicilio.tipoUbicacion = this.domicilio.tipoUbicacion;
        this.emergencia.domicilio.codigoPostal = this.domicilio.codigoPostal;
        this.emergencia.domicilio.tipoAsentamiento = this.domicilio.tipoAsentamiento;
        this.getAsentamientoPorCP(this.emergencia.domicilio.codigoPostal)
          .then(() => {
            this.emergencia.domicilio.asentamiento = this.filtraCatalogoId(this.catAsentamiento, this.emergencia.domicilio.asentamiento.id);
            this.changueTipoAsentamiento(this.emergencia.domicilio.asentamiento);
            this.showCP = false;
          })
          .catch(() => {
            this.emergencia.domicilio.asentamiento = null;
            this.showCP = false;
          });
        this.emergencia.domicilio.asentamiento = this.domicilio.asentamiento;
      } else {
        this.emergencia.domicilio.tipoUbicacion = TipoUbicacion.EXTRANJERA;
        this.emergencia.domicilio.pais = this.domicilio.pais;
        this.emergencia.domicilio.cpExtranjero = this.domicilio.cpExtranjero;
        this.emergencia.domicilio.estadoExtranjero = this.domicilio.estadoExtranjero;
        this.emergencia.domicilio.ciudadExtranjero = this.domicilio.ciudadExtranjero;
        this.emergencia.domicilio.condadoExtranjero = this.domicilio.condadoExtranjero;
        this.emergencia.domicilio.calleExtranjero = this.domicilio.calleExtranjero;
        this.emergencia.domicilio.numeroExtranjero = this.domicilio.numeroExtranjero;
        this.emergencia.domicilio.referenciasExtranjero = this.domicilio.descripcion;
        this.cleanUbicacionNacional();
      }
    }
  }

  //Busqueda de comicilio por CP
  public getCP(cp, automatico = false) {
    this.showCP = true;
    this.catalogoService()
      .get('getCatCodigosPostalesFilter', cp)
      .then(res => {
        if (res.length >= 1) {
          this.catAsentamiento = this.formatCatalogos('getCatCodigosPostalesFilter', cloneDeep(res));
          this.emergencia.domicilio.estado = this.filtraCatalogoClave(this.catEstado, res[0].value.id_entidad);
          if (this.catAsentamiento.length == 1) {
            this.emergencia.domicilio.asentamiento = this.catAsentamiento[0].value;
            this.changueTipoAsentamiento(res[0].value);
          } else {
            if (!automatico) {
              this.emergencia.domicilio.asentamiento = null;
              this.emergencia.domicilio.tipoAsentamiento = null;
            }
          }
          this.getCatMunicipio(this.emergencia.domicilio.estado).then(() => {
            this.emergencia.domicilio.municipio = this.filtraCatalogoClave(this.catMunicipio, res[0].value.id_municipio);
            this.getCatLocalidad(this.emergencia.domicilio.estado.id, this.emergencia.domicilio.municipio.clave).then(() => {
              if (this.catAsentamiento.length == 1) {
                this.emergencia.domicilio.localidad = this.catLocalidad[0].value;
              } else {
                this.emergencia.domicilio.localidad = null;
              }
              this.showCP = false;
            });
          });
        } else {
          this.emergencia.domicilio.estado = null;
          this.emergencia.domicilio.municipio = null;
          this.emergencia.domicilio.localidad = null;
          this.emergencia.domicilio.asentamiento = null;
          this.emergencia.domicilio.tipoAsentamiento = null;
          this.catLocalidad = [];
          this.catAsentamiento = [];
          this.$v.emergencia.domicilio.$reset();
          this.showCP = false;
        }
      });
  }

  /**
   * se hace un filtro a los catalogos por id para sacar el modelo y setearlo en el formulario
      esto soluciona el problema de mayusculas y minusculas
   * @param cat catalogo que se va a filtrar
   * @param val valor con el cual se va a filtrar
   * @returns el valor del catalogo
   */
  // filtra catalogo por clave
  public filtraCatalogoClave(cat, val) {
    const filtro = cat.filter(e => e.value.clave == val);
    return filtro.length > 0 ? filtro[0].value : null;
  }

  // filtra catalogo por Id
  public filtraCatalogoId(cat, val) {
    const filtro = cat.filter(e => e.value.id == val);
    return filtro.length > 0 ? filtro[0].value : null;
  }

  //Acepta solo numeros
  public numbersOnly(evt) {
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault();
    } else {
      return true;
    }
  }

  public getCatMunicipio(value) {
    return new Promise((resolve, reject) => {
      this.catalogoService()
        .get('getMunicipiosEstado', value.id)
        .then(res => {
          this.catMunicipio = this.formatCatalogos('getMunicipiosEstado', res);
          resolve('ok');
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getCatLocalidad(estado, municipio) {
    return new Promise((resolve, reject) => {
      this.catalogoService()
        .get('getLocalidadesMunicipiosEstados', estado, municipio)
        .then(res => {
          this.catLocalidad = this.formatCatalogos('getLocalidadesMunicipiosEstados', res);
          resolve('ok');
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getCatEstado() {
    return new Promise((resolve, reject) => {
      this.catalogoService()
        .get('getAreaGeoestadisticaEstatal')
        .then(res => {
          this.catEstado = res;
          resolve('ok');
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getCatTipoAsentamiento() {
    this.catalogoService()
      .get('getTipoAsentamientos')
      .then(res => {
        this.catTipoAsentamiento = res;
      });
  }

  /** Copia las propiedades de un objeto */
  public shallowCopy<T extends object>(source: T): T {
    return {
      ...source,
    };
  }

  /**
   * Setea y deshabilita el campo sexo dependiendo el id del catalogo parentesco
   * Habilita campo sexo cuando la opcion es conyuge
   * @param e valor del catalogo parentesco
   */
  public sexoParentescoChange(e) {
    if (e.id == '1' || e.id == '3' || e.id == '6') {
      this.emergencia.sexo = { id: 'F', nombre: 'Femenino' };
      this.sexoDisabled = true;
    } else if (e.id == '2' || e.id == '4' || e.id == '7') {
      this.emergencia.sexo = { id: 'M', nombre: 'Masculino' };
      this.sexoDisabled = true;
    } else {
      this.emergencia.sexo = null;
      this.sexoDisabled = false;
    }
  }

  /**
   * Regresa fecha con 18 años menos para que no se pueda seleccionar un menor de edad
   */
  public validateDate() {
    const currentDate = new Date();
    this.startDate = currentDate.getFullYear() - 18;
    this.starMonth = currentDate.getMonth();
    this.minAgeDate.setFullYear(this.startDate);
  }

  public changueTipoAsentamiento(val) {
    this.emergencia.domicilio.tipoAsentamiento = this.filtraCatalogoId(this.catTipoAsentamiento, val.id_tipo_asentamiento);
  }

  private formatCatalogos(tipo: string, catalogo: any[]) {
    switch (tipo) {
      case 'getCatCodigosPostalesFilter':
        catalogo.forEach(c => {
          if (c.value) {
            delete c.value.id_entidad;
            delete c.value.id_municipio;
          }
        });
        break;
      case 'getMunicipiosEstado':
        catalogo.forEach(c => {
          if (c.value) {
            delete c.value.id_estado;
          }
        });
        break;
      case 'getLocalidadesMunicipiosEstados':
        catalogo.forEach(c => {
          if (c.value) {
            delete c.value.id_estado;
            delete c.value.id_municipio;
          }
        });
        break;
    }
    return catalogo;
  }

  /**
   *setea información de CP desde la consulta
   */
  public setCP() {
    this.showCP = true;
    this.getCatEstado()
      .then(() => {
        this.emergencia.domicilio.estado = this.filtraCatalogoClave(this.catEstado, this.emergencia.domicilio.estado.clave);
        this.getCatMunicipio(this.emergencia.domicilio.estado)
          .then(() => {
            this.emergencia.domicilio.municipio = this.filtraCatalogoClave(this.catMunicipio, this.emergencia.domicilio.municipio.clave);
            this.getCatLocalidad(this.emergencia.domicilio.estado.id, this.emergencia.domicilio.municipio.clave)
              .then(() => {
                if (this.emergencia.domicilio.localidad == null || this.emergencia.domicilio.localidad?.nombre == 'NINGUNO') {
                  this.emergencia.domicilio.localidad = null;
                } else {
                  this.emergencia.domicilio.localidad = this.filtraCatalogoClave(
                    this.catLocalidad,
                    this.emergencia.domicilio.localidad.clave
                  );
                }
                if (this.emergencia.domicilio.codigoPostal) {
                  this.getAsentamientoPorCP(this.emergencia.domicilio.codigoPostal)
                    .then(() => {
                      this.emergencia.domicilio.asentamiento = this.filtraCatalogoId(
                        this.catAsentamiento,
                        this.emergencia.domicilio.asentamiento.id
                      );
                      this.changueTipoAsentamiento(this.emergencia.domicilio.asentamiento);
                      this.showCP = false;
                    })
                    .catch(() => {
                      this.emergencia.domicilio.asentamiento = null;
                      this.showCP = false;
                    });
                } else {
                  this.catAsentamiento = [];
                  this.emergencia.domicilio.asentamiento = null;
                  this.catTipoAsentamiento = [];
                  this.emergencia.domicilio.tipoAsentamiento = null;
                  this.showCP = false;
                }
              })
              .catch(() => {
                this.catLocalidad = [];
                this.emergencia.domicilio.localidad = null;
                this.showCP = false;
              });
          })
          .catch(() => {
            this.catMunicipio = [];
            this.emergencia.domicilio.municipio = null;
            this.showCP = false;
          });
      })
      .catch(() => {
        this.catEstado = [];
        this.emergencia.domicilio.estado = null;
        this.showCP = false;
      });
  }

  /**
   * getA
   */
  public getAsentamientoPorCP(cp) {
    return new Promise((resolve, reject) => {
      this.catalogoService()
        .get('getCatCodigosPostalesFilter', cp)
        .then(res => {
          this.catAsentamiento = res;
          resolve('ok');
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public validaCambiofecha() {
    if (
      this.convertirFecha(this.emergencia.fechaNacimiento) > this.minAgeDate ||
      this.convertirFecha(this.emergencia.fechaNacimiento) < new Date(1920, 0, 1)
    ) {
      this.alerta.mostrar(this.$t('contactos-emergencia.alerts.fecha-incorrecta'), 'warning', 10);
      this.emergencia.fechaNacimiento = this.emergencia.fechaNacimiento + ' '; //Genera fecha invalida
    }
  }

  private convertirFecha(fechaString: string): Date {
    if (fechaString) {
      if (fechaString.includes('-')) {
        const [year, month, day] = fechaString.split('-').map(val => parseInt(val));
        return new Date(year, month - 1, day);
      } else {
        const [day, month, year] = fechaString.split('/').map(val => parseInt(val));
        return new Date(year, month - 1, day);
      }
    }
  }
}
