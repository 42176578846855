var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { lg: "12" } },
        [
          _c("cards-list", {
            attrs: {
              id: _vm.id,
              items: _vm.dependientesCard,
              title: _vm.title,
              mostrarListado: false,
              disabledBtnAcctions: !_vm.formShow,
            },
            on: {
              remove: _vm.eliminaDependiente,
              edit: _vm.prepareDependiente,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { lg: "12" } },
        [_c("alerta", { ref: "alerta", attrs: { id: "alertContacto" } })],
        1
      ),
      _vm._v(" "),
      _vm.formShow
        ? [
            _c(
              "b-col",
              { attrs: { sm: "12", md: "4" } },
              [
                _c("input-text", {
                  attrs: {
                    id: "dependientes-nombre-id",
                    label: _vm.$t("dependientes.nombre.label"),
                    readonly: false,
                    required: true,
                    valid: _vm.$v.dependientesNuevo.nombre.$dirty
                      ? !_vm.$v.dependientesNuevo.nombre.$error
                      : null,
                    validationsCommons: {
                      requiredValue: !_vm.$v.dependientesNuevo.nombre.required,
                      requiredMessage: _vm.$t(
                        "dependientes.nombre.validations.required"
                      ),
                      minValue: !_vm.$v.dependientesNuevo.nombre.minLength,
                      minMessage: _vm.$t(
                        "dependientes.nombre.validations.minMessage",
                        { min: "2" }
                      ),
                      maxValue: !_vm.$v.dependientesNuevo.nombre.maxLength,
                      maxMessage: _vm.$t(
                        "dependientes.nombre.validations.maxMessage",
                        { max: "50" }
                      ),
                    },
                    maxCaracteres: 50,
                  },
                  model: {
                    value: _vm.$v.dependientesNuevo.nombre.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.dependientesNuevo.nombre, "$model", $$v)
                    },
                    expression: "$v.dependientesNuevo.nombre.$model",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-col",
              { attrs: { sm: "12", md: "4" } },
              [
                _c("input-text", {
                  attrs: {
                    id: "dependientes.apellido-paterno-id",
                    label: _vm.$t("dependientes.apellido-paterno.label"),
                    readonly: false,
                    required: true,
                    valid: _vm.$v.dependientesNuevo.primerApellido.$dirty
                      ? !_vm.$v.dependientesNuevo.primerApellido.$error
                      : null,
                    validationsCommons: {
                      requiredValue:
                        !_vm.$v.dependientesNuevo.primerApellido.required,
                      requiredMessage: _vm.$t(
                        "dependientes.apellido-paterno.validations.required"
                      ),
                      minValue:
                        !_vm.$v.dependientesNuevo.primerApellido.minLength,
                      minMessage: _vm.$t(
                        "dependientes.apellido-paterno.validations.minMessage",
                        { min: "2" }
                      ),
                      maxValue:
                        !_vm.$v.dependientesNuevo.primerApellido.maxLength,
                      maxMessage: _vm.$t(
                        "dependientes.apellido-paterno.validations.maxMessage",
                        { max: "50" }
                      ),
                    },
                    maxCaracteres: 50,
                  },
                  model: {
                    value: _vm.$v.dependientesNuevo.primerApellido.$model,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.$v.dependientesNuevo.primerApellido,
                        "$model",
                        $$v
                      )
                    },
                    expression: "$v.dependientesNuevo.primerApellido.$model",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-col",
              { attrs: { sm: "12", md: "4" } },
              [
                _c("input-text", {
                  attrs: {
                    id: "dependientes.apellido-materno-id",
                    label: _vm.$t("dependientes.apellido-materno.label"),
                    readonly: false,
                    valid: _vm.$v.dependientesNuevo.segundoApellido.$dirty
                      ? !_vm.$v.dependientesNuevo.segundoApellido.$error
                      : null,
                    validationsCommons: {
                      requiredValue:
                        !_vm.$v.dependientesNuevo.segundoApellido.required,
                      requiredMessage: _vm.$t(
                        "dependientes.apellido-materno.validations.required"
                      ),
                      minValue:
                        !_vm.$v.dependientesNuevo.segundoApellido.minLength,
                      minMessage: _vm.$t(
                        "dependientes.apellido-materno.validations.minMessage",
                        { min: "2" }
                      ),
                      maxValue:
                        !_vm.$v.dependientesNuevo.segundoApellido.maxLength,
                      maxMessage: _vm.$t(
                        "dependientes.apellido-materno.validations.maxMessage",
                        { max: "50" }
                      ),
                    },
                    maxCaracteres: 50,
                  },
                  model: {
                    value: _vm.$v.dependientesNuevo.segundoApellido.$model,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.$v.dependientesNuevo.segundoApellido,
                        "$model",
                        $$v
                      )
                    },
                    expression: "$v.dependientesNuevo.segundoApellido.$model",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-col",
              { attrs: { sm: "4" } },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { sm: "12", md: "8" } },
                      [
                        _c("input-date-time", {
                          attrs: {
                            id: "dependientes-nacimiento-id",
                            label: _vm.$t("dependientes.nacimiento.label"),
                            placeholder: _vm.$t(
                              "dependientes.nacimiento.placeholder"
                            ),
                            description: _vm.$t(
                              "dependientes.nacimiento.description"
                            ),
                            readonly: false,
                            required: true,
                            limitDate: true,
                            valid: _vm.$v.dependientesNuevo.fechaNacimiento
                              .$dirty
                              ? !_vm.$v.dependientesNuevo.fechaNacimiento.$error
                              : null,
                            validationsCommons: {
                              requiredValue:
                                !_vm.$v.dependientesNuevo.fechaNacimiento
                                  .required,
                              requiredMessage: _vm.$t(
                                "dependientes.nacimiento.validations.required"
                              ),
                            },
                          },
                          on: { change: _vm.changeEdad },
                          model: {
                            value:
                              _vm.$v.dependientesNuevo.fechaNacimiento.$model,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.$v.dependientesNuevo.fechaNacimiento,
                                "$model",
                                $$v
                              )
                            },
                            expression:
                              "$v.dependientesNuevo.fechaNacimiento.$model",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { attrs: { sm: "12", md: "4" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: { label: _vm.$t("dependientes.edad.label") },
                          },
                          [
                            _c(
                              "b-badge",
                              {
                                staticClass: "padding-badge",
                                attrs: {
                                  pill: "",
                                  variant:
                                    _vm.edad >= 18 ? "success" : "warning",
                                },
                              },
                              [_vm._v(_vm._s(_vm.edad) + " ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-col",
              { attrs: { sm: "12", md: "4" } },
              [
                _c("input-select-one", {
                  attrs: {
                    id: "dependientes-parentesco-id",
                    label: _vm.$t("dependientes.parentesco.label"),
                    readonly: false,
                    required: true,
                    options: _vm.parentestcoOption,
                    valid: _vm.$v.dependientesNuevo.parentesco.$dirty
                      ? !_vm.$v.dependientesNuevo.parentesco.$error
                      : null,
                    validationsCommons: {
                      requiredValue:
                        !_vm.$v.dependientesNuevo.parentesco.required,
                      requiredMessage: _vm.$t(
                        "dependientes.parentesco.validations.required"
                      ),
                      regexMessage: _vm.$t(
                        "dependientes.parentesco.validations.regexMessage"
                      ),
                    },
                  },
                  on: { change: _vm.sexoParentescoChange },
                  model: {
                    value: _vm.$v.dependientesNuevo.parentesco.$model,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.$v.dependientesNuevo.parentesco,
                        "$model",
                        $$v
                      )
                    },
                    expression: "$v.dependientesNuevo.parentesco.$model",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-col",
              { attrs: { md: "4" } },
              [
                _c("input-select-radio", {
                  attrs: {
                    id: "dependientes-sexo-id",
                    label: _vm.$t("dependientes.sexo.label"),
                    options: _vm.sexoOptions,
                    readonly: _vm.sexoDisabled,
                    required: false,
                    valid: _vm.$v.dependientesNuevo.sexo.$dirty
                      ? !_vm.$v.dependientesNuevo.sexo.$error
                      : null,
                  },
                  model: {
                    value: _vm.$v.dependientesNuevo.sexo.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.dependientesNuevo.sexo, "$model", $$v)
                    },
                    expression: "$v.dependientesNuevo.sexo.$model",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("b-col", { attrs: { col: "", cols: "12" } }, [
              _c(
                "div",
                { staticClass: "form-group float-right" },
                [
                  _c("span", { staticClass: "label-hidden" }),
                  _vm._v(" "),
                  _vm.editingIndex !== null
                    ? _c(
                        "b-button",
                        {
                          attrs: { variant: "outline-primary" },
                          on: { click: _vm.resetForm },
                        },
                        [
                          _c("span", [_vm._v("Cancelar")]),
                          _vm._v(" "),
                          _c("span", { staticClass: "icon-cerrar" }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: { click: _vm.agregaDependiente },
                    },
                    [
                      _vm.editingIndex === null
                        ? _c("span", [_vm._v("Agregar")])
                        : _c("span", [_vm._v("Editar")]),
                      _vm._v(" "),
                      _c("b-icon", {
                        attrs: { icon: "plus", "aria-hidden": "true" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }