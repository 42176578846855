import Component, { mixins } from 'vue-class-component';
import { Emit, Prop, PropSync, Ref, VModel } from 'vue-property-decorator';
import JhiDataUtils from '@/shared/data/data-utils.service';
import { File } from '../commons/file.model';
import type AlertaComponent from '@/components/alerta/alerta.component';

@Component({
  inheritAttrs: false,
})
export default class InputImageComponent extends mixins(JhiDataUtils) {
  public filelist = [];
  public url = null;
  tipoFormatoInvalido = false;
  pesoInvalido = false;
  nombreInvalido = false;
  file = {
    contentType: null,
    content: null,
    nombre: null,
    size: null,
    uri: null,
  };
  content = null;

  public regexp;
  public regexpMaxCaracter;

  @Prop({ required: true })
  id: string;

  @Prop({ default: '' })
  label: string;

  @Prop({ default: '' })
  tiposMime: string;

  @Prop({ default: true })
  valid: boolean;

  @VModel({ required: true })
  documento: File;

  @Prop({ default: '' })
  description: string;

  @PropSync('estadoInvalido', { type: Boolean })
  estado;

  /**
   * Peso máximo en KB
   */
  @Prop({ required: true, type: Number })
  pesoMaximo: number;

  @Prop({ default: 'imagen' })
  type: string;

  @Ref() readonly inputFile!: HTMLInputElement;

  @Ref()
  alerta!: AlertaComponent;

  mounted() {
    if (this.documento) {
      this.url = `${this.documento.uri}/preview`;
      this.filelist.push({ name: this.documento.nombre });
    }
  }

  @Emit()
  async onChange() {
    if (this.validaTipoFormato() || this.validaPeso() || this.validaNombre()) {
      this.estado = true;
    }

    if (this.tipoFormatoInvalido) {
      this.alerta.mostrar(this.$t('image.alert.type'), 'danger', 20);
      return;
    }

    if (this.pesoInvalido) {
      this.alerta.mostrar(this.$t('image.alert.size', { size: this.pesoMaximo / 1024 }), 'danger', 20);
      return;
    }

    if (this.nombreInvalido) {
      this.alerta.mostrar(this.$t('image.alert.name-image'), 'danger', 20);
      return;
    }

    return new Promise(resolve => {
      const file = this.inputFile.files[0];
      this.filelist.push(file);
      if (this.filelist.length > 1) {
        this.filelist.splice(0, 1);
      }
      this.toBase64(file, base64Data => {
        this.content = base64Data;
        this.file.contentType = this.filelist[0].type;
        this.file.content = this.content;
        this.file.nombre = this.filelist[0].name;
        this.file.size = this.filelist[0].size;
        this.file.uri = this.documento ? this.documento.uri : null;
        this.documento = this.file;
        this.url = URL.createObjectURL(this.filelist[0]); // Se crea objeto para visualizar la imagen
        this.setModificable(this.file);
      });
      this.inputFile.value = '';
      this.alerta.mostrar(this.$t('image.alert.success'), 'primary', 20);
      resolve(true);
    });
  }

  remove() {
    this.filelist.splice(0, 1);
    this.resetInput();
    this.file.contentType = null;
    this.file.contentType = null;
    this.file.content = null;
    this.file.nombre = null;
    this.file.size = null;
    this.file.uri = null;
    this.setModificable(new File());
    this.alerta.mostrar(this.$t('image.alert.delete'), 'primary', 20);
  }

  resetInput() {
    this.estado = false;
    this.pesoInvalido = false;
    this.nombreInvalido = false;
  }

  dragover(event) {
    event.preventDefault();
  }

  dragleave(event) {
    // Clean up
    event.currentTarget.classList.add('bg-gray-100');
    event.currentTarget.classList.remove('bg-green-300');
  }

  drop(event) {
    if (this.filelist.length >= 1) {
      return;
    } else {
      event.preventDefault();
      this.inputFile.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    }
  }

  /**
   * Almacena en store la imagen a modificar
   */
  public setModificable(modificable) {
    this.$store.commit('setFotoModificable', {
      contentType: modificable.contentType,
      content: modificable.content,
      nombre: modificable.nombre,
      size: modificable.size,
      uri: this.documento ? this.documento.uri : null,
    });
  }

  @Emit()
  private validaTipoFormato() {
    const extensiones = this.tiposMime.split(',');
    for (const index in extensiones) {
      const extension = extensiones[index];
      if (this.inputFile.files[0].name.toUpperCase().endsWith(extension.toUpperCase().trim())) {
        this.tipoFormatoInvalido = false;
        return false;
      }
    }
    this.tipoFormatoInvalido = true;
    return true;
  }

  /**
   * Valida el peso del documento.
   */
  @Emit()
  private validaPeso() {
    if (this.inputFile.files[0].size / 1024 <= this.pesoMaximo) {
      this.pesoInvalido = false;
      return false;
    }
    this.pesoInvalido = true;
    return true;
  }

  /**
   * Valida el nombre del documento.
   */
  @Emit()
  private validaNombre() {
    // eslint-disable-next-line no-misleading-character-class
    this.regexpMaxCaracter = /^.{1,50}$/;

    //formato imagen
    this.regexp =
      /^([a-z0-9]|á|á|é|é|í|í|ó|ó|ú|ú|Á|Á|É|É|Í|Í|Ó|Ó|Ú|Ú|ñ|ñ|Ñ|Ñ|ä|ä|ë|ë|ï|ï|ö|ö|ü|ü|Ä|Ä|Ë|Ë|Ï|Ï|Ö|Ö|Ü|Ü|à|à|è|è|ì|ì|ò|ò|ù|ù|À|À|È|È|Ì|Ì|Ò|Ò|Ù|Ù|!|@|#|\$|%|\^|&|\*|\)|\(|\+|=|\[|\]|\}|\||\{|~|<|>|\.|_|-| )+\.(jpg|jpeg|png)$/im;

    if (this.regexp.test(this.inputFile.files[0].name) && this.regexpMaxCaracter.test(this.inputFile.files[0].name)) {
      this.nombreInvalido = false;
      return false;
    }
    this.nombreInvalido = true;
    return true;
  }

  public cambiar() {
    document.getElementById('file_' + this.id).click();
  }
}
