export interface IFile {
  contentType?: string;
  content?: any;
  nombre?: string;
  size?: number;
  uri?: string;
}

export class File implements IFile {
  constructor(public contentType?: string, public content?: any, public nombre?: string, public size?: number, public uri?: string) {
    this.contentType = this.contentType ? this.contentType : '';
    this.content = this.content ? this.content : '';
    this.nombre = this.nombre ? this.nombre : '';
    this.size = this.size ? this.size : null;
    this.uri = this.uri ? this.uri : null;
  }
}
