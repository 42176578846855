import {
  Configuration,
  ArticulosApi,
  LibrosApi,
  CapitulosApi,
  DesarrollosTecnologicosInnovacionesApi,
  PropiedadesIntelectualesApi,
  TransferenciasTecnologicasApi,
  TotalDocumentosApi,
  CitasApi,
} from '@/shared/aportaciones';
const aportacionesConfiguration = new Configuration({ basePath: '/services/msaportaciones/api' });

const articulosApi = new ArticulosApi(aportacionesConfiguration);
const librosApi = new LibrosApi(aportacionesConfiguration);
const capitulosApi = new CapitulosApi(aportacionesConfiguration);
const desarrollosTecnologicosInnovacionesApi = new DesarrollosTecnologicosInnovacionesApi(aportacionesConfiguration);
const propiedadesIntelectualesApi = new PropiedadesIntelectualesApi(aportacionesConfiguration);
const transferenciasTecnologicasApi = new TransferenciasTecnologicasApi(aportacionesConfiguration);
const totalDocumentosApi = new TotalDocumentosApi(aportacionesConfiguration);
const aportacionesApiApor = new TotalDocumentosApi(aportacionesConfiguration);
const citasApi = new CitasApi(aportacionesConfiguration);

export {
  articulosApi,
  librosApi,
  capitulosApi,
  desarrollosTecnologicosInnovacionesApi,
  propiedadesIntelectualesApi,
  transferenciasTecnologicasApi,
  totalDocumentosApi,
  aportacionesApiApor,
  citasApi,
};
