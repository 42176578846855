var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "mt-4" }, [_vm._v("Actividad en la red")]),
      _vm._v(" "),
      _c(
        "b-skeleton-wrapper",
        {
          attrs: { loading: _vm.loading },
          scopedSlots: _vm._u([
            {
              key: "loading",
              fn: function () {
                return [_c("skeleton", { attrs: { type: "social" } })]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _vm._l(_vm.items, function (item) {
            return _c(
              "b-card",
              { key: item.id, staticClass: "rizoma-social mt-5" },
              [
                _c(
                  "b-row",
                  { attrs: { "align-v": "center" } },
                  [
                    _c(
                      "b-col",
                      [
                        _c("b-avatar", {
                          attrs: {
                            variant: "info",
                            src: item.account.avatar,
                            alt: "avatar",
                            size: "3.5rem",
                          },
                        }),
                        _vm._v(" "),
                        _c("h1", { staticClass: "d-inline" }, [
                          _c(
                            "a",
                            {
                              staticClass: "link-primary ml-3",
                              attrs: { href: item.account.url },
                            },
                            [
                              _c("strong", [
                                _vm._v(_vm._s(item.account.username)),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml-3 mr-3" }, [_vm._v("-")]),
                        _vm._v(" "),
                        _c("b-icon", {
                          staticClass: "helper-text",
                          attrs: { icon: "clock-history" },
                        }),
                        _vm._v(" "),
                        _c("em", { staticClass: "helper-text" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("timeElapsed")(item.account.created_at)
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "border-custom" }),
                _vm._v(" "),
                _c(
                  "b-row",
                  { attrs: { "align-v": "center" } },
                  [
                    _c("b-col", [
                      _c(
                        "a",
                        {
                          staticClass: "link-unstyled",
                          attrs: { href: item.url },
                        },
                        [
                          _c("div", {
                            domProps: { innerHTML: _vm._s(item.content) },
                          }),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-row",
                  _vm._l(item.media_attachments, function (attachment) {
                    return _c(
                      "b-col",
                      { key: attachment.id, staticClass: "p-3 col-md-3 px-0" },
                      [
                        _c("b-img", {
                          attrs: {
                            thumbnail: "",
                            fluid: "",
                            rounded: "",
                            src: attachment.preview_url,
                            alt: "Image 1",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }