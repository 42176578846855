import { Component, Inject, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import {
  Citas,
  Solicitud,
  SolicitudApi,
  TotalProductosApi,
  TotalesAccesoUniversal,
  TotalesCitas,
  TotalesFormacionComunidad,
  TotalesProduccionCientifica,
  TotalesProduccionTecnologica,
} from '@/shared/perfil-usuario/api';
import {
  ArticulosApi,
  CapitulosApi,
  LibrosApi,
  PropiedadesIntelectualesApi,
  TransferenciasTecnologicasApi,
  DesarrollosTecnologicosInnovacionesApi,
  TotalProductos,
  CitasApi,
  CitasTotales,
} from '@/shared/aportaciones/api';
import AlertService from '@/shared/alert/alert.service';
import EntitySolicitudFactory from '@/shared/entity-commons/entity-solicitud.factory';
import EntityFactory from '@/shared/entity-commons/entity.factory';
import { isEqual, cloneDeep } from 'lodash';

@Component({
  computed: {
    ...mapGetters(['indicadorSolicitud', 'estadoSolicitudEnviada']),
  },
})
export default class ResumenProductosComponent extends Vue {
  @Inject('articulosApi') private articulosApi: () => ArticulosApi;
  @Inject('capitulosApi') private capitulosApi: () => CapitulosApi;
  @Inject('librosApi') private librosApi: () => LibrosApi;
  @Inject('propiedadesIntelectualesApi') private propiedadesIntelectualesApi: () => PropiedadesIntelectualesApi;
  @Inject('transferenciasTecnologicasApi') private transferenciasTecnologicasApi: () => TransferenciasTecnologicasApi;
  @Inject('desarrollosTecnologicosInnovacionesApi')
  private desarrollosTecnologicosInnovacionesApi: () => DesarrollosTecnologicosInnovacionesApi;
  @Inject('totalProductosApi') private totalProductosApi: () => TotalProductosApi;
  @Inject('citasApi') private citasApi: () => CitasApi;
  @Inject('alertService') public alertService: () => AlertService;
  @Inject('solicitudApi') public solicitudApi: () => SolicitudApi;

  public indicadorSolicitudBandera = this.$store.getters.indicadorSolicitud;
  public estadoSolicitudEnviada;

  public totalesProduccionCientifica: TotalesProduccionCientifica = EntitySolicitudFactory.getTotalesProduccionCientifica();
  public articulosCientifica: TotalProductos = EntitySolicitudFactory.getDefaultTotales();
  public capitulosCientifica: TotalProductos = EntitySolicitudFactory.getDefaultTotales();
  public librosCientifica: TotalProductos = EntitySolicitudFactory.getDefaultTotales();

  public totalesProduccionTecnologica: TotalesProduccionTecnologica = EntitySolicitudFactory.getTotalesProduccionTecnologica();
  public desarrolloTecnologicoInnovacion: TotalProductos = EntitySolicitudFactory.getDefaultTotales();
  public propiedadIntelectual: TotalProductos = EntitySolicitudFactory.getDefaultTotales();
  public transferenciaTecnologica: TotalProductos = EntitySolicitudFactory.getDefaultTotales();

  public totalesAccesoUniversal: TotalesAccesoUniversal = EntitySolicitudFactory.getTotalesAccesoUniversal();
  public articulosDifusion: TotalProductos = EntitySolicitudFactory.getDefaultTotales();
  public librosDifusion: TotalProductos = EntitySolicitudFactory.getDefaultTotales();
  public capitulosDifusion: TotalProductos = EntitySolicitudFactory.getDefaultTotales();
  public divulgacion: TotalProductos = EntitySolicitudFactory.getDefaultTotales();
  public participacionCongresos: TotalProductos = EntitySolicitudFactory.getDefaultTotales();

  public totalesFormacionComunidad: TotalesFormacionComunidad = EntitySolicitudFactory.getTotalesFormacionComunidad();
  public trabajosTitulacion: TotalProductos = EntitySolicitudFactory.getDefaultTotales();
  public docencia: TotalProductos = EntitySolicitudFactory.getDefaultTotales();
  public desarrolloInstitucional: TotalProductos = EntitySolicitudFactory.getDefaultTotales();

  public citas: Citas = EntitySolicitudFactory.getCitasT();
  public totalesCitas: Array<CitasTotales> = EntitySolicitudFactory.getDefaultTotalesCitas();

  public solicitud;

  public INGRESO = EntitySolicitudFactory.tipoSolucion()[4].value;
  public EMERITO = EntitySolicitudFactory.tipoSolucion()[3].value;

  public ENVIADA = EntitySolicitudFactory.estadoSolicitudEnum().enviada;
  public CANCELADA = EntitySolicitudFactory.estadoSolicitudEnum().cancelada;

  //tablas
  public itemsProduccion = [];
  public itemsTecnologica = [];
  public itemsFormacion = [];
  public itemsAcceso = [];

  public errorConsultaTotales = false;

  mounted() {
    if (this.$store.getters.solicitud) {
      this.initTotales();
    }

    this.setItemsProduccion();
    this.setItemsTecnologica();
    this.setItemsFormacion();
    this.setItemsAcceso();
  }

  public async initTotales() {
    if (!this.estadoSolicitudEnviada) {
      const promises = [
        this.articulosApi().getTotalesArticulos(EntityFactory.Tipos()[0].value),
        this.articulosApi().getTotalesArticulos(EntityFactory.Tipos()[1].value),
        this.capitulosApi().getTotalesCapitulos(EntityFactory.Tipos()[0].value),
        this.capitulosApi().getTotalesCapitulos(EntityFactory.Tipos()[1].value),
        this.librosApi().getTotalesibros(EntityFactory.Tipos()[0].value),
        this.librosApi().getTotalesibros(EntityFactory.Tipos()[1].value),
        this.propiedadesIntelectualesApi().getTotalesPropiedadesIntelectuales(),
        this.transferenciasTecnologicasApi().getTotalesTransferenciasTecnologicas(),
        this.desarrollosTecnologicosInnovacionesApi().getTotalesDesarrollosTecnologicosInnovaciones(),
        this.totalProductosApi().getTotalProductos(),
        this.citasApi().getAllCitas(),
      ];

      const results = await Promise.allSettled(promises);

      results.forEach((result, index) => {
        if (result.status === 'fulfilled') {
          const res = result.value;
          // Si res.data es un array, comprueba su longitud. Si es un objeto, comprueba si tiene propiedades.
          if ((Array.isArray(res.data) && res.data.length) || (typeof res.data === 'object' && Object.keys(res.data).length)) {
            switch (index) {
              case 0:
                if ('totalProducto' in res.data && res.data.totalProducto) {
                  this.articulosCientifica = {
                    totalProducto: res.data.totalProducto,
                    totalProductoPrincipal: res.data.totalProductoPrincipal ? res.data.totalProductoPrincipal : 0,
                  };
                }
                break;
              case 1:
                if ('totalProducto' in res.data && res.data.totalProducto) {
                  this.articulosDifusion = {
                    totalProducto: res.data.totalProducto,
                    totalProductoPrincipal: res.data.totalProductoPrincipal ? res.data.totalProductoPrincipal : 0,
                  };
                }
                break;
              case 2:
                if ('totalProducto' in res.data && res.data.totalProducto) {
                  this.capitulosCientifica = {
                    totalProducto: res.data.totalProducto,
                    totalProductoPrincipal: res.data.totalProductoPrincipal ? res.data.totalProductoPrincipal : 0,
                  };
                }
                break;
              case 3:
                if ('totalProducto' in res.data && res.data.totalProducto) {
                  this.capitulosDifusion = {
                    totalProducto: res.data.totalProducto,
                    totalProductoPrincipal: res.data.totalProductoPrincipal ? res.data.totalProductoPrincipal : 0,
                  };
                }
                break;
              case 4:
                if ('totalProducto' in res.data && res.data.totalProducto) {
                  this.librosCientifica = {
                    totalProducto: res.data.totalProducto,
                    totalProductoPrincipal: res.data.totalProductoPrincipal ? res.data.totalProductoPrincipal : 0,
                  };
                }
                break;
              case 5:
                if ('totalProducto' in res.data && res.data.totalProducto) {
                  this.librosDifusion = {
                    totalProducto: res.data.totalProducto,
                    totalProductoPrincipal: res.data.totalProductoPrincipal ? res.data.totalProductoPrincipal : 0,
                  };
                }
                break;
              case 6:
                if ('totalProducto' in res.data && res.data.totalProducto) {
                  this.propiedadIntelectual = {
                    totalProducto: res.data.totalProducto,
                    totalProductoPrincipal: res.data.totalProductoPrincipal ? res.data.totalProductoPrincipal : 0,
                  };
                }
                break;
              case 7:
                if ('totalProducto' in res.data && res.data.totalProducto) {
                  this.transferenciaTecnologica = {
                    totalProducto: res.data.totalProducto,
                    totalProductoPrincipal: res.data.totalProductoPrincipal ? res.data.totalProductoPrincipal : 0,
                  };
                }
                break;
              case 8:
                if ('totalProducto' in res.data && res.data.totalProducto) {
                  this.desarrolloTecnologicoInnovacion = {
                    totalProducto: res.data.totalProducto,
                    totalProductoPrincipal: res.data.totalProductoPrincipal ? res.data.totalProductoPrincipal : 0,
                  };
                }
                break;
              case 9:
                if (res.data) {
                  if ('totalDivulgacion' in res.data && res.data.totalDivulgacion) {
                    this.divulgacion = {
                      totalProducto: res.data.totalDivulgacion,
                      totalProductoPrincipal: res.data.totalDivulgacionPrincipal ? res.data.totalDivulgacionPrincipal : 0,
                    };
                  }
                  if ('totalCongresos' in res.data && res.data.totalCongresos) {
                    this.participacionCongresos = {
                      totalProducto: res.data.totalCongresos,
                      totalProductoPrincipal: res.data.totalCongresosPrincipal ? res.data.totalCongresosPrincipal : 0,
                    };
                  }

                  // Se resetea a cero pues esta variable se arma en front y se quedaba con información previa llamando al servicio nuevamente sumando más.
                  this.docencia.totalProducto = 0;
                  this.docencia.totalProductoPrincipal = 0;
                  if ('totalDiplomados' in res.data && res.data.totalDiplomados) {
                    this.docencia = {
                      totalProducto: this.docencia.totalProducto + res.data.totalDiplomados,
                      totalProductoPrincipal: res.data.totalDiplomadosPrincipal
                        ? this.docencia.totalProductoPrincipal + res.data.totalDiplomadosPrincipal
                        : this.docencia.totalProductoPrincipal,
                    };
                  }

                  if ('totalDocencia' in res.data && res.data.totalDocencia) {
                    this.docencia = {
                      totalProducto: this.docencia.totalProducto + res.data.totalDocencia,
                      totalProductoPrincipal: res.data.totalDocenciaPrincipal
                        ? this.docencia.totalProductoPrincipal + res.data.totalDocenciaPrincipal
                        : this.docencia.totalProductoPrincipal,
                    };
                  }

                  if ('totalTesis' in res.data && res.data.totalTesis) {
                    this.trabajosTitulacion = {
                      totalProducto: res.data.totalTesis,
                      totalProductoPrincipal: res.data.totalTesisPrincipal ? res.data.totalTesisPrincipal : 0,
                    };
                  }

                  if ('totalDesarrolloInstitucional' in res.data && res.data.totalDesarrolloInstitucional) {
                    this.desarrolloInstitucional.totalProducto = res.data.totalDesarrolloInstitucional;
                  }
                }
                break;
              case 10:
                if (Array.isArray(res.data) && res.data.length != 0) this.totalesCitas = res.data;
                break;
            }
          }
        } else {
          const err = result.reason;
          if (err?.response?.data?.detail) {
            this.alertService().showError(this, err.response.data.detail);
          } else {
            this.alertService().showError(this, 'Promise at index ' + index + ' failed with ' + result.reason);
          }

          this.errorConsultaTotales = true;
        }
      });
    } else {
      this.setTotales();
    }

    //Seteo Para tablas
    this.setItemsProduccion();
    this.setItemsTecnologica();
    this.setItemsFormacion();
    this.setItemsAcceso();
    this.setItemsResumenCitas();

    //Seteo para Objetos de solicitud
    this.setTotalesProduccionCientifica();
    this.setTotalesProduccionTecnologica();
    this.setTotalesAccesoUniversal();
    this.setFotalesFormacionComunidad();
    this.setTotalesCitas();
    if (!this.estadoSolicitudEnviada) {
      this.setTotalSolicitud();
    } else {
      this.$store.commit('setsolicitudTotalesBandera', false);
    }
  }

  public closeModal() {
    this.$bvModal.hide('complementoSolicitudModal');
  }

  fieldsResumenCitas = [
    { key: 'name', label: this.$t('resumen.tablas.citas.tipo') },
    { key: 'cantidad', label: this.$t('resumen.tablas.citas.cantidad'), class: 'text-center' },
    { key: 'a', label: this.$t('resumen.tablas.citas.A'), class: 'text-center' },
    { key: 'b', label: this.$t('resumen.tablas.citas.B'), class: 'text-center' },
    { key: 'ab', label: this.$t('resumen.tablas.citas.AB'), class: 'text-center' },
  ];

  itemsResumenCitas = [
    {
      name: this.$t('resumen.tablas.produccion.articulos'),
      cantidad: this.totalesCitas[0].totalProductos,
      a: this.totalesCitas[0].totalCitasA,
      b: this.totalesCitas[0].totalCitasB,
      ab: this.totalesCitas[0].totalCitas,
    },
    {
      name: this.$t('resumen.tablas.produccion.libros'),
      cantidad: this.totalesCitas[1].totalProductos,
      a: this.totalesCitas[1].totalCitasA,
      b: this.totalesCitas[1].totalCitasB,
      ab: this.totalesCitas[1].totalCitas,
    },
    {
      name: this.$t('resumen.tablas.produccion.capitulos'),
      cantidad: this.totalesCitas[2].totalProductos,
      a: this.totalesCitas[2].totalCitasA,
      b: this.totalesCitas[2].totalCitasB,
      ab: this.totalesCitas[2].totalCitas,
    },
  ];

  private setItemsResumenCitas() {
    //Solo vista
    const articulos = 0;
    const libros = 1;
    const capitulos = 2;

    if (this.totalesCitas.length > 0) {
      for (const total of this.totalesCitas) {
        const citaX = {
          name: total.categoria,
          cantidad: total.totalProductos,
          a: total.totalCitasA,
          b: total.totalCitasB,
          ab: total.totalCitas,
        };

        if (total.categoria == 'Articulo') {
          citaX.name = this.$t('resumen.tablas.produccion.articulos').toString();
          this.$set(this.itemsResumenCitas, articulos, citaX);
        }

        if (total.categoria == 'Libro') {
          citaX.name = this.$t('resumen.tablas.produccion.libros').toString();
          this.$set(this.itemsResumenCitas, libros, citaX);
        }

        if (total.categoria == 'Capitulo') {
          citaX.name = this.$t('resumen.tablas.produccion.capitulos').toString();
          this.$set(this.itemsResumenCitas, capitulos, citaX);
        }
      }
    }
  }

  fieldsProduccion = [
    { key: 'name', label: this.$t('resumen.tablas.producto') },
    { key: 'productos', label: '#', class: 'text-center' },
    { key: 'destacados', label: '', class: 'text-center' },
  ];

  //Producción científica y humanista
  private setItemsProduccion() {
    this.itemsProduccion = [
      {
        name: this.$t('resumen.tablas.produccion.articulos'),
        productos: this.articulosCientifica.totalProducto,
        destacados: this.articulosCientifica.totalProductoPrincipal,
        nameDestacados: 'CIENTIFICA-Articulos',
      },
      {
        name: this.$t('resumen.tablas.produccion.libros'),
        productos: this.librosCientifica.totalProducto,
        destacados: this.librosCientifica.totalProductoPrincipal,
        nameDestacados: 'CIENTIFICA-Libros',
      },
      {
        name: this.$t('resumen.tablas.produccion.capitulos'),
        productos: this.capitulosCientifica.totalProducto,
        destacados: this.capitulosCientifica.totalProductoPrincipal,
        nameDestacados: 'CIENTIFICA-Capitulos',
      },
    ];
  }

  fieldsTecnologica = [
    { key: 'name', label: this.$t('resumen.tablas.producto') },
    { key: 'productos', label: '#', class: 'text-center' },
    { key: 'destacados', label: '', class: 'text-center' },
  ];

  private setItemsTecnologica() {
    if (!this.propiedadIntelectual.totalProductoPrincipal) this.propiedadIntelectual.totalProductoPrincipal = 0;
    this.itemsTecnologica = [
      {
        name: this.$t('resumen.tablas.tecnologica.desarrollo-tec'),
        productos: this.desarrolloTecnologicoInnovacion.totalProducto,
        destacados: this.desarrolloTecnologicoInnovacion.totalProductoPrincipal,
        nameDestacados: 'DesarrollosTecnologicosInnovaciones',
      },
      {
        name: this.$t('resumen.tablas.tecnologica.propiedad'),
        productos: this.propiedadIntelectual.totalProducto,
        destacados: this.propiedadIntelectual.totalProductoPrincipal,
        nameDestacados: 'PropiedadesIntelectuales',
      },
      {
        name: this.$t('resumen.tablas.tecnologica.transferencia-tec'),
        productos: this.transferenciaTecnologica.totalProducto,
        destacados: this.transferenciaTecnologica.totalProductoPrincipal,
        nameDestacados: 'TransferenciasTecnologicas',
      },
    ];
  }

  fieldsFormacion = [
    { key: 'name', label: this.$t('resumen.tablas.producto') },
    { key: 'productos', label: '#', class: 'text-center' },
    { key: 'destacados', label: '', class: 'text-center' },
  ];

  private setItemsFormacion() {
    this.itemsFormacion = [
      {
        name: this.$t('resumen.tablas.formacion.desarrollo'),
        productos: this.desarrolloInstitucional.totalProducto,
        destacados: this.desarrolloInstitucional.totalProductoPrincipal,
        nameDestacados: 'DesarrolloInstitucional',
      },
      {
        name: this.$t('resumen.tablas.formacion.docencia'),
        productos: this.docencia.totalProducto,
        destacados: this.docencia.totalProductoPrincipal,
        nameDestacados: 'CursosImpartidos',
      },
      {
        name: this.$t('resumen.tablas.formacion.titulacion'),
        productos: this.trabajosTitulacion.totalProducto,
        destacados: this.trabajosTitulacion.totalProductoPrincipal,
        nameDestacados: 'Tesis',
      },
    ];
  }

  fieldsAcceso = [
    { key: 'name', label: this.$t('resumen.tablas.producto') },
    { key: 'productos', label: '#', class: 'text-center' },
    { key: 'destacados', label: '', class: 'text-center' },
  ];

  private setItemsAcceso() {
    this.itemsAcceso = [
      {
        name: this.$t('resumen.tablas.acceso.articulos'),
        productos: this.articulosDifusion.totalProducto,
        destacados: this.articulosDifusion.totalProductoPrincipal,
        nameDestacados: 'DIFUSION-Articulos',
      },
      {
        name: this.$t('resumen.tablas.acceso.libros'),
        productos: this.librosDifusion.totalProducto,
        destacados: this.librosDifusion.totalProductoPrincipal,
        nameDestacados: 'DIFUSION-Libros',
      },
      {
        name: this.$t('resumen.tablas.acceso.capitulos'),
        productos: this.capitulosDifusion.totalProducto,
        destacados: this.capitulosDifusion.totalProductoPrincipal,
        nameDestacados: 'DIFUSION-Capitulos',
      },
      {
        name: this.$t('resumen.tablas.acceso.divulgacion'),
        productos: this.divulgacion.totalProducto,
        destacados: this.divulgacion.totalProductoPrincipal,
        nameDestacados: 'Divulgacion',
      },
      {
        name: this.$t('resumen.tablas.acceso.participacion'),
        productos: this.participacionCongresos.totalProducto,
        destacados: this.participacionCongresos.totalProductoPrincipal,
        nameDestacados: 'Congresos',
      },
    ];
  }

  fieldsLogros = [
    { key: 'name', label: 'Producto' },
    { key: 'productos', label: '#', class: 'text-center' },
    { key: 'destacados', label: '', class: 'text-center' },
  ];

  itemsLogros = [{ name: 'Logros', productos: 2, destacados: '' }];

  public setTotalesProduccionCientifica() {
    this.totalesProduccionCientifica = {
      articulosCientifica: this.articulosCientifica,
      librosCientifica: this.librosCientifica,
      capitulosCientifica: this.capitulosCientifica,
      total: {
        totalProducto:
          this.articulosCientifica.totalProducto + this.librosCientifica.totalProducto + this.capitulosCientifica.totalProducto,
        totalProductoPrincipal:
          this.articulosCientifica.totalProductoPrincipal +
          this.librosCientifica.totalProductoPrincipal +
          this.capitulosCientifica.totalProductoPrincipal,
      },
    };
  }

  public setTotalesProduccionTecnologica() {
    this.totalesProduccionTecnologica = {
      desarrolloTecnologicoInnovacion: this.desarrolloTecnologicoInnovacion,
      propiedadIntelectual: this.propiedadIntelectual,
      transferenciaTecnologica: this.transferenciaTecnologica,
      total: {
        totalProducto:
          this.desarrolloTecnologicoInnovacion.totalProducto +
          this.propiedadIntelectual.totalProducto +
          this.transferenciaTecnologica.totalProducto,
        totalProductoPrincipal:
          this.desarrolloTecnologicoInnovacion.totalProductoPrincipal +
          this.propiedadIntelectual.totalProductoPrincipal +
          this.transferenciaTecnologica.totalProductoPrincipal,
      },
    };
  }

  public setTotalesAccesoUniversal() {
    this.totalesAccesoUniversal = {
      articulosDifusion: this.articulosDifusion,
      librosDifusion: this.librosDifusion,
      capitulosDifusion: this.capitulosDifusion,
      divulgacion: this.divulgacion,
      participacionCongresos: this.participacionCongresos,
      total: {
        totalProducto:
          this.articulosDifusion.totalProducto +
          this.librosDifusion.totalProducto +
          this.capitulosDifusion.totalProducto +
          this.divulgacion.totalProducto +
          this.participacionCongresos.totalProducto,
        totalProductoPrincipal:
          this.articulosDifusion.totalProductoPrincipal +
          this.librosDifusion.totalProductoPrincipal +
          this.capitulosDifusion.totalProductoPrincipal +
          this.divulgacion.totalProductoPrincipal +
          this.participacionCongresos.totalProductoPrincipal,
      },
    };
  }

  public setFotalesFormacionComunidad() {
    this.totalesFormacionComunidad = {
      trabajosTitulacion: this.trabajosTitulacion,
      docencia: this.docencia,
      desarrolloInstitucional: this.desarrolloInstitucional,
      total: {
        totalProducto: this.trabajosTitulacion.totalProducto + this.docencia.totalProducto + this.desarrolloInstitucional.totalProducto,
        totalProductoPrincipal: this.trabajosTitulacion.totalProductoPrincipal + this.docencia.totalProductoPrincipal,
      },
    };
  }

  public setTotalesCitas() {
    this.citas = {
      articulo: {
        cantidad: this.itemsResumenCitas[0].cantidad,
        citasA: this.itemsResumenCitas[0].a,
        citasB: this.itemsResumenCitas[0].b,
        totales: this.itemsResumenCitas[0].ab,
      },
      libro: {
        cantidad: this.itemsResumenCitas[1].cantidad,
        citasA: this.itemsResumenCitas[1].a,
        citasB: this.itemsResumenCitas[1].b,
        totales: this.itemsResumenCitas[1].ab,
      },
      capitulo: {
        cantidad: this.itemsResumenCitas[2].cantidad,
        citasA: this.itemsResumenCitas[2].a,
        citasB: this.itemsResumenCitas[2].b,
        totales: this.itemsResumenCitas[2].ab,
      },
      totalesCitas: {
        cantidad: this.itemsResumenCitas[0].cantidad + this.itemsResumenCitas[1].cantidad + this.itemsResumenCitas[2].cantidad,
        citasA: this.itemsResumenCitas[0].a + this.itemsResumenCitas[1].a + this.itemsResumenCitas[2].a,
        citasB: this.itemsResumenCitas[0].b + this.itemsResumenCitas[1].b + this.itemsResumenCitas[2].b,
        totales: this.itemsResumenCitas[0].ab + this.itemsResumenCitas[1].ab + this.itemsResumenCitas[2].ab,
      },
    };
  }

  public setTotalSolicitud() {
    const solicitudStore = this.$store.getters.solicitud;

    if (solicitudStore) {
      if (!this.errorConsultaTotales) {
        this.solicitud = cloneDeep(solicitudStore);

        this.solicitud.totalesProduccionCientifica = this.totalesProduccionCientifica;
        this.solicitud.totalesProduccionTecnologica = this.totalesProduccionTecnologica;
        this.solicitud.totalesAccesoUniversal = this.totalesAccesoUniversal;
        this.solicitud.totalesFormacionComunidad = this.totalesFormacionComunidad;
        this.solicitud.citas = this.citas;

        if (!this.areObjectsEqual(solicitudStore, this.solicitud)) {
          this.solicitudApi()
            .updateSolicitud(this.solicitud.id, this.solicitud)
            .then(res => {
              this.$store.commit('setSolicitud', this.solicitud);
              //Actualiza valor para cerrar spiner en app Component
              this.$store.commit('setsolicitudTotalesBandera', false);
            })
            .catch(err => {
              this.alertService().showError(this, err.response.data.detail);
              //Actualiza valor para cerrar spiner en app Component
              this.$store.commit('setsolicitudTotalesBandera', false);
            });
        } else {
          this.$store.commit('setsolicitudTotalesBandera', false);
        }
      } else {
        this.alertService().showError(this, this.$t('resumen.alert.error-totales').toString());
        this.$store.commit('setsolicitudTotalesBandera', false);
      }
    } else {
      //En caso de que se actualice la pagina y se pierda la solicitud se consultara al servicio
      this.getSolicitud();
    }
  }

  private areObjectsEqual(obj1: Solicitud, obj2: Solicitud) {
    return isEqual(obj1, obj2);
  }

  public getSolicitud(): void {
    this.solicitudApi()
      .getSolicitudes()
      .then(res => {
        if ((Array.isArray(res.data) && res.data.length) || (typeof res.data === 'object' && Object.keys(res.data).length)) {
          this.solicitud = res.data[0];
          this.$store.commit('setSolicitud', this.solicitud);
          //Actualizacion solicitud para el store
          delete this.solicitud.nivelAspira.clave;
          this.setTotalSolicitud();
        }
      })
      .catch(err => {
        this.alertService().showError(this, err.response.data.detail);
        this.$store.commit('setsolicitudTotalesBandera', false);
      });
  }

  public setTotales() {
    const solicitudStore = this.$store.getters.solicitud.filter(s => s.solucionSolicitud.tipoSolucion == 'INGRESO_PERMANENCIA_PROMOCION');
    this.articulosCientifica = solicitudStore[0].totalesProduccionCientifica.articulosCientifica;
    this.capitulosCientifica = solicitudStore[0].totalesProduccionCientifica.capitulosCientifica;
    this.librosCientifica = solicitudStore[0].totalesProduccionCientifica.librosCientifica;
    this.desarrolloTecnologicoInnovacion = solicitudStore[0].totalesProduccionTecnologica.desarrolloTecnologicoInnovacion;
    this.propiedadIntelectual = solicitudStore[0].totalesProduccionTecnologica.propiedadIntelectual;
    this.transferenciaTecnologica = solicitudStore[0].totalesProduccionTecnologica.transferenciaTecnologica;
    this.articulosDifusion = solicitudStore[0].totalesAccesoUniversal.articulosDifusion;
    this.librosDifusion = solicitudStore[0].totalesAccesoUniversal.librosDifusion;
    this.capitulosDifusion = solicitudStore[0].totalesAccesoUniversal.capitulosDifusion;
    this.divulgacion = solicitudStore[0].totalesAccesoUniversal.divulgacion;
    this.participacionCongresos = solicitudStore[0].totalesAccesoUniversal.participacionCongresos;
    this.trabajosTitulacion = solicitudStore[0].totalesFormacionComunidad.trabajosTitulacion;
    this.docencia = solicitudStore[0].totalesFormacionComunidad.docencia;
    this.desarrolloInstitucional = solicitudStore[0].totalesFormacionComunidad.desarrolloInstitucional;
    this.totalesCitas = this.getTotalesCitas(solicitudStore[0].citas);
  }

  public getTotalesCitas(citas: Citas): Array<CitasTotales> {
    return [
      this.getTotalCitas('Articulo', citas.articulo),
      this.getTotalCitas('Libro', citas.libro),
      this.getTotalCitas('Capitulo', citas.capitulo),
    ];
  }

  public getTotalCitas(categoria: string, citas: TotalesCitas): CitasTotales {
    return {
      categoria: categoria,
      totalCitasA: citas.citasA,
      totalCitasB: citas.citasB,
      totalCitas: citas.totales,
      totalProductos: citas.cantidad,
    };
  }
}
