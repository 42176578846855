var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("b-table", {
        attrs: {
          striped: false,
          borderless: true,
          "no-border-collapse": false,
          busy: _vm.busy,
          small: true,
          hover: false,
          fixed: false,
          "head-variant": "light",
          items: _vm.items,
          fields: _vm.columns,
        },
        scopedSlots: _vm._u([
          {
            key: "table-busy",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "text-center text-danger my-2" },
                  [
                    _c("b-spinner", { staticClass: "align-middle" }),
                    _vm._v(" "),
                    _c("strong", [_vm._v("Cargando...")]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "cell(logo)",
            fn: function (row) {
              return [
                _c("b-avatar", {
                  attrs: {
                    src:
                      "data:" +
                      row.item.imagen.contentType +
                      ";base64," +
                      row.item.imagen.content,
                    "badge-variant": "custom",
                    "badge-offset": "-0.5em",
                    size: "lg",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "badge",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm._f("formatAvatarText")(
                                    row.item.plataformaClave
                                  )
                                ) +
                                "\n        "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ]
            },
          },
          {
            key: "cell(acciones)",
            fn: function (row) {
              return [
                _c("c-action-buttons", {
                  attrs: {
                    params: { paramId: row.item[_vm.trackedBy] },
                    editView: _vm.editView,
                    detailsView: _vm.detailsView,
                  },
                  on: {
                    delete: function ($event) {
                      return _vm.prepareRemove(row.item)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("c-delete-modal", {
        attrs: { id: "removeEntity" },
        on: {
          onDelete: function ($event) {
            return _vm.remove()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }