import AccountService from '@/account/account.service';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
@Component({
  computed: {
    ...mapGetters(['account']),
  },
})
export default class DataUserSidebarComponent extends Vue {}
