import { Component, Vue } from 'vue-property-decorator';

@Component
export default class TrayectoriaMenu extends Vue {
  private activeCollapse: string | null = null;

  public toggleCollapse(panelId: string): void {
    if (this.activeCollapse === panelId) {
      this.activeCollapse = null;
    } else {
      this.activeCollapse = panelId;
    }
  }

  public isOpen(panelId: string): boolean {
    return this.activeCollapse === panelId;
  }
}
