import { render, staticRenderFns } from "./sidebar.vue?vue&type=template&id=98caad28&scoped=true&"
import script from "./sidebar.component.ts?vue&type=script&lang=ts&aria-label=Navegaci%C3%B3n%20lateral&"
export * from "./sidebar.component.ts?vue&type=script&lang=ts&aria-label=Navegaci%C3%B3n%20lateral&"
import style0 from "./sidebar.vue?vue&type=style&index=0&id=98caad28&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98caad28",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/1/gateway/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('98caad28')) {
      api.createRecord('98caad28', component.options)
    } else {
      api.reload('98caad28', component.options)
    }
    module.hot.accept("./sidebar.vue?vue&type=template&id=98caad28&scoped=true&", function () {
      api.rerender('98caad28', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/core/sidebar/sidebar.vue"
export default component.exports