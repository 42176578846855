var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-header-close": "",
        "header-bg-variant": "dark",
        "body-bg-variant": "light",
        "header-text-variant": "white",
        id: _vm.id,
      },
    },
    [
      _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
        _c("span", {
          attrs: { id: "crewApp.delete.title" },
          domProps: { textContent: _vm._s(_vm.$t("entity.save.title")) },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-body" }, [
        (_vm.totalPrincipal < _vm.totalReferenciaPrincipal &&
          _vm.productoPrincipal === true) ||
        _vm.productoPrincipal === false
          ? _c(
              "p",
              {
                attrs: { id: "jhi-delete-celula-heading" },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("entity.save.questionProductoPrincipal")
                  ),
                },
              },
              [
                _vm._v(
                  "\n      ¿Esta seguro de actualizar la propiedad de producto destacado?\n    "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.totalPrincipal === _vm.totalReferenciaPrincipal &&
        _vm.productoPrincipal === true
          ? _c(
              "p",
              {
                attrs: { id: "jhi-delete-celula-heading" },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("entity.save.validaProductoPrincipal", {
                      totalPrincipal: _vm.totalPrincipal,
                    })
                  ),
                },
              },
              [
                _vm._v(
                  "\n      Ha revasado el numero de productos destacados que es " +
                    _vm._s(_vm.totalPrincipal) +
                    "\n    "
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
        _c("button", {
          staticClass: "btn btn-outline-primary",
          attrs: { type: "button" },
          domProps: { textContent: _vm._s(_vm.$t("entity.action.cancel")) },
          on: {
            click: function ($event) {
              return _vm.closeModal()
            },
          },
        }),
        _vm._v(" "),
        (_vm.totalPrincipal < _vm.totalReferenciaPrincipal &&
          _vm.productoPrincipal === true) ||
        _vm.productoPrincipal === false
          ? _c("button", {
              staticClass: "btn btn-primary",
              attrs: { type: "button", id: "jhi-confirm-delete-celula" },
              domProps: { textContent: _vm._s(_vm.$t("entity.action.save")) },
              on: {
                click: function ($event) {
                  return _vm.actualizaPrincipal()
                },
              },
            })
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }