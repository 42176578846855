var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("hr", { staticClass: "divider" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "user-info_ text" },
      [
        _c(
          "span",
          { domProps: { textContent: _vm._s(_vm.$t("user.publications")) } },
          [_vm._v("Publications")]
        ),
        _vm._v(" "),
        _c("b-badge", { attrs: { variant: "info" } }, [_vm._v("0")]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "user-info_ text" },
      [
        _c(
          "span",
          {
            domProps: {
              textContent: _vm._s(_vm.$t("user.technological-production")),
            },
          },
          [_vm._v(" Technological production")]
        ),
        _vm._v(" "),
        _c("b-badge", { attrs: { variant: "info" } }, [_vm._v("0")]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "user-info_ text" },
      [
        _c(
          "span",
          { domProps: { textContent: _vm._s(_vm.$t("user.teaching")) } },
          [_vm._v("Teaching")]
        ),
        _vm._v(" "),
        _c("b-badge", { attrs: { variant: "info" } }, [_vm._v("0")]),
      ],
      1
    ),
    _vm._v(" "),
    _c("hr", { staticClass: "divider" }),
    _vm._v(" "),
    _c("div", { staticClass: "user-info_ footer-" }, [
      _c("span", { domProps: { textContent: _vm._s(_vm.$t("user.email")) } }, [
        _vm._v("Email"),
      ]),
      _vm._v(" "),
      _c("small", { staticClass: "d-block" }, [
        _vm._v(" " + _vm._s(_vm.account.email) + " "),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "user-info_ text" }, [
      _c("small", [
        _vm._v("\n      Centro de Investigación Científica de Yucatán\n      "),
        _c("span", { staticClass: "d-block" }, [
          _vm._v(" Unidad de Biotecnología"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }