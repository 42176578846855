import { Module } from 'vuex';

export const dmsStore: Module<any, any> = {
  state: {
    fotoModificable: null,
  },
  getters: {
    fotoModificable: state => state.fotoModificable,
  },
  mutations: {
    setFotoModificable(state, fotoModificable) {
      state.fotoModificable = fotoModificable;
    },
  },
};
