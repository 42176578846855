var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.account
        ? _c(
            "b-container",
            [
              _c(
                "b-row",
                { staticClass: "mb-3", attrs: { id: "header-2a" } },
                [
                  _c(
                    "b-col",
                    { staticClass: "avatar", attrs: { cols: "7" } },
                    [
                      _vm.foto !== ""
                        ? _c("b-avatar", {
                            staticClass: "avatar_img",
                            class:
                              _vm.scrollPosition <= 100 ? "muestra" : "esconde",
                            attrs: { src: _vm.foto, size: "2.581rem" },
                          })
                        : _c(
                            "b-img",
                            _vm._b(
                              {
                                staticClass: "avatar_img",
                                attrs: {
                                  rounded: "circle",
                                  alt: _vm.account.firstName,
                                },
                              },
                              "b-img",
                              _vm.mainProps,
                              false
                            )
                          ),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.account.firstName) +
                            " " +
                            _vm._s(_vm.account.lastName)
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-col", { attrs: { cols: "5" } }, [
                    _c("div", { staticClass: "user_cvu align-middle" }, [
                      _c(
                        "span",
                        {
                          class:
                            _vm.scrollPosition <= 100 ? "muestra" : "esconde",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("global.menu.account.idrizoma", {
                                id: _vm.account.email,
                              })
                            ),
                          },
                        },
                        [_vm._v("ID Rizoma: " + _vm._s(_vm.account.email))]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("global.menu.account.nocvu", {
                                cvu: _vm.account.cvu,
                              })
                            ),
                          },
                        },
                        [_vm._v("No. CVU: " + _vm._s(_vm.account.cvu))]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              false
                ? _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("div", { staticClass: "jumbotron count" }, [
                          _c("div", { staticClass: "container" }, [
                            _c("div", { staticClass: "row avance" }, [
                              _c("div", { staticClass: "col-md-8" }, [
                                _c("div", [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t(
                                            "global.menu.account.id-rizoma"
                                          )
                                        ),
                                      },
                                    },
                                    [_vm._v("id")]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "helper-text" }, [
                                  _vm._v(_vm._s(_vm.account.email)),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-4" }, [
                                _c("div", [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("global.menu.account.no-cvu")
                                        ),
                                      },
                                    },
                                    [_vm._v("cvu")]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "helper-text" }, [
                                  _vm._v(_vm._s(_vm.account.cvu)),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$route.path === "/" && !_vm.account
        ? _c("div", { staticClass: "container" }, [
            _c("h1", [
              _vm._v("RIZOMA "),
              _c(
                "small",
                {
                  domProps: { textContent: _vm._s(_vm.$t("global.pleaselog")) },
                },
                [_vm._v("Please log in to continue.")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "jumbotron count" }, [
              _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "row align-items-center" }, [
                  _c("div", { staticClass: "col-12 count__box" }, [
                    _c(
                      "h2",
                      {
                        domProps: {
                          textContent: _vm._s(_vm.$t("global.welcome")),
                        },
                      },
                      [_vm._v("Welcome back! to CONAHCYT")]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }