import { Component, Prop, Vue } from 'vue-property-decorator';

const VALIDATIONS = function () {
  return {
    totalDocumento: {},
    totalPrincipal: {},
  };
};

@Component({
  validations: VALIDATIONS,
})
export default class TotalDocumentosComponent extends Vue {
  @Prop({ required: true, type: Number })
  public totalDocumento;

  @Prop({ required: true, type: Number })
  public totalPrincipal;

  @Prop({ default: false })
  public readonly: boolean;

  @Prop({ default: null })
  public validComponent: boolean;

  @Prop({ default: 30 })
  public totalReferencia: number;

  @Prop({ default: 9 })
  public totalReferenciaPrincipal: number;

  mounted(): void {
    this.getTotal();
  }

  public getTotal(): void {
    this.totalDocumento = Number(this.totalDocumento);
    this.totalPrincipal = Number(this.totalPrincipal);
  }
}
