var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c("b-col", { attrs: { lg: "12" } }, [
        !_vm.becasExtranjero
          ? _c("h3", {
              staticClass: "mt-4 mb-4",
              domProps: {
                textContent: _vm._s(_vm.$t("area-conocimiento.title")),
              },
            })
          : _c("h4", {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("area-conocimiento.title-alt.becas-extranjero")
                ),
              },
            }),
      ]),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { lg: "12" } },
        [
          _vm.alertaSolicitud
            ? _c(
                "b-alert",
                { staticClass: "mb-4", attrs: { show: "", variant: "info" } },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("area-conocimiento.alert.emerito")
                      ),
                    },
                  }),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { lg: "12" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12", md: "12", lg: "6" } },
                [
                  _c("input-select-one", {
                    attrs: {
                      id: "area-id",
                      label: _vm.$t("area-conocimiento.area.label"),
                      options: _vm.catArea,
                      readonly: _vm.areaLectura,
                      required: true,
                      valid: _vm.$v.areaConocimiento.area.$dirty
                        ? !_vm.$v.areaConocimiento.area.$error
                        : null,
                      validationsCommons: {
                        requiredValue: !_vm.$v.areaConocimiento.area.required,
                        requiredMessage: _vm.$t(
                          "area-conocimiento.area.validations.required"
                        ),
                      },
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onAreaChanged($event)
                      },
                    },
                    model: {
                      value: _vm.$v.areaConocimiento.area.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.areaConocimiento.area, "$model", $$v)
                      },
                      expression: "$v.areaConocimiento.area.$model",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { sm: "12", md: "12", lg: "6" } },
                [
                  _c("input-select-one", {
                    attrs: {
                      id: "campo-id",
                      label: _vm.$t("area-conocimiento.campo.label"),
                      options: _vm.catCampo,
                      readonly: _vm.campoLectura,
                      required: true,
                      valid: _vm.$v.areaConocimiento.campo.$dirty
                        ? !_vm.$v.areaConocimiento.campo.$error
                        : null,
                      validationsCommons: {
                        requiredValue: !_vm.$v.areaConocimiento.campo.required,
                        requiredMessage: _vm.$t(
                          "area-conocimiento.campo.validations.required"
                        ),
                      },
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onCampoChanged($event)
                      },
                    },
                    model: {
                      value: _vm.$v.areaConocimiento.campo.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.areaConocimiento.campo, "$model", $$v)
                      },
                      expression: "$v.areaConocimiento.campo.$model",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { sm: "12", md: "12", lg: "6" } },
                [
                  _c("input-select-one", {
                    attrs: {
                      id: "disciplina-id",
                      label: _vm.$t("area-conocimiento.disciplina.label"),
                      options: _vm.catDisciplina,
                      readonly: _vm.disciplinaLectura,
                      required: true,
                      valid: _vm.$v.areaConocimiento.disciplina.$dirty
                        ? !_vm.$v.areaConocimiento.disciplina.$error
                        : null,
                      validationsCommons: {
                        requiredValue:
                          !_vm.$v.areaConocimiento.disciplina.required,
                        requiredMessage: _vm.$t(
                          "area-conocimiento.disciplina.validations.required"
                        ),
                      },
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onDisciplinaChanged($event)
                      },
                    },
                    model: {
                      value: _vm.$v.areaConocimiento.disciplina.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.areaConocimiento.disciplina,
                          "$model",
                          $$v
                        )
                      },
                      expression: "$v.areaConocimiento.disciplina.$model",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { sm: "12", md: "12", lg: "6" } },
                [
                  _c("input-select-one", {
                    attrs: {
                      id: "subdisciplina-id",
                      label: _vm.$t("area-conocimiento.subdisciplina.label"),
                      options: _vm.catSubdisciplina,
                      readonly: _vm.subdisciplinaLectura,
                      required: true,
                      valid: _vm.$v.areaConocimiento.subdisciplina.$dirty
                        ? !_vm.$v.areaConocimiento.subdisciplina.$error
                        : null,
                      validationsCommons: {
                        requiredValue:
                          !_vm.$v.areaConocimiento.subdisciplina.required,
                        requiredMessage: _vm.$t(
                          "area-conocimiento.subdisciplina.validations.required"
                        ),
                      },
                    },
                    model: {
                      value: _vm.$v.areaConocimiento.subdisciplina.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.areaConocimiento.subdisciplina,
                          "$model",
                          $$v
                        )
                      },
                      expression: "$v.areaConocimiento.subdisciplina.$model",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-overlay", {
                attrs: {
                  show: _vm.showAC,
                  "no-wrap": "",
                  "spinner-type": "grow",
                  "spinner-variant": "primary",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }