var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "soluciones-card" },
    [
      _c("b-overlay", {
        staticClass: "position-fixed",
        attrs: {
          show: _vm.showSolicitudCancelacion,
          "no-wrap": "",
          "spinner-type": "grow",
          opacity: ".40",
          "spinner-variant": "primary",
          "z-index": "999999",
        },
      }),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "12" } },
        [_c("alerta", { ref: "alerta" })],
        1
      ),
      _vm._v(" "),
      _vm.solicitudes.length > 0
        ? _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c("div", { staticClass: "title-block" }, [
                _c("h2", { staticClass: "title mt-4" }, [
                  _c(
                    "span",
                    {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("solicitudes.title-solicitudes-activas")
                        ),
                      },
                    },
                    [_vm._v("activas")]
                  ),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "sparkline bar",
                    attrs: { "data-type": "bar" },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "b-skeleton-wrapper",
                {
                  attrs: { loading: _vm.loadingSolicitudes },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "loading",
                        fn: function () {
                          return [_c("skeleton", { attrs: { type: "card" } })]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2052223983
                  ),
                },
                [
                  _vm._v(" "),
                  _vm._l(_vm.solicitudesAct, function (item, index) {
                    return _c(
                      "span",
                      { key: item.id },
                      [
                        item.estadoAjusteBeca &&
                        item.estadoAjusteBeca != _vm.PROGRAMA_NO_ELEGIBLE
                          ? _c(
                              "b-alert",
                              {
                                staticClass: "mt-3",
                                attrs: { variant: "warning", show: "" },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "solicitudes.solicitud-becas.info"
                                        )
                                      ),
                                    },
                                  },
                                  [_vm._v(" info mensaje ")]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "b-card",
                          {
                            staticClass: "mb-4 mt-3 custom-card-solicitud",
                            attrs: {
                              header: item.estadoAjusteBeca
                                ? _vm.getBadgeTextBecas(item.estadoAjusteBeca)
                                : "",
                              "header-bg-variant": _vm.getBadgeVariantBecas(
                                item.estadoAjusteBeca
                              ),
                              "header-text-variant": "white",
                              "border-variant": item.estadoAjusteBeca
                                ? _vm.getBadgeVariantBecas(
                                    item.estadoAjusteBeca
                                  )
                                : "",
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "icon-solicitud bg-icon",
                            }),
                            _vm._v(" "),
                            _c("b-row", [
                              _c("ul", { staticClass: "felx-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "left-felx" },
                                  [
                                    _c("b-card-text", [
                                      _c(
                                        "div",
                                        { staticClass: "font-weight-bolder" },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                item.solucionSolicitud
                                                  .nombreConvocatoria
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          "\n                    Fecha de creación: " +
                                            _vm._s(
                                              _vm._f("formatoFecha")(
                                                item.estadoSolicitud[0]
                                                  .fechaAlta
                                                  ? item.estadoSolicitud[0]
                                                      .fechaAlta
                                                  : ""
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      item.adeudoSancion
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "b-badge",
                                                {
                                                  attrs: { variant: "danger" },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        item.adeudoSancion
                                                          .descripcion
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "right-felx card-btn" },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "b-badge",
                                          {
                                            staticClass: "mb-2",
                                            attrs: {
                                              pill: "",
                                              variant: item.estadoSolicitud
                                                ? _vm.getBadgeVariantBecas2(
                                                    item.estadoSolicitud
                                                  )
                                                : "primary",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                item.solucionSolicitud
                                                  .tipoSolucion ===
                                                  "EMERITOS" &&
                                                  item.estadoSolicitud[
                                                    item.estadoSolicitud
                                                      .length - 1
                                                  ].estado === "CREADA"
                                                  ? "INICIADA"
                                                  : item.estadoSolicitud[
                                                      item.estadoSolicitud
                                                        .length - 1
                                                    ].estado
                                              ) + "\n                  "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              variant: "outline-primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.navigateSolicitudes(
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "icon-ver",
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$t("entity.action.see")
                                                  ),
                                                },
                                              },
                                              [_vm._v("Ver")]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        item.solucionSolicitud.tipoSolucion ==
                                          "BECAS_NACIONALES" &&
                                        item.estadoActual == "FORMALIZADA"
                                          ? _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  variant: "outline-primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.consultarCartaISSSTE(
                                                      item.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "icon-descargar",
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t(
                                                          "solicitudes.btn-carta-issste"
                                                        )
                                                      ),
                                                    },
                                                  },
                                                  [_vm._v("ISSSTE")]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.permiteCancelar()[index]
                                          ? _c(
                                              "span",
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      variant:
                                                        "outline-primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.cancelaSidebar(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass:
                                                        "icon-cerrar",
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.$t(
                                                              "solicitudes.cancelar.btn-cancelar"
                                                            )
                                                          ),
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Cancelar solicitud"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("b-col", { attrs: { md: "12" } }, [
        _c("div", { staticClass: "title-block" }, [
          _c("h2", { staticClass: "title mt-4" }, [
            _c(
              "span",
              {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("solicitudes.convocatorias-abiertas")
                  ),
                },
              },
              [_vm._v("Convocatorias")]
            ),
            _vm._v(" "),
            _c("span", {
              staticClass: "sparkline bar",
              attrs: { "data-type": "bar" },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "12 mt-2 mb-1" } },
        [
          _c("b-alert", { attrs: { show: "", variant: "primary" } }, [
            _c("span", {
              domProps: {
                textContent: _vm._s(_vm.$t("solicitudes.alert.datos.msg")),
              },
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "font-italic",
              domProps: {
                textContent: _vm._s(_vm.$t("solicitudes.alert.datos.msg1")),
              },
            }),
            _vm._v(" "),
            _c("span", {
              domProps: {
                textContent: _vm._s(_vm.$t("solicitudes.alert.datos.msg2")),
              },
            }),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "12" } },
        [
          _c(
            "b-skeleton-wrapper",
            {
              attrs: { loading: _vm.loadingSolicitudes },
              scopedSlots: _vm._u([
                {
                  key: "loading",
                  fn: function () {
                    return [_c("skeleton", { attrs: { type: "card" } })]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              [
                _c(
                  "div",
                  _vm._l(_vm.solucionesCard, function (item, index) {
                    return _c(
                      "span",
                      { key: item.id },
                      [
                        _c(
                          "b-card",
                          { staticClass: "mb-4 custom-card-solicitud" },
                          [
                            _c("span", {
                              staticClass: "icon-convocatoria bg-icon",
                            }),
                            _vm._v(" "),
                            _c("b-row", [
                              _c("ul", { staticClass: "felx-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "left-felx" },
                                  [
                                    _c("b-card-text", [
                                      _c(
                                        "div",
                                        { staticClass: "font-weight-bolder" },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(item.title) +
                                              "\n                      "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      item.tipoBecaExtranjero ==
                                        "ESPECIALIDADES_MEDICAS" ||
                                      item.tipo == "BECAS_NACIONALES" ||
                                      item.tipoBecaExtranjero ==
                                        "CREACION_FUTURO"
                                        ? _c("span", [
                                            item.description
                                              ? _c("div", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      item.description
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            item.footer && item.completo == null
                                              ? _c("div", [
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(item.footer) +
                                                      "\n                        "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "right-felx card-btn" },
                                  [
                                    _vm._l(item.badge, function (b) {
                                      return _c(
                                        "span",
                                        { key: b.id },
                                        [
                                          b.badge
                                            ? _c(
                                                "b-badge",
                                                {
                                                  attrs: {
                                                    pill: "",
                                                    variant: b.primary
                                                      ? "primary"
                                                      : "",
                                                  },
                                                },
                                                [_vm._v(_vm._s(b.badge) + " ")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    }),
                                    _vm._v(" "),
                                    _vm.permiteParticipar()[index]
                                      ? _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              variant: "outline-primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.creaSolicitud(item)
                                              },
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "icon-participar",
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$t(
                                                      "entity.action.participar"
                                                    )
                                                  ),
                                                },
                                              },
                                              [_vm._v("participar")]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("alerta-card", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.solucionesCard.length == 0,
                      expression: "solucionesCard.length == 0",
                    },
                  ],
                  attrs: { existeConvocatoria: false },
                }),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "solicitudModal",
            title: _vm.$t("solicitudes.alert.title"),
            size: "lg",
            centered: "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "b-button",
                    {
                      staticClass: "float-right",
                      attrs: { variant: "outline-primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$bvModal.hide("solicitudModal")
                        },
                      },
                    },
                    [_vm._v(" Cancelar ")]
                  ),
                  _vm._v(" "),
                  _c("router-link", {
                    attrs: {
                      to: { name: _vm.tipoSolicitudRouter },
                      custom: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var navigate = ref.navigate
                          return [
                            _c(
                              "b-button",
                              {
                                staticClass: "float-right",
                                attrs: { variant: "primary" },
                                on: { click: navigate },
                              },
                              [_vm._v(" Aceptar ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.indicadorSolicitud == "INGRESO_PERMANENCIA_PROMOCION"
            ? _c("div", [
                _c("div", {
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("solicitudes.modal.ingreso")),
                  },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.indicadorSolicitud == "EMERITOS"
            ? _c("div", [
                _c("div", {
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("solicitudes.modal.emeritos")),
                  },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.indicadorSolicitud == "BECAS_NACIONALES"
            ? _c("div", [
                _c("div", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("solicitudes.modal.becas-nacionales")
                    ),
                  },
                }),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "programaModal",
            title: _vm.$t("solicitudes.programa-modal.title"),
            size: "lg",
            centered: "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      staticClass: "float-right",
                      attrs: { variant: "primary" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(" Aceptar ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("div", [
            this.categoriaNoElegible
              ? _c("div", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("solicitudes.modal.categoria-no-elegible")
                    ),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            this.noElegible
              ? _c("div", {
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("solicitudes.modal.no-elegible")),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            this.mesInicioEstudiosInvalido
              ? _c("div", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("solicitudes.modal.mes-inicio-estudios-invalido")
                    ),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            this.validaCveGrado
              ? _c("div", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("solicitudes.modal.becas-nacionales")
                    ),
                  },
                })
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "infoProgramaModal",
            title: _vm.$t("solicitudes.info-programa-modal.title"),
            size: "lg",
            centered: "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      staticClass: "float-right",
                      attrs: { variant: "primary" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(" Aceptar ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "8" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("becas-nacionales.programa.label"),
                          },
                        },
                        [
                          _vm.informacionSNP.participante
                            ? _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.informacionSNP.participante.programa
                                      .nombre
                                  )
                                ),
                              ])
                            : _c("p", [_vm._v("---")]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { staticClass: "custom-badge-primary", attrs: { md: "4" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t(
                              "becas-nacionales.snp-cordinador.duracion-oficial"
                            ),
                          },
                        },
                        [
                          _vm.informacionSNP.participante
                            ? _c(
                                "b-badge",
                                {
                                  staticClass: "padding-badge",
                                  attrs: { pill: "", variant: "primary" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.informacionSNP.participante.programa
                                        .duracionOficial
                                    )
                                  ),
                                ]
                              )
                            : _c("p", [_vm._v("---")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t(
                              "becas-nacionales.snp-cordinador.grado"
                            ),
                          },
                        },
                        [
                          _vm.informacionSNP.participante
                            ? _c("b-form-input", {
                                attrs: { readonly: "" },
                                model: {
                                  value:
                                    _vm.informacionSNP.participante.programa
                                      .grado,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.informacionSNP.participante.programa,
                                      "grado",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "informacionSNP.participante.programa.grado",
                                },
                              })
                            : _c("p", [_vm._v("---")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t(
                              "becas-nacionales.snp-cordinador.tipo"
                            ),
                          },
                        },
                        [
                          _vm.informacionSNP.participante
                            ? _c("b-form-input", {
                                attrs: { readonly: "" },
                                model: {
                                  value:
                                    _vm.informacionSNP.participante.programa
                                      .tipo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.informacionSNP.participante.programa,
                                      "tipo",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "informacionSNP.participante.programa.tipo",
                                },
                              })
                            : _c("p", [_vm._v("---")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("solicitudes.info-programa-modal.mjs")
                  ),
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "tipoEspecialModal",
            title: "Sistema Nacional de Posgrados",
            size: "lg",
            centered: "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      staticClass: "float-right",
                      attrs: { variant: "primary" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(" Aceptar ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("div", [
            _c("div", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("solicitudes.modal.tipo-especial", {
                    programa: _vm.nombrePrograma,
                  })
                ),
              },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "cancelarSolicitud",
            title: _vm.$t("solicitudes.cancelar.title"),
            size: "lg",
            centered: "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "btn-group float-right" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary" },
                          on: { click: _vm.confirmaCancelarSolicitud },
                        },
                        [
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("solicitudes.cancelar.btn-cancelar")
                                ),
                              },
                            },
                            [_vm._v("Cancelar solicitud")]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "icon-cerrar" }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c("input-text-area", {
                    attrs: {
                      id: "motivo-id",
                      label: _vm.$t("solicitudes.cancelar.motivo.label"),
                      description: _vm.$t(
                        "solicitudes.cancelar.motivo.description"
                      ),
                      readonly: false,
                      required: true,
                      valid: _vm.$v.cancelacion.comentario.$dirty
                        ? !_vm.$v.cancelacion.comentario.$error
                        : null,
                      validationsCommons: {
                        requiredValue: !_vm.$v.cancelacion.comentario.required,
                        requiredMessage: _vm.$t(
                          "solicitudes.cancelar.motivo.validations.required"
                        ),
                        minValue: !_vm.$v.cancelacion.comentario.minLength,
                        minMessage: _vm.$t(
                          "solicitudes.cancelar.motivo.validations.minMessage",
                          { min: "50" }
                        ),
                        maxValue: !_vm.$v.cancelacion.comentario.maxLength,
                        maxMessage: _vm.$t(
                          "solicitudes.cancelar.motivo.validations.maxMessage",
                          { max: "2000" }
                        ),
                      },
                      maxCaracteres: 2000,
                    },
                    model: {
                      value: _vm.$v.cancelacion.comentario.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.cancelacion.comentario, "$model", $$v)
                      },
                      expression: "$v.cancelacion.comentario.$model",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }