var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _vm.tipoDato == "generales"
        ? _c("b-col", { attrs: { md: "12" } }, [
            _c("div", { staticClass: "title-block" }, [
              _c("h3", { staticClass: "title mt-4 mb-4" }, [
                _vm.solicitudActiva.solucionSolicitud
                  ? _c(
                      "span",
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.solicitudActiva.solucionSolicitud
                                .nombreConvocatoria
                            ) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _vm.solicitudActiva.adeudoSancion
                          ? _c("b-badge", { attrs: { variant: "danger" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.solicitudActiva.adeudoSancion
                                      .descripcion
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("span", {
                  staticClass: "sparkline bar",
                  attrs: { "data-type": "bar" },
                }),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "12" } },
        [
          _c(
            "b-card",
            {
              attrs: {
                "border-variant":
                  _vm.solicitudActiva.estadoSolicitud[
                    _vm.solicitudActiva.estadoSolicitud.length - 1
                  ].estado == "CREADA"
                    ? "primary"
                    : _vm.solicitudActiva.estadoSolicitud[
                        _vm.solicitudActiva.estadoSolicitud.length - 1
                      ].estado == "CANCELADA"
                    ? "danger"
                    : "success",
                "header-bg-variant":
                  _vm.solicitudActiva.estadoSolicitud[
                    _vm.solicitudActiva.estadoSolicitud.length - 1
                  ].estado == "CREADA"
                    ? "primary"
                    : _vm.solicitudActiva.estadoSolicitud[
                        _vm.solicitudActiva.estadoSolicitud.length - 1
                      ].estado == "CANCELADA"
                    ? "danger"
                    : "success",
                header:
                  "Solicitud " +
                  _vm.solicitudActiva.estadoSolicitud[
                    _vm.solicitudActiva.estadoSolicitud.length - 1
                  ].estado,
                "header-text-variant": "white",
              },
            },
            [
              _c(
                "b-row",
                [
                  _vm.tipoDato == "generales"
                    ? _c(
                        "b-col",
                        { attrs: { md: "4" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "datos-solicitud.numero-solicitud"
                                ),
                              },
                            },
                            [_c("p", [_vm._v(_vm._s(_vm.solicitudActiva.id))])]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.tipoDato == "generales"
                    ? _c(
                        "b-col",
                        { attrs: { md: "4" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("datos-solicitud.tipo-solicitud"),
                              },
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.solicitudActiva.tipoSolicitud.nombre
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.tipoDato == "generales"
                    ? _c(
                        "b-col",
                        { attrs: { md: "4" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("datos-solicitud.fecha-creacion"),
                              },
                            },
                            [_c("p", [_vm._v(_vm._s(_vm.fechaCreacion))])]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }