import { render, staticRenderFns } from "./social.vue?vue&type=template&id=0f50ed49&scoped=true&"
import script from "./social.component.ts?vue&type=script&lang=ts&"
export * from "./social.component.ts?vue&type=script&lang=ts&"
import style0 from "./social.vue?vue&type=style&index=0&id=0f50ed49&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f50ed49",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/0/gateway/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0f50ed49')) {
      api.createRecord('0f50ed49', component.options)
    } else {
      api.reload('0f50ed49', component.options)
    }
    module.hot.accept("./social.vue?vue&type=template&id=0f50ed49&scoped=true&", function () {
      api.rerender('0f50ed49', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/components/social/social.vue"
export default component.exports