import { Component, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import { maxLength, numeric, required } from 'vuelidate/lib/validators';
import isEqual from 'lodash.isequal';
import type AlertaComponent from '@/components/alerta/alerta.component';

const VALIDATIONS = function () {
  return {
    nuevoAutor: {
      nombre: {
        required,
        maxLength: maxLength(100),
      },
      primerApellido: {
        required,
        maxLength: maxLength(60),
      },
      segundoApellido: {
        maxLength: maxLength(60),
      },
      orcId: {
        required,
        maxLength: maxLength(100),
      },
      orden: {
        numeric,
        required,
        maxLength: maxLength(3),
      },
    },
  };
};

@Component({
  validations: VALIDATIONS,
})
export default class AutoresComponent extends Vue {
  @Ref()
  alerta!: AlertaComponent;
  //reactividad retorna valores al padre
  @PropSync('value', { type: Array, default: () => [] })
  public autores: Array<any>;

  //propiedad para solo vista
  @Prop({ default: false })
  public readonly: boolean;

  // validacion del padre
  @Prop({ default: false })
  public validAuthors: boolean;

  //cantidad de registros por pagina en tabla
  @Prop({ default: 10 })
  public recordsPage: number;

  @Prop({ default: false })
  public required: boolean;

  /**
   *validComponent formulario desde el componente (padre)
   *
   */
  @Watch('validAuthors', { immediate: true, deep: true })
  handler(value: any) {
    if (value) {
      this.validAutorComponent = this.autores.length == 0;
    } else {
      this.validAutorComponent = false;
    }
  }

  public validAutorComponent = false; // valida dentro del componente

  public baseOptions: Array<any> = [];
  public removeIndex = null;

  public existeCambioOrdenEdit = false;
  public existeCambioForm = false;

  //variables paginacion tabla
  public currentPage = 1;
  public sortBy = 'orden';
  public sortDesc = false;
  public sortDirection = 'asc';

  public fieldsAutor = [
    {
      key: 'nombre',
      label: this.$t('autores.table.nombre').toString(),
      class: 'text-center',
      sortable: true,
    },
    {
      key: 'primerApellido',
      label: this.$t('autores.table.primerApellido').toString(),
      class: 'text-center',
      sortable: true,
    },
    {
      key: 'segundoApellido',
      label: this.$t('autores.table.segundoApellido').toString(),
      class: 'text-center',
      sortable: true,
    },
    { key: 'orcId', label: this.$t('autores.table.orcId').toString(), class: 'text-center', sortable: true },
    { key: 'orden', label: this.$t('autores.table.orden').toString(), class: 'text-center', sortable: true, sortDirection: 'asc' },
    { key: 'acciones', label: this.$t('autores.table.acciones').toString(), class: 'text-center' },
  ];

  public nuevoAutor: {
    nombre?: any;
    primerApellido?: any;
    segundoApellido?: any;
    orcId?: any;
    orden?: any;
  } = {
    nombre: null,
    primerApellido: null,
    segundoApellido: null,
    orcId: null,
    orden: null,
  };

  mounted() {
    if (this.readonly) this.fieldsAutor.pop();
    this.actualizaIndices();
  }

  //Se crean modelos y se envian al CARD y EMIT
  public agregaAutor(): void {
    if (!this.$v.nuevoAutor.$invalid) {
      if (this.validaDuplicados(this.nuevoAutor)) {
        this.alerta.mostrar(this.$t('autores.alert.duplicate'), 'warning', 20);
      } else {
        if (this.validaOrden(this.nuevoAutor)) {
          const form = JSON.parse(JSON.stringify(this.nuevoAutor));
          if (this.removeIndex === null) {
            this.autores.push(form);
            this.actualizaIndices();
            this.alerta.mostrar(this.$t('autores.alert.add'), 'success', 20);
          } else {
            const index = this.autores.findIndex(autor => autor.indice === this.removeIndex);
            this.$set(this.autores, index, form);
            this.removeIndex = null;
            this.actualizaIndices();
            this.alerta.mostrar(this.$t('autores.alert.edit'), 'success', 20);
            this.existeCambioForm = false;
            this.existeCambioOrdenEdit = false;
          }
          this.resetForm();
        } else {
          this.alerta.mostrar(this.$t('autores.alert.orden'), 'warning', 20);
        }
      }
      this.validaArregloAutores(this.autores);
    } else {
      this.$v.$touch();
    }
  }

  public validaDuplicados(val) {
    let validaDu = false;
    let validaDu1 = false;
    let validaDu2 = false;
    validaDu1 = this.autores.some(g =>
      isEqual(
        { nombre: g.nombre, primerApellido: g.primerApellido, segundoApellido: g.segundoApellido, orcId: g.orcId },
        { nombre: val.nombre, primerApellido: val.primerApellido, segundoApellido: val.segundoApellido, orcId: val.orcId }
      )
    );
    validaDu = validaDu1;
    if (this.removeIndex != null) {
      // valida todo por que cambio el orden
      if ((this.existeCambioOrdenEdit && this.existeCambioForm) || this.existeCambioOrdenEdit) {
        validaDu2 = this.autores.some(g => isEqual(g, val));

        validaDu = validaDu2;
        if (!validaDu2 && this.existeCambioForm) {
          validaDu = validaDu1;
        }
      }
    }
    return validaDu;
  }

  public validaOrden(autor) {
    let res = true;
    if (this.removeIndex === null) {
      this.autores.some(c => {
        if (c.orden == autor.orden) res = false;
      });
    } else {
      for (let i = 0; i < this.autores.length; i++) {
        if (i === this.removeIndex - 1) {
          continue;
        }
        if (this.autores[i].orden == autor.orden) res = false;
      }
    }
    return res;
  }

  public prepareAutor(item, index) {
    this.removeIndex = index;
    this.nuevoAutor = this.shallowCopy(item);
  }

  public eliminaAutor(indice): void {
    const index = this.autores.findIndex(autor => autor.indice === indice);
    this.autores.splice(index, 1);
    this.$bvModal.hide('removeAutor');
    this.alerta.mostrar(this.$t('autores.alert.delete'), 'warning', 20);
    this.validaArregloAutores(this.autores);
    const totalPage = Math.ceil(this.autores.length / this.recordsPage);
    if (this.currentPage > totalPage) this.currentPage = totalPage;
    this.resetForm();
  }

  public prepareRemove(indice: any): void {
    this.removeIndex = indice;
    this.$bvModal.show('removeAutor');
  }

  public remove(): void {
    this.eliminaAutor(this.removeIndex);
  }

  public resetForm() {
    this.nuevoAutor.nombre = null;
    this.nuevoAutor.primerApellido = null;
    this.nuevoAutor.segundoApellido = null;
    this.nuevoAutor.orcId = null;
    this.nuevoAutor.orden = null;
    this.$v.nuevoAutor.$reset();
    this.removeIndex = null;
  }

  /** Copia las propiedades de un objeto */
  public shallowCopy(source) {
    return JSON.parse(JSON.stringify(source));
  }

  public validaArregloAutores(aa) {
    return (this.validAutorComponent = aa.length == 0);
  }

  numbersOnly(evt) {
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault();
    } else {
      return true;
    }
  }

  public cambiaOrden() {
    this.existeCambioOrdenEdit = true;
  }

  public cambiaForm() {
    this.existeCambioForm = true;
  }

  public actualizaIndices() {
    if (this.autores && this.autores.length > 0) {
      this.autores.forEach((actor, index) => {
        actor.indice = index + 1;
      });
    }
  }

  public soloLetras(evt) {
    const charCode = evt.keyCode;
    // Permitir letras (A-Z, a-z), ñ, Ñ y espacios (32)
    if (
      charCode > 31 &&
      (charCode < 65 || charCode > 90) &&
      (charCode < 97 || charCode > 122) &&
      charCode !== 32 &&
      charCode !== 241 &&
      charCode !== 209
    ) {
      evt.preventDefault();
    }
  }
}
