import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class AlertaComponent extends Vue {
  mensaje = null;

  public mostrar(
    texto: any,
    tipo: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' = 'info',
    duracion = 10,
    descartable = true
  ) {
    this.mensaje = {
      texto,
      descartable,
      duracion,
      tipo,
    };
  }

  public limpiar(): void {
    this.mensaje = null;
  }

  cambioCuentaRegresiva(duracion: number) {
    this.mensaje.duracion = duracion;
  }
}
