import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import PropertyCommons from '../commons/property.model';

import { mixins } from 'vue-class-component';
@Component
export default class InputBooleanComponent extends mixins(PropertyCommons) {
  @Prop({ required: true })
  public value!: boolean;

  @Prop()
  public label!: string;

  get boolValue(): boolean {
    return this.value;
  }

  set boolValue(newValue: boolean) {
    this.$emit('input', newValue);
  }

  cambio(e) {
    this.$emit('change', e);
  }
}
