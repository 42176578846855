import { Component, Inject, Prop, PropSync, Watch, Vue } from 'vue-property-decorator';

import CatalogoService from '@/shared/catalogo/catalogo.service';
import { required } from 'vuelidate/lib/validators';

const VALIDATIONS = function () {
  return {
    ocde: {
      sector: {
        id: {},
        nombre: {},
        required,
      },
      division: {
        id: {},
        nombre: {},
        required,
      },
      grupo: {
        id: {},
        nombre: {},
        required,
      },
      clase: {
        id: {},
        nombre: {},
        required,
      },
    },
  };
};

@Component({
  validations: VALIDATIONS,
})
export default class OcdeComponent extends Vue {
  @Inject('catalogoService') private catalogoService: () => CatalogoService;

  @PropSync('value', { required: true, type: Object })
  public ocde;

  @Prop({ default: false })
  public readonly: boolean;

  @Prop({ default: null })
  public edit: any;

  @Prop({ default: null })
  public validComponent: boolean;
  public showOcde = false;

  @Watch('validComponent', { immediate: true, deep: true })
  handler(value: any) {
    if (value) {
      this.$v.ocde.$touch();
    } else {
      this.$v.ocde.$reset();
    }
  }
  public sectorOcdeLectura = false;
  public divisionOcdeLectura = true;
  public grupoOcdeLectura = true;
  public claseOcdeLectura = true;

  public sectorOcdeOptions: Array<any> = [];

  public divisionOcdeOptions: Array<any> = [];

  public grupoOcdeOptions: Array<any> = [];

  public claseOcdeOptions: Array<any> = [];

  public busyDivision = false;
  public busyGrupo = false;
  public busyClase = false;

  public catalogoServiceName = {
    getSectorOcde: 'getOcdeSectores',
    getDivisionOcde: 'getOcdeDivisionesOcdeSector',
    getGrupoOcde: 'getOcdeGruposOcdeDivisiones',
    getClaseOcde: 'getOcdeClasesOcdeGrupos',
  };

  mounted(): void {
    if (this.edit != null) {
      if (this.ocde?.sector?.id != null) {
        this.setDataOcde();
      }
      this.soloLectura(this.readonly);
    }
    this.initCatalogos();
  }

  public initCatalogos(): void {
    this.catalogoService()
      .get(this.catalogoServiceName.getSectorOcde)
      .then(result => {
        this.sectorOcdeOptions = result;
      });
  }

  private limpiaCatalogo(catalogo: any): any[] {
    if (catalogo.length && catalogo[0].value) {
      const propiedades = Object.getOwnPropertyNames(catalogo[0].value).filter(p => p != 'id' && p != 'clave' && p != 'nombre');
      catalogo.forEach(c => {
        if (c.value) {
          propiedades.forEach(p => delete c.value[p]);
        }
      });
    }
    return catalogo;
  }

  public async onSectorOcdeChanged(e: any, deleteModel: boolean): Promise<void> {
    this.busyDivision = true;
    if (e) {
      return this.catalogoService()
        .get(this.catalogoServiceName.getDivisionOcde, e.id)
        .then(result => {
          // Setea los catalogos siguientes
          this.divisionOcdeOptions = this.limpiaCatalogo(result);
          this.grupoOcdeOptions = [];
          this.claseOcdeOptions = [];

          // habilita y deshabilita campos
          this.divisionOcdeLectura = false;
          this.grupoOcdeLectura = true;
          this.claseOcdeLectura = true;

          // Setea null los modelos siguientes
          if (deleteModel) {
            this.ocde.division = null;
            this.ocde.grupo = null;
            this.ocde.clase = null;
          }

          //busy
          this.busyDivision = false;

          Promise.resolve();
        });
    } else {
      return Promise.resolve();
    }
  }

  public async onDivisionOcdeChanged(e: any, deleteModel: boolean): Promise<void> {
    this.busyGrupo = true;
    if (e) {
      return this.catalogoService()
        .get(this.catalogoServiceName.getGrupoOcde, e.id)
        .then(result => {
          // Setea los catalogos siguientes
          this.grupoOcdeOptions = this.limpiaCatalogo(result);
          this.claseOcdeOptions = [];

          // habilita y deshabilita campos
          this.grupoOcdeLectura = false;
          this.claseOcdeLectura = true;

          // Setea null los modelos siguientes
          if (deleteModel) {
            this.ocde.grupo = null;
            this.ocde.clase = null;
          }

          //busy
          this.busyGrupo = false;

          Promise.resolve();
        });
    } else {
      return Promise.resolve();
    }
  }

  public async onGrupoOcdeChanged(e: any, deleteModel: boolean): Promise<any> {
    this.busyClase = true;
    if (e) {
      return this.catalogoService()
        .get(this.catalogoServiceName.getClaseOcde, e.id)
        .then(result => {
          // Setea los catalogos siguientes
          this.claseOcdeOptions = this.limpiaCatalogo(result);

          // habilita y deshabilita campos
          this.edit != null ? this.soloLectura(this.readonly) : (this.claseOcdeLectura = false);

          // Setea null los modelos siguientes
          if (deleteModel) {
            this.ocde.clase = null;
          }

          //busy
          this.busyClase = false;

          Promise.resolve();
        });
    } else {
      return Promise.resolve();
    }
  }
  public setDataOcde() {
    this.showOcde = true;
    this.onSectorOcdeChanged(this.ocde.sector, false)
      .then(() => {
        this.onDivisionOcdeChanged(this.ocde.division, false)
          .then(() => {
            this.onGrupoOcdeChanged(this.ocde.grupo, false);
            this.showOcde = false;
          })
          .catch(() => {
            this.showOcde = false;
          });
      })
      .catch(() => {
        this.showOcde = false;
      });
  }

  public soloLectura(val: boolean) {
    this.sectorOcdeLectura = val;
    this.divisionOcdeLectura = val;
    this.grupoOcdeLectura = val;
    this.claseOcdeLectura = val;
  }
}
