import AccountService from '@/account/account.service';
import AdminMenu from '@/core/sidebar/admin-menu/admin-menu.vue';
import userProfileMenu from '@/core/sidebar/user-profile-menu/user-profile-menu.vue';
import DataUserSidebarComponent from '@/core/user/data-user-sidebar.vue';
import EntitiesMenu from '@/entities/entities-menu.vue';
import TranslationService from '@/locale/translation.service';
import AlertService from '@/shared/alert/alert.service';
import EntityFactory from '@/shared/entity-commons/entity.factory';
import { BgColor, DatosGeneralesApi, Template } from '@/shared/perfil-usuario';
import PerfilService from '@/shared/perfil/perfil.service';
import Component from 'vue-class-component';
import { Inject, Prop, Ref, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
  components: {
    'entities-menu': EntitiesMenu,
    'admin-menu': AdminMenu,
    'data-user-sidebar': DataUserSidebarComponent,
    userProfileMenu,
  },
  computed: {
    ...mapGetters(['account']),
    fields() {
      return EntityFactory.getModalPULabel(this.$t.bind(this));
    },
    items() {
      return EntityFactory.getTemplateOptions(this.$t.bind(this));
    },
  },
})
export default class SidebarComponent extends Vue {
  @Inject('translationService') private translationService: () => TranslationService;
  @Inject('accountService') private accountService: () => AccountService;
  @Inject('perfilService') private perfilService: () => PerfilService;
  @Inject('miPerfilApi') private miPerfilApi: () => DatosGeneralesApi;
  @Inject('alertService') private alertService: () => AlertService;

  // public version = 'v' + VERSION;
  public version = 'v0.0.1';
  private currentLanguage = this.$store.getters.currentLanguage;
  private languages: any = this.$store.getters.languages;
  private hasAnyAuthorityValue = false;
  public popOverDisabled = true;
  public centerWidth;
  private activeCollapse: string | null = null;

  @Prop({ default: 'perfil-unico.pdf' })
  public nombrePdf: string;
  public contenidoBase64 = '';
  public pdfReady = false;
  public showOverlay = false;
  public TEMPLATE_COMPLETO = EntityFactory.getTemplateOptions(this.$t.bind(this))[0].value;
  public TEMPLATE_RESUMEN = EntityFactory.getTemplateOptions(this.$t.bind(this))[1].value;
  public TEMPLATE_TARJETA = EntityFactory.getTemplateOptions(this.$t.bind(this))[2].value;

  //Reset var closeSidebar, popOverDisabled para evitar conflicto en dimecion < 768
  mounted() {
    window.onresize = () => {
      this.centerWidth = document.documentElement.clientWidth;
      if (this.centerWidth < 768) {
        this.$store.dispatch('closeSidebar');
        this.popOverDisabled = true;
      }
    };
  }

  created() {
    this.translationService().refreshTranslation(this.currentLanguage);
  }

  public subIsActive(input) {
    const paths = Array.isArray(input) ? input : [input];
    return paths.some(path => {
      return this.$route.path.indexOf(path) === 0; // current path starts with this path string
    });
  }

  public changeLanguage(newLanguage: string): void {
    this.translationService().refreshTranslation(newLanguage);
  }

  public isActiveLanguage(key: string): boolean {
    return key === this.$store.getters.currentLanguage;
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public hasAnyAuthority(authorities: any): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth(authorities)
      .then(value => {
        this.hasAnyAuthorityValue = value;
        this.perfilService().retrieveInicialPerfil();
      });
    return this.hasAnyAuthorityValue;
  }

  public get openAPIEnabled(): boolean {
    return this.$store.getters.activeProfiles.indexOf('api-docs') > -1;
  }

  public get inProduction(): boolean {
    return this.$store.getters.activeProfiles.indexOf('prod') > -1;
  }

  public toggleCollapse(panelId: string): void {
    if (this.activeCollapse === panelId) {
      this.activeCollapse = null;
    } else {
      this.activeCollapse = panelId;
    }
  }

  public isOpen(panelId: string): boolean {
    return this.activeCollapse === panelId;
  }

  /**
   * Muestra un modal con el template específico para la selección de documentos.
   * Utiliza BootstrapVue para mostrar el modal identificado como 'menu-template'.
   */
  public consultarDocumentoPU(): void {
    this.showOverlay = true;
    this.miPerfilApi()
      .getReportePerfil(this.TEMPLATE_COMPLETO, BgColor.AZUL)
      .then(res => {
        this.contenidoBase64 = res.data.contenidoBase64;
        this.descargarPdf(this.contenidoBase64);
        this.showOverlay = false;
      })
      .catch(() => {
        this.showOverlay = false;
        this.alertService().showError(this, this.$t('perfil-unico.error-descarga').toString());
      });
    // this.$bvModal.show('menu-template'); // TODO correccion para descarga de distinto PDF
  }

  /**
   * Genera la vista previa de un documento PDF basado en la plantilla seleccionada.
   *
   * @param item El tipo de plantilla seleccionado, que puede ser COMPLETO, RESUMEN o TARJETA.
   * @param bgColor El color de fondo seleccionado para la plantilla.
   *
   */
  public vistaPreviaPdf(item, bgColor: BgColor) {
    this.showOverlay = true;
    this.contenidoBase64 = '';
    this.pdfReady = false;
    let template = null;
    switch (item.value) {
      case Template.COMPLETO:
        template = this.TEMPLATE_COMPLETO;
        break;
      case Template.RESUMEN:
        template = this.TEMPLATE_RESUMEN;
        break;
      case Template.TARJETA:
        template = this.TEMPLATE_TARJETA;
        break;
      default:
        template = this.TEMPLATE_COMPLETO;
        break;
    }
    this.miPerfilApi()
      .getReportePerfil(template, bgColor)
      .then(res => {
        this.contenidoBase64 = `data:application/pdf;base64,${res.data.contenidoBase64}`;
        this.pdfReady = true;
        this.showOverlay = false;
      })
      .catch(() => {
        this.alertService().showError(this, this.$t('perfil-unico.error-descarga').toString());
        this.showOverlay = false;
      });
  }

  /**
   * Genera un documento PDF basado en la plantilla seleccionada y lo descarga.
   *
   * @param item El tipo de plantilla seleccionado, que puede ser COMPLETO, RESUMEN o TARJETA.
   * @param bgColor El color de fondo seleccionado para la plantilla.
   *
   */
  public generarPdf(item, bgColor: BgColor) {
    this.showOverlay = true;
    this.contenidoBase64 = '';
    this.pdfReady = false;
    let template = null;
    switch (item.value) {
      case Template.COMPLETO:
        template = this.TEMPLATE_COMPLETO;
        break;
      case Template.RESUMEN:
        template = this.TEMPLATE_RESUMEN;
        break;
      case Template.TARJETA:
        template = this.TEMPLATE_TARJETA;
        break;
      default:
        template = this.TEMPLATE_COMPLETO;
        break;
    }
    this.miPerfilApi()
      .getReportePerfil(template, bgColor)
      .then(res => {
        this.contenidoBase64 = res.data.contenidoBase64;
        this.descargarPdf(this.contenidoBase64);
        this.showOverlay = false;
      })
      .catch(() => {
        this.showOverlay = false;
        this.alertService().showError(this, this.$t('perfil-unico.error-descarga').toString());
      });
  }

  /**
   * Descarga un archivo PDF en base a un contenido codificado en Base64.
   *
   * @param pdf El contenido del archivo PDF codificado en formato Base64.
   *
   */
  public descargarPdf(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement('a');
    const fileName = this.nombrePdf;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  public showButtons(id) {
    return id !== 1 && id !== 2;
  }

  public showDropdown(id) {
    return id === 1 || id === 2;
  }
}
