import { Component, Inject, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import { numeric, required } from 'vuelidate/lib/validators';
import isEqual from 'lodash.isequal';
import type AlertaComponent from '@/components/alerta/alerta.component';
import CatalogoService from '@/shared/catalogo/catalogo.service';

const VALIDATIONS = function () {
  return {
    nuevaEtapa: {
      etapaDesarrollo: {
        required,
      },
      horasInvertidas: {
        required,
        numeric,
      },
      costo: {
        required,
        numeric,
      },
      fechaInicio: {
        required,
      },
      fechaFin: {
        required,
      },
    },
  };
};

@Component({
  validations: VALIDATIONS,
})
export default class EtapaComponent extends Vue {
  @Inject('catalogoService') private catalogoService: () => CatalogoService;
  @Ref()
  alerta!: AlertaComponent;
  //reactividad retorna valores al padre
  @PropSync('value', { type: Array, default: () => [] })
  public etapas: Array<any>;

  //propiedad para solo vista
  @Prop({ default: false })
  public readonly: boolean;

  // validacion del padre
  @Prop({ default: false })
  public validEtapa: boolean;

  //cantidad de registros por pagina en tabla
  @Prop({ default: 10 })
  public recordsPage: number;

  /**
   *validComponent formulario desde el componente (padre)
   *
   */
  @Watch('validEtapa', { immediate: true, deep: true })
  handler(value: any) {
    if (value != null) this.validEtapaComponent = !value;
  }

  public validEtapaComponent = false; // valida dentro del componente

  public etapaDesarrolloOptions: Array<any> = [];
  public baseOptions: Array<any> = [];
  public removeIndex = null;
  public removeXorden = null;

  //variables paginacion tabla
  public currentPage = 1;
  public sortBy = 'orden';
  public sortDesc = false;
  public sortDirection = 'asc';

  public fieldsEtapa = [
    {
      key: 'etapaDesarrollo.nombre',
      label: this.$t('etapa.table.etapa').toString(),
      class: 'text-center',
      sortable: true,
    },
    {
      key: 'horasInvertidas',
      label: this.$t('etapa.table.horas-hombre').toString(),
      class: 'text-center',
      sortable: true,
    },
    {
      key: 'costo',
      label: this.$t('etapa.table.costo').toString(),
      class: 'text-center',
      sortable: true,
    },
    {
      key: 'fechaInicio',
      label: this.$t('etapa.table.fecha-inicio').toString(),
      class: 'text-center',
      sortable: true,
    },
    {
      key: 'fechaFin',
      label: this.$t('etapa.table.fecha-fin').toString(),
      class: 'text-center',
      sortable: true,
    },
    {
      key: 'acciones',
      label: this.$t('etapa.table.acciones').toString(),
      class: 'text-center',
    },
  ];

  public nuevaEtapa: {
    etapaDesarrollo?: any;
    horasInvertidas?: any;
    costo?: any;
    fechaInicio?: any;
    fechaFin?: any;
  } = {
    etapaDesarrollo: null,
    horasInvertidas: null,
    costo: null,
    fechaInicio: null,
    fechaFin: null,
  };

  mounted() {
    if (this.readonly) this.fieldsEtapa.pop();
    this.initCatalogos();
  }
  public initCatalogos(): void {
    this.catalogoService()
      .get('getCatEtapaDesarrollos')
      .then(result => {
        this.etapaDesarrolloOptions = result;
      });
  }

  //Se crean modelos y se envian al CARD y EMIT
  public agregaEtapa(): void {
    if (!this.$v.nuevaEtapa.$invalid) {
      if (this.validaDuplicados(this.nuevaEtapa)) {
        this.alerta.mostrar(this.$t('etapa.alert.duplicate'), 'warning', 20);
      } else {
        const form = JSON.parse(JSON.stringify(this.nuevaEtapa));
        if (this.removeIndex === null) {
          this.etapas.push(form);
          this.alerta.mostrar(this.$t('etapa.alert.add'), 'success', 20);
        } else {
          this.$set(this.etapas, this.removeIndex, form);
          this.removeIndex = null;
          this.alerta.mostrar(this.$t('etapa.alert.edit'), 'success', 20);
        }
        this.resetForm();
      }
      this.validaArregloEtapa(this.etapas);
    } else {
      this.$v.$touch();
    }
  }

  public validaDuplicados(val) {
    return this.etapas.some(g => isEqual(g, val));
  }
  public prepareAutor(item, index) {
    this.removeIndex = index;
    this.nuevaEtapa = this.shallowCopy(item);
  }

  public eliminaAutor(orden): void {
    const index = this.etapas.findIndex(autor => autor.orden === orden);
    this.etapas.splice(index, 1);
    this.$bvModal.hide('removeAutor');
    this.alerta.mostrar(this.$t('etapa.alert.delete'), 'warning', 20);
    this.validaArregloEtapa(this.etapas);
    const totalPage = Math.ceil(this.etapas.length / this.recordsPage);
    if (this.currentPage > totalPage) this.currentPage = totalPage;
    this.resetForm();
  }

  public prepareRemove(orden: any): void {
    this.removeXorden = orden;
    this.$bvModal.show('removeAutor');
  }

  public remove(): void {
    this.eliminaAutor(this.removeXorden);
  }

  public resetForm() {
    this.nuevaEtapa.etapaDesarrollo = null;
    this.nuevaEtapa.horasInvertidas = null;
    this.nuevaEtapa.costo = null;
    this.nuevaEtapa.fechaInicio = null;
    this.nuevaEtapa.fechaFin = null;
    this.removeIndex = null;
    setTimeout(() => {
      this.$v.nuevaEtapa.$reset();
    }, 1);
  }
  /** Copia las propiedades de un objeto */
  public shallowCopy(source) {
    return JSON.parse(JSON.stringify(source));
  }

  public validaArregloEtapa(aa) {
    return (this.validEtapaComponent = aa.length == 0);
  }

  public numbersOnly(evt) {
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault();
    } else {
      return true;
    }
  }

  public limpiaFechaFin() {
    this.nuevaEtapa.fechaFin = null;
  }
}
