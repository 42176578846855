import * as Entities from '@/shared/perfil-usuario/api';
import EntityFactory from './entity.factory';

export default class EntitySolicitudFactory {
  public static getSolicitud(): Entities.Solicitud {
    return {
      id: null,
      nivelAspira: null,
      tipoPerfil: null,
      consideracionAcademica: null,
      comiteEvaluacion: null,
      fortalecimientoComunidad: null,
      accesoUniversal: null,
      investigacionFrontera: null,
      areaConocimiento: EntityFactory.getAreaConocimiento(),
      tipoSolicitud: null,
      estadoSolicitud: [],
      numeroSolicitud: null,
      fechaCreacion: null,
      documentoSolicitud: null,
      documentoPu: null,
      documentoNacionalidad: EntityFactory.getDocumentoProbatorio(),
      solucionSolicitud: null,
      solicitante: this.getSolicitante(),
      acreditacion: [],
      formacionAcademica: [],
      totalesProduccionCientifica: null,
      totalesProduccionTecnologica: null,
      totalesAccesoUniversal: null,
      totalesFormacionComunidad: null,
      citas: null,
      reporteGlobalCitas: null,
      adeudoSancion: null,
      urlDocumentosProbatorios: null,
      tipoVigenciaActual: null,
    };
  }

  public static getSolicitudDatosEvaluacion(): any {
    return {
      rfc: null,
      curp: null,
      correoPrincipal: null,
      telefonoMovil: null,
      empleoActual: null,
      domicilio: null,
    };
  }

  public static getNivelAspira(): any {
    return [
      { value: { id: '1', nombre: 'Candidato' }, text: 'Candidato' },
      { value: { id: '2', nombre: 'Investigador Nacional Nivel I' }, text: 'Investigador Nacional Nivel I' },
      { value: { id: '3', nombre: 'Investigador Nacional Nivel II' }, text: 'Investigador Nacional Nivel II' },
      { value: { id: '4', nombre: 'Investigador Nacional Nivel III' }, text: 'Investigador Nacional Nivel III' },
    ];
  }

  public static getDefaultTotales(): Entities.TotalesProductoYPrincipal {
    return {
      totalProducto: 0,
      totalProductoPrincipal: 0,
    };
  }

  public static getDefaultTotalProductosPerfil(): Entities.TotalProductos {
    return {
      totalTesis: 0,
      totalTesisPrincipal: 0,
      totalDivulgacion: 0,
      totalCongresos: 0,
      totalDocencia: 0, //cursos
      totalDocenciaPrincipal: 0, //cursos
      totalDiplomados: 0,
      totalDiplomadosPrincipal: 0,
    };
  }

  public static getDefaultTotalesCitas(): any[] {
    return [this.getDefaultTotalCitas('Articulo'), this.getDefaultTotalCitas('Libro'), this.getDefaultTotalCitas('Capitulo')];
  }

  public static getDefaultTotalCitas(categoria: string): any {
    return {
      categoria: categoria,
      totalCitasA: 0,
      totalCitasB: 0,
      totalCitas: 0,
      totalProductos: 0,
    };
  }

  public static getDefaultTCitas(): Entities.TotalesCitas {
    return {
      cantidad: 0,
      citasA: 0,
      citasB: 0,
      totales: 0,
    };
  }

  public static getTotalesProduccionCientifica(): Entities.TotalesProduccionCientifica {
    return {
      articulosCientifica: this.getDefaultTotales(),
      capitulosCientifica: this.getDefaultTotales(),
      librosCientifica: this.getDefaultTotales(),
      total: this.getDefaultTotales(),
    };
  }

  public static getTotalesProduccionTecnologica(): Entities.TotalesProduccionTecnologica {
    return {
      desarrolloTecnologicoInnovacion: this.getDefaultTotales(),
      propiedadIntelectual: this.getDefaultTotales(),
      transferenciaTecnologica: this.getDefaultTotales(),
      total: this.getDefaultTotales(),
    };
  }

  public static getTotalesAccesoUniversal(): Entities.TotalesAccesoUniversal {
    return {
      articulosDifusion: this.getDefaultTotales(),
      librosDifusion: this.getDefaultTotales(),
      capitulosDifusion: this.getDefaultTotales(),
      divulgacion: this.getDefaultTotales(),
      participacionCongresos: this.getDefaultTotales(),
      total: this.getDefaultTotales(),
    };
  }

  public static getTotalesFormacionComunidad(): Entities.TotalesFormacionComunidad {
    return {
      trabajosTitulacion: this.getDefaultTotales(),
      docencia: this.getDefaultTotales(),
      desarrolloInstitucional: this.getDefaultTotales(),
      total: this.getDefaultTotales(),
    };
  }

  public static getCitasT(): Entities.Citas {
    return {
      articulo: this.getDefaultTCitas(),
      libro: this.getDefaultTCitas(),
      capitulo: this.getDefaultTCitas(),
      totalesCitas: this.getDefaultTCitas(),
    };
  }

  public static tipoSolucion() {
    return [
      { value: Entities.TipoSolucion.AYUDANTES },
      { value: Entities.TipoSolucion.BECAS_EXTRANJERAS },
      { value: Entities.TipoSolucion.BECAS_NACIONALES },
      { value: Entities.TipoSolucion.EMERITOS },
      { value: Entities.TipoSolucion.INGRESO_PERMANENCIA_PROMOCION },
      { value: Entities.TipoSolucion.POSGRADO_NACIONAL },
      { value: Entities.TipoSolucion.CUENTAS_BANCARIAS },
      { value: Entities.TipoSolucion.ACREDITACIONES },
    ];
  }

  public static estadoSolicitudEnum() {
    return {
      creada: Entities.EstadoSolicitud.CREADA,
      enviada: Entities.EstadoSolicitud.ENVIADA,
      cancelada: Entities.EstadoSolicitud.CANCELADA,
      EN_CAPTURA: Entities.EstadoSolicitud.EN_CAPTURA,
      ASIGNADA: Entities.EstadoSolicitud.ASIGNADA,
      EN_ACTUALIZACION: Entities.EstadoSolicitud.EN_ACTUALIZACION,
      REVISADA: Entities.EstadoSolicitud.REVISADA,
      APROBADA: Entities.EstadoSolicitud.APROBADA,
      RECHAZADA: Entities.EstadoSolicitud.RECHAZADA,
      BAJA: Entities.EstadoSolicitud.BAJA,
      RECLASIFICADA: Entities.EstadoSolicitud.RECLASIFICADA,
      FORMALIZADA: Entities.EstadoSolicitud.FORMALIZADA,
      OPERACION_EN_PROCESO: Entities.EstadoSolicitud.OPERACION_EN_PROCESO,
      TERMINADO: Entities.EstadoSolicitud.TERMINADO,
      IMPROCEDENTE: Entities.EstadoSolicitud.IMPROCEDENTE,
      VALIDADA: Entities.EstadoSolicitud.VALIDADA,
      EN_CORRECCION: Entities.EstadoSolicitud.EN_CORRECCION,
      EN_FIRMA: Entities.EstadoSolicitud.EN_FIRMA,
      DICTAMINADA: Entities.EstadoSolicitud.DICTAMINADA,
      PREASIGNADA: Entities.EstadoSolicitud.PREASIGNADA,
      FINALIZADA: Entities.EstadoSolicitud.FINALIZADA,
    };
  }

  public static getSolicitante() {
    return {
      rfc: null,
      curp: null,
      correo: null,
      cvu: null,
      nombre: null,
      primerApellido: null,
      segundoApellido: null,
      edad: null,
      sexo: null,
      entidadNacimiento: null,
      nacionalidad: null,
    };
  }

  public static getSolicitanteBecas(): Entities.Solicitante {
    return {
      rfc: null,
      curp: null,
      correo: null,
      cvu: null,
      nombre: null,
      primerApellido: null,
      segundoApellido: null,
      edad: null,
      sexo: null,
      nacionalidad: null,
      domicilio: EntityFactory.getDomicilio(),
      mediosContacto: [],
      lugarNacimiento: null,
      paisNacimiento: null,
      fechaNacimiento: null,
      estadoCivil: null,
      idiomas: [],
      gradoAcademico: EntityFactory.getGradoAcademico(),
    };
  }

  public static getTipoPerfil(): any {
    return [
      {
        value: { id: 'a', nombre: 'Perfil con énfasis en investigación de ciencia básica y de frontera' },
        text: 'Perfil con énfasis en investigación de ciencia básica y de frontera',
      },
      {
        value: { id: 'b', nombre: 'Perfil con énfasis en investigación para la incidencia socioambiental' },
        text: 'Perfil con énfasis en investigación para la incidencia socioambiental',
      },
      {
        value: { id: 'c', nombre: 'Perfil con énfasis en desarrollo tecnológico e innovación' },
        text: 'Perfil con énfasis en desarrollo tecnológico e innovación',
      },
    ];
  }

  public static getSolicitudCancelada(): Entities.SolicitudCancelada {
    return {
      id: null,
      idSolicitud: null,
      comentario: null,
      documentoProbatorio: null,
      estadoSolicitud: null,
    };
  }

  public static tipoSolicitudEnum() {
    return { ingreso: Entities.TipoSolicitud.INGRESO, permanencia: Entities.TipoSolicitud.PERMANENCIA };
  }

  //BECAS
  public static getSolicitudBecas(): Entities.Solicitud {
    return {
      solucionSolicitud: null,
      periodo: this.getPeriodosBecas(),
      dependientesEconomicos: [],
      escolaridadFamiliar: [],
      contactosEmergencia: [],
      solicitante: this.getSolicitanteBecas(),
      programa: this.getParticipacionBrief(),
      fechaActualizacionPrograma: null,
      servicioIssste: null,
    };
  }

  public static getPeriodosBecas(): Entities.PeriodoBeca {
    return {
      fechaInicioEstudios: null,
      fechaFinEstudios: null,
      fechaInicioBeca: null,
      fechaFinBeca: null,
      duracionBeca: null,
      duracionEstudios: null,
      periodicidad: null,
      rangoFechasBecaEditado: null,
    };
  }

  public static getPeriodosFechasBecas() {
    return {
      fechaInicioBeca: null,
      fechaFinBeca: null,
    };
  }

  public static getParticipacionBrief(): Entities.ParticipacionBrief {
    return {
      id: null,
      solicitudId: null,
      contenedorId: null,
      rol: null,
      participante: this.getParticipante(),
    };
  }

  public static getParticipante() {
    return {
      cvu: null,
      login: null,
      nombre: null,
      apellidoPaterno: null,
      apellidoMaterno: null,
      curp: null,
      rfc: null,
      genero: null,
      correo: null,
      nacionalidad: null,
      nacionalidadCodigo: null,
      inicioEstudios: null,
      obtencionGrado: null,
      reincorporacion: null,
      finEstudios: null,
      tiempoDedicacion: null,
      promedioAnterior: null,
      programa: this.getPrograma(),
      institucion: this.getInstitucionPrograma(),
      sede: this.getSede(),
    };
  }

  public static getPrograma() {
    return {
      id: null,
      clave: null,
      nombre: null,
      tipo: null,
      cveGrado: null,
      periodoLectivo: null,
      duracionOficial: null,
      totalPeriodosLectivos: null,
      fechaTerminoVigencia: null,
      caracteristica: null,
      orientacion: null,
      modalidad: null,
      areasConocimiento: [],
    };
  }

  public static getInstitucionPrograma(): Entities.InstitucionPrograma {
    return {
      claveInstitucion: null,
      nombreInstitucion: null,
    };
  }

  public static getSede(): Entities.Sede {
    return {
      claveSede: null,
      sede: null,
      nombreDependencia: null,
    };
  }

  public static estadoAjusteBecaEnum() {
    return {
      vigenciaBeca: Entities.EstadoAjusteBeca.VIGENCIA_BECA,
      fueraPeriodoEnvio: Entities.EstadoAjusteBeca.FUERA_PERIODO_ENVIO,
      programaNoElegible: Entities.EstadoAjusteBeca.PROGRAMA_NO_ELEGIBLE,
      programaIncorrecto: Entities.EstadoAjusteBeca.PROGRAMA_INCORRECTO,
    };
  }
}
