import EntitySolicitudFactory from '@/shared/entity-commons/entity-solicitud.factory';

export default function resolveBadgeVariant(estado) {
  switch (estado) {
    case EntitySolicitudFactory.estadoSolicitudEnum().EN_CAPTURA:
      return 'primary';
    case EntitySolicitudFactory.estadoSolicitudEnum().enviada:
      return 'success';
    case EntitySolicitudFactory.estadoSolicitudEnum().cancelada:
      return 'danger';
    case EntitySolicitudFactory.estadoSolicitudEnum().REVISADA:
      return 'info';
    case EntitySolicitudFactory.estadoSolicitudEnum().APROBADA:
      return 'success';
    case EntitySolicitudFactory.estadoSolicitudEnum().RECHAZADA:
      return 'danger';
    case EntitySolicitudFactory.estadoSolicitudEnum().BAJA:
      return 'secondary';
    case EntitySolicitudFactory.estadoSolicitudEnum().RECLASIFICADA:
      return 'success';
    case EntitySolicitudFactory.estadoSolicitudEnum().FORMALIZADA:
      return 'warning';
    case EntitySolicitudFactory.estadoSolicitudEnum().OPERACION_EN_PROCESO:
      return 'dark';
    case EntitySolicitudFactory.estadoSolicitudEnum().TERMINADO:
      return 'success';
    default:
      return 'primary';
  }
}
