import axios, { AxiosPromise } from 'axios';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class SocialService extends Vue {
  public timeline(): AxiosPromise<any> {
    return axios.get('https://social.crip.conacyt.mx/api/v1/timelines/public');
  }
}
