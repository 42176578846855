import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const MiPerfil = () => import('@/entities/msPerfil/acerca-de-mi/mi-perfil/mi-perfil.vue');

// prettier-ignore
const Grupos = () => import('@/entities/msPerfil/acerca-de-mi/grupos/grupos.vue');

// prettier-ignore
const TrayectoriaAcademica = () => import('@/entities/msPerfil/educacion/trayectoria-academica/trayectoria-academica.vue');

// prettier-ignore
const Cursos = () => import('@/entities/msPerfil/educacion/cursos/cursos.vue');

// prettier-ignore
const Logros = () => import('@/entities/msPerfil/educacion/logros/logros.vue');

// prettier-ignore
const Idiomas = () => import('@/entities/msPerfil/educacion/idiomas/idiomas.vue');

// prettier-ignore
const TrayectoriaProfesional = () => import('@/entities/msPerfil/experiencia/trayectoria-profesional/trayectoria-profesional.vue');

// prettier-ignore
const EvaluacionesOtorgadas = () => import('@/entities/msPerfil/experiencia/evaluaciones-otorgadas/evaluaciones-otorgadas.vue');

// prettier-ignore
const Estancias = () => import('@/entities/msPerfil/experiencia/estancias/estancias.vue');

// prettier-ignore
const CursosImpartidos = () => import('@/entities/msPerfil/experiencia/formacion/cursos-impartidos/cursos-impartidos.vue');

// prettier-ignore
const Tesis = () => import('@/entities/msPerfil/experiencia/formacion/tesis/tesis.vue');

// prettier-ignore
const Diplomados = () => import('@/entities/msPerfil/experiencia/formacion/diplomados/diplomados.vue');

// prettier-ignore
const Congresos = () => import('@/entities/msPerfil/experiencia/formacion/congresos/congresos.vue');

// prettier-ignore
const EniGemaSisdai = () => import('@/entities/msPerfil/experiencia/eni-gema-sisdai/eni-gema-sisdai.vue');

// prettier-ignore
const RegistroUsuariosExternos = () => import('@/entities/msPerfil/registro-usuarios-externos/nuevo-usuario-externo.vue');

// prettier-ignore
const Divulgacion = () => import('@/entities/msPerfil/experiencia/formacion/divulgacion/divulgacion.vue');

// prettier-ignore
const Articulos = () => import('@/entities/msPerfil/aportaciones/articulos/articulos.vue');

// prettier-ignore
const Libros = () => import('@/entities/msPerfil/aportaciones/libros/libros.vue');

// prettier-ignore
const Capitulos = () => import('@/entities/msPerfil/aportaciones/capitulos/capitulos.vue');

// prettier-ignore
const DesarrolloInstitucional = () => import('@/entities/msPerfil/educacion/desarrollo-institucional/desarrollo-institucional.vue');

// prettier-ignore
const DesarrollosTecnologicosInnovaciones = () => import('@/entities/msPerfil/aportaciones/desarrollos-tecnologicos-innovaciones/desarrollos-tecnologicos-innovaciones.vue');

// prettier-ignore
const PropiedadesIntelectuales = () => import('@/entities/msPerfil/aportaciones/propiedades-intelectuales/propiedades-intelectuales.vue');

// prettier-ignore
const TransferenciasTecnologicas = () => import('@/entities/msPerfil/aportaciones/transferencias-tecnologicas/transferencias-tecnologicas.vue');
const Solicitudes = () => import('@/entities/msPerfil/solicitudes/solicitudes.vue');
const DatosEvaluar = () => import('@/entities/msPerfil/solicitudes/datos-evaluacion/datos-evaluacion.vue');
const DatosEvaluarEmeritos = () => import('@/entities/msPerfil/solicitudes/2023/emeritos/datos-evaluacion/datos-evaluacion.vue');
const DatosEvaluarBecasNacionales = () => import('@/entities/msPerfil/solicitudes/becas-nacionales/datos-evaluacion/datos-evaluacion.vue');
const DatosEvaluarBecasExtCuba = () => import('@/entities/msPerfil/solicitudes/becas-extranjero/cuba/datos-evaluacion.vue');
const BecasExtranjeroComponent = () => import('@/entities/msPerfil/solicitudes/becas-extranjero/becas-extranjero.vue');
// prettier-ignore
const CambioEmail = () => import('@/entities/msPerfil/cuenta-usuario/cambio-email/cambio-email.vue');
// prettier-ignore
const CambioPassword = () => import('@/entities/msPerfil/cuenta-usuario/cambio-password/cambio-password.vue');

const CorreoAlternativo = () => import('@/entities/msPerfil/cuenta-usuario/correo-alternativo/correo-alternativo.vue');

const PdfPuComponent = () => import('@/entities/msPerfil/pdf-pu/pdf-pu.vue');

const CompartirinformacionComponent = () => import('@/entities/msPerfil/compartir-informacion/compartir-informacion.vue');

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: '/acerca-de-mi/mi-perfil',
      name: 'MiPerfil',
      component: MiPerfil,
      meta: {
        breadcrumb: [{ name: 'home', link: '/' }, { name: 'about' }, { name: 'profile' }],
      },
    },

    {
      path: '/acerca-de-mi/grupos',
      name: 'Grupos',
      component: Grupos,
      meta: {
        breadcrumb: [{ name: 'home', link: '/' }, { name: 'about' }, { name: 'sociodemographic' }],
      },
    },
    {
      path: '/educacion/trayectoria-academica',
      name: 'TrayectoriaAcademica',
      component: TrayectoriaAcademica,
      meta: {
        breadcrumb: [{ name: 'home', link: '/' }, { name: 'education' }, { name: 'academic-background' }],
      },
    },
    {
      path: '/educacion/cursos',
      name: 'Cursos',
      component: Cursos,
      meta: {
        breadcrumb: [{ name: 'home', link: '/' }, { name: 'education' }, { name: 'courses' }],
      },
    },
    {
      path: '/educacion/logros',
      name: 'Logros',
      component: Logros,
      meta: {
        breadcrumb: [{ name: 'home', link: '/' }, { name: 'education' }, { name: 'achievements' }],
      },
    },
    {
      path: '/educacion/idiomas',
      name: 'Idiomas',
      component: Idiomas,
      meta: {
        breadcrumb: [{ name: 'home', link: '/' }, { name: 'education' }, { name: 'languages' }],
      },
    },
    {
      path: '/trayectoria-profesional/empleo',
      name: 'TrayectoriaProfesional',
      component: TrayectoriaProfesional,
      meta: {
        breadcrumb: [{ name: 'home', link: '/' }, { name: 'career-path' }, { name: 'job' }],
      },
    },
    {
      path: '/trayectoria-profesional/evaluaciones',
      name: 'EvaluacionesOtorgadas',
      component: EvaluacionesOtorgadas,
      meta: {
        breadcrumb: [{ name: 'home', link: '/' }, { name: 'career-path' }, { name: 'evaluations' }],
      },
    },
    {
      path: '/trayectoria-profesional/estancias-investigacion',
      name: 'Estancias',
      component: Estancias,
      meta: {
        breadcrumb: [{ name: 'home', link: '/' }, { name: 'career-path' }, { name: 'research' }],
      },
    },
    {
      path: '/trayectoria-profesional/eni-gema-sisdai',
      name: 'EniGemaSisdai',
      component: EniGemaSisdai,
      meta: {
        breadcrumb: [{ name: 'home', link: '/' }, { name: 'career-path' }, { name: 'eni-gema-sisdai' }],
      },
    },
    {
      path: '/registro-usuarios-externos',
      name: 'RegistroUsuariosExternos',
      component: RegistroUsuariosExternos,
      meta: { authorities: [Authority.INTERNAL_USER_API_RIZOMA] },
    },
    {
      path: '/trayectoria-profesional/formacion-comunidad/docencia/cursos-impartidos',
      name: 'CursosImpartidos',
      component: CursosImpartidos,
      meta: {
        breadcrumb: [
          { name: 'home', link: '/' },
          { name: 'career-path' },
          { name: 'hcti' },
          { name: 'teaching' },
          { name: 'courses-taught' },
        ],
      },
    },
    {
      path: '/trayectoria-profesional/formacion-comunidad/trabajos-titulacion',
      name: 'Tesis',
      component: Tesis,
      meta: {
        breadcrumb: [{ name: 'home', link: '/' }, { name: 'career-path' }, { name: 'hcti' }, { name: 'degree-work' }],
      },
    },
    {
      path: '/trayectoria-profesional/formacion-comunidad/docencia/diplomados-impartidos',
      name: 'Diplomados',
      component: Diplomados,
      meta: {
        breadcrumb: [{ name: 'home', link: '/' }, { name: 'career-path' }, { name: 'hcti' }, { name: 'teaching' }, { name: 'diploma' }],
      },
    },
    {
      path: '/trayectoria-profesional/acceso-universal/participacion-congresos',
      name: 'Congresos',
      component: Congresos,
      meta: {
        breadcrumb: [{ name: 'home', link: '/' }, { name: 'career-path' }, { name: 'universal-knowledge' }, { name: 'congress' }],
      },
    },
    {
      path: '/trayectoria-profesional/acceso-universal/divulgacion',
      name: 'Divulgacion',
      component: Divulgacion,
      meta: {
        breadcrumb: [{ name: 'home', link: '/' }, { name: 'career-path' }, { name: 'universal-knowledge' }, { name: 'disclosure' }],
      },
    },
    {
      path: '/trayectoria-profesional/produccion/cientifica-humanistica/articulos',
      name: 'CIENTIFICA-Articulos',
      component: Articulos,
      meta: {
        breadcrumb: [
          { name: 'home', link: '/' },
          { name: 'career-path' },
          { name: 'production' },
          { name: 'scientist' },
          { name: 'articles' },
        ],
      },
    },
    {
      path: '/trayectoria-profesional/produccion/cientifica-humanistica/libros',
      name: 'CIENTIFICA-Libros',
      component: Libros,
      meta: {
        breadcrumb: [
          { name: 'home', link: '/' },
          { name: 'career-path' },
          { name: 'production' },
          { name: 'scientist' },
          { name: 'books' },
        ],
      },
    },
    {
      path: '/trayectoria-profesional/produccion/cientifica-humanistica/capitulos',
      name: 'CIENTIFICA-Capitulos',
      component: Capitulos,
      meta: {
        breadcrumb: [
          { name: 'home', link: '/' },
          { name: 'career-path' },
          { name: 'production' },
          { name: 'scientist' },
          { name: 'chapters' },
        ],
      },
    },
    {
      path: '/trayectoria-profesional/acceso-universal-conocimiento/difusion/articulos',
      name: 'DIFUSION-Articulos',
      component: Articulos,
      meta: {
        breadcrumb: [
          { name: 'home', link: '/' },
          { name: 'career-path' },
          { name: 'universal-knowledge' },
          { name: 'spreading' },
          { name: 'articles' },
        ],
      },
    },
    {
      path: '/trayectoria-profesional/acceso-universal-conocimiento/difusion/libros',
      name: 'DIFUSION-Libros',
      component: Libros,
      meta: {
        breadcrumb: [
          { name: 'home', link: '/' },
          { name: 'career-path' },
          { name: 'universal-knowledge' },
          { name: 'spreading' },
          { name: 'books' },
        ],
      },
    },
    {
      path: '/trayectoria-profesional/acceso-universal-conocimiento/difusion/capitulos',
      name: 'DIFUSION-Capitulos',
      component: Capitulos,
      meta: {
        breadcrumb: [
          { name: 'home', link: '/' },
          { name: 'career-path' },
          { name: 'universal-knowledge' },
          { name: 'spreading' },
          { name: 'chapters' },
        ],
      },
    },
    {
      path: '/educacion/desarrollo-institucional',
      name: 'DesarrolloInstitucional',
      component: DesarrolloInstitucional,
      meta: {
        breadcrumb: [{ name: 'home', link: '/' }, { name: 'career-path' }, { name: 'hcti' }, { name: 'institutional-development' }],
      },
    },
    {
      path: '/aportaciones/desarrollos-tecnologicos-innovaciones',
      name: 'DesarrollosTecnologicosInnovaciones',
      component: DesarrollosTecnologicosInnovaciones,
      meta: {
        breadcrumb: [
          { name: 'home', link: '/' },
          { name: 'career-path' },
          { name: 'production' },
          { name: 'technological' },
          { name: 'technological-development' },
        ],
      },
    },
    {
      path: '/aportaciones/propiedades-intelectuales',
      name: 'PropiedadesIntelectuales',
      component: PropiedadesIntelectuales,
      meta: {
        breadcrumb: [
          { name: 'home', link: '/' },
          { name: 'career-path' },
          { name: 'production' },
          { name: 'technological' },
          { name: 'intellectual-property' },
        ],
      },
    },
    {
      path: '/aportaciones/transferencias-tecnologicas',
      name: 'TransferenciasTecnologicas',
      component: TransferenciasTecnologicas,
      meta: {
        breadcrumb: [
          { name: 'home', link: '/' },
          { name: 'career-path' },
          { name: 'production' },
          { name: 'technological' },
          { name: 'technology-transfer' },
        ],
      },
    },
    {
      path: '/solicitudes',
      name: 'Solicitudes',
      component: Solicitudes,
    },
    {
      path: '/registro-solicitud/datos-evaluacion',
      name: 'DatosEvaluarIngreso',
      component: DatosEvaluar,
    },
    {
      path: '/registro-solicitud/datos-evaluacion/:idSolicitud/edicion',
      name: 'DatosEvaluarIngresoEdit',
      component: DatosEvaluar,
    },
    {
      path: '/registro-solicitud/2023/emeritos/datos-evaluacion',
      name: 'DatosEvaluarEmerito',
      component: DatosEvaluarEmeritos,
    },
    {
      path: '/registro-solicitud/2023/emeritos/datos-evaluacion/:idSolicitud/edicion',
      name: 'DatosEvaluarEmeritosEdit',
      component: DatosEvaluarEmeritos,
    },
    {
      path: '/registro-solicitud/becas-nacionales/datos-evaluacion',
      name: 'DatosEvaluarBecasNacionales',
      component: DatosEvaluarBecasNacionales,
    },
    {
      path: '/registro-solicitud/becas-nacionales/datos-evaluacion/:idSolicitud/edicion',
      name: 'DatosEvaluarBecasNacionalesEdit',
      component: DatosEvaluarBecasNacionales,
    },
    {
      path: '/registro-solicitud/becas-extranjero/cuba/datos-evaluacion',
      name: 'DatosEvaluarBecasExtCuba',
      component: DatosEvaluarBecasExtCuba,
    },
    {
      path: '/registro-solicitud/becas-extranjero/cuba/datos-evaluacion/:idSolicitud/edicion',
      name: 'DatosEvaluarBecasExtCubaEdit',
      component: DatosEvaluarBecasExtCuba,
    },
    {
      path: '/registro-solicitud/becas-extranjero/:beca',
      name: 'BecasExtranjero',
      component: BecasExtranjeroComponent,
    },
    {
      path: '/registro-solicitud/becas-extranjero/:beca/:idSolicitud',
      name: 'BecasExtranjeroEdit',
      component: BecasExtranjeroComponent,
    },
    {
      path: '/cuenta/cambio-email',
      name: 'CambioEmail',
      component: CambioEmail,
      meta: {
        breadcrumb: [{ name: 'home', link: '/' }, { name: 'account' }, { name: 'email-change' }],
      },
    },
    {
      path: '/cuenta/cambio-password',
      name: 'CambioPassword',
      component: CambioPassword,
      meta: {
        breadcrumb: [{ name: 'home', link: '/' }, { name: 'account' }, { name: 'password-change' }],
      },
    },
    {
      path: '/compartir-informacion/',
      name: 'CompartirInformacion',
      component: CompartirinformacionComponent,
    },
    {
      path: '/cuenta/correo-alternativo',
      name: 'CorreoAlternativo',
      component: CorreoAlternativo,
      meta: {
        breadcrumb: [{ name: 'home', link: '/' }, { name: 'account' }, { name: 'alternative-email' }],
      },
    },
    {
      path: '/pdf-pu/:cvu',
      name: 'PdfPu',
      component: PdfPuComponent,
      meta: {},
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
