var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.mensaje
        ? _c(
            "b-alert",
            {
              attrs: {
                show: _vm.mensaje.duracion,
                dismissible: _vm.mensaje.descartable,
                variant: _vm.mensaje.tipo,
              },
              on: {
                dismissed: function ($event) {
                  _vm.mensaje.duracion = 0
                },
                "dismiss-count-down": function ($event) {
                  return _vm.cambioCuentaRegresiva($event)
                },
              },
            },
            [_c("span", { domProps: { innerHTML: _vm._s(_vm.mensaje.texto) } })]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }