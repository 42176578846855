import * as Entities from '@/shared/perfil-usuario/api';
import * as EntitiesAportaciones from '@/shared/aportaciones/api';

export default class EntityFactory {
  public static getDefaultCatalogo(): any {
    return {
      id: null,
      nombre: '',
    };
  }

  public static getDefaultCatalogoClave(): any {
    return {
      id: null,
      clave: null,
      nombre: '',
    };
  }

  public static getDefaultCatalogoClaveInstitucion(): any {
    return {
      //id: diez-mil para institución - manual e ingManInst para ingresoManualInstitucion
      id: 10000,
      clave: 'ingManInst',
      nombre: '',
    };
  }

  public static getDefaultPublicaPrivada(): any {
    return {
      id: null,
      nombre: '',
      id2: null,
      nombre2: '',
    };
  }

  public static getNacionalExtranjeroOptions(translateFunction: (key: string) => string): any {
    return [
      { value: Entities.TipoUbicacion.NACIONAL, text: translateFunction('domicilio.fields.nacionalExtranjero.location.mexico') },
      { value: Entities.TipoUbicacion.EXTRANJERA, text: translateFunction('domicilio.fields.nacionalExtranjero.location.abroad') },
    ];
  }

  public static getBooleanOptions(translateFunction: (key: string) => string): any {
    return [
      { value: { id: 'false', nombre: 'No' }, text: translateFunction('forms.boolean.no') },
      { value: { id: 'true', nombre: 'Si' }, text: translateFunction('forms.boolean.si') },
    ];
  }

  public static getRawBooleanOptions(translateFunction: (key: string) => string): any {
    return [
      { value: false, text: translateFunction('forms.boolean.no') },
      { value: true, text: translateFunction('forms.boolean.si') },
    ];
  }

  public static getEsExtranjeroBooleanOptions(translateFunction: (key: string) => string): any {
    return [
      { value: false, text: translateFunction('domicilio.fields.nacionalExtranjero.nationality.mexicana') },
      { value: true, text: translateFunction('domicilio.fields.nacionalExtranjero.nationality.extranjera') },
    ];
  }

  public static getTipoDomicilioOptions(translateFunction: (key: string) => string): any {
    return [
      { value: Entities.TipoDomicilio.URBANO, text: translateFunction('forms.domicilio.urbano') },
      { value: Entities.TipoDomicilio.RURAL, text: translateFunction('forms.domicilio.rural') },
    ];
  }

  public static getTipoInstitucionOptions(translateFunction: (key: string) => string): any {
    return [
      { value: { id: '1', nombre: 'Pública' }, text: translateFunction('forms.institucion.publica') },
      { value: { id: '2', nombre: 'Privada' }, text: translateFunction('forms.institucion.privada') },
      { value: { id: '3', nombre: 'Ambas' }, text: translateFunction('forms.institucion.ambas') },
    ];
  }

  public static getTipoEntidadOptions(translateFunction: (key: string) => string): any {
    return [
      { value: Entities.TipoEntidad.CONACYT, text: translateFunction('forms.entidad.conahcyt') },
      { value: Entities.TipoEntidad.OTROS, text: translateFunction('forms.entidad.otros') },
    ];
  }

  public static getTipoProgramaOptions(translateFunction: (key: string) => string): any {
    return [
      { value: Entities.TipoPrograma.SNP, text: translateFunction('forms.programa.snp') },
      { value: Entities.TipoPrograma.NO_SNP, text: translateFunction('forms.programa.no-snp') },
    ];
  }

  public static getTipoFormacion(translateFunction: (key: string) => string): any {
    return [
      { value: Entities.TipoFormacionContinua.CURSO, text: translateFunction('forms.formacion.curso') },
      { value: Entities.TipoFormacionContinua.CERTIFICACION_MEDICA, text: translateFunction('forms.formacion.certificacion') },
      { value: Entities.TipoFormacionContinua.RECERTIFICACION_MEDICA, text: translateFunction('forms.formacion.recertificacion') },
    ];
  }

  public static getTipoMaterial(translateFunction: (key: string) => string): any {
    return [
      { value: Entities.TipoMaterial.DIDACTICO, text: translateFunction('forms.material.didactico') },
      { value: Entities.TipoMaterial.APOYO, text: translateFunction('forms.material.apoyo') },
    ];
  }

  public static getTipoPublicaciones(translateFunction: (key: string) => string): any {
    return [
      { value: true, text: translateFunction('forms.publicacion.arbitrada') },
      { value: false, text: translateFunction('forms.publicacion.no-arbitrada') },
    ];
  }

  public static getTipoInnovacion(translateFunction: (key: string) => string): any {
    return [
      { value: Entities.TipoInnovacion.DISRUPTIVA, text: translateFunction('forms.innovacion.disruptiva') },
      { value: Entities.TipoInnovacion.INCREMENTAL, text: translateFunction('forms.innovacion.incremental') },
    ];
  }

  public static getTipoPublicaPrivada(translateFunction: (key: string) => string): any {
    return [
      { value: { id: '01', nombre: 'Pública', id2: '01', nombre2: 'Federal' }, text: translateFunction('forms.publica-privada.federal') },
      { value: { id: '01', nombre: 'Pública', id2: '02', nombre2: 'Estatal' }, text: translateFunction('forms.publica-privada.estatal') },
      {
        value: { id: '01', nombre: 'Pública', id2: '03', nombre2: 'Municipal' },
        text: translateFunction('forms.publica-privada.municipal'),
      },
      { value: { id: '02', nombre: 'Privada', id2: '', nombre2: '' }, text: translateFunction('forms.publica-privada.privada') },
    ];
  }

  public static getDemo(): any {
    return {
      nombre: 'defaul',
      tipoPerfil: this.getDefaultCatalogo(),
      preguntas: [],
    };
  }

  public static getCatalogoExtendido(): any {
    return {
      id: null,
      nombre: null,
      clave: null,
      version: null,
    };
  }

  public static getAreaModelo(): any {
    return {
      id: null,
      nombre: null,
      clave: null,
    };
  }

  public static getAreaConocimiento(): any {
    return {
      area: null,
      campo: null,
      disciplina: null,
      subdisciplina: null,
    };
  }

  public static getDomicilio(): Entities.Domicilio {
    return {
      tipoUbicacion: Entities.TipoUbicacion.NACIONAL,
      codigoPostal: null,
      estado: null,
      municipio: null,
      localidad: null,
      asentamiento: null,
      tipoAsentamiento: null,
      compartir: false,
      tipoDomicilio: Entities.TipoDomicilio.URBANO,
      tipoVialidadRural: null,
      tipoVialidadUrbano: null,
      nombreVialidad: null,
      exteriorAnterior: null,
      exteriorNumerico: null,
      exteriorAlfanumerico: null,
      interiorNumerico: null,
      interiorAlfanumerico: null,
      requiereDetalle: false,
      tipoReferencia01: null,
      nombreReferencia01: null,
      tipoReferencia02: null,
      nombreReferencia02: null,
      tipoReferencia03: null,
      nombreReferencia03: null,
      descripcion: null,
      administracion: null,
      derechoTransito: null,
      codigo: null,
      origenTramo: null,
      destinoTramo: null,
      cadenamiento: null,
      margen: null,
      pais: null,
      cpExtranjero: null,
      estadoExtranjero: null,
      ciudadExtranjero: null,
      condadoExtranjero: null,
      calleExtranjero: null,
      numeroExtranjero: null,
    };
  }

  public static getMiPerfil(): Entities.MiPerfil {
    return {
      fotografia: undefined,
      semblanza: null,
      linkedin: null,
      mediosContacto: [],
      intereses: [],
      habilidades: [],
      areaConocimiento: this.getAreaConocimiento(),
      orcId: null,
      researcherIdThomson: null,
      arXivAuthorId: null,
      pubMedAuthorId: null,
      openId: null,
      curp: null,
      nombre: null,
      primerApellido: null,
      segundoApellido: null,
      fechaNacimiento: null,
      sexo: null,
      paisNacimiento: null,
      entidadFederativa: null,
      rfc: null,
      estadoCivil: null,
      nacionalidad: null,
      domicilio: this.getDomicilio(),
      documentoExtranjero: undefined,
      contactosEmergencia: [],
      dependientesEconomicos: [],
    };
  }

  public static setModelGrupos(): any {
    return {
      valor: {
        id: '',
        nombre: '',
      },
      compartir: false,
    };
  }

  public static getGrupos(): Entities.Grupos {
    return {
      disDif: this.setModelGrupos(),
      disCam: this.setModelGrupos(),
      disVer: this.setModelGrupos(),
      disMov: this.setModelGrupos(),
      disApr: this.setModelGrupos(),
      disOir: this.setModelGrupos(),
      disHab: this.setModelGrupos(),
      disBan: this.setModelGrupos(),
      disRea: this.setModelGrupos(),
      perteCost: this.setModelGrupos(),
      pertePue: null,
      autodenominacion: null,
      perteUste: this.setModelGrupos(),
      perteAnte: this.setModelGrupos(),
      madreFamilia: this.setModelGrupos(),
      formacionBasica: this.setModelGrupos(),
      formacionSecundaria: this.setModelGrupos(),
      formacionMedia: this.setModelGrupos(),
      formacionTecnica: this.setModelGrupos(),
    };
  }

  public static getDesarrolloTecnologicoInnovacion(): EntitiesAportaciones.DesarrolloTecnologicoInnovacion {
    return {
      producto: { id: '1', nombre: 'Desarrollo tecnologico' },
      nombre: null,
      tipoDesarrollo: null,
      descripcionDesarrollo: null,
      objetivosDesarollo: null,
      problemaAtiende: null,
      numeroPersonas: null,
      justificacionBeneficiarios: null,
      generacionValorImpacto: null,
      descripcionInnovacion: null,
      scian: this.getScian(),
      ocde: this.getOcde(),
      recibioApoyoConacyt: false,
      fondoPrograma: null,
      descripcionApoyo: null,
      tituloProyectoApoyado: null,
      numeroProyectoApoyado: null,
      nivelMadurez: null,
      otrosResultados: null,
      rolParticipacion: null,
      otroRolParticipacion: null,
      descripcionActividades: null,
      periodoParticipacionInicio: null,
      periodoParticipacionFin: null,
      principalesResultados: null,
      actoresArticulados: [],
      eje: null,
      apoyo: this.getApoyoConacyt(),
      productoPrincipal: null,
      documento: null,
    };
  }

  public static getPropiedadIntelectual(): EntitiesAportaciones.PropiedadIntelectual {
    return {
      tipoProteccion: null,
      otraProteccion: null,
      titulo: null,
      resumen: null,
      solicitantes: [],
      rolParticipacion: null,
      otroRolParticipacion: null,
      numeroSolicitudMexico: null,
      fechaSolicitudMexico: null,
      solicitudInternacional: false,
      numerosSolicitudInternacional: null,
      numeroPublicacionInternacional: null,
      estado: null,
      eje: null,
      apoyo: this.getApoyoConacyt(),
      productoPrincipal: null,
      documento: null,
    };
  }

  public static getTransferenciaTecnologica(): EntitiesAportaciones.TransferenciaTecnologica {
    return {
      tipoActividad: null,
      otraActividad: null,
      descripcionActividad: null,
      rolParticipacion: null,
      otroRolParticipacion: null,
      descripcionActividades: null,
      periodoParticipacionInicio: null,
      periodoParticipacionFin: null,
      principalesResultados: null,
      actoresArticulados: [],
      eje: null,
      apoyo: this.getApoyoConacyt(),
      institucionReceptora: this.getInstitucionModel(),
      productoPrincipal: null,
      documento: null,
    };
  }

  public static getDesarrolloInstitucional(): Entities.DesarrolloInstitucional {
    return {
      participacionProyectos: null,
      participacionPlanes: null,
      eje: null,
      documentoProbatorio: null,
    };
  }

  public static getGradoAcademico(): Entities.GradoAcademico {
    return {
      nivelEscolaridad: null,
      titulo: null,
      estatus: null,
      cedulaProfesional: null,
      opcionTitulacion: null,
      tituloTesis: null,
      fechaObtencion: null,
      institucion: this.getInstitucionModel(),
      documento: null,
    };
  }

  public static getCurso(): Entities.Curso {
    return {
      formacion: null,
      nombre: null,
      anio: null,
      horasTotales: null,
      institucion: this.getInstitucionModel(),
    };
  }

  public static getCertificacionMedica(): Entities.CertificacionMedica {
    return {
      tipo: null,
      nombre: null,
      folio: null,
      certificador: null,
      especialidad: null,
      inicioVigencia: null,
      finVigencia: null,
    };
  }

  public static getLogro(): Entities.Logro {
    return {
      tipo: Entities.TipoEntidad.CONACYT,
      nombre: null,
      anio: null,
      distincion: null,
      institucion: this.getInstitucionModel(),
      pais: null,
      descripcion: null,
    };
  }

  public static getLogroConacyt(): Entities.Logro {
    return {
      tipo: Entities.TipoEntidad.CONACYT,
      nombre: null,
      anio: null,
    };
  }

  public static getLogroOtros(): Entities.Logro {
    return {
      tipo: Entities.TipoEntidad.OTROS,
      anio: null,
      distincion: null,
      institucion: this.getInstitucionModel(),
      pais: null,
      descripcion: null,
    };
  }

  public static getIdioma(): Entities.Idioma {
    return {
      nombre: null,
      dominio: null,
      conversacion: null,
      lectura: null,
      escritura: null,
      esCertificado: false,
      nombreInstitucion: null,
      fechaEvaluacion: null,
      documentoProbatorio: null,
      inicioVigencia: null,
      finVigencia: null,
      puntuacion: null,
      nivelConferido: null,
    };
  }

  public static getLengua(): Entities.Lengua {
    return {
      nombre: null,
      dominio: null,
      conversacion: null,
      lectura: null,
      escritura: null,
      esCertificado: false,
      fechaEvaluacion: null,
      documentoProbatorio: null,
      inicioVigencia: null,
      finVigencia: null,
      puntuacion: null,
    };
  }

  public static getEmpleo(): Entities.Empleo {
    return {
      esActual: false,
      esPrincipal: false,
      perfil: null,
      institucion: this.getInstitucionModel(),
      fechaInicio: null,
      fechaFin: null,
      nombramiento: null,
      logros: null,
      documento: null,
    };
  }

  public static getEstancia(): Entities.Estancia {
    return {
      institucion: this.getInstitucionModel(),
      nombre: null,
      fechaInicio: null,
      fechaFin: null,
      tipo: null,
      logros: null,
      scian: this.getScianPerfil(),
      ocde: this.getOcdePerfil(),
    };
  }

  public static getEvaluacion(): Entities.Evaluacion {
    return {
      tipo: this.getDefaultCatalogo(),
      nombreFondo: null,
      fechaAsignacion: null,
      fechaAceptacion: null,
      fechaEvaluacion: null,
      descripcion: null,
      nombreInstitucion: null,
      productoEvaluado: this.getDefaultCatalogo(),
      tipoEvaluacion: this.getDefaultCatalogo(),
      nombreProducto: null,
      fechaInicio: null,
      fechaFin: null,
      cargo: null,
    };
  }

  public static getEvaluacionConacyt(): Entities.Evaluacion {
    return {
      tipo: Entities.TipoEntidad.CONACYT,
      nombreFondo: null,
      fechaAsignacion: null,
      fechaAceptacion: null,
      fechaEvaluacion: null,
      descripcion: null,
    };
  }

  public static getEvaluacionOtros(): Entities.Evaluacion {
    return {
      tipo: Entities.TipoEntidad.OTROS,
      nombreInstitucion: null,
      productoEvaluado: null,
      tipoEvaluacion: null,
      nombreProducto: null,
      fechaInicio: null,
      fechaFin: null,
      cargo: null,
    };
  }

  public static getProgramaSnp(): Entities.ProgramaSnp {
    return {
      claveSede: null,
      claveDependencia: null,
      referenciaPrograma: null,
    };
  }

  public static getCursoImpartido(): Entities.CursoImpartido {
    return {
      tipoPrograma: Entities.TipoPrograma.SNP,
      claveInstitucionSnp: null,
      programaSnp: null,
      nombreCurso: null,
      horasTotales: null,
      fechaInicio: null,
      fechaFin: null,
      nivelEscolaridad: null,
      institucion: null,
      nombrePrograma: null,
      productoPrincipal: null,
    };
  }

  public static getTesisDirigida(): Entities.TesisDirigida {
    return {
      tipoTrabajoTitulacion: null,
      tipoPrograma: Entities.TipoPrograma.SNP,
      claveInstitucionSnp: null,
      programaSnp: this.getProgramaSnp(),
      titulo: null,
      estado: null,
      fechaAprobacion: null,
      fechaObtencionGrado: null,
      nombre: null,
      primerApellido: null,
      segundoApellido: null,
      gradoAcademico: null,
      rol: null,
      institucion: null,
      productoPrincipal: null,
    };
  }

  public static getDocumentoProbatorio(): Entities.DocumentoProbatorio {
    return {
      documento: {
        nombre: '',
        uri: '',
      },
    };
  }

  public static getArchivo(): Entities.Archivo {
    return {
      nombre: '',
      uri: '',
    };
  }

  public static getDiplomado(): Entities.Diplomado {
    return {
      institucion: this.getInstitucionModel(),
      nombreDiplomado: null,
      nombreCurso: null,
      anio: null,
      horasTotales: null,
      productoPrincipal: null,
    };
  }

  public static getCongreso(): Entities.Congreso {
    return {
      nombre: null,
      tituloTrabajo: null,
      tipoParticipacion: null,
      pais: null,
      fecha: null,
      productoPrincipal: false,
    };
  }

  public static getTrabajoDivulgacion(): Entities.TrabajoDivulgacion {
    return {
      tituloTrabajo: null,
      tipoParticipacion: null,
      tipoEvento: null,
      nombreInstitucion: null,
      dirigidoA: null,
      fecha: null,
      tipoDivulgacion: null,
      tipoMedio: null,
      notasPeriodisticas: null,
      productoObtenido: [],
      productoPrincipal: false,
    };
  }

  public static setAreaConocimiento(): Entities.AreaConocimiento {
    return {
      area: this.getAreaModelo(),
      campo: this.getAreaModelo(),
      disciplina: this.getAreaModelo(),
      subdisciplina: this.getAreaModelo(),
    };
  }

  public static setInstitucion(): any {
    return {
      buscadorInstitucion: '',
      tipoInstitucion: {
        id: '01',
        nombre: 'Nacional',
      },
      publicaPrivada: this.getDefaultPublicaPrivada(),
      pais: this.getDefaultCatalogo(),
      entidad: this.getDefaultCatalogoClave(),
      municipio: this.getDefaultCatalogoClave(),
      institucion: this.getDefaultCatalogoClave(),
      institucionManual: this.getDefaultCatalogoClaveInstitucion(),
    };
  }

  public static getInstitucionSinTipo() {
    return {
      publicaPrivada: null,
      pais: null,
      entidad: null,
      municipio: null,
      institucion: null,
    };
  }

  public static getInstitucionModel(): Entities.Institucion {
    return {
      id: null,
      clave: null,
      nombre: null,
      tipo: null,
      pais: null,
      entidad: null,
      municipio: null,
      nivelUno: null,
      nivelDos: null,
    };
  }

  public static getOcdePerfil(): Entities.Ocde {
    return {
      sector: null,
      division: null,
      grupo: null,
      clase: null,
    };
  }

  public static getScianPerfil(): Entities.Scian {
    return {
      sector: null,
      subsector: null,
      rama: null,
      subrama: null,
      clase: null,
    };
  }

  public static getRangosPersonasMexico() {
    return [
      { value: EntitiesAportaciones.RangoPersonasMexico.CIEN_A_MIL, text: '100-1000' },
      { value: EntitiesAportaciones.RangoPersonasMexico.MIL_UNO_A_DIEZ_MIL, text: '1001-10000' },
      { value: EntitiesAportaciones.RangoPersonasMexico.DIEZ_MIL_UNO_A_CIEN_MIL, text: '10001-100000' },
      { value: EntitiesAportaciones.RangoPersonasMexico.MAS_DE_CIEN_MIL, text: '+100001' },
    ];
  }

  public static Tipos() {
    return [{ value: EntitiesAportaciones.Tipo.CIENTIFICA }, { value: EntitiesAportaciones.Tipo.DIFUSION }];
  }

  public static getOcde(): EntitiesAportaciones.Ocde {
    return {
      sector: null,
      division: null,
      grupo: null,
      clase: null,
    };
  }

  public static getScian(): EntitiesAportaciones.Scian {
    return {
      sector: null,
      subsector: null,
      rama: null,
      subrama: null,
      clase: null,
    };
  }

  public static getCitas(): EntitiesAportaciones.Cita {
    return {
      urlCita: null,
      citaA: null,
      citaB: null,
      totalCitas: null,
    };
  }

  public static getArticulo(): EntitiesAportaciones.Articulo {
    return {
      eje: null,
      apoyo: this.getApoyoConacyt(),
      issn: null,
      issnElectronico: null,
      doi: null,
      nombreRevista: null,
      titulo: null,
      anio: null,
      productoPrincipal: false,
      rolParticipacion: null,
      estado: null,
      objetivo: null,
      cita: this.getCitas(),
      tipo: null,
      autores: [],
      documento: null,
    };
  }

  public static getLibro(): EntitiesAportaciones.Libro {
    // TODO COMPLETAR INICIALIZACION
    return {
      eje: null,
      apoyo: this.getApoyoConacyt(),
      isbn: null,
      doi: null,
      titulo: null,
      anio: null,
      pais: null,
      idioma: null,
      editorial: null,
      isbnTraducido: null,
      idiomaTraducido: null,
      tituloTraducido: null,
      rolParticipacion: null,
      estado: null,
      objetivo: null,
      estaDictaminado: false,
      productoPrincipal: false,
      cita: this.getCitas(),
      tipo: null,
      autores: [],
      documento: null,
    };
  }

  public static getCapitulo(): EntitiesAportaciones.Capitulo {
    return {
      eje: null,
      apoyo: this.getApoyoConacyt(),
      isbn: null,
      doi: null,
      tituloLibro: null,
      anio: null,
      editorial: null,
      titulo: null,
      numero: null,
      rolParticipacion: null,
      estado: null,
      objetivo: null,
      estaDictaminado: false,
      productoPrincipal: false,
      cita: this.getCitas(),
      tipo: null,
      autores: [],
      documento: null,
    };
  }

  public static getColaboracion(): any {
    return {
      tipo: null,
      url: null,
      opcionesSisdai: [],
      descripcion: null,
    };
  }

  public static getApoyoConacyt(): EntitiesAportaciones.Apoyo {
    return {
      recibioApoyoConacyt: false,
      fondoPrograma: null,
    };
  }

  public static getcatISSN(translateFunction: (key: string) => string): any {
    return [
      { value: { id: '1', nombre: 'Impreso' }, text: translateFunction('forms.issn.impreso') },
      { value: { id: '2', nombre: 'Electrónico' }, text: translateFunction('forms.issn.electronico') },
      { value: { id: '3', nombre: 'Ambos' }, text: translateFunction('forms.issn.ambos') },
    ];
  }

  public static getTieneIssn(): any {
    return {
      id: '',
      nombre: '',
    };
  }

  public static getCatEniGemaSisdai(translateFunction: (key: string) => string): any {
    return [
      { value: { id: '1', nombre: 'ENI' }, text: translateFunction('eni_gema_sisdai.subtitle_eni') },
      { value: { id: '2', nombre: 'Gema' }, text: translateFunction('eni_gema_sisdai.subtitle_gema') },
      { value: { id: '3', nombre: 'Sisdai' }, text: translateFunction('eni_gema_sisdai.subtitle_sisdai') },
    ];
  }

  public static catOpcionesSisdai(translateFunction: (key: string) => string): any {
    return [
      { value: { id: '1', nombre: 'componentes' }, text: translateFunction('eni_gema_sisdai.sisdai.componentes') },
      { value: { id: '2', nombre: 'css' }, text: translateFunction('eni_gema_sisdai.sisdai.css') },
      { value: { id: '3', nombre: 'graficas' }, text: translateFunction('eni_gema_sisdai.sisdai.graficas') },
      { value: { id: '4', nombre: 'mapas' }, text: translateFunction('eni_gema_sisdai.sisdai.mapas') },
    ];
  }

  public static getOpcionEniGemaSisdai(): any {
    return {
      id: '',
      nombre: '',
    };
  }

  public static getConsideraciones() {
    return {
      consideracionesAcademicas: null,
      participacion: null,
      fortalecimientoComunidad: null,
      accesoUniversal: null,
      investigacionFrontera: null,
    };
  }

  public static getcatAgregarProductosOptions(translateFunction: (key: string) => string): any {
    return [
      { value: { id: '1', nombre: 'Desarrollo tecnologico' }, text: translateFunction('forms.producto.desarrollo') },
      { value: { id: '2', nombre: 'Innovacion' }, text: translateFunction('forms.producto.innovacion') },
    ];
  }

  public static tipoAvisoEnum() {
    return {
      AVISO_PRIVACIDAD_INTEGRAL: Entities.TipoAviso.AVISO_PRIVACIDAD_INTEGRAL,
      AVISO_PRIVACIDAD_SIMPLIFICADO: Entities.TipoAviso.AVISO_PRIVACIDAD_SIMPLIFICADO,
    };
  }

  public static getAviso(): Entities.Aviso {
    return {
      id: null,
      fechaPublicacion: null,
      cvu: null,
      fechaAceptacion: null,
      tipo: null,
      aceptado: false,
      aceptaTransferencia: false,
    };
  }
  public static getContactoEmergencia() {
    return {
      id: null,
      nombre: null,
      primerApellido: null,
      segundoApellido: null,
      parentesco: null,
      sexo: null,
      fechaNacimiento: null,
      correo: null,
      confirmCorreo: null,
      lada: null,
      telefono: null,
      celular: null,
      tipoContacto: null,
      domicilio: this.getContactoEmergenciaDomicilio(),
    };
  }

  public static getContactoEmergenciaDomicilio() {
    return {
      pais: null,
      estado: null,
      ciudad: null,
      codigoPostal: null,
      localidad: null,
      municipio: null,
      asentamiento: null,
      tipoAsentamiento: null,
      tipoUbicacion: null,
      cpExtranjero: null,
      estadoExtranjero: null,
      ciudadExtranjero: null,
      condadoExtranjero: null,
      calleExtranjero: null,
      numeroExtranjero: null,
      referenciasExtranjero: null,
    };
  }

  // Contactos de emergencia
  public static getCatParentesco(translateFunction: (key: string) => string): any {
    return [
      { value: { id: '1', nombre: 'Madre' }, text: translateFunction('forms.parentesco.madre') },
      { value: { id: '2', nombre: 'Padre' }, text: translateFunction('forms.parentesco.padre') },
      { value: { id: '3', nombre: 'Hermana' }, text: translateFunction('forms.parentesco.hermana') },
      { value: { id: '4', nombre: 'Hermano' }, text: translateFunction('forms.parentesco.hermano') },
      { value: { id: '5', nombre: 'Cónyuge' }, text: translateFunction('forms.parentesco.conyuge') },
      { value: { id: '6', nombre: 'Hija' }, text: translateFunction('forms.parentesco.hija') },
      { value: { id: '7', nombre: 'Hijo' }, text: translateFunction('forms.parentesco.hijo') },
    ];
  }

  // Dependientes Perfil
  public static getCatParentescoDependiente(translateFunction: (key: string) => string): any {
    return [
      { value: { id: '1', nombre: 'Hija' }, text: translateFunction('forms.parentesco.hija') },
      { value: { id: '2', nombre: 'Hijo' }, text: translateFunction('forms.parentesco.hijo') },
      { value: { id: '3', nombre: 'Cónyuge' }, text: translateFunction('forms.parentesco.conyuge') },
      { value: { id: '4', nombre: 'Concubino' }, text: translateFunction('forms.parentesco.concubino') },
      { value: { id: '5', nombre: 'Hija mayor de edad' }, text: translateFunction('forms.parentesco.hija-mayor-edad') },
      { value: { id: '6', nombre: 'Hijo mayor de edad' }, text: translateFunction('forms.parentesco.hijo-mayor-edad') },
      { value: { id: '7', nombre: 'Padre' }, text: translateFunction('forms.parentesco.padre') },
      { value: { id: '8', nombre: 'Madre' }, text: translateFunction('forms.parentesco.madre') },
    ];
  }

  public static getDependientesEconomicos(): Entities.DependienteEconomico {
    return {
      curp: null,
      orden: null,
      nombre: null,
      primerApellido: null,
      segundoApellido: null,
      fechaNacimiento: null,
      sexo: null,
      parentesco: null,
      esExtranjero: null,
    };
  }

  public static getCatFamiliar(translateFunction: (key: string) => string): any {
    return [
      { value: { id: '1', nombre: 'Madre' }, text: translateFunction('forms.parentesco.madre') },
      { value: { id: '2', nombre: 'Padre' }, text: translateFunction('forms.parentesco.padre') },
      { value: { id: '3', nombre: 'Hermana' }, text: translateFunction('forms.parentesco.hermana') },
      { value: { id: '4', nombre: 'Hermano' }, text: translateFunction('forms.parentesco.hermano') },
      { value: { id: '5', nombre: 'Conyuge' }, text: translateFunction('forms.parentesco.conyuge') },
      { value: { id: '6', nombre: 'Hijo' }, text: translateFunction('forms.parentesco.hijo') },
      { value: { id: '7', nombre: 'Hija' }, text: translateFunction('forms.parentesco.hija') },
      { value: { id: '8', nombre: 'ninguno' }, text: translateFunction('forms.parentesco.ninguno') },
      { value: { id: '9', nombre: 'Concubinario' }, text: translateFunction('forms.parentesco.concubinario') },
      { value: { id: '10', nombre: 'Concubina' }, text: translateFunction('forms.parentesco.concubinario') },
      { value: { id: '11', nombre: 'Hijo mayor de edad' }, text: translateFunction('forms.parentesco.hijo-mayor-edad') },
      { value: { id: '12', nombre: 'Hija mayor de edad' }, text: translateFunction('forms.parentesco.hija-mayor-edad') },
    ];
  }

  public static getCatOcupacion(translateFunction: (key: string) => string): any {
    return [
      { value: { id: '1', nombre: 'Ama de casa' }, text: translateFunction('forms.ocupacion.ama-de-casa') },
      { value: { id: '2', nombre: 'Profesionistas' }, text: translateFunction('forms.ocupacion.profesionistas') },
      { value: { id: '3', nombre: 'Tecnicos' }, text: translateFunction('forms.ocupacion.tecnicos') },
      { value: { id: '4', nombre: 'Trabajadores de la educacion' }, text: translateFunction('forms.ocupacion.educacion') },
      { value: { id: '4', nombre: 'Trabajadores artes' }, text: translateFunction('forms.ocupacion.artes') },
      { value: { id: '5', nombre: 'Trabajadores agricolas' }, text: translateFunction('forms.ocupacion.profesionistas') },
      { value: { id: '6', nombre: 'Trabajadores artesanos' }, text: translateFunction('forms.ocupacion.artesanos') },
      { value: { id: '7', nombre: 'Trabajadores fabriles' }, text: translateFunction('forms.ocupacion.fabriles') },
      { value: { id: '8', nombre: 'Trabajadores administrativos' }, text: translateFunction('forms.ocupacion.administrativos') },
      { value: { id: '9', nombre: 'Trabajadores personales' }, text: translateFunction('forms.ocupacion.personales') },
      { value: { id: '10', nombre: 'Trabajadores vigilancia' }, text: translateFunction('forms.ocupacion.vigilancia') },
      { value: { id: '11', nombre: 'Comerciantes' }, text: translateFunction('forms.ocupacion.comerciantes') },
      { value: { id: '12', nombre: 'Conductores' }, text: translateFunction('forms.ocupacion.conductores') },
      { value: { id: '13', nombre: 'Operadores' }, text: translateFunction('forms.ocupacion.operadores') },
      { value: { id: '14', nombre: 'Funcionarios' }, text: translateFunction('forms.ocupacion.funcionarios') },
      { value: { id: '15', nombre: 'Desempleados' }, text: translateFunction('forms.ocupacion.desempleados') },
    ];
  }

  public static getCambioEmail(): any {
    return {
      emailNuevo: null,
      confirmacionEmail: null,
    };
  }

  public static geListaIncidentesOptions(translateFunction: (key: string) => string): any {
    return [
      {
        value: 'Sale el mensaje “ocurrió un error por favor ingrese más tarde”',
        text: translateFunction('forms.incidentes.ingrese-despues'),
      },
      { value: 'Sale el mensaje “error interno en el servidor”', text: translateFunction('forms.incidentes.error-servidor') },
      { value: 'No aparece la entidad federativa', text: translateFunction('forms.incidentes.error-entidad') },
      { value: 'La fecha de Nacimiento no coincide con la CURP', text: translateFunction('forms.incidentes.error-curp') },
      {
        value: 'No aparece el teléfono móvil en la solicitud y está registrado en PU',
        text: translateFunction('forms.incidentes.error-telefono'),
      },
      { value: 'No aparece la institución', text: translateFunction('forms.incidentes.error-institucion') },
      {
        value: 'No se visualiza en la solicitud el total de artículos registrados en PU',
        text: translateFunction('forms.incidentes.error-articulo'),
      },
      {
        value: 'No aparece el estudiante para registrarlo en la matrícula del programa',
        text: translateFunction('forms.incidentes.error-estudiante'),
      },
      {
        value: 'El aspirante no puede registrarse, le sale el mensaje “hemos detectado que no se encuentra registrado en un programa”',
        text: translateFunction('forms.incidentes.error-aspirante'),
      },
    ];
  }

  public static getTemplateOptions(translateFunction: (key: string) => string): any {
    return [
      { id: 1, value: Entities.Template.COMPLETO, name: translateFunction('mi-perfil.template.completo') },
      { id: 2, value: Entities.Template.RESUMEN, name: translateFunction('mi-perfil.template.resumen') },
      { id: 3, value: Entities.Template.TARJETA, name: translateFunction('mi-perfil.template.tarjeta') },
    ];
  }

  public static getListaSeccionesOptions(translateFunction: (key: string) => string): any {
    return [
      {
        value: 'Solicitud',
        text: translateFunction('global.sugerencia.solicitud'),
      },
      {
        value: 'Cuenta usuario',
        text: translateFunction('global.sugerencia.cuenta'),
      },
      {
        value: 'Acerca de',
        text: translateFunction('global.sugerencia.acerca'),
      },
      {
        value: 'Educación',
        text: translateFunction('global.sugerencia.educacion'),
      },
      {
        value: 'Trayectoria Profesional',
        text: translateFunction('global.sugerencia.trayectoria'),
      },
      {
        value: 'Otro',
        text: translateFunction('global.sugerencia.otro'),
      },
    ];
  }

  public static getModalPULabel(translateFunction: (key: string) => string): any {
    return [
      {
        key: 'name',
        label: translateFunction('global.modal-pu.table.name'),
      },
      {
        key: 'action',
        label: translateFunction('global.modal-pu.table.action'),
      },
    ];
  }
}
