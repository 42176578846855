import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';
@Component({
  computed: {
    ...mapGetters(['account']),
  },
})
export default class DataUserComponent extends Vue {
  public account;
  public mainProps = {
    blank: true,
    blankColor: '#777',
    width: 75,
    height: 75,
    class: 'm1',
  };

  public scrollPosition: number = window.scrollY;

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  private handleScroll() {
    this.scrollPosition = window.scrollY;
  }

  public get foto(): string {
    const uri = this.$store.getters.perfil?.fotografia?.uri ?? '';
    return uri != '' ? `${uri}/preview` : '../../../content/images/foto-empty1.svg';
  }
}
