import Component, { mixins } from 'vue-class-component';
import PropertyCommons from '../commons/property.model';
import { Inject, Prop, PropSync, Ref, Watch } from 'vue-property-decorator';
import { required, minLength, maxLength, helpers, numeric, requiredIf } from 'vuelidate/lib/validators';
import CatalogoService from '@/shared/catalogo/catalogo.service';
import type AlertaComponent from '@/components/alerta/alerta.component';
import isEqual from 'lodash.isequal';

const CORREO = helpers.regex('correo', /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);

const VALIDATIONS = function () {
  return {
    nuevoMedioDeContacto: {
      tipo: {
        id: {},
        nombre: {},
        required,
      },
      correo: {
        required: requiredIf(() => this.correoBandera),
        CORREO,
      },
      telefono: {
        required: requiredIf(() => !this.correoBandera),
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      principal: {},
      compartir: {},
    },
  };
};

@Component({
  validations: VALIDATIONS,
})
export default class ContactoComponent extends mixins(PropertyCommons) {
  @Inject('catalogoService') private catalogoService: () => CatalogoService;

  @Ref()
  alerta!: AlertaComponent;

  public catTipoDeContacto: Array<any> = [];
  correoBandera = false;
  public labelTipo = this.$t('contacto.labelCorreoTelefono');
  public placeholderTipo = this.$t('contacto.labelCorreoTelefono');
  public readonlyTipo = true;

  @PropSync('value', { type: Array, default: () => [] })
  public mediosDeContacto;

  @Prop({ required: false, default: null })
  index: number;

  @Watch('index', { immediate: true, deep: true })
  handler(value: any) {
    if (value || value == 0) {
      this.prepareContacto(this.mediosDeContacto);
    }
  }

  public editingIndex = null;
  public isSaving = false;
  public alertaContactoId;

  mounted() {
    this.getTipoContacto();
    this.alertaContactoId = document.querySelector(`[id="tipo-id"]`);
  }

  public getTipoContacto() {
    this.catalogoService()
      .get('getTipoContactos')
      .then(res => {
        this.catTipoDeContacto = res;
      });
  }

  protected nuevoMedioDeContacto: {
    tipo: any;
    correo?: any;
    telefono?: any;
    principal?: any;
    compartir: any;
    valor: any;
  } = {
    tipo: null,
    correo: null,
    telefono: null,
    principal: false,
    compartir: false,
    valor: null,
  };

  @Prop({ default: '' })
  title: string;

  @Prop({ required: false, default: 1 })
  public readonly min!: number;

  @Prop({ required: false, default: false, type: Boolean })
  public readonly readonly!: boolean;

  //Se crean modelos y se envian al CARD y EMIT
  public agregaTelefono(): void {
    if (!this.$v.nuevoMedioDeContacto.$invalid) {
      if (this.validaDuplicados(this.nuevoMedioDeContacto)) {
        this.alerta.mostrar(this.$t('contacto.alert.duplicate'), 'warning', 20);
        this.alertaContactoId.scrollIntoView({ block: 'center', inline: 'center' });
      } else {
        if (this.index === null) {
          this.mediosDeContacto.push(this.creaMedioContacto(this.nuevoMedioDeContacto));
        } else {
          const nmc = this.nuevoMedioDeContacto;
          this.nuevoMedioDeContacto.valor = this.tipoContacto(nmc.tipo) ? nmc.correo : nmc.telefono;
          this.$set(this.mediosDeContacto, this.index, this.creaMedioContacto(nmc));
        }
        this.resetForm();
      }
    } else {
      this.$v.$touch();
    }
  }

  //Crea modelo que se va a guardar
  private creaMedioContacto(contacto) {
    return {
      tipo: {
        id: contacto.tipo.id,
        nombre: contacto.tipo.nombre,
      },
      valor: this.tipoContacto(contacto.tipo) ? contacto.correo : contacto.telefono,
      principal: contacto.principal,
      compartir: contacto.compartir,
    };
  }

  public resetForm(): void {
    this.nuevoMedioDeContacto.tipo = null;
    this.nuevoMedioDeContacto.correo = null;
    this.nuevoMedioDeContacto.telefono = null;
    this.nuevoMedioDeContacto.principal = false;
    this.nuevoMedioDeContacto.compartir = false;
    this.nuevoMedioDeContacto.valor = null;
    this.labelTipo = this.$t('contacto.labelCorreoTelefono');
    this.placeholderTipo = this.$t('contacto.labelCorreoTelefono');
    this.readonlyTipo = true;
    this.editingIndex = null;
    setTimeout(() => {
      this.$v.nuevoMedioDeContacto.$reset();
    }, 10);
    this.mostrarFormContactoChange();
  }

  //recibe el tipo de contacto para etiquetas y placehholder
  public changeTipo(e): void {
    this.readonlyTipo = false;
    this.nuevoMedioDeContacto.principal = false;
    this.nuevoMedioDeContacto.correo = null;
    this.nuevoMedioDeContacto.telefono = null;
    this.nuevoMedioDeContacto.compartir = false;
    if (this.tipoContacto(e)) {
      this.correoBandera = true;
      this.labelTipo = this.$t('contacto.correo.label');
      this.placeholderTipo = this.$t('contacto.correo.placeholder');
    } else {
      this.correoBandera = false;
      this.labelTipo = this.$t('contacto.telefono.label');
      this.placeholderTipo = this.$t('contacto.telefono.placeholder');
    }
  }

  //retorna el valor correcto para mostrar en el CARD
  public resVal(e) {
    if (e.valor) {
      return e.valor;
    } else if (e.correo) {
      return e.correo;
    } else {
      return e.telefono;
    }
  }
  //TODO modificar comparacion de id cuando se tengan los catalogos finales
  public tipoContacto(e) {
    if (e.id == 'COR' || e.id == '1') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * validaDuplicados
   * this.mediosDeContacto.some(v => v.text == val) busca dentro del arreglo coicidencias y devuelve true o false
   */
  public validaDuplicados(c) {
    c.valor = this.tipoContacto(c.tipo) ? c.correo : c.telefono;
    const val = this.tipoContacto(c.tipo) ? this.resVal(c) : this.resVal(c);
    return this.mediosDeContacto.some(m => isEqual(m, val));
  }

  //seteo de información al formulario de la información que viene del padre
  public prepareContacto(contacto) {
    if (this.index || this.index == 0) {
      this.nuevoMedioDeContacto.principal = contacto[this.index].principal;
      this.nuevoMedioDeContacto.tipo = contacto[this.index].tipo;
      this.readonlyTipo = false;
      if (this.tipoContacto(contacto[this.index].tipo)) {
        this.correoBandera = true;
        this.labelTipo = this.$t('contacto.correo.label');
        this.placeholderTipo = this.$t('contacto.correo.placeholder');
        this.nuevoMedioDeContacto.correo = contacto[this.index].valor;
        this.nuevoMedioDeContacto.tipo = {
          id: 'COR',
          nombre: 'Correo electrónico',
        };
      } else {
        this.correoBandera = false;
        this.labelTipo = this.$t('contacto.telefono.label');
        this.placeholderTipo = this.$t('contacto.telefono.placeholder');
        this.nuevoMedioDeContacto.telefono = contacto[this.index].valor;
        if (this.nuevoMedioDeContacto.tipo.id == 2 || this.nuevoMedioDeContacto.tipo.id == 'TEL') {
          this.nuevoMedioDeContacto.tipo = {
            id: 'TEL',
            nombre: 'Teléfono',
          };
        } else {
          this.nuevoMedioDeContacto.tipo = {
            id: 'MOV',
            nombre: 'Móvil',
          };
        }
      }
    }
  }

  //acepta solo numeros
  numbersOnly(evt) {
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault();
    } else {
      return true;
    }
  }

  //emit a componente padre
  public mostrarFormContactoChange() {
    this.$emit('mostrarFormContactoChange');
  }

  //Valida diferencia de objetos para habilitar el boton guardar en la edición
  public esIgual(ac) {
    if (this.tipoContacto(this.mediosDeContacto[this.index].tipo)) {
      return (
        this.mediosDeContacto[this.index].principal == ac.principal &&
        this.mediosDeContacto[this.index].valor == ac.correo &&
        this.mediosDeContacto[this.index].tipo.id == ac.tipo.id
      );
    } else {
      return (
        this.mediosDeContacto[this.index].principal == ac.principal &&
        this.mediosDeContacto[this.index].valor == ac.telefono &&
        this.mediosDeContacto[this.index].tipo.id == ac.tipo.id
      );
    }
  }
}
