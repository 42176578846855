import { Component, Inject, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import { maxLength, required, requiredIf } from 'vuelidate/lib/validators';
import isEqual from 'lodash.isequal';
import type AlertaComponent from '@/components/alerta/alerta.component';
import CatalogoService from '@/shared/catalogo/catalogo.service';
import EntityFactory from '@/shared/entity-commons/entity.factory';

const VALIDATIONS = function () {
  return {
    nuevoActor: {
      nombre: {
        required,
        maxLength: maxLength(100),
      },
      primerApellido: {
        required,
        maxLength: maxLength(60),
      },
      segundoApellido: {
        maxLength: maxLength(60),
      },
      rol: {
        required,
      },
    },
  };
};

@Component({
  validations: VALIDATIONS,
})
export default class ActoresTecnoComponent extends Vue {
  @Inject('catalogoService') private catalogoService: () => CatalogoService;
  @Ref()
  alerta!: AlertaComponent;
  //reactividad retorna valores al padre
  @PropSync('value', { type: Array, default: () => [] })
  public actores: Array<any>;

  //propiedad para solo vista
  @Prop({ default: false })
  public readonly: boolean;

  // validacion del padre
  @Prop({ default: false })
  public validAuthors: boolean;

  //cantidad de registros por pagina en tabla
  @Prop({ default: 10 })
  public recordsPage: number;

  //tipo de pantalla 1: Desarrollo Tec, 2: Propiedad Intelectal, 3: Transferencia Tecnologica
  @Prop({ default: 1 })
  public tipo: number;

  @Prop({ default: false })
  public required: boolean;

  /**
   *validComponent formulario desde el componente (padre)
   *
   */
  @Watch('validAuthors', { immediate: true, deep: true })
  handler(value: any) {
    if (value != null) this.validAutorComponent = !value;
  }

  public validAutorComponent = false; // valida dentro del componente

  public rolesOptions: Array<any> = [];
  public removeIndex = null;

  //variables paginacion tabla
  public currentPage = 1;
  public sortBy = 'nombre';
  public sortDesc = false;
  public sortDirection = 'asc';

  public fieldsActor = [
    {
      key: 'nombre',
      label: this.$t('actores-tecno.table.nombre').toString(),
      class: 'text-center',
      sortable: true,
    },
    {
      key: 'primerApellido',
      label: this.$t('actores-tecno.table.primerApellido').toString(),
      class: 'text-center',
      sortable: true,
    },
    {
      key: 'segundoApellido',
      label: this.$t('actores-tecno.table.segundoApellido').toString(),
      class: 'text-center',
      sortable: true,
    },
    { key: 'rol.nombre', label: this.$t('actores-tecno.table.rol').toString(), class: 'text-center', sortable: true },
    { key: 'acciones', label: this.$t('actores-tecno.table.acciones').toString(), class: 'text-center' },
  ];

  public nuevoActor: {
    nombre?: any;
    primerApellido?: any;
    segundoApellido?: any;
    rol?: any;
  } = {
    nombre: null,
    primerApellido: null,
    segundoApellido: null,
    rol: null,
  };

  mounted() {
    if (this.readonly) this.fieldsActor.pop();
    this.initCatalogos();
    this.actualizaIndices();
  }

  public initCatalogos(): void {
    this.rolesOptions = [];
    if (this.tipo == 1) {
      this.catalogoService()
        .get('getCatRolParticipacionTecs')
        .then(result => {
          this.rolesOptions = result;
        });
    } else if (this.tipo == 3 || this.tipo == 2) {
      this.catalogoService()
        .get('getCatRolParticipacionPis')
        .then(result => {
          this.rolesOptions = result;
        });
    }
  }

  //Se crean modelos y se envian al CARD y EMIT
  public agregaAutor(): void {
    if (!this.$v.nuevoActor.$invalid) {
      if (this.validaDuplicados(this.nuevoActor)) {
        this.alerta.mostrar(
          this.tipo == 2 ? this.$t('actores-tecno.alert.duplicate2') : this.$t('actores-tecno.alert.duplicate'),
          'warning',
          20
        );
      } else {
        const form = JSON.parse(JSON.stringify(this.nuevoActor));
        if (this.removeIndex === null) {
          this.actores.push(form);
          this.actualizaIndices();
          this.alerta.mostrar(this.tipo == 2 ? this.$t('actores-tecno.alert.add2') : this.$t('actores-tecno.alert.add'), 'success', 20);
        } else {
          const index = this.actores.findIndex(autor => autor.indice === this.removeIndex);
          this.$set(this.actores, index, form);
          this.removeIndex = null;
          this.actualizaIndices();
          this.alerta.mostrar(this.tipo == 2 ? this.$t('actores-tecno.alert.edit2') : this.$t('actores-tecno.alert.edit'), 'success', 20);
        }
        this.resetForm();
      }
      this.validaArregloActores(this.actores);
    } else {
      this.$v.$touch();
    }
  }

  public validaDuplicados(val) {
    return this.actores.some(g => isEqual(g, val));
  }

  public prepareActor(item, index) {
    this.removeIndex = index;
    this.nuevoActor = this.shallowCopy(item);
  }

  public eliminaAutor(index): void {
    this.actores.splice(index, 1);
    this.$bvModal.hide('removeAutor');
    this.alerta.mostrar(this.$t('actores-tecno.alert.delete'), 'warning', 20);
    this.validaArregloActores(this.actores);
    const totalPage = Math.ceil(this.actores.length / this.recordsPage);
    if (this.currentPage > totalPage) this.currentPage = totalPage;
    this.resetForm();
  }

  public prepareRemove(index: any): void {
    this.removeIndex = index;
    this.$bvModal.show('removeAutor');
  }

  public remove(): void {
    this.eliminaAutor(this.removeIndex);
  }

  public resetForm() {
    this.nuevoActor.nombre = null;
    this.nuevoActor.primerApellido = null;
    this.nuevoActor.segundoApellido = null;
    this.nuevoActor.rol = null;
    setTimeout(() => {
      this.$v.nuevoActor.$reset();
    }, 1);
    this.removeIndex = null;
  }
  /** Copia las propiedades de un objeto */
  public shallowCopy(source) {
    return JSON.parse(JSON.stringify(source));
  }

  public validaArregloActores(aa) {
    return (this.validAutorComponent = aa.length == 0);
  }

  public actualizaIndices() {
    if (this.actores && this.actores.length > 0) {
      this.actores.forEach((actor, index) => {
        actor.indice = index + 1;
      });
    }
  }
}
